import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { tokenize } from 'ngx-bootstrap/typeahead';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class CorsInterceptor implements HttpInterceptor {
  constructor(private router: Router,@Inject(PLATFORM_ID) private platformId: Object){}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Check if the request is to the Google Maps API
    if (!request.url.includes('test.sagepay.com') && !request.url.includes('login')) {
      // Add the CORS header
      let token=null;
      if (isPlatformBrowser(this.platformId)) {
        token = localStorage.getItem('token');
        if (token == null || token === "") {
          token = localStorage.getItem('token_b');
        }

        if (token) {
          request = request.clone({
            setHeaders: {
              "Authorization": `${token}`
            }
          });
        }
      }
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // Check for a 401 error
        if (error.status === 401) {
          // Handle the 401 error with your business logic (e.g., logout the user, redirect to login page, etc.)
          if (isPlatformBrowser(this.platformId)) {
          localStorage.clear();
          window.location.reload();
          }
          this.router.navigate(["/"])// Your logout logic from the AuthService
          // You can also redirect to a login page or show a dialog to inform the user about the authentication error.
        }

        // Continue the error handling to the downstream error handlers
        return throwError(error);
      })
    );
  }
}
