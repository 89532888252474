import { Component, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-mdf-board',
  templateUrl: './mdf-board.component.html',
  styleUrls: ['./mdf-board.component.scss']
})
export class MdfBoardComponent {
  bsModalRef!: BsModalRef;
  modalImageUrl! : string;
  constructor(private metadataService: MetadataService,private modalService: BsModalService){}
  
  ngOnInit(): void {
    this.metadataService.setCanonicalLink();
    this.metadataService.setTitle('default');
    this.metadataService.setDescription('default');
  }
  openImageModal(template: TemplateRef<any>, url: string): void {
    this.modalImageUrl = url;
    this.bsModalRef = this.modalService.show(template, { class: 'img-modal'});
  }

  closeModal(): void {
    this.bsModalRef.hide();
  }


}
