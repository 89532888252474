import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/service/data-service';
import { environment } from 'environment.uat';

@Component({
  selector: 'app-footer-navigations',
  templateUrl: './footer-navigations.component.html',
  styleUrls: ['./footer-navigations.component.scss']
})
export class FooterNavigationsComponent {
  versionVariable : string = environment.version;
  hideFooter:boolean=false;
  searchKey=""
  constructor(private router: Router,private _dataService: DataService){
  this._dataService.getHeaderFooterFlag().subscribe((data: any) => {      
    this.hideFooter=data;
  });

  }
  findProduct(event:any){
    this.router.navigate(['/choose-your-boards'], {
      queryParams: { "searchKey": this.searchKey }
    });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

}
