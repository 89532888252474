import { Component } from '@angular/core';

@Component({
  selector: 'app-blog-message',
  templateUrl: './blog-message.component.html',
  styleUrls: ['./blog-message.component.scss']
})
export class BlogMessageComponent {

}
