import { Component } from '@angular/core';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-edging',
  templateUrl: './edging.component.html',
  styleUrls: ['./edging.component.scss']
})
export class EdgingComponent {
  constructor(private metadataService: MetadataService){
    
  }
  ngOnInit(): void {
    this.metadataService.setTitle('Edging and Banding Service - Cutwrights');
    this.metadataService.setDescription('Our highly efficient Industrial Homag edge banders are equipped with the latest technology to ensure we can offer an exceptional finish on a vast range of different edge banding options.');
    this.metadataService.setCanonicalLink();
  }

}
