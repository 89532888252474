import { Component } from '@angular/core';

@Component({
  selector: 'app-blog-reception',
  templateUrl: './blog-reception.component.html',
  styleUrls: ['./blog-reception.component.scss']
})
export class BlogReceptionComponent {

}
