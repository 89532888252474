<table align="center" border="0" cellpadding="0" cellspacing="0" height="100%" id="bodyTable" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;height:100%;margin:0;padding:0;width:100%;background-color:#FAFAFA;" width="100%">
    <tbody>
        <tr>
            <td align="center" id="bodyCell" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;height:100%;margin:0;padding:0;width:100%;border-top:0;" valign="top">
                <div class="page-container">
                    <section id="blog-datasheet" class="no-padding">
                        <div class="container blog">
                            <div class="row no-gutter">
                                <div class="col-md-7 col-sm-7 blog-box-text text-left article">
                                    <div class="img-responsive"><img src="../../../assets/images/Reception.png" alt="Cutwrights truck" style="width: 100%; height: 100%;" width="682" height="387"><br></div>
                                    <div class="date">Date: 09.07.2018, Author: Cutwrights</div>
                                    <h2>New Reception Office</h2>
                                    <p><br></p>
                                    <p><span style="color:#757575;font-family:Helvetica, sans-serif;font-size:12pt;">&nbsp;</span></p>
                                    <p><br></p>
                                    <p><span style="color:#757575;font-family:Helvetica, sans-serif;font-size:12pt;">We&apos;re renovating our ground floor office area to provide a&nbsp;</span><strong style="color:#757575;font-family:Helvetica, sans-serif;font-size:12pt;">new reception</strong><span style="color:#757575;font-family:Helvetica, sans-serif;font-size:12pt;">&nbsp;and&nbsp;</span><strong style="color:#757575;font-family:Helvetica, sans-serif;font-size:12pt;">collection point</strong><span style="color:#757575;font-family:Helvetica, sans-serif;font-size:12pt;">.</span></p>
                                    <p><span style="font-size:12.0pt;line-height:107%;font-family:'Helvetica',sans-serif;color:#757575;"><br>This area may be out of service for a week or so but the <strong>showroom is still open</strong> - just pop upstairs and see a member of the team and they will assist you.</span></p>
                                    <p><br></p>
                                    <p><br></p>
                                    <p><img src="../../../assets/images/Reception.png"alt="Cutwrights truck" style="width: 100%; height: 100%;" width="682" height="387"><br></div>
                                    <p><span style="font-size:9.0pt;line-height:107%;font-family:'Helvetica',sans-serif;color:#757575;">[Interpretation of possible reception design]</span></p>
                                    <p>&nbsp;</p>
                                    <p><span style="font-size:12.0pt;line-height:107%;font-family:'Helvetica',sans-serif;color:#757575;">During the renovation, we apologize for any inconvenience and offer our sincere thanks for your patience and support.</span></p>
                                    <p><br></p>
                                    <p><br></p>
                                    <p><span style="font-size:12.0pt;line-height:107%;font-family:'Helvetica',sans-serif;color:#757575;">If you would like any further info then please let us know on <a href="mailto:info@cutwrights.com?subject=Rebranding%20Announcement%20-%20More%20Info" target="_blank"><span style="color:#007C89;">{{"info@cutwrights.com"}}</span></a></span></p>
                                    <p><br></p>
                                    <p><br></p>
                                </div>
                                <div class="col-md-5 col-sm-5 blog-box-text text-left">
                                    <p><br></p>
                                </div>
                            </div>
                    </section>
                </div><br>
            </td>
        </tr>
    </tbody>
</table>