<section>
    <section class="banner-pages banner-bg-blog" role="banner">
        <div class="container">
            <div class="col-md-10 col-md-offset-1">
                <div class="banner-pages-text text-left">
                    <h1 style="font-size: 50px;">News</h1>

                    <h2>&nbsp;</h2>
                </div><!-- banner text -->
            </div>
        </div>
    </section>

</section><!-- banner -->
<section id="intro" class="section">
    <div class="container">
        <div class="intro">
            <span class="site-navigation"><a [routerLink]="['/']">Home</a> » Blog</span>
        </div>
    </div>
</section><!-- intro ends-->

<section id="blog" class="no-padding">
  
    <div class="container blog">
        <div class="row no-gutter">
            <div class="col-md-6 col-sm-6 blog-box-pic">
                <div class="img-responsive">
                    <img src="../../../assets/images/Main.Logo.png"
                        alt="Message from Tom and Chris on COVID-19" class="img-responsive"
                        style="width: 100%; height: 100%; filter: invert(0);" width="585" height="137">
                </div>

            </div>
            <div class="col-md-6 col-sm-6 blog-box-text text-left">
                <h3>Message from Tom and Chris on COVID-19</h3>
                <div class="date">Date: 20.03.2020, Author: Tom Wright &amp; Chris Wright</div>
                <p>Dear Valued Customer,As the world reacts to the COVID-19, we just wanted to briefly take this
                    opportunity to advise you that we are still here for you.Our warehouse and phone lines are open, and
                    although our showroom is now closed, we can still send out samples where required. DeliveriesOur
                    scheduled deliveries will continue as normal. We have advised our drivers to be as helpful as they
                    can whilst making every effort to maintain social distancing. If you are self-isolating, we would
                    appreciate </p>
                <a [routerLink]="['/blog/blog-message']" style="padding-left:20px;">
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                    Read more...
                </a>

            </div>
        </div>
    </div>
    <!-- <div class="container blog">
        <div class="row no-gutter">
            <div class="col-md-6 col-sm-6 blog-box-pic">
                <div class="img-responsive">
                    <img src="https://www.cutwrights.com/new/Home/ShowArticleImage/26055b7e-a4a2-45be-b292-1fabc5b0a575?width=585"
                        alt="Rebranding Announcement" class="img-responsive"
                        style="width: 100%; height: 100%; filter: invert(0);" width="585" height="158">
                </div>

            </div>
            <div class="col-md-6 col-sm-6 blog-box-text text-left">
                <h3>Rebranding Announcement</h3>
                <div class="date">Date: 30.01.2019, Author: Cutwrights</div>
                <p>Cutwrights have a new look!So&amp;nbsp;what's changing and when?-&amp;nbsp; &amp;nbsp;Fear not, there
                    won't be any changes to services or the way we work just a fresh look and feel to our website,
                    stationery, vans and depot signage.-&amp;nbsp; &amp;nbsp;You'll spot the new designs from Feb
                    2019.&amp;nbsp; &amp;nbsp; &amp;nbsp;We at Cutwrights&amp;nbsp;are&amp;nbsp;all about
                    precision.&amp;nbsp;It&amp;rsquo;s the attention to detail that helps you craft beautiful
                    furniture,&amp;nbsp;and&amp;nbsp;gives you the confidence to make us your board cutting s</p>
                <a [routerLink]="['/customer/blog-rebranding']" style="padding-left:20px;">
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                    Read more...
                </a>

            </div>
        </div>
    </div> -->
    <div class="container blog">
        <div class="row no-gutter">
            <div class="col-md-6 col-sm-6 blog-box-pic">
                <div class="img-responsive">
                    <img src="../../../assets/images/Reception.png"
                        alt="New Reception Office" class="img-responsive"
                        style="width: 100%; height: 100%; filter: invert(0);" width="585" height="332">
                </div>

            </div>
            <div class="col-md-6 col-sm-6 blog-box-text text-left">
                <h3>New Reception Office</h3>
                <div class="date">Date: 09.07.2018, Author: Cutwrights</div>
                <p>&amp;nbsp;We're renovating our ground floor office area to provide a&amp;nbsp;new
                    reception and collection point.This area may be out of service for a week or so
                    but the showroom is still open&amp;nbsp;- just pop upstairs and see a member of the team
                    and they will assist you.[Interpretation of possible reception design]&amp;nbsp;During the
                    renovation, we apologize for any inconvenience and offer our sincere thanks for your patience and
                    support.If you would like any further info then please let us</p>
                <a [routerLink]="['/blog/blog-reception']" style="padding-left:20px;">
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                    Read more...
                </a>

            </div>
        </div>
    </div>
    <div class="container blog">
        <div class="row no-gutter">
            <div class="col-md-6 col-sm-6 blog-box-pic">
                <div class="img-responsive">
                    <img src="../../../assets/images/discount.png"
                        alt="*NEW RANGE INTRODUCTORY OFFER* - 30% OFF Kronospan MFC" class="img-responsive"
                        style="width: 100%; height: 100%; filter: invert(0);" width="585" height="268">
                </div>

            </div>
            <div class="col-md-6 col-sm-6 blog-box-text text-left">
                <h3>*NEW RANGE INTRODUCTORY OFFER* - 30% OFF Kronospan MFC</h3>
                <div class="date">Date: 09.07.2018, Author: CUT|WRIGHTS</div>
                <p>
                    <a [routerLink]="['/blog/blog-offer']" style="padding-left:20px;">
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                        Read more...
                    </a>

            </div>
        </div>
    </div>
    <br>
</section>

<!-- newsletter sign up -->
<div style="display: none">
    <form action="/new/Account/AjaxLogin" autocomplete="off" class="popup" data-ajax="true" data-ajax-method="POST"
        data-ajax-success="onAjaxLoginSuccess" id="login_form" method="post" novalidate="novalidate">
        <h5 id="pop-login-error">Login to my cutwrights</h5>
        <p>Please fill the form below. If you forgot your password please <a id="popup-reset-password"
                href="#resetpassword_form">click here</a>.</p>
        <div id="loginAlertDiv">
        </div>
        <p>
            <label class="required" for="Email">E-mail</label>
            <input autocomplete="on" autofocus="autofocus" data-val="true" data-val-email="Invalid E-mail Address"
                data-val-required="The E-mail field is required." id="autofocusField" name="Email"
                placeholder="Probably your email address" size="30" type="text" value="">
            <span class="field-validation-valid" data-valmsg-for="Email" data-valmsg-replace="true"
                style="display:block; padding-left:25%;"></span>
        </p>
        <p>
            <label class="required" for="Password">Password</label>
            <input autocomplete="off" data-val="true" data-val-required="The Password field is required." id="Password"
                name="Password" placeholder="Your password" size="30" type="password">
            <span class="field-validation-valid" data-valmsg-for="Password" data-valmsg-replace="true"
                style="display:block; padding-left:25%;"></span>
        </p>
        <p>
            <label></label>
            <input id="loginButton" type="submit" value="Login" class="btn btn-large">
        </p>
        <p>
            <em></em>
        </p>
    </form>
</div>
<div style="display:none">
    <form action="/new/Account/ResetPassword" class="popup" data-ajax="true" data-ajax-method="POST"
        data-ajax-success="onResetPasswordSuccess" id="resetpassword_form" method="post" novalidate="novalidate">
        <h5 id="signup-error">Reset password</h5>
        <p>Please fill the form to receive a reset password email.</p>
        <div id="resetPasswordAlertDiv">
        </div>
        <p>
            <label class="required" for="Email">E-mail</label>
            <input autocomplete="on" autofocus="autofocus" data-val="true" data-val-email="Invalid E-mail Address"
                data-val-required="The E-mail field is required." id="autofocusField" name="Email"
                placeholder="Your e-mail address" size="30" type="text" value="">
            <span class="field-validation-valid" data-valmsg-for="Email" data-valmsg-replace="true"
                style="display:block; padding-left:25%;"></span>

        </p>
        <p>
            <label></label>
            <input type="submit" value="Send" class="btn btn-large">
        </p>
        <p>
            <em></em>
        </p>
    </form>
</div>
<div style="display: none">
    <form action="/new/Account/SignUp" autocomplete="off" class="popup" data-ajax="true" data-ajax-method="POST"
        data-ajax-success="onSignUpSuccess" id="signup_form" method="post" novalidate="novalidate">
        <h5 id="signup-error">Sign up</h5>
        <p>Please fill the form to receive an activation mail.</p>
        <div id="signUpAlertDiv">
        </div>
        <p>
            <label class="required" for="signupFirstName">First name:</label>
            <input autofocus="autofocus" data-val="true" data-val-required="First name is required." id="autofocusField"
                name="signupFirstName" placeholder="Your first name" size="30" type="text" value="">
            <span class="field-validation-valid" data-valmsg-for="signupFirstName" data-valmsg-replace="true"
                style="display:block; padding-left:25%;"></span>
        </p>
        <p>
            <label class="required" for="signupLastName">Last name:</label>
            <input data-val="true" data-val-required="Last name is required." id="signupLastName" name="signupLastName"
                placeholder="Your last name" size="30" type="text" value="">
            <span class="field-validation-valid" data-valmsg-for="signupLastName" data-valmsg-replace="true"
                style="display:block; padding-left:25%;"></span>
        </p>
        <p>
            <label for="signupCompany">Company:</label>
            <input id="signupCompany" name="signupCompany" placeholder="Your company name" size="30" type="text"
                value="">

        </p>
        <p>
            <label class="required" for="signupEmail">E-mail:</label>
            <input data-val="true" data-val-email="Invalid E-mail Address" data-val-required="Email is required."
                id="signupEmail" name="signupEmail" placeholder="Your email address" size="30" type="text" value="">
            <span class="field-validation-valid" data-valmsg-for="signupEmail" data-valmsg-replace="true"
                style="display:block; padding-left:25%;"></span>
        </p>
        <p>
            <label for="signupPhone">Phone Number:</label>
            <input id="signupPhone" name="signupPhone" placeholder="Your phone number" size="30" type="text" value="">
        </p>
        <p>
            <label for="signupAddress">Address:</label>
            <textarea cols="20" id="signupAddress" name="signupAddress" placeholder="Your address" rows="4"
                size="30"></textarea>
        </p>
        <p>
            <label for="signupCity">City:</label>
            <input id="signupCity" name="signupCity" placeholder="Your city" size="30" type="text" value="">
        </p>
        <p>
            <label class="required" for="signupPostcode">Postcode:</label>
            <input data-val="true" data-val-required="Postcode is required." id="signupPostcode" name="signupPostcode"
                placeholder="Your postcode" size="30" type="text" value="">
            <span class="field-validation-valid" data-valmsg-for="signupPostcode" data-valmsg-replace="true"
                style="display:block; padding-left:25%;"></span>
        </p>
        <p>
            <label class="required" for="signupPassword">Password:</label>
            <input data-val="true" data-val-required="Password is required." id="signupPassword" name="signupPassword"
                placeholder="Your password" size="30" type="text" value="">
            <span class="field-validation-valid" data-valmsg-for="signupPassword" data-valmsg-replace="true"
                style="display:block; padding-left:25%;"></span>
        </p>
        <p>

            <label for="signupType">Type:</label>
            <select id="signupType" name="signupType" style="width: 70%;">
                <option value="trade">Trade</option>
                <option value="retail">Retail</option>
            </select>
        </p>
        <p>
            <label for="signupTerms">Terms:</label>
            <input data-val="true" data-val-required="The Terms: field is required." id="signupTerms" name="signupTerms"
                type="checkbox" value="true"><input name="signupTerms" type="hidden" value="false"><span> I accept the
                terms &amp; conditions</span>
            <input id="signupTerms" name="signupTerms" type="hidden" value="False">
        </p>
        <p>
            <label for="signupQuotesNotification">Quotes notification:</label>
            <input checked="checked" data-val="true" data-val-required="The Quotes notification: field is required."
                id="signupQuotesNotification" name="signupQuotesNotification" type="checkbox" value="true"><input
                name="signupQuotesNotification" type="hidden" value="false"><span> I allow Cutwrights to send Quote
                Notifications</span>
            <input id="signupQuotesNotification" name="signupQuotesNotification" type="hidden" value="True">
        </p>
        <p>
            <label for="signupMarketing">Marketing:</label>
            <input checked="checked" data-val="true" data-val-required="The Marketing: field is required."
                id="signupMarketing" name="signupMarketing" type="checkbox" value="true"><input name="signupMarketing"
                type="hidden" value="false"><span> I allow Cutwrights to send Marketing Information</span>
            <input id="signupMarketing" name="signupMarketing" type="hidden" value="True">
        </p>
        <p>
            <a href="/new/Resources/cutwrights-term-and-condition.pdf" target="_blank">Terms &amp; Conditions</a> |
            <a href="/new/Resources/cutwrights-privacy.pdf" target="_blank">Privacy Policy</a>
        </p>
        <p>

            <label></label>
            <input type="submit" value="Sign up" class="btn btn-large">
        </p>
        <p>

            <em></em>
        </p>
    </form>


</div>