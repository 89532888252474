<section class="banner-pages banner home-banner" role="banner">
    <div class="container">
        <div class="col-md-10 col-md-offset-1">
            <div class="banner-text text-left">
                <h1>Our Precision.<br>
                    Your Craft.</h1>

                <p>We help craftspeople like you<br>
                    to produce beautifully<br>
                    tailored furniture</p>

            </div><!-- banner text -->
        </div>
    </div>
</section>

<div class="container">
    <div class="row no-gutter">
        <div class="col-md-12 col-sm-12 how-box text-center text-query-middle">
            <h3><a [routerLink]="['/customer/get-quote']">Quote 24/7</a> or Call 0208 755 4829</h3>
        </div>
    </div>
</div><!-- how we can help you -->

<section id="selectmenu" class="selectmenu no-padding">
    <div class="container">
        <div class="row no-gutter">
            <div class="col-md-4 col-sm-4 selectmenu-box text-center">
                <img src="../../../assets/images/menu-mainpage-see-our-range@2x.jpg"
                    alt="Board Range" class="img-responsive" id="img-range" width="390" height="167">
                <div class="selectmenu-box-title">
                    <a [routerLink]="['/board-range']">
                        <h3>Board Range</h3>
                    </a>
                </div>
            </div>
            <div class="col-md-4 col-sm-4 selectmenu-box text-center dropdown">
                <ul class="beam-menu beam-nav-sub dropdown-content" style="opacity: 0.7;">
                    <li><a [routerLink]="['/cnc-cutting']">Cutting</a></li>
                    <li><a [routerLink]="['/edge-banding']">Edging</a></li>
                    <li><a [routerLink]="['/cnc-drilling-service']">Drilling</a></li>
                    <li><a [routerLink]="['/other-services']">Others</a></li>
                </ul>
                <img src="../../../assets/images/menu-mainpage-services@2x.jpg"
                    alt="See Our Services" class="img-responsive" id="img-services" width="390" height="167">
                <div class="selectmenu-box-title">
                    <a class="show-beam">
                        <h3>See Our Services</h3>
                    </a>
                </div>
            </div>
            <div class="col-md-4 col-sm-4 selectmenu-box text-center">
                <img src="../../../assets/images/menu-mainpage-get-a-quote@2x.jpg"
                    alt="Get a Quote" class="img-responsive" id="img-quote" width="390" height="167">
                <div class="selectmenu-box-title">
                    <a [routerLink]="['/customer/get-quote']">
                        <h3>Get a Quote</h3>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section><!-- select menu -->
<section id="separator" class="separator-drilling no-padding">
    <div class="container">
        <div class="row no-gutter">
            <div class="col-md-12 col-sm-12 text-center separator-drilling-separator">
                <img src="../../../assets/images/image-separator-drilling.jpg" alt="" class="img-responsive">
            </div>
        </div>
    </div>
</section><!-- separator -->
<section id="whatwedo" class="whatwedo no-padding">
    <div class="container">
        <div class="row no-gutter">

            <div class="col-md-6 col-sm-12 whatwedo-box text-left">
                <h2>Cutting, Edging &amp; Drilling Service</h2>

                <p>At Cutwrights, we help craftspeople like you to produce beautiful finished products for your
                    customers.</p>

                <p>Using the latest in automated machinery, we deliver what we believe to be the “The Ultimate
                    Cutting, Edging and Drilling Service”, across a huge range of boards and finishes.</p>

                <p>Whether you’re a Carpenter, Builder, DIYer we can provide you with the components you need to
                    build bespoke furniture. We have a showroom available to inspire you which includes our hand
                    painted shaker doors, made to measure drawer boxes and handle range. Our experienced team and
                    Carpenters are on hand to offer advice and a variety of samples to go home with.</p>

                <p>Quotes can be obtained online in under a minute!</p>

                <p>Using our personal delivery service ensures your order will reach your chosen destination on
                    time. We’re here to help;&nbsp;if you only require one panel or a volume run you can be sure to
                    receive the shortest lead times, excellent customer service and the highest quality product.</p>
            </div>

            <div class="col-md-6 col-sm-12">

                <div class="showroom-main pull-left">

                    <div class="showroom-btn">
                        <a class="btn-white btn-white-large" [routerLink]="['/show-room']">Learn More</a>
                    </div>

                </div>



                <div class="showroom-box-title pull-right">



                </div>

            </div>
        </div>
    </div>
</section><!-- what we do -->

<section id="testimonials" class="testimonials no-padding">
    <div class="container">
        <div class="row no-gutter">
            <div class="col-md-12 col-sm-12 testimonials-box text-center">
                <div id="slideshow">
                    <div class="active" [ngStyle]="{'opacity': opacity}" *ngFor="let comment of comments; let i=index" [hidden]="i !== currentIndex">
                        <h4><em>{{comment.comment}}</em></h4>
                        <p>- {{comment.name}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section><!-- testimonials -->

<section id="technology" class="technology no-padding">
    <div class="container ">
        <div class="row no-gutter">
            <div class="col-sm-12 col-md-6 technology-container">

                <div class="technology-box pull-left">

                    <h4>Discover the machinery that makes it happen</h4>
                    <p>Our systems use the latest technology boasting some of the most reliable and efficient
                        machinery the industry has to offer. From your instructions, we can cut an extensive
                        collection of sheet materials to size and fast. </p>



                    <a class="popup popup-video" target="_blank"
                        href="https://www.youtube.com/watch?v=3GIOR_1uUfY?fs=1&amp;autoplay=1">
                        <img src="../../../assets/images/images-technology.jpg" alt="Showroom" class="img-responsive">
                    </a>

                </div>

                <div class="technology-box-title pull-right">



                </div>

            </div>
            <div class="col-md-6 col-sm-6 why-you-need-box text-left">
                <h3>Who Uses Cutwrights?</h3>

                <ul>
                    <li>Carpenters</li>
                    <li>Builders</li>
                    <li>DIY Enthusiast</li>
                    <li>Property Developers</li>
                    <li>Cabinet makers</li>
                    <li>Exhibition Companies</li>
                </ul>
            </div>
        </div>
    </div>
</section><!-- technology -->

<section id="needtospeakus" class="needtospeakus">
    <div class="container">
        <div class="row no-gutter">
            <div class="col-md-4 col-sm-12">
                <div class="needtospeakus-box-top">
                    <h5>Please get in touch</h5>
                    <p>
                        Our experienced team are ready to answer any questions.<br><br>
                        <a id="" href="https://cutwrightsuat.dcslsoftware.com/new/Home/ContactUs"
                            class="btn btn-large popup">Contact us</a>
                    </p>
                </div>
                <div class="needtospeakus-box-bottom">
                    <h3>Book Your Visit To Our Production Facility and Showroom</h3>

                    <p>Tel.: 0208 755 4829</p>

                    <p><strong>Cutwrights Ltd.</strong><br>
                        Unit 6 Links Industrial Estate, Popham Close<br>
                        Hanworth, Middlesex<br>
                        TW13 6JE</p>
                </div>
            </div>
            <div class="col-md-8 col-sm-12">
                <div class="needtospeakus-map ">
                    <iframe id="indexMapsFrame"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9949.070098041737!2d-0.38313028691574447!3d51.43488065382924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487673513d9a9735%3A0xaae0f84057eeb704!2sCutwrights+Ltd!5e0!3m2!1sen!2suk!4v1414765578690"
                        class="map" frameborder="0" zoom="4"></iframe>

                </div>
            </div>
        </div>
    </div>
</section>