<div class="hollow-board">
    <section class="banner-pages banner-bg-ourrange" role="banner">
        <div class="container">
            <div class="col-md-10 col-md-offset-1">
                <div class="banner-pages-text text-left">
                    <h1>Hollow Core board</h1>
                    <h3>We offer over 300 different boards and 500 different edgings.</h3>
                </div><!-- banner text -->
            </div>
        </div>
    </section>

    <section id="intro" class="section">
        <div class="container">
            <div class="intro">
                <span class="site-navigation"><a [routerLink]="['/']">Home</a> » <a [routerLink]="['/board-range']">Board Range</a> » Hollow Core board</span>
            </div>  
        </div>
    </section>

    <section id="products-selection" class="no-padding">
        <div class="container products-selection-table">
            <div class="row no-gutter">
                <div class="col-md-6">
                    <a (click)="openImageModal(imagePopup,'../../../assets/images/CWLITE50MM Raw Edge.jpg')" class="gallery-box" title="Hollow Core board" data-fancybox-group="thumb">
                        <img src="../../../assets/images/CWLITE50MM Raw Edge.jpg" alt="Hollow Core board" class="img-responsive">
                    </a>
                </div>
                <div class="col-md-6">
                    <div class="products-list-item-title" style="text-align: center">
                        <p>Hollow Core board</p>
                    </div>
                    <div class="products-list-item-properties" style="padding-bottom:14px">
                        <ul>
                            <li>
                                A Composite board with strong surface layers combined with light yet robust cardboard honeycomb core. In addition, its lightweight features and outstanding stability offers many design and construction options.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="services-drilling-list2" class="products-selection services-edging-list no-padding">
        <div class="container" style="background-color: #ffffff">
            
            <div class="row no-gutter" style="padding-top: 30px; padding-bottom: 30px;text-align: center">
                <div class="col-md-4" style="margin-bottom: 10px">
                    <a class="btn-white btn-white-large" [routerLink]="['/choose-your-boards']">Choose your boards</a>
                </div>
                <div class="col-md-4" style="margin-bottom: 10px">
                    <a class="btn-white btn-white-large" [routerLink]="['/customer/get-quote']">Get a Quote</a>
                </div>
                <div class="col-md-4" style="margin-bottom: 10px">
                    <a id="" [routerLink]="['/contact-us']" class="btn-white btn-white-large">Contact us</a>
                </div>
            </div>
            <div class="row no-gutter" style="margin-bottom:1px; background-color:#c2c2c2">
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Raw</h4>
                        </div>
                        <img src="../../../assets/images/img-raw-1x@2.jpg" alt="Raw" class="img-responsive">
                        <div class="services-list-desc">
                            <p>A raw hollowcore board has either an MDF or chipboard face that is suitable for onward processing. The MDF board is manufactured with 5mm MDF skins that can be primed ready to take paint, or the chipboard which has 8mm skins usually requires the pressing of veneer or laminate to create a ready to use panel.</p>
                        </div>
                    </div>
            </div>
        </div>
    </section>

</div>
<ng-template #imagePopup>
    <form>
        <span class="close" style="opacity: 1.5;" (click)="closeModal()">&times;</span>
        <div class="row">
            <div class="col-md-12">
                    <img alt="image" class="img-responsive"
                    [src]="modalImageUrl" alt="image" class="img-responsive">
            </div>
            </div>
        </form>
</ng-template>