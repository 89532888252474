import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, firstValueFrom, forkJoin } from "rxjs";
import { EndpointApiService } from "./endpoint-api.service";
import { User } from "../models/user.model";
import { addDays, isWeekend, isSameDay } from 'date-fns';
export interface Impersonate{
  user :User
  admin:User
}
@Injectable({
    providedIn: 'root'
  })
  export class DataService {
    private dataSubject = new BehaviorSubject<boolean>(false);
    private panelOffcutSubject = new BehaviorSubject<boolean>(true);
    impersonateSubject = new Subject<Impersonate>();
    constructor(private epService:EndpointApiService) {}
    data:any;

    isWeekend(date: Date): boolean {
      return isWeekend(date);
    }
    isBankHoliday(date: Date, bankHolidays: Date[]): boolean {
      return bankHolidays.some(holiday => isSameDay(date, holiday));
    }
    addDaysWithWeekendHandling(date: Date, days: number, bankHolidays: Date[]): Date {
      do{
        if(!this.isWeekend(date) && !this.isBankHoliday(date,bankHolidays)){          
          days--;
        }
        date=addDays(date,1);
      }while (days>0 || this.isWeekend(date) || this.isBankHoliday(date,bankHolidays) )
      
      return date;
    }

    setHeaderFooterFlag(data: boolean): void {
      this.dataSubject.next(data);
    }
  
    getHeaderFooterFlag(): Observable<boolean> {
      return this.dataSubject.asObservable();
    }

    setPanelAndOffcut(data:boolean):void{
      this.panelOffcutSubject.next(data);
    }

    getPanelAndOffcut(): Observable<boolean>{
      return this.panelOffcutSubject.asObservable();
    }


  emitImpersonateFlow(data:Impersonate){
    this.impersonateSubject.next(data);
  }
  closeImpersonateFlow(){
    this.impersonateSubject.complete();
  }
  async fetchData(){

        let data = await firstValueFrom(forkJoin([
            this.epService.getMaterialsData(),
            this.epService.getEdgesData(),
            this.epService.getBoardsData(),
            this.epService.getMaterialTags(),
          this.epService.getGenSasUrl()]))
        this.data = data    
     }    
}
export function initializeApp(appInitializer: DataService) {
    return () => appInitializer.fetchData();
  }