import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AdminLayoutComponent } from "./admin-layout/admin-layout.component";
import { CustomerListComponent } from "./customer-list/customer-list.component";
import { OrdersComponent } from "./orders/orders.component";
import { AdminGuard } from "../shared/auth-gaurds/admin-gaurd";
import { ReceivedQuotesComponent } from "./received-quotes/received-quotes.component";
import { QuoteDetailsComponent } from "../customer/quote-details/quote-details.component";
import { OrderDetailsComponent } from "./order-details/order-details.component";
import { TransportComponent } from "./transport/transport.component";
import {TransportTabNewComponent} from "./transport-tab-new/transport-tab-new.component";
import { PrintJobLabelComponent } from "./print-job-label/print-job-label.component";
import { PrintDoorComponent } from "./print-door/print-door.component";
import { StockComponent } from "./stock/stock.component";
import { OffCutComponent } from "./off-cut/off-cut.component";
import { materialTagsResolver } from "../service/endpoint-api.service";
import { InvoiceReceiptComponent } from "../customer/invoice-receipt/invoice-receipt.component";

const routes: Routes = [
  {
    path: 'admin', 
    component: AdminLayoutComponent,
    // canActivate:[AdminGuard],
    children: [
     {
       path: '',
       redirectTo: 'customers',
       pathMatch: 'full',
      //  canActivate:[AdminGuard],
     },
     {
      path: 'customers',
      component: CustomerListComponent,
      canActivate:[AdminGuard],
    },
    {
      path: 'orders',
      component: OrdersComponent,
      canActivate:[AdminGuard],
    },
    {
      path: 'received-quotes',
      component: ReceivedQuotesComponent,
      canActivate:[AdminGuard],
    },
    {
      path: 'quote-details/:quoteId',
      component: QuoteDetailsComponent,
      canActivate:[AdminGuard],
    },
    {
      path: 'invoices',
      component: OrdersComponent,
      canActivate:[AdminGuard],
    },
    {
      path: 'order-details/:orderId',
      component: OrderDetailsComponent,
      canActivate:[AdminGuard],
    },
    {
      path: 'order-details/:orderId/:token',
      component: OrderDetailsComponent,
    },
    {
      path: 'transport',
      component: TransportComponent,
    },
    {
      path: 'collection-notes/:id',
      component: TransportTabNewComponent,
    },
    {
      path: 'print-job-label/:orderId',
      component: PrintJobLabelComponent,
    },
    {
      path: 'print-door/:id',
      component: PrintDoorComponent,
    },
    {
      path: 'invoice-receipt/:quoteId/:type',
      component: InvoiceReceiptComponent
    },
    {
      path: 'stock',
      component: StockComponent,
      resolve: {
        materials: materialTagsResolver
      }
    },
    {
      path: 'off-cut',
      component: OffCutComponent,
      resolve: {
        materials: materialTagsResolver
      }
    }
    ]
  }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class AdminRoutingModule { }