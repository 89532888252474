import { EventEmitter, NgModule } from "@angular/core";
import { HeaderNavigationsComponent } from './header-navigations/header-navigations.component';
import { UserAuthorizationComponent } from './user-authorization/user-authorization.component';
import { RouterModule } from "@angular/router";
import { FooterNavigationsComponent } from './footer-navigations/footer-navigations.component';
import { LoaderComponent } from './loader/loader.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { AlertModule } from 'ngx-bootstrap/alert';
import { AlertComponent } from './alert-set/alert/alert.component';

@NgModule({
    declarations: [
    HeaderNavigationsComponent,
    UserAuthorizationComponent,
    FooterNavigationsComponent,
    LoaderComponent,
    AlertComponent
  ],
    imports: [
      RouterModule,
      FormsModule,
      CommonModule,
      ReactiveFormsModule,
      AlertModule.forRoot(),
    ],
    exports: [
        HeaderNavigationsComponent,
        UserAuthorizationComponent,
        FooterNavigationsComponent,
        LoaderComponent
    ]
  })
  export class SharedModule { 
  static refreshHeaderEvent: EventEmitter<void> = new EventEmitter<void>();

  static refreshHeader(): void {
    SharedModule.refreshHeaderEvent.emit();
  }
  }