<div *ngIf="detailsFetched" class="print-door-container">
    <div>
        <div>
            <div class="shaker-door-cutting-list">
                <h2>Job Card {{order.orderReference}} ({{order.jobReference}})</h2>
                <h3>
                    {{order.collection? "Collection":"Delivery"}} date and time {{order.neededBy}}
                    <br>
                    Description Shaker Doors Order No.{{order.orderReference}}
                </h3>
                <div *ngIf="doors.length == 0" id="Main_ctl00_upList" isupdatepanel="true">

                    <div class="list">

                        There are no shaker doors to display.

                    </div>
                    <input type="hidden" name="Root$Main$ctl00$SelectedListItemIdHolder"
                        id="Main_ctl00_SelectedListItemIdHolder">
                </div>
            </div>
            <!-- «End| List: ShakerDoor - Shaker door cutting list» -->
        </div>
        <div *ngIf="doors.length > 0">
            <div *ngFor="let door of doors">
                <div class="item">
                    <div class="label">
                        Shaker Door name: 
                    </div>
                    <div class="value">
                        {{door.name}}
                    </div>
                </div>
                <div class="item">
                    <div class="label">
                        Size of Shaker Door:
                    </div>
                    <div class="value" style="display: inline;">
                        <span>Height: {{door.height}} Width: {{door.width}} Qty: {{door.quantity}}</span>
                    </div>
                </div>
                
                <div class="item">
                    <div class="label">
                        Needs priming: 
                    </div>
                     <div class="value" style="display: inline;">
                        {{door.primed?"Yes":"No"}}
                    </div> 
                </div>
                <div class="item">
                    <div class="label">
                        Hinges Position(x:y):
                        <span style="font-weight: normal;">
                            <ng-container *ngFor="let hinge of door.hinges">
                                {{" (" + door.drillingdistance + "," + hinge.y + ")"}}
                            </ng-container>
                        </span>
                    </div>
                </div>
                <div class="item">
                    <div class="label">
                        No Of MidRails: {{door.midrails.length}} :
                        <span style="font-weight: normal;">
                            <ng-container *ngFor="let hinge of door.midrails">
                                {{" (" + hinge.position + ")"}}
                            </ng-container>
                        </span>
                    </div>
                </div>
                <div  *ngIf="doors.length > 0" class="item">
                    <div class="value">
        
                        <!-- «Start| List: ShakerPart - Plain Drawers» -->
                        <div id="Main_ctl02_gridList_lstShakerPart_PlainDrawers_0_upList_0" isupdatepanel="true">
        
                            <div class="list">
                                <div>
                                    <table class="grid" cellspacing="0" currentsort=""
                                        id="Main_ctl02_gridList_lstShakerPart_PlainDrawers_0_gridList_0"
                                        style="border-collapse:collapse;">
                                        <tbody>
                                            <tr class="header-row">
                                                <th scope="col"><a
                                                        >Material
                                                        description</a></th>
                                                <th scope="col"><a
                                                       >Length</a>
                                                </th>
                                                <th scope="col"><a
                                                        >Width</a>
                                                </th>
                                                <th scope="col"><a
                                                       >Quantity</a>
                                                </th>
                                                <th scope="col"><a
                                                       >Description</a>
                                                </th>
                                            </tr>
                                            <tr *ngFor="let smat of getMaterials('door',door.shakerId)">
                                                <td>
                                                    {{smat.MaterialDescription}}
                                                </td>
                                                <td>
                                                    {{smat.Length}}
                                                </td>
                                                <td>
                                                    {{smat.Width}}
                                                </td>
                                                <td>
                                                    {{smat.Quantity}}
                                                </td>
                                                <td>
                                                    {{smat.Description}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div *ngIf="drawers.length == 0" id="Main_ctl00_upList" isupdatepanel="true">

            <div class="list">

                There are no drawers to display.

            </div>
            <input type="hidden" name="Root$Main$ctl00$SelectedListItemIdHolder"
                id="Main_ctl00_SelectedListItemIdHolder">
        </div>
        <div *ngIf="drawers.length > 0">
            <div *ngFor="let door of drawers">
                <div class="item">
                    <div class="label">
                        Shaker Drawer name:
                    </div>
                    <div class="value">
                        {{door.name}}
                    </div>
                </div>
                <div class="item">
                    <div class="label">
                        Size of Shaker Drawer:
                    </div>
                    <div class="value" style="display: inline;">
                        Height:{{door.height}} Width:{{door.width}} Qty:{{door.quantity}}
                    </div>
                </div>
                <div class="item">
                    <div class="label">
                        Needs priming:
                    </div>
                    <div class="value" style="display: inline;">
                        {{door.primed?"Yes":"No"}}
                    </div>
                </div>
                <div class="item">
                    <div class="value">
                        <hr>
                    </div>
                </div>
                <div   class="item">
                    <div class="value">
        
                        <!-- «Start| List: ShakerPart - Plain Drawers» -->
                        <div id="Main_ctl02_gridList_lstShakerPart_PlainDrawers_0_upList_0" isupdatepanel="true">
        
                            <div class="list">
                                <div>
                                    <table class="grid" cellspacing="0" currentsort=""
                                        id="Main_ctl02_gridList_lstShakerPart_PlainDrawers_0_gridList_0"
                                        style="border-collapse:collapse;">
                                        <tbody>
                                            <tr class="header-row">
                                                <th scope="col"><a
                                                      >Material
                                                        description</a></th>
                                                <th scope="col"><a
                                                       >Length</a>
                                                </th>
                                                <th scope="col"><a
                                                       >Width</a>
                                                </th>
                                                <th scope="col"><a
                                                        >Quantity</a>
                                                </th>
                                                <th scope="col"><a
                                                       >Description</a>
                                                </th>
                                            </tr>
                                            <tr *ngFor="let smat of getMaterials('drawer',door.shakerId)">
                                                <td>
                                                    {{smat.MaterialDescription}}
                                                </td>
                                                <td>
                                                    {{smat.Length}}
                                                </td>
                                                <td>
                                                    {{smat.Width}}
                                                </td>
                                                <td>
                                                    {{smat.Quantity}}
                                                </td>
                                                <td>
                                                    {{smat.Description}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        
            </div>
        </div>
                <div *ngIf="plainD.length == 0" id="Main_ctl00_upList" isupdatepanel="true">

            <div class="list">

                There are no plain doors to display.

            </div>
            <input type="hidden" name="Root$Main$ctl00$SelectedListItemIdHolder"
                id="Main_ctl00_SelectedListItemIdHolder">
        </div>
        <div *ngIf="plainD.length > 0">
            <div *ngFor="let door of plainD">
                <div class="item">
                    <div class="label">
                        Plain Drawer name:
                    </div>
                    <div class="value">
                        {{door.name}}
                    </div>
                </div>
                <div class="item">
                    <div class="label">
                        Size of Plain Drawer:
                    </div>
                    <div class="value" style="display: inline;">
                        Height:{{door.height}} Width:{{door.width}} Qty:{{door.quantity}}
                    </div>
                </div>
                <div class="item">
                    <div class="label">
                        Needs priming:
                    </div>
                    <div class="value" style="display: inline;">
                        {{door.primed?"Yes":"No"}}
                    </div>
                </div>
                <div class="item">
                    <div class="value">
                        <hr>
                    </div>
                </div>
                <div  *ngIf="plainD.length > 0" class="item">
                    <div class="value">
        
                        <!-- «Start| List: ShakerPart - Plain Drawers» -->
                        <div id="Main_ctl02_gridList_lstShakerPart_PlainDrawers_0_upList_0" isupdatepanel="true">
        
                            <div class="list">
                                <div>
                                    <table class="grid" cellspacing="0" currentsort=""
                                        id="Main_ctl02_gridList_lstShakerPart_PlainDrawers_0_gridList_0"
                                        style="border-collapse:collapse;">
                                        <tbody>
                                            <tr class="header-row">
                                                <th scope="col"><a
                                                      >Material
                                                        description</a></th>
                                                <th scope="col"><a
                                                        >Length</a>
                                                </th>
                                                <th scope="col"><a
                                                        >Width</a>
                                                </th>
                                                <th scope="col"><a
                                                        >Quantity</a>
                                                </th>
                                                <th scope="col"><a
                                                        >Description</a>
                                                </th>
                                            </tr>
                                            <tr *ngFor="let smat of getMaterials('pdoor',door.shakerId)">
                                                <td>
                                                    {{smat.MaterialDescription}}
                                                </td>
                                                <td>
                                                    {{smat.Length}}
                                                </td>
                                                <td>
                                                    {{smat.Width}}
                                                </td>
                                                <td>
                                                    {{smat.Quantity}}
                                                </td>
                                                <td>
                                                    {{smat.Description}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div>


        </div>

    </div>
</div>