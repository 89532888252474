import { NgModule } from "@angular/core";
import { CreditLimitComponent } from './credit-limit/credit-limit.component';
import { PaymentsComponent } from './payments/payments.component';
import { DiscountsComponent } from './discounts/discounts.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";


@NgModule({
  declarations: [
    CreditLimitComponent,
    PaymentsComponent,
    DiscountsComponent
  ],
  imports: [ BrowserModule,CommonModule, FormsModule,ReactiveFormsModule],
  exports: [CreditLimitComponent, 
    PaymentsComponent,
    DiscountsComponent]
})

export class SubMenuModule {}