<div class="board-range-details">
    <section id="products-selection" class="no-padding">
        <div class="container products-selection-table">
            <div class="row no-gutter">
                <div class="col-md-6">
                    <a (click)="openImageModal(imagePopup)"
                        rel="gallery01" class="gallery-box" title="25mm Fire Resistant Medite MDF Class B"
                        data-fancybox-group="thumb1">
                        <img
                                *ngIf="checkCondition()" [src]="getHref()" alt="image"
                                class="img-responsive"><img *ngIf="!checkCondition()"
                                src="../../../assets/images/alternate_board.jpeg" alt="image" class="img-responsive">
                    </a>
                </div>
                <div class="col-md-6">
                    <div class="products-list-item-title" style="text-align: center; padding: 3%;">
                        <h1>{{this.material['description']}}</h1>
                    </div>
                    <div class="products-list-item-properties">
                        <p>{{getMaterialDescription()}}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="products-selection" class="products-selection no-padding">

        <div class="container">
            <div class="row no-gutter" style="padding-top: 30px; padding-bottom: 30px;text-align: center">
                <div class="col-md-3" style="margin-bottom: 10px">
                    <a class="btn-white btn-white-large" [routerLink]="['/choose-your-boards']"
                        style="width:195px; height: 50px">Back</a>
                </div>
                <!-- <div class="col-md-3" style="margin-bottom: 10px">
                    <a href="" id="addToListLink" class="btn-white btn-white-large"
                        style="width:195px; height:50px"><i class="fa fa-plus" aria-hidden="true"></i> Add to list</a>
                </div> -->
                <div class="col-md-3" style="margin-bottom: 10px">
                    <a class="btn-white btn-white-large" [routerLink]="['/customer/get-quote']" style="width:195px; height: 50px">Get a
                        Quote</a>
                </div>
                <div class="col-md-3" style="margin-bottom: 10px">
                    <a id="" [routerLink]="['/contact-us']" class="btn-white btn-white-large"
                        style="width:195px; height:50px">Contact us</a>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row no-gutter products-selection-area">
                <div class="col-lg-12 col-md-12 col-sm-12 products-selection-table">

                    <div class="products-list-item-title" style="text-align: center; padding:1.4%">
                        <h1>{{material['description']}}</h1>
                    </div>

                    <div class="products-list-item-properties">
                        <h2>Board Product Properties</h2>
                        <ul>
                            <li>
                                <h3>Product code: {{m_code}}</h3>
                            </li>
                            <li>
                                <h3>Board Range: {{material['type']}}</h3>
                            </li>
                            <li>
                                <h3>{{getMaterialDescription()}}</h3>
                            </li>
                            <li>
                                <h3>Board Types: <b>{{material['type']}}</b></h3>
                            </li>
                            <li>
                                <h3>Product Suitable Uses: <b>{{a_data[material['type']]['suitable_users']}}</b></h3>
                            </li>
                            <li>
                                <h3>Special Properties: <b>{{a_data[material['type']]['special_properties']}}</b></h3>
                            </li>
                            <li>
                                <h3>Material Finishes: <b>{{getMaterialFinishes()}}</b></h3>
                            </li>
                            <li>
                                <h3>Material Textures: <b>{{getMaterialTextures()}}</b></h3>
                            </li>
                            <li>
                                <h3>Special Offers: <b>{{getSpecialD()}}</b></h3>
                            </li>
                            <li>
                                <h3>Brands: <b>{{getBrands()}}</b></h3>
                            </li>
                            <li>
                                <h3>Sheet Sizes: <b>{{getSheetSizes()}}</b></h3>
                            </li>
                            <li>
                                <h3>Thicknesses: <b>{{material['thickness']}}mm</b></h3>
                            </li>
                            <li>
                                <h3>Lead Times:{{getLeadTimes()}} <b></b></h3>
                            </li>

                            <li>
                                <h3>Price: <b>£{{getMaxPrice()}}</b></h3>
                            </li>

                        </ul>

                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

<ng-template #imagePopup>
    <form>
        <span class="close" (click)="closeModal()">&times;</span>
        <div class="row">
            <div class="col-md-12">
                    <img
                            *ngIf="checkCondition()" [src]="getHref()" alt="image"
                            class="img-responsive"><img *ngIf="!checkCondition()"
                            src="../../../assets/images/alternate_board.jpeg" alt="image" class="img-responsive">
            </div>
            </div>
        </form>
</ng-template>