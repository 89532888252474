import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private clEventSubject = new Subject<{ type: string, data: Boolean }>();
  private signUpEventSubject = new Subject<{ type: string, data: Boolean }>();

  CLFlag$ = this.clEventSubject.asObservable();
  signUpEvent$ = this.signUpEventSubject.asObservable();

  showCLSuccess(CLFlag: Boolean) {
    this.clEventSubject.next({ type: 'success', data:CLFlag});
  }
  showCLDecline(CLFlag: Boolean) {
    this.clEventSubject.next({ type: 'decline', data: CLFlag});
  }

  showSignUpSuccess(signUpSuccessFlag: Boolean){
    this.signUpEventSubject.next({ type: 'success', data: signUpSuccessFlag });
  }
  showSignUpDecline(signUpDeclineFlag: Boolean){
    this.signUpEventSubject.next({ type: 'decline', data: signUpDeclineFlag });
  }
  
}
