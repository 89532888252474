import { Component } from '@angular/core';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-others',
  templateUrl: './others.component.html',
  styleUrls: ['./others.component.scss']
})
export class OthersComponent {
  constructor(private metadataService: MetadataService){
    
  }
  ngOnInit(): void {
    this.metadataService.setCanonicalLink();
    this.metadataService.setTitle('Additional Services - Priming, Doors, Drawer Boxes, J Profile Doors, Bonding - Cutwrights');
    this.metadataService.setDescription('Committed to providing a range of other services such as Priming, Shaker Style Doors, Drawer Boxes, J Profile Doors and Bonding service.');
  }

}
