
    <section>
        <header id="header">
            <section id="headline" class="headline">
                <div class="container">
                    <div class="row no-gutter">
                        <div class="col-md-6 col-sm-6">
                            <div class="headline-left text-query-middle"><br></div>
                        </div>
                    </div>
                </div>
            </section>
        </header>
    </section>
    <section id="intro" class="section">
        <div class="container"><br></div>
    </section><!-- intro ends-->
    <div class="page-container">
        <section id="blog-datasheet" class="no-padding">
            <div class="container blog">
                <div class="row no-gutter">
                    <div class="col-md-7 col-sm-7 blog-box-text text-left article">
                        <div class="img-responsive"><img src="../../../assets/images/Main.Logo.png" alt="Cutwrights truck" style="width: 100%; height: 100%;" width="682" height="160"><br></div>
                        <div class="date">Date: 20.03.2020, Author: Tom Wright &amp; Chris Wright</div>
                        <h2>Message from Tom and Chris on COVID-19</h2>
                        <p><br></p>
                        <p><span lang="EN-US">Dear Valued Customer,</span></p>
                        <p><br></p>
                        <p><span lang="EN-US">As the world reacts to the COVID-19, we just wanted to briefly take this opportunity to advise you that we are still here for you.</span></p>
                        <p><span lang="EN-US">Our warehouse and phone lines are open, and although o</span><span lang="EN-US">ur showroom is now closed, we can still send out samples where required.&nbsp;</span></p>
                        <p><br></p>
                        <p><strong><span lang="EN-US">Deliveries</span></strong></p>
                        <p><span lang="EN-US">Our scheduled deliveries will continue as normal. We have advised our drivers to be as helpful as they can whilst making every effort to maintain social distancing. If you are self-isolating, we would appreciate your honesty and we can either reschedule your order or possibly make arrangements to leave it outside your door.&nbsp;</span></p>
                        <p><br></p>
                        <p><strong><span lang="EN-US">Collections</span></strong></p>
                        <p><span lang="EN-US">We are still accepting customer collections. If you are collecting an order, when you arrive, we ask you politely to remain in your vehicle and call us on 020 8755 4829 quoting the job number that you wish to collect. One of our warehouse staff will bring your order out to you as normal.</span></p>
                        <p><br></p>
                        <p><strong><span lang="EN-US">Payments</span></strong></p>
                        <p><span lang="EN-US">We are currently only accepting online payments or bank transfers&nbsp;</span></p>
                        <p><br></p>
                        <p><span lang="EN-US">Thank you for your cooperation, understanding and patience during these uncertain times.</span></p>
                        <p><br></p>
                        <p><span style="font-family:Calibri, sans-serif;font-size:11pt;">Tom and Chris</span></p>
                        <p><br></p>
                    </div>
                </div>
            </div>
        </section>
    </div>
   