import { Component, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Order } from 'src/app/models/cut-wrights.model';
import { User } from 'src/app/models/user.model';
import { EndpointApiService } from 'src/app/service/endpoint-api.service';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['./enquiry.component.scss']
})
export class EnquiryComponent implements OnInit {
  constructor(private metadataService: MetadataService,private apiService: EndpointApiService,private router: Router){}
  orderChecked: string = '';
  orderIds = []
  orders: Order[] = []
  selectedOrderId!:any;
  selectedOrder!:any;
  user!:User;
  submitted = false
  c_note=''
  change():void{
    this.selectedOrder= this.orders.filter(order => order.orderId == this.selectedOrderId)[0]
  }
  ngOnInit(): void {
    this.metadataService.setTitle('default');
    this.metadataService.setDescription('default');
    this.metadataService.setCanonicalLink();
    const token = localStorage.getItem('token');
    const userString = localStorage.getItem('user');
    if (token) {
      this.user = JSON.parse(userString as string)
    }
    this.apiService.getCustomerOrders(this.user.userId).subscribe(data=>{
      // this.isLoading = false;
      (data as any).forEach((order:any) => {
        delete order.Id
        this.orders.push(order)
      });
    });
  }
  postEnquiry(){
    if(this.selectedOrderId || this.orderChecked != 'Yes'){
      this.apiService.postEnquiry({"customerId":this.user.userId, "quoteId":this.selectedOrderId||"","customerNote":this.c_note}).subscribe(data =>{
        this.submitted = true
      })
    }
  }
  goToQuotes(){
      this.router.navigate([`/customer/my-quote/${this.user.userId}`])
  }
}

