import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Order, Part, Quote, formattedDeliveryWindow } from 'src/app/models/cut-wrights.model';
import { Shaker, eShakerType } from 'src/app/models/shaker.model';
import { DataService } from 'src/app/service/data-service';
import { EndpointApiService } from 'src/app/service/endpoint-api.service';
import { MetadataService } from 'src/app/service/metadata.service';
import jsPDF from 'jspdf';
import "jspdf-autotable";
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-transport-tab-new',
  templateUrl: './transport-tab-new.component.html',
  styleUrls: ['./transport-tab-new.component.scss']
})
export class TransportTabNewComponent {
  orderId= ""
  order!:Order;
  quote!:Quote;
  collection:boolean=false;
  detailsFetched = false
  hideFooter: boolean = false;
  hideHeader: boolean = true;
  jobRef:string='';
  constructor(private metadataService: MetadataService,private epService:EndpointApiService, private route:ActivatedRoute, private dataService: DataService){
    this.orderId = this.route.snapshot.paramMap.get('id') as string;
    this.epService.getOrder(this.orderId,true).subscribe(data => {
      this.order=data
      this.jobRef=this.order.jobReference;
      if (this.order.collection==true){
        this.collection=true;
      }
      this.epService.getQuote(this.order.quoteId).subscribe(data =>{
        this.quote = data
        this.detailsFetched = true
        this.hideFooter=true;
        this.hideHeader=true;
        this.dataService.setHeaderFooterFlag(this.hideFooter);
        this.dataService.setHeaderFooterFlag(this.hideHeader);
      })
    })
  }
  ngOnInit(): void {
    this.metadataService.setTitle('Collection/Delivery_' + this.jobRef);
    this.metadataService.setDescription('default');
  }
  getDeliveryWindow(deliverywindow:any){
    return formattedDeliveryWindow[deliverywindow];
  }
  getPartsC(): number {
    var count = 0;
    this.quote.parts.forEach((elem: Part) => {
      count += Number(elem.quantity) * 1
    });
    return count;
  }
  getShakerDoorsC() {
    let count = 0
    this.quote.shakers?.forEach((sha: Shaker) => {
      if (sha.type == eShakerType.ShakerDoor) {
        count+=sha.quantity;
      }
    })
    return count;
  }
  getShakerDrawerC() {
    let count = 0
    this.quote.shakers?.forEach((sha: Shaker) => {
      if (sha.type == eShakerType.ShakerDrawer) {
        count+=sha.quantity;
      }
    })
    return count;
  }
  getPlainDrawerC() {
    let count = 0
    this.quote.shakers?.forEach((sha: Shaker) => {
      if (sha.type == eShakerType.PlainDrawer) {
        count+=sha.quantity;
      }
    })
    return count;
  }
  getPriming(part:any){
    return (part as any).primed ? "Yes":"No";
  } 
  truncateValue(value: string): string {
    return this.epService.truncateDecimal(value);
  }
  getMidrails(part:any){
    return (part as any).midrails.length
  }
  getHingeSide(part:any){
    return (part as any).hingeside == 1 ? "W2":"W1"
  }
  getType(part:any){
    return eShakerType[part.type]
  }
}