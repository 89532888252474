import { Component, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MetadataService } from 'src/app/service/metadata.service';


@Component({
  selector: 'app-drilling',
  templateUrl: './drilling.component.html',
  styleUrls: ['./drilling.component.scss']
})
export class DrillingComponent {
  bsModalRef!: BsModalRef;
  modalImageUrl! : string;
  constructor(private modalService: BsModalService,private metadataService: MetadataService){}

  ngOnInit(): void {
    this.metadataService.setCanonicalLink();
    this.metadataService.setTitle('CNC Drilling Service for Panels & Boards - Cutwrights');
    this.metadataService.setDescription('Drilled panels from Cutwrights save a whole lot of time and add value to your panels when using our CNC drilling service.');
  }

  
  openImageModal(template: TemplateRef<any>, url: string): void {
    this.modalImageUrl = url;
    this.bsModalRef = this.modalService.show(template, { class: 'img-modal'});
  }


  closeModal(): void {
    this.bsModalRef.hide();
  }
}
