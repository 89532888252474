
                    <table align="center" border="0" cellpadding="0" cellspacing="0" height="100%" id="bodyTable" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;height:100%;margin:0;padding:0;width:100%;background-color:#FAFAFA;" width="100%">
                        <tbody>
                            <tr>
                                <td align="center" id="bodyCell" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;height:100%;margin:0;padding:0;width:100%;border-top:0;" valign="top">
                                    <!-- BEGIN TEMPLATE // -->
                                    <table border="0" cellpadding="0" cellspacing="0" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" width="100%">
                                        <tbody>
                                            <tr>
                                                <td align="center" id="templatePreheader" style="background:#FAFAFA none no-repeat center/cover;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;background-color:#FAFAFA;background-image:none;background-repeat:no-repeat;background-position:center;background-size:cover;border-top:0;border-bottom:0;padding-top:9px;padding-bottom:9px;" valign="top">
                                                    <!--[if (gte mso 9)|(IE)]> <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;"> <tr> <td align="center" valign="top" width="600" style="width:600px;"> <![endif]-->
                                                    <table align="center" border="0" cellpadding="0" cellspacing="0" class="templateContainer k-table" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;max-width:600px !important;" width="100%">
                                                        <tbody>
                                                            <tr>
                                                                <td class="preheaderContainer" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="top">
                                                                    <table border="0" cellpadding="0" cellspacing="0" class="mcnTextBlock k-table" style="min-width:100%;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" width="100%">
                                                                        <tbody class="mcnTextBlockOuter">
                                                                            <tr>
                                                                                <td class="mcnTextBlockInner" style="padding-top:9px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="top"><br></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <table border="0" cellpadding="0" cellspacing="0" class="mcnImageBlock k-table" style="min-width:100%;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" width="100%">
                                                                        <tbody class="mcnImageBlockOuter">
                                                                            <tr>
                                                                                <td class="mcnImageBlockInner" style="padding:0px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="top">
                                                                                    <table align="left" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer k-table" style="min-width:100%;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" width="100%">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <!-- <td class="mcnImageContent" style="padding-right:0px;padding-left:0px;padding-top:0;padding-bottom:0;text-align:center;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="top"><a href="http://www.cutwrights.com" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" target="_blank" title=""><img align="center" alt="" class="mcnImage" src="https://gallery.mailchimp.com/87853d81414c89aa4116c7abf/images/dde713fe-fa05-4e7c-afdf-3782d9783ba7.jpg" style="max-width: 800px; padding-bottom: 0px; border: 0px; height: auto; outline: none; text-decoration: none;" width="600"></a></td> -->
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <table border="0" cellpadding="0" cellspacing="0" class="mcnDividerBlock k-table" style="min-width:100%;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;table-layout:fixed !important;" width="100%">
                                                                        <tbody class="mcnDividerBlockOuter">
                                                                            <tr>
                                                                                <td class="mcnDividerBlockInner" style="min-width:100%;padding:5px 18px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;">
                                                                                    <table border="0" cellpadding="0" cellspacing="0" class="mcnDividerContent k-table" style="min-width:100%;border-top:2px solid #EAEAEA;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" width="100%">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;"><br></td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table><!-- <td class="mcnDividerBlockInner" style="padding: 18px;"> <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" /> -->
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <table border="0" cellpadding="0" cellspacing="0" class="mcnImageBlock k-table" style="min-width:100%;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" width="100%">
                                                                        <tbody class="mcnImageBlockOuter">
                                                                            <tr>
                                                                                <td class="mcnImageBlockInner" style="padding:0px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="top">
                                                                                    
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="center" id="templateHeader" style="background:#FFFFFF none no-repeat center/cover;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;background-color:#FFFFFF;background-image:none;background-repeat:no-repeat;background-position:center;background-size:cover;border-top:0;border-bottom:0;padding-top:9px;padding-bottom:0;" valign="top">
                                                    <!--[if (gte mso 9)|(IE)]> <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;"> <tr> <td align="center" valign="top" width="600" style="width:600px;"> <![endif]-->
                                                    <table align="center" border="0" cellpadding="0" cellspacing="0" class="templateContainer k-table" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;max-width:600px !important;" width="100%">
                                                        <tbody>
                                                            <tr>
                                                                <td class="headerContainer" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="top">
                                                                    <table border="0" cellpadding="0" cellspacing="0" class="mcnImageBlock k-table" style="min-width:100%;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" width="100%">
                                                                        <tbody class="mcnImageBlockOuter">
                                                                            <tr>
                                                                                <td class="mcnImageBlockInner" style="padding:9px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="top">
                                                                                    <table align="left" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer k-table" style="min-width:100%;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" width="100%">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td class="mcnImageContent" style="padding-right:9px;padding-left:9px;padding-top:0;padding-bottom:0;text-align:center;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="top"><a href="https://www.cutwrights.com//board-range" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" target="_blank" title=""><img align="center" alt="" class="mcnImage" src="../../../assets/images/discount1.png" style="max-width: 983px; padding-bottom: 0px; border: 0px; height: auto; outline: none; text-decoration: none;" width="564"></a></td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <table border="0" cellpadding="0" cellspacing="0" class="mcnImageBlock k-table" style="min-width:100%;" width="100%">
                                                                        <tbody class="mcnImageBlockOuter">
                                                                            <tr>
                                                                                <td class="mcnImageBlockInner" style="padding:9px;" valign="top">
                                                                                    <table align="left" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer k-table" style="min-width:100%;" width="100%">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td class="mcnImageContent" style="padding:0px 9px;text-align:center;" valign="top"><a href="mailto:sales@cutwrights.com?subject=SUMMER%20SPECIAL%20PROMOTION&body=Hello%20Cutwrights!%0A%0AI%20can%20confirm%20that%20I%20have%205%20or%20more%20Kronospan%20MFC%20boards%20of%20my%20quotation.%0A%0AQuotation%20Number%3A%20%0A%0APlease%20apply%20my%2030%25%20discount.%0A%0A%22SUMMER%20SPECIAL%22%0A%0AMany%20thanks%2C%0A%0A" target="_blank" title=""><img align="center" alt="" class="mcnImage" src="../../../assets/images/discount2.png" style="max-width: 800px; padding-bottom: 0px;" width="564"></a></td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <table border="0" cellpadding="0" cellspacing="0" class="mcnImageBlock k-table" style="min-width:100%;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" width="100%">
                                                                        <tbody class="mcnImageBlockOuter">
                                                                            <tr>
                                                                                <td class="mcnImageBlockInner" style="padding:9px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="top">
                                                                                    <table align="left" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer k-table" style="min-width:100%;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" width="100%">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td class="mcnImageContent" style="padding-right:9px;padding-left:9px;padding-top:0;padding-bottom:0;text-align:center;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="top"><a href="https://www.cutwrights.com/customer/get-quote" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" target="_blank" title=""><img align="center" alt="" class="mcnImage" src="../../../assets/images/discount3.png" style="max-width: 1024px; padding-bottom: 0px; border: 0px; height: auto; outline: none; text-decoration: none;" width="564"></a></td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table><br>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <!--[if (gte mso 9)|(IE)]> </td> </tr> </table> <![endif]-->
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="center" id="templateBody" style="background:#FFFFFF none no-repeat center/cover;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;background-color:#FFFFFF;background-image:none;background-repeat:no-repeat;background-position:center;background-size:cover;border-top:0;border-bottom:0;padding-top:9px;padding-bottom:9px;" valign="top">
                                                    <!--[if (gte mso 9)|(IE)]> <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;"> <tr> <td align="center" valign="top" width="600" style="width:600px;"> <![endif]-->
                                                    <table align="center" border="0" cellpadding="0" cellspacing="0" class="templateContainer k-table" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;max-width:600px !important;" width="100%">
                                                        <tbody>
                                                            <tr>
                                                                <td class="bodyContainer" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="top">
                                                                    <table border="0" cellpadding="0" cellspacing="0" class="mcnButtonBlock k-table" style="min-width:100%;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" width="100%">
                                                                        <tbody class="mcnButtonBlockOuter">
                                                                            <tr>
                                                                                <td align="center" class="mcnButtonBlockInner" style="padding-top:0;padding-right:18px;padding-bottom:18px;padding-left:18px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="top">
                                                                                    <table border="0" cellpadding="0" cellspacing="0" class="mcnButtonContentContainer k-table" style="border-collapse:separate !important;border-radius:2px;background-color:#9C0000;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td align="center" class="mcnButtonContent" valign="middle"><a class="mcnButton " href="https://www.cutwrights.com/ChooseYourBoards" style="font-weight:bold;letter-spacing:normal;line-height:100%;text-align:center;text-decoration:none;color:#FFFFFF;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;display:block;" target="_blank" title="SHOP NOW">SHOP NOW</a></td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <table border="0" cellpadding="0" cellspacing="0" class="mcnTextBlock k-table" style="min-width:100%;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" width="100%">
                                                                        <tbody class="mcnTextBlockOuter">
                                                                            <tr>
                                                                                <td class="mcnTextBlockInner" style="padding-top:9px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="top">
                                                                                    <!--[if mso]> <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;"> <tr> <![endif]-->
                                                                                    <!--[if mso]> <td valign="top" width="600" style="width:600px;"> <![endif]-->
                                                                                    <table align="left" border="0" cellpadding="0" cellspacing="0" class="mcnTextContentContainer k-table" style="max-width:100%;min-width:100%;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" width="100%">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td class="mcnTextContent" style="padding-top:0;padding-right:18px;padding-bottom:9px;padding-left:18px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;word-break:break-word;color:#202020;font-family:Helvetica;font-size:16px;line-height:150%;text-align:left;" valign="top">
                                                                                                    <div style="text-align:center;"><strong><span style="font-size:24px;"><span style="font-family:roboto,helvetica neue,helvetica,arial,sans-serif;">PRICE COMPARISON</span></span></strong></div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                    <!--[if mso]> </td> <![endif]-->
                                                                                    <!--[if mso]> </tr> </table> <![endif]-->
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <table border="0" cellpadding="0" cellspacing="0" class="mcnImageBlock k-table" style="min-width:100%;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" width="100%">
                                                                        <tbody class="mcnImageBlockOuter">
                                                                            <tr>
                                                                                <td class="mcnImageBlockInner" style="padding:9px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="top">
                                                                                    <table align="left" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer k-table" style="min-width:100%;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" width="100%">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td class="mcnImageContent" style="padding-right:9px;padding-left:9px;padding-top:0;padding-bottom:0;text-align:center;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="top"><a href="https://www.cutwrights.com/ChooseYourBoards" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" target="_blank" title="Kronospan and Egger Price Comparison"><img align="center" alt="" class="mcnImage" src="../../../assets/images/discount4.png" style="max-width: 696px; padding-bottom: 0px; border: 0px; height: auto; outline: none; text-decoration: none;" width="564"></a></td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <table border="0" cellpadding="0" cellspacing="0" class="mcnTextBlock k-table" style="min-width:100%;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" width="100%">
                                                                        <tbody class="mcnTextBlockOuter">
                                                                            <tr>
                                                                                <td class="mcnTextBlockInner" style="padding-top:9px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="top">
                                                                                    <!--[if mso]> <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;"> <tr> <![endif]-->
                                                                                    <!--[if mso]> <td valign="top" width="600" style="width:600px;"> <![endif]-->
                                                                                    <table align="left" border="0" cellpadding="0" cellspacing="0" class="mcnTextContentContainer k-table" style="max-width:100%;min-width:100%;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" width="100%">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td class="mcnTextContent" style="padding-top:0;padding-right:18px;padding-bottom:9px;padding-left:18px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;word-break:break-word;color:#202020;font-family:Helvetica;font-size:16px;line-height:150%;text-align:left;" valign="top">
                                                                                                    <div style="text-align:center;"><span style="font-size:13px;"><span style="font-family:trebuchet ms,lucida grande,lucida sans unicode,lucida sans,tahoma,sans-serif;"><strong>The not too small print:</strong></span></span><br><span style="font-size:12px;"><span style="font-family:trebuchet ms,lucida grande,lucida sans unicode,lucida sans,tahoma,sans-serif;">Offer valid until midnight Friday 31<sup>st</sup> August 2018.<br>Guidelines: Email <a href="mailto:sales@cutwrights.com" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;color:#2BAADF;font-weight:normal;text-decoration:underline;">{{"sales@cutwrights.com"}}</a> with the code &lsquo;SUMMER SPECIAL&rsquo; and your order number if you have 5 or more Kronospan Melamine Faced Chipboard Boards (MDF core not applicable)&nbsp;on your quotation to get 30% discount off the price of those boards.&nbsp;</span></span><br><strong><span style="font-size:13px;"><span style="font-family:trebuchet ms,lucida grande,lucida sans unicode,lucida sans,tahoma,sans-serif;">If you have 50m or more of matching edging on your quotation you will be eligible 30% off&nbsp;edging.&nbsp;</span></span></strong><br><span style="font-size:12px;"><span style="font-family:trebuchet ms,lucida grande,lucida sans unicode,lucida sans,tahoma,sans-serif;">30% off discount excludes delivery charge and&nbsp;VAT.<br>Any existing discounts will be subsumed within the Promotional Discount so not more than 30% off will be granted on an order.</span></span></div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                    <!--[if mso]> </td> <![endif]-->
                                                                                    <!--[if mso]> </tr> </table> <![endif]-->
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <table border="0" cellpadding="0" cellspacing="0" class="mcnImageBlock k-table" style="min-width:100%;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" width="100%">
                                                                        <tbody class="mcnImageBlockOuter">
                                                                            <tr>
                                                                                <td class="mcnImageBlockInner" style="padding:9px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="top">
                                                                                    <table align="left" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer k-table" style="min-width:100%;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" width="100%">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td class="mcnImageContent" style="padding-right:9px;padding-left:9px;padding-top:0;padding-bottom:0;text-align:center;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="top"><a href="https://www.cutwrights.com/new/" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" target="_blank" title=""><img align="center" alt="" class="mcnImage" src="../../../assets/images/discount5.png" style="max-width: 800px; padding-bottom: 0px; border: 0px; height: auto; outline: none; text-decoration: none;" width="564"></a></td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <!--[if (gte mso 9)|(IE)]> </td> </tr> </table> <![endif]-->
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="center" id="templateFooter" style="background:#FAFAFA none no-repeat center/cover;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;background-color:#FAFAFA;background-image:none;background-repeat:no-repeat;background-position:center;background-size:cover;border-top:0;border-bottom:0;padding-top:9px;padding-bottom:9px;" valign="top">
                                                    <!--[if (gte mso 9)|(IE)]> <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;"> <tr> <td align="center" valign="top" width="600" style="width:600px;"> <![endif]-->
                                                    <table align="center" border="0" cellpadding="0" cellspacing="0" class="templateContainer k-table" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;max-width:600px !important;" width="100%">
                                                        <tbody>
                                                            <tr>
                                                                <td class="footerContainer" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="top">
                                                                    <table border="0" cellpadding="0" cellspacing="0" class="mcnFollowBlock k-table" style="min-width:100%;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" width="100%">
                                                                        <tbody class="mcnFollowBlockOuter">
                                                                            <tr>
                                                                                <td align="center" class="mcnFollowBlockInner" style="padding:9px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="top">
                                                                                    <table border="0" cellpadding="0" cellspacing="0" class="mcnFollowContentContainer k-table" style="min-width:100%;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" width="100%">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td align="center" style="padding-left:9px;padding-right:9px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;">
                                                                                                    <table border="0" cellpadding="0" cellspacing="0" class="mcnFollowContent k-table" style="min-width:100%;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" width="100%">
                                                                                                        <tbody>
                                                                                                            <tr>
                                                                                                                <td align="center" style="padding-top:9px;padding-right:9px;padding-left:9px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="top">
                                                                                                                    <table align="center" border="0" cellpadding="0" cellspacing="0" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;">
                                                                                                                        <tbody>
                                                                                                                            <tr>
                                                                                                                                <td align="center" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="top">
                                                                                                                                    <!--[if mso]> <table align="center" border="0" cellspacing="0" cellpadding="0"> <tr> <![endif]-->
                                                                                                                                    <!--[if mso]> <td align="center" valign="top"> <![endif]-->
                                                                                                                                    <table align="left" border="0" cellpadding="0" cellspacing="0" style="display:inline;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;">
                                                                                                                                        <tbody>
                                                                                                                                            <tr>
                                                                                                                                                <td class="mcnFollowContentItemContainer" style="padding-right:10px;padding-bottom:9px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="top">
                                                                                                                                                    <table border="0" cellpadding="0" cellspacing="0" class="mcnFollowContentItem k-table" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" width="100%">
                                                                                                                                                        <tbody>
                                                                                                                                                            <tr>
                                                                                                                                                                <td align="left" style="padding-top:5px;padding-right:10px;padding-bottom:5px;padding-left:9px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="middle">
                                                                                                                                                                    <table align="left" border="0" cellpadding="0" cellspacing="0" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" width="">
                                                                                                                                                                        <tbody>
                                                                                                                                                                            <tr>
                                                                                                                                                                                <td align="center" class="mcnFollowIconContent" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="middle" width="24"><a href="https://twitter.com/cutwrightsltd" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" target="_blank"><img height="24" src="../../../assets/images/color-twitter-48.png" style="margin: 0 auto 0 0; display: block; border: 0px; height: auto; outline: none; text-decoration: none;" width="24"></a></td>
                                                                                                                                                                            </tr>
                                                                                                                                                                        </tbody>
                                                                                                                                                                    </table>
                                                                                                                                                                </td>
                                                                                                                                                            </tr>
                                                                                                                                                        </tbody>
                                                                                                                                                    </table>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                        </tbody>
                                                                                                                                    </table>
                                                                                                                                    <!--[if mso]> </td> <![endif]-->
                                                                                                                                    <!--[if mso]> <td align="center" valign="top"> <![endif]-->
                                                                                                                                    <table align="left" border="0" cellpadding="0" cellspacing="0" style="display:inline;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;">
                                                                                                                                        <tbody>
                                                                                                                                            <tr>
                                                                                                                                                <td class="mcnFollowContentItemContainer" style="padding-right:10px;padding-bottom:9px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="top">
                                                                                                                                                    <table border="0" cellpadding="0" cellspacing="0" class="mcnFollowContentItem k-table" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" width="100%">
                                                                                                                                                        <tbody>
                                                                                                                                                            <tr>
                                                                                                                                                                <td align="left" style="padding-top:5px;padding-right:10px;padding-bottom:5px;padding-left:9px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="middle">
                                                                                                                                                                    <table align="left" border="0" cellpadding="0" cellspacing="0" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" width="">
                                                                                                                                                                        <tbody>
                                                                                                                                                                            <tr>
                                                                                                                                                                                <td align="center" class="mcnFollowIconContent" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="middle" width="24"><a href="https://www.facebook.com/CutwrightsLtd/" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" target="_blank"><img height="24" src="../../../assets/images/color-facebook-48.png" style="margin: 0 auto 0 0; display: block; border: 0px; height: auto; outline: none; text-decoration: none;" width="24"></a></td>
                                                                                                                                                                            </tr>
                                                                                                                                                                        </tbody>
                                                                                                                                                                    </table>
                                                                                                                                                                </td>
                                                                                                                                                            </tr>
                                                                                                                                                        </tbody>
                                                                                                                                                    </table>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                        </tbody>
                                                                                                                                    </table>
                                                                                                                                    <!--[if mso]> </td> <![endif]-->
                                                                                                                                    <!--[if mso]> <td align="center" valign="top"> <![endif]-->
                                                                                                                                    <table align="left" border="0" cellpadding="0" cellspacing="0" style="display:inline;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;">
                                                                                                                                        <tbody>
                                                                                                                                            <tr>
                                                                                                                                                <td class="mcnFollowContentItemContainer" style="padding-right:10px;padding-bottom:9px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="top">
                                                                                                                                                    <table border="0" cellpadding="0" cellspacing="0" class="mcnFollowContentItem k-table" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" width="100%">
                                                                                                                                                        <tbody>
                                                                                                                                                            <tr>
                                                                                                                                                                <td align="left" style="padding-top:5px;padding-right:10px;padding-bottom:5px;padding-left:9px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="middle">
                                                                                                                                                                    <table align="left" border="0" cellpadding="0" cellspacing="0" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" width="">
                                                                                                                                                                        <tbody>
                                                                                                                                                                            <tr>
                                                                                                                                                                                <td align="center" class="mcnFollowIconContent" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="middle" width="24"><a href="www.cutwrights.com" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" target="_blank"><img height="24" src="../../../assets/images/color-link-48.png" style="margin: 0 auto 0 0; display: block; border: 0px; height: auto; outline: none; text-decoration: none;" width="24"></a></td>
                                                                                                                                                                            </tr>
                                                                                                                                                                        </tbody>
                                                                                                                                                                    </table>
                                                                                                                                                                </td>
                                                                                                                                                            </tr>
                                                                                                                                                        </tbody>
                                                                                                                                                    </table>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                        </tbody>
                                                                                                                                    </table>
                                                                                                                                    <!--[if mso]> </td> <![endif]-->
                                                                                                                                    <!--[if mso]> <td align="center" valign="top"> <![endif]-->
                                                                                                                                    <table align="left" border="0" cellpadding="0" cellspacing="0" style="display:inline;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;">
                                                                                                                                        <tbody>
                                                                                                                                            <tr>
                                                                                                                                                <td class="mcnFollowContentItemContainer" style="padding-right:10px;padding-bottom:9px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="top">
                                                                                                                                                    <table border="0" cellpadding="0" cellspacing="0" class="mcnFollowContentItem k-table" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" width="100%">
                                                                                                                                                        <tbody>
                                                                                                                                                            <tr>
                                                                                                                                                                <td align="left" style="padding-top:5px;padding-right:10px;padding-bottom:5px;padding-left:9px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="middle">
                                                                                                                                                                    <table align="left" border="0" cellpadding="0" cellspacing="0" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" width="">
                                                                                                                                                                        <tbody>
                                                                                                                                                                            <tr>
                                                                                                                                                                                <td align="center" class="mcnFollowIconContent" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="middle" width="24"><a href="http://www.linkedin.com/company/cutwrights-ltd" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" target="_blank"><img height="24" src="../../../assets/images/color-linkedin-48.png" style="margin: 0 auto 0 0; display: block; border: 0px; height: auto; outline: none; text-decoration: none;" width="24"></a></td>
                                                                                                                                                                            </tr>
                                                                                                                                                                        </tbody>
                                                                                                                                                                    </table>
                                                                                                                                                                </td>
                                                                                                                                                            </tr>
                                                                                                                                                        </tbody>
                                                                                                                                                    </table>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                        </tbody>
                                                                                                                                    </table>
                                                                                                                                    <!--[if mso]> </td> <![endif]-->
                                                                                                                                    <!--[if mso]> <td align="center" valign="top"> <![endif]-->
                                                                                                                                    <table align="left" border="0" cellpadding="0" cellspacing="0" style="display:inline;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;">
                                                                                                                                        <tbody>
                                                                                                                                            <tr>
                                                                                                                                                <td class="mcnFollowContentItemContainer" style="padding-right:0;padding-bottom:9px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="top">
                                                                                                                                                    <table border="0" cellpadding="0" cellspacing="0" class="mcnFollowContentItem k-table" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" width="100%">
                                                                                                                                                        <tbody>
                                                                                                                                                            <tr>
                                                                                                                                                                <td align="left" style="padding-top:5px;padding-right:10px;padding-bottom:5px;padding-left:9px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="middle">
                                                                                                                                                                    <table align="left" border="0" cellpadding="0" cellspacing="0" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" width="">
                                                                                                                                                                        <tbody>
                                                                                                                                                                            <tr>
                                                                                                                                                                                <td align="center" class="mcnFollowIconContent" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="middle" width="24"><a href="https://www.instagram.com/cutwrightsltd/" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" target="_blank"><img height="24" src="../../../assets/images/color-instagram-48.png" style="margin: 0 auto 0 0; display: block; border: 0px; height: auto; outline: none; text-decoration: none;" width="24"></a></td>
                                                                                                                                                                            </tr>
                                                                                                                                                                        </tbody>
                                                                                                                                                                    </table>
                                                                                                                                                                </td>
                                                                                                                                                            </tr>
                                                                                                                                                        </tbody>
                                                                                                                                                    </table>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                        </tbody>
                                                                                                                                    </table>
                                                                                                                                    <!--[if mso]> </td> <![endif]-->
                                                                                                                                    <!--[if mso]> </tr> </table> <![endif]-->
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <table border="0" cellpadding="0" cellspacing="0" class="mcnTextBlock k-table" style="min-width:100%;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" width="100%">
                                                                        <tbody class="mcnTextBlockOuter">
                                                                            <tr>
                                                                                <td class="mcnTextBlockInner" style="padding-top:9px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" valign="top">
                                                                                    <!--[if mso]> <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;"> <tr> <![endif]-->
                                                                                    <!--[if mso]> <td valign="top" width="600" style="width:600px;"> <![endif]-->
                                                                                    <table align="left" border="0" cellpadding="0" cellspacing="0" class="mcnTextContentContainer k-table" style="max-width:100%;min-width:100%;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;" width="100%">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td class="mcnTextContent" style="padding:0px 18px 9px;font-style:normal;font-weight:normal;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;word-break:break-word;color:#656565;font-family:Helvetica;font-size:12px;line-height:150%;text-align:center;" valign="top">
                                                                                                    <h5 style="text-align:center;display:block;margin:0;padding:0;"><span style="font-family:trebuchet ms,lucida grande,lucida sans unicode,lucida sans,tahoma,sans-serif;"><span style="font-size:11px;">Monday to Friday 08:00-17:00<br>We are closed on bank holidays<br>Tel: 020 8755 4829<br>Email:&nbsp;<a href="mailto:info@cutwrights.com" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;color:#656565;font-weight:normal;text-decoration:underline;">{{"info@cutwrights.com"}}</a><br><br>Cutwrights Ltd registered in England and Wales.<br>Company No: 07010445<br>VAT No: 802164665</span></span><br><span style="font-family:trebuchet ms,lucida grande,lucida sans unicode,lucida sans,tahoma,sans-serif;"><span style="font-size:11px;"><a href="https://www.cutwrights.com/new/Resources/cutwrights-term-and-condition.pdf" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;color:#656565;font-weight:normal;text-decoration:underline;" target="_blank">Terms &amp; Conditions</a> | <a href="https://www.cutwrights.com/new/Resources/cutwrights-privacy.pdf" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;color:#656565;font-weight:normal;text-decoration:underline;" target="_blank">Privacy Policy</a><br>&copy;2018 all rights reserved.</span></span></h5>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                    <!--[if mso]> </td> <![endif]-->
                                                                                    <!--[if mso]> </tr> </table> <![endif]-->&nbsp;
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>

