<div class="mdf-board">
    <section class="banner-pages banner-bg-ourrange" role="banner">
        <div class="container">
            <div class="col-md-10 col-md-offset-1">
                <div class="banner-pages-text text-left">
                    <h1>MDF board</h1>
                     <h3>We offer over 300 different boards and 500 different edgings.</h3>
                </div><!-- banner text -->
            </div>
        </div>
    </section>

    <section id="intro" class="section">
        <div class="container">
            <div class="intro">
                <span class="site-navigation"><a [routerLink]="['/']">Home</a> » <a [routerLink]="['/board-range']">Board Range</a> » MDF board</span>
            </div>
        </div>
    </section>

    <section id="products-selection" class="no-padding">
        <div class="container products-selection-table">
            <div class="row no-gutter">
                <div class="col-md-6">
                    <a (click)="openImageModal(imagePopup,'../../../assets/images/images-our-range-6@2x.jpg')" class="gallery-box" title="MDF board" data-fancybox-group="thumb">
                        <img src="../../../assets/images/images-our-range-6@2x.jpg" alt="MDF board" class="img-responsive">
                    </a>
                </div>
                <div class="col-md-6">
                    <div class="products-list-item-title" style="text-align: center">
                        <p>MDF board</p>
                    </div>
                    <div class="products-list-item-properties" style="padding-bottom:14px">
                        <ul>
                            <li>
                                MDF is a medium density fibreboard used in many internal applications. The smooth surface of the boards is suitable for many finishing applications, they are ideal for machining into profiles too. We offer a comprehensive range of all types of MDF including Standard, Moisture Resistant, Fire Retardant and the latest innovation Tricoya- which can be used in exterior applications. The MDF we offer comes in a variety of thicknesses and board sizes. Our preferred brand to sell is Medite.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="services-drilling-list2" class="products-selection services-edging-list no-padding">
        <div class="container" style="background-color: #ffffff">
            
            <div class="row no-gutter" style="padding-top: 30px; padding-bottom: 30px;text-align: center">
                <div class="col-md-4" style="margin-bottom: 10px">
                    <a class="btn-white btn-white-large" [routerLink]="['/choose-your-boards']">Choose your boards</a>
                </div>
                <div class="col-md-4" style="margin-bottom: 10px">
                    <a class="btn-white btn-white-large" [routerLink]="['/customer/get-quote']">Get a Quote</a>
                </div>
                <div class="col-md-4" style="margin-bottom: 10px">
                    <a id="" [routerLink]="['/contact-us']" class="btn-white btn-white-large">Contact us</a>
                </div>
            </div>
            <div class="row no-gutter" style="margin-bottom:1px; background-color:#c2c2c2">
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Standard MDF</h4>
                        </div>
                        <img src="../../../assets/images/img-standard-mdf-1x@2.jpg" alt="Standard MDF" class="img-responsive">
                        <div class="services-list-desc">
                            <p>Our preferred Standard MDF is Medite Premier, these boards are a dependable good quality product with consistent thickness and face properties. There is a full range of thicknesses available from 3mm to 50mm and at varying board sizes.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Moisture Resistant MDF</h4>
                        </div>
                        <img src="../../../assets/images/img-moisture-resistant-mdf-1x@2.jpg" alt="Moisture Resistant MDF" class="img-responsive">
                        <div class="services-list-desc">
                            <p>Moisture Resistant (MR) MDF is designed for use in humid environments so is the preferred material for use in kitchens and bathrooms. These boards have high dimensional stability so make them suitable for machining and shaping.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Fire Resistant MDF</h4>
                        </div>
                        <img src="../../../assets/images/img-fire-resistant.mdf-1x@2.jpg" alt="Fire Resistant MDF" class="img-responsive">
                        <div class="services-list-desc">
                            <p>There are two different classes of flame retardant (FR) MDF available, either Class B or Class C which are specifically developed for use where Euro class B or C regulations are in place for MDF board.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Tricoya</h4>
                        </div>
                        <img src="../../../assets/images/img-tricoya-1x@2.jpg" alt="Tricoya" class="img-responsive">
                        <div class="services-list-desc">
                            <p>The ultimate MDF with a 50 Year guarantee when used outside, Tricoya is a highly versatile product. The board is manufactured using a high performance resin that bestows impressive characteristics such as superior dimensional stability, durability and fungal resistance even when exposed to changing weather conditions. These properties make Tricoya ideal for use as external cladding, fascia panels, signage as well as window and door components, or even speciality furniture for use in wet environments such as swimming pools.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Valchromat</h4>
                        </div>
                        <img src="../../../assets/images/img-valchromat-1x@2.jpg" alt="Valchromat" class="img-responsive">
                        <div class="services-list-desc">
                            <p>The Valchromat range is an evolution of MDF board that is a coloured throughout, and due to the organic dyes and resin used in the manufacturing process the board has an increased mechanical strength, higher moisture resistance and requires less effort of finish.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Exterior MDF</h4>
                        </div>
                        <img src="../../../assets/images/img-exterior-mdf-1x@2.jpg" alt="Exterior MDF" class="img-responsive">
                        <div class="services-list-desc">
                            <p>The boards have been developed to meet with building regulations for a range of external applications as well as allowing all the freedom of Standard MDF. Used frequently in sheltered exterior environments as signs, shop fronts and garden components this material has good durability but it is recommended that the panels are coated with a suitable weather proof finish.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Ecologique ZF MDF</h4>
                        </div>
                        <img src="../../../assets/images/img-ecologique-zf-mdf-1x@2.jpg" alt="Ecologique ZF MDF" class="img-responsive">
                        <div class="services-list-desc">
                            <p>Medite Ecologique is an MDF board with Zero added Formaldehyde (ZF), making this board relevant for use in environmentally sensitive situations where emissions must be the least possible. The ZF qualities of this material make it suitable for use in art galleries, hospitals or schools.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Ultralight</h4>
                        </div>
                        <img src="../../../assets/images/img-ultralight-1x@2.jpg" alt="Ultralight" class="img-responsive">
                        <div class="services-list-desc">
                            <p>As suggested by the name of this board the Medite Ultralite is a lighter weight alternative to standard MDF whilst still maintaining excellent stability and smoothness of surface. The Ultralite board can be used where easier handling or reduced tool wear is required, making it the choice for exhibition stands, or TV , film and theatre sets, or event furniture.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>V-Groove and beaded</h4>
                        </div>
                        <img src="../../../assets/images/img-v-groove-and-beaded-1x@2.jpg" alt="V-Groove and beaded" class="img-responsive">
                        <div class="services-list-desc">
                            <p>The MDF board has a pre-machined profile of the V-groove and bead, this gives the impression of the use of tongue and groove panelling without the added labour or problems with shrinkage. The panels are easy to install using standard fixings and are popular for use in all areas of the home as well as restaurants and other commercial buildings.</p>
                        </div>
                    </div>
            </div>
        </div>
    </section>
</div>
<ng-template #imagePopup>
    <form>
        <span class="close" style="opacity: 1.5;" (click)="closeModal()">&times;</span>
        <div class="row">
            <div class="col-md-12">
                    <img alt="image" class="img-responsive"
                    [src]="modalImageUrl" alt="image" class="img-responsive">
            </div>
            </div>
        </form>
</ng-template>