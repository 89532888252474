<div class="decorative-board">
    <section class="banner-pages banner-bg-ourrange" role="banner">
        <div class="container">
            <div class="col-md-10 col-md-offset-1">
                <div class="banner-pages-text text-left">
                    <h1>Decorative board</h1>
                    <h3>We offer over 300 different boards and 500 different edgings.</h3>
                </div><!-- banner text -->
            </div>
        </div>
    </section>

    <section id="intro" class="section">
        <div class="container">
            <div class="intro">
                <span class="site-navigation"><a [routerLink]="['/']">Home</a> » <a
                        [routerLink]="['/board-range']">Board Range</a> » Decorative board</span>
            </div>

        </div>
    </section>

    <section id="products-selection" class="no-padding">
        <div class="container products-selection-table">
            <div class="row no-gutter">
                <div class="col-md-6">
                    <a (click)="openImageModal(imagePopup,'../../../assets/images/18-MET-SAP4-GLOSS-ALV-MDF1mm.jpg')" class="gallery-box"
                        title="Decorative board" data-fancybox-group="thumb">
                        <img src="../../../assets/images/18-MET-SAP4-GLOSS-ALV-MDF1mm.jpg" alt="Decorative board"
                            class="img-responsive">
                    </a>
                </div>
                <div class="col-md-6">
                    <div class="products-list-item-title" style="text-align: center">
                        <p>Decorative board</p>
                    </div>
                    <div class="products-list-item-properties" style="padding-bottom:14px">
                        <ul>
                            <li>
                                Our wide range of decorative boards contains both contemporary and traditional styles;
                                with material finishes such as Matt, Gloss, Woodgrain, Leather and Uni Colours. These
                                versatile products can be used as door fronts, feature panels or as an alternative to a
                                Bonded Laminate. By combining these materials with carcasses made from melamine faced
                                board you can cultivate almost any style for kitchens, bedrooms, bathrooms and the shop
                                fitting industries.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="services-drilling-list2" class="products-selection services-edging-list no-padding">
        <div class="container" style="background-color: #ffffff">

            <div class="row no-gutter" style="padding-top: 30px; padding-bottom: 30px;text-align: center">
                <div class="col-md-4" style="margin-bottom: 10px">
                    <a class="btn-white btn-white-large" [routerLink]="['/choose-your-boards']">Choose your
                        boards</a>
                </div>
                <div class="col-md-4" style="margin-bottom: 10px">
                    <a class="btn-white btn-white-large" [routerLink]="['/customer/get-quote']">Get a Quote</a>
                </div>
                <div class="col-md-4" style="margin-bottom: 10px">
                    <a id="" [routerLink]="['/contact-us']" class="btn-white btn-white-large">Contact us</a>
                </div>
            </div>
            <div class="row no-gutter" style="margin-bottom:1px; background-color:#c2c2c2">
                <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                    <div class="services-list-title">
                        <h4>Matt</h4>
                    </div>
                    <img src="../../../assets/images/img-matt-1x@2.jpg" alt="Matt" class="img-responsive">
                    <div class="services-list-desc">
                        <p>The matt boards have a high quality design that is right on trend for that elegant,
                            contemporary look. These boards have a MDF core and a melamine face that has been through an
                            innovative coating process to give a distinct velvety appearance that absorbs light, giving
                            a true matt finish.</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                    <div class="services-list-title">
                        <h4>Gloss</h4>
                    </div>
                    <img src="../../../assets/images/img-gloss-1x@2.jpg" alt="Gloss" class="img-responsive">
                    <div class="services-list-desc">
                        <p>Our gloss boards are available in an array of different colours and styles. The boards are
                            manufactured using either an MDF or chipboard core which then has an acrylic or melamine
                            face bonded to it. All gloss materials are supplied with a protective surface film that once
                            removed, leaves the near mirror quality surface on display.</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                    <div class="services-list-title">
                        <h4>Fabric</h4>
                    </div>
                    <img src="../../../assets/images/img-fabric-1x@2.jpg" alt="Fabric" class="img-responsive">
                    <div class="services-list-desc">
                        <p>Boards with a fabric appearance are very popular for bedrooms and bathrooms where the finish
                            imitates similar textiles in the environment. The panels give a warm and tactile feel that
                            can be used in conjunction with both contemporary and traditional styles.</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                    <div class="services-list-title">
                        <h4>Stone</h4>
                    </div>
                    <img src="../../../assets/images/img-stone-1x@2.jpg" alt="Stone" class="img-responsive">
                    <div class="services-list-desc">
                        <p>With authentic reproductions of such materials as concrete, granite and minerals the stone
                            boards are being used as lightweight alternatives whilst still producing the required visual
                            effect.</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                    <div class="services-list-title">
                        <h4>Metal</h4>
                    </div>
                    <img src="../../../assets/images/img-metal-1x@2.jpg" alt="Metal" class="img-responsive">
                    <div class="services-list-desc">
                        <p>There are two different types of board that we offer that have a metallic effect; there are
                            melamine faced boards that have a chipboard core, or there is a MDF board which is coated on
                            one side with a real aluminium sheet and a white balancer on the reverse. These new options
                            are being used more and more as decorative wall panelling as well as fitted furniture.</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                    <div class="services-list-title">
                        <h4>Leather</h4>
                    </div>
                    <img src="../../../assets/images/img-leather-1x@2.jpg" alt="Leather" class="img-responsive">
                    <div class="services-list-desc">
                        <p>Either through the use of texture or the printed design our leather effect boards offer a
                            cost effective alternative for real leather panels. There are a range of colours available
                            that are made using a melamine face, this also produces a durable surface that’s easy to
                            keep clean.</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                    <div class="services-list-title">
                        <h4>Wood</h4>
                    </div>
                    <img src="../../../assets/images/img-wood-1x@2.jpg" alt="Wood" class="img-responsive">
                    <div class="services-list-desc">
                        <p>The quantity of woodgrain boards that are available is vast, ranging from simple, smooth
                            textured representations through to highly detailed designs with a synchronised woodgrain
                            texture that matches to the image. Whether a traditional or modern look is required there
                            will be a board to fit.</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                    <div class="services-list-title">
                        <h4>Colour</h4>
                    </div>
                    <img src="../../../assets/images/img-colour-1x@2.jpg" alt="Colour" class="img-responsive">
                    <div class="services-list-desc">
                        <p>From highly popular neutral colours to decadent, deeper colours, there will be something
                            within the colour chart that fits your needs. There is availability across different
                            textures and finishes such as matt, gloss and woodgrain.</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                    <div class="services-list-title">
                        <h4>White</h4>
                    </div>
                    <img src="../../../assets/images/img-white-1x@2.jpg" alt="White" class="img-responsive">
                    <div class="services-list-desc">
                        <p>The most commonly used boards for all applications, there are a variety of textures and
                            finishes available from mirror gloss, to boards with a patterned relief pressed onto the
                            surface. White boards can be acrylic faced on an MDF core or melamine faced on a chipboard,
                            MDF or even plywood core.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<ng-template #imagePopup>
    <form>
        <span class="close" style="opacity: 1.5;" (click)="closeModal()">&times;</span>
        <div class="row">
            <div class="col-md-12">
                    <img alt="image" class="img-responsive"
                    [src]="modalImageUrl" alt="image" class="img-responsive">
            </div>
            </div>
        </form>
</ng-template>