
   <!-- <app-header-navigations></app-header-navigations> -->
   <div class="content-area">
      <div class="content-area-content ">
         <div>
            <h2 class="font-weight">Customers</h2>
            <div>
               <div class="search">
                  <span class="search-body">
                     <div class="item">
                        <span class="label">
                           Find:
                        </span>
                        <span class="input">
                           <input [(ngModel)]="searchKey" (keyup.enter)="searchUsers()" name="Root$Main$ctl00$txtKeywordSearch" type="text"  id="Main_ctl00_txtKeywordSearch" class="txt textbox search-box" >
                        </span>
                           <div class="buttons" >
                              <input type="submit" name="Main_ctl00_btnSearch" (click)="searchUsers()" value="Search" id="Main_ctl00_btnSearch">
                           
                              <input  class="csv-button" type="submit"
                              value="Export to CSV" (click)="getOrdersCSV()" id="Main_ctl00_btnExportNewCustomersInCSV">
                           </div>
                     </div>
                     
                  </span>

               </div>
                  <ng-template #loading>
                     <div class="loader">
                     <app-loader></app-loader>
                     </div>
                  </ng-template>
               <div class="list" *ngIf='(users$ | async); else loading'>
                  <div>
                     <table class="grid" cellspacing="0" currentsort="CompanyName DESC" id="Main_ctl00_gridList"
                        style="border-collapse:collapse;table-layout:fixed; width:100%;overflow-wrap: break-word;">
                        <tbody>
                           <tr class="header-row">
                              <th scope="col"><a
                                    >Reference
                                    Number</a></th>
                              <th scope="col"><a
                                    >Name</a></th>
                              <th scope="col"><a>Company name</a></th>
                              <th scope="col"><a
                                    >Type</a></th>
                              <th scope="col"><a
                                    >Phone
                                    number</a></th>
                              <th scope="col"><a
                                    >Email</a>
                              </th>
                              <th scope="col">Password</th>
                              <!-- <th scope="col">Edit</th>
                              <th scope="col"><a
                                    >Archived</a>
                              </th>
                              <th scope="col">Delete</th> -->
                              <th scope="col">Impersonate</th>
                              <th scope="col">Delete</th>
                           </tr>
                           <div *ngIf="emptySearch" style="margin-top: 50px;">
                              <b>NO RESULTS FOUND. SORRY.</b>
                           </div>
                           <tr *ngFor="let user of users$ | async">
                              <td id="Main_ctl00_gridList_btnReference_0">
                                 {{user.reference}}
                              </td>
                              <td>
                                 <a (click)="callImpersonate(user.userId)" class="hyperlink" id="Main_ctl00_gridList_btnName_0"
                                    >{{user.displayName}}</a>
                              </td>
                              <td>
                                 {{user.companyName}}
                              </td>
                              <td>
                                 Trade
                              </td>
                              <td>
                                 {{user.mobile}}
                              </td>
                              <td>
                                 {{user.email}}
                              </td>
                              <td>
                                 <a (click)="fp(user.email)" id="Main_ctl00_gridList_btnPasswordChange_0"
                                   >Reset Password</a>
                              </td>
                              <!-- <td>
                                 <a><img name="Root$Main$ctl00$gridList$ctl02$btnEdit"
                                       id="Main_ctl00_gridList_btnEdit_0" title="Edit" src="../../../assets/images/edit.png"
                                       alt="Edit"></a>
                              </td>
                              <td>

                                 <a>Archive</a>
                              </td>
                              <td>
                                 <input type="image" name="Root$Main$ctl00$gridList$ctl02$btnDeleteCommand"
                                    id="Main_ctl00_gridList_btnDeleteCommand_0" title="Delete"
                                 
                                    src="../../../assets/images/delete.png" alt="Delete">
                              </td> -->
                              <td>
                                 <a (click)="callImpersonate(user.userId)"
                                    id="Main_ctl00_gridList_btnImpersonate_0">Impersonate</a>
                              </td>
                              <td>
                                 <a (click)="openDeleteUserModal(deleteUserPopup,user.email)"
                                 id="Main_ctl00_gridList_btnImpersonate_0">Delete</a>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
              
            </div>
         </div>

      </div>
   </div>
   <ng-template #resetPopup>
      <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"><b>{{modalHeader}}</b></h5>
            </div>
            <div class="modal-body">
                <p style="color: black;">{{ modalMessage }}</p>
                </div>
            <div class="modal-footer">
                <button type="button" class="modal-button" (click)="bsModalRef.hide()">Ok</button>
            </div>
      </div>
  </ng-template>

  <ng-template #deleteUserPopup>
   <div class="modal-content">
         <div class="modal-header">
             <h5 class="modal-title"><b>Are you sure ?</b></h5>
         </div>
         <div class="modal-body">
             <p style="color: black;">Do you want to DELETE this User ({{deleteUserEmail}}), once deleted this user can not be recovered?</p>
             </div>
         <div class="modal-footer">
             <button type="button" class="modal-button" (click)="deleteUser(deleteUserEmail);bsModalRef.hide()">Confirm</button>
             <button type="button" class="modal-button" (click)="bsModalRef.hide()">Cancel</button>
         </div>
   </div>
</ng-template>

