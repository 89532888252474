
<!-- 1. SIGN UP-->
<div [@fadeOut]="animationState" class="alert alert-danger alert-dismissible alert-overlap" role="alert" *ngIf="signUpDeclineFlag">
    <button (click)="signUpAlertDismiss()" type="button" class="close btn-close" data-dismiss="alert"
      aria-label="Close"><span aria-hidden="true">&times;</span></button>
    <strong>Warning!</strong> Please check your input.
  </div>
  <div  class="alert alert-success alert-dismissible alert-overlap" role="alert" *ngIf="signUpSuccessFlag">
    <button (click)="signUpAlertDismiss()" type="button" class="close btn-close" data-dismiss="alert"
      aria-label="Close"><span aria-hidden="true">&times;</span></button>
    <strong>Yay!</strong> You've Signed up Successfully. Please check your <strong>EMAIL</strong> for password.
  </div>

  <!-- 2. CREDIT LIMIT-->

  <div [@fadeOut]="animationState" class="alert alert-danger alert-dismissible alert-overlap" role="alert" *ngIf="clDeclineFlag">
    <button (click)="creditLimitAlertsDismiss()" type="button" class="close btn-close" data-dismiss="alert"
      aria-label="Close"><span aria-hidden="true">&times;</span></button>
    <strong>Warning!</strong> Please check your input.
  </div>

  <div [@fadeOut]="animationState" class="alert alert-success alert-dismissible alert-overlap" role="alert" *ngIf="clSuccessFlag">
    <button (click)="creditLimitAlertsDismiss()" type="button" class="close btn-close" data-dismiss="alert"
      aria-label="Close"><span aria-hidden="true">&times;</span></button>
    <strong>Yay!</strong> Credit Limit Updated.
  </div>