export interface Shaker {
  name: string;
  height: number;
  width: number;
  quantity: number;
  primed: boolean;
  weight: number;
  type: eShakerType;
  totalnumberofmidrails: number;
  midrails: Midrail[];
  hingeholes35mm: boolean;
  useinsertahinges: boolean;
  hingeside: eHingeSide;
  totalnumberofhingeholes: number;
  hinges: Hinge[];
  drillingdistance: number;
  // blumInserta: boolean; //removed by KD as dupicate variable wrt useinsertahinges
  shakerId:string;
}

export enum eShakerType {
  ShakerDoor = 0,
  ShakerDrawer = 1,
  PlainDrawer = 2
}
export const eShakerTypeM = {
  0: "ShakerDoor",
  1: "ShakerDrawer",
  2: "PlainDrawer "
}

export enum eHingeSide {
  Left = 0,
  Right = 1
}

export interface Midrail {
  position: number;
}

export interface Hinge {
  y: number;
}
