import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { getEnvironment } from '../../../environment-loader';


@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private environment = getEnvironment()
  //  private baseUrl = 'https://cutwrights-quote-prod.azurewebsites.net/api/customer/paymentinit?code=vturStVSKkdi1JaB56iOCPsM6WHiPIG4B9p7ALtBtVARAzFuaRQTMQ==&clientId=default';
  private baseUrl = this.environment.baseUrl
  constructor(private http: HttpClient) { }

  makePayment(paymentData: any) {
    // const params = new HttpParams({ fromObject: paymentData });
    return this.http.post(this.baseUrl, paymentData);
  }
}