<div class="main-body">
    <div class="content-area">
      <div class="content-area-content">

        <div class="sub-menu">
          <span *ngIf="impersonate == 'true'" [class]="subMenu.account ? 'selected': ''">
              <div class="item">
                  <a
                     (click)="subMenuClick('account')">
                      Account
                  </a>
              </div>
          </span>
          <span *ngIf="impersonate == 'true'" [class]="subMenu.creditLimit ? 'selected': ''">
              <div class="item">
                  <a
                  (click)="subMenuClick('creditLimit')">
                      Credit Limit
                  </a>
              </div>
          </span>
          <span *ngIf="impersonate == 'true'" [class]="subMenu.payment ? 'selected': ''">
            <div class="item">
                <a
                (click)="subMenuClick('payment')">
                    Payments
                </a>
            </div>
        </span>
        <span *ngIf="impersonate == 'true'" [class]="subMenu.discount ? 'selected': ''">
          <div class="item">
              <a
              (click)="subMenuClick('discount')">
                  Discounts
              </a>
          </div>
      </span>
       </div>
       <div class="credit-limit account-section" *ngIf="subMenu.creditLimit">
         <app-credit-limit></app-credit-limit>
       </div>

       <div class="payments account-section" *ngIf="subMenu.payment">
         <app-payments></app-payments>
       </div>

       <div class="discounts account-section" *ngIf="subMenu.discount">
        <app-discounts></app-discounts>
       </div>

        <form [formGroup]="customerForm" *ngIf="subMenu.account" class="account-section">
          <h1 class="mb-5">Customer details</h1>
          
          <div class="form">
            <!-- Company Name -->
            <div class="item">
              <span class="item-body">
                <div class="label">
                  <label for="Main_ctl00_txtCompanyName" id="Main_ctl00_lblCompanyName">
                    Company name:
                  </label>
                </div>
                <div class="input">
                  <input
                    formControlName="companyName"
                    type="text"
                    maxlength="200"
                    id="Main_ctl00_txtCompanyName"
                    class="textbox textbox"
                  />
                </div>
              </span>
            </div>
             <!-- Reference -->
             <div *ngIf="impersonate == 'true'" class="item">
              <span class="item-body">
                <div class="label">
                  <label for="Main_ctl00_txtCompanyName" id="Main_ctl00_lblCompanyName">
                    Reference Number: *
                  </label>
                </div>
                <div class="input">
                  <input
                    formControlName="referenceNumber"
                    type="text"
                    maxlength="200"
                    id="Main_ctl00_txtCompanyName"
                    class="textbox textbox"
                  />
                </div>
              </span>
            </div>
            <!-- Name -->
            <div class="item">
              <span class="item-body">
                <div class="label">
                  <label class="required" for="Main_ctl00_txtName" id="Main_ctl00_lblName">
                    Display Name:
                  </label>
                </div>
                <div class="input">
                  <input
                    formControlName="displayName"
                    type="text"
                    maxlength="200"
                    id="Main_ctl00_txtName"
                    class="textbox textbox"
                  />
                  <span id="Main_ctl00_rqvName" style="visibility:hidden;">*</span>
                  <div *ngIf="customerForm.get('displayName')?.hasError('required') && customerForm.get('displayName')?.touched" class="text-danger">
                    Display Name is required.
                  </div>
                </div>
                
              </span>
              
            </div>

            <div class="item">
              <span class="item-body">
                <div class="label">
                  <label class="required" for="Main_ctl00_txtName" id="Main_ctl00_lblName">
                    First Name:
                  </label>
                </div>
                <div class="input">
                  <input
                    formControlName="firstName"
                    type="text"
                    maxlength="200"
                    id="Main_ctl00_txtName"
                    class="textbox textbox"
                  />
                  <span id="Main_ctl00_rqvName" style="visibility:hidden;">*</span>
                  <div *ngIf="customerForm.get('firstName')?.hasError('required') && customerForm.get('firstName')?.touched" class="text-danger">
                    First Name is required.
                  </div>
                </div>
              </span>
            </div>

            <div class="item">
              <span class="item-body">
                <div class="label">
                  <label class="required" for="Main_ctl00_txtName" id="Main_ctl00_lblName">
                    Last Name:
                  </label>
                </div>
                <div class="input">
                  <input
                    formControlName="lastName"
                    type="text"
                    maxlength="200"
                    id="Main_ctl00_txtName"
                    class="textbox textbox"
                  />
                  <span id="Main_ctl00_rqvName" style="visibility:hidden;">*</span>
                  <div *ngIf="customerForm.get('lastName')?.hasError('required') && customerForm.get('lastName')?.touched" class="text-danger">
                    Last Name is required.
                  </div>
                </div>
              </span>
            </div>
            <!-- New Password -->
            <div class="item">
              <span class="item-body">
                <div class="label">
                  <label for="Main_ctl00_txtPassword" id="Main_ctl00_lblPassword">
                    New Password:
                  </label>
                </div>
                <div class="input">
                  <input
                    formControlName="newPassword"
                    [type]="newPasswordField? 'text' : 'password'"
                    type="password"
                    maxlength="100"
                    id="Main_ctl00_txtPassword"
                    class="textbox textbox"
                    autocomplete="nope"
                  />
                  <i class="fa fa-eye-slash" (click)="toggleNewPassword()"></i>
                  <span id="Main_ctl00_rqvPassword" style="visibility:hidden;">*</span>
                </div>
              </span>
            </div>
<!-- Confirm Password -->
<div class="item">
  <span class="item-body">
    <div class="label">
      <label for="Main_ctl00_txtPasswordConfirm" id="Main_ctl00_lblPassword">
        Confirm Password:
      </label>
    </div>
    <div class="input">
      <input
        formControlName="confirmPassword"
        [type]="confirmPasswordField ? 'text' : 'password'"
        maxlength="100"
        id="Main_ctl00_txtPasswordConfirm"
        class="textbox textbox"
      />
      <i class="fa fa-eye-slash" (click)="toggleConfirmPassword()"></i>
      <span id="Main_ctl00_rqvPassword" style="visibility:hidden;">*</span>
      
      <div *ngIf="(customerForm.get('confirmPassword')!.hasError('passwordMismatch') && customerForm.get('confirmPassword')!.touched)" class="text-danger">
        Passwords do not match.
      </div>
      
    </div>
  </span>
</div>

            <!-- Mobile phone number -->
            <div class="item">
              <span class="item-body">
                <div class="label">
                  <label for="Main_ctl00_txtMobilePhoneNumber" id="Main_ctl00_lblMobilePhoneNumber">
                    Mobile phone number:
                  </label>
                </div>
                <div class="input">
                  <input
                    formControlName="mobile"
                    type="text"
                    maxlength="20"
                    id="Main_ctl00_txtMobilePhoneNumber"
                    class="textbox textbox"
                  />
                </div>
              </span>
            </div>
  
            <!-- Send Quote Notifications checkbox -->
            <div class="item">
              <span class="item-body">
                <div class="label">
                  <label for="Main_ctl00_chkQuotesNotification" id="Main_ctl00_lblQuotesNotification">
                    Send Quote Notifications:
                  </label>
                </div>
                <div class="input">
                  <span><input id="Main_ctl00_chkQuotesNotification" type="checkbox" formControlName="notifications" /></span>
                </div>
              </span>
            </div>
  
            <!-- Send Marketing checkbox -->
            <div class="item">
              <span class="item-body">
                <div class="label">
                  <label for="Main_ctl00_chkMarketing" id="Main_ctl00_lblMarketing">
                    Send Marketing:
                  </label>
                </div>
                <div class="input">
                  <span><input id="Main_ctl00_chkMarketing" type="checkbox" formControlName="marketing" /></span>
                </div>
              </span>
            </div>
            <div formGroupName="billingAddress" >
            <!-- Billing address details -->
            <h2 id="Main_ctl00_groupBillingAddressDetails" class="box-header">
              Billing Address Details
            </h2>
            <div class="item">
              <span class="item-body">
                <div class="label">
                  <label for="Main_ctl00_txtAddress" id="Main_ctl00_lblAddress">
                    Address:
                  </label>
                </div>
                <div class="input" >
                  <textarea formControlName="address" rows="5" cols="20" id="Main_ctl00_txtAddress" class="text-area" maxlength="49"></textarea>
                </div>
              </span>
            </div>
            <div class="item">
              <span class="item-body">
                <div class="label">
                  <label for="Main_ctl00_txtCity" id="Main_ctl00_lblCity">
                    City:
                  </label>
                </div>
                <div class="input">
                  <input formControlName="city" type="text" maxlength="200" id="Main_ctl00_txtCity" class="textbox textbox" maxlength="30" />
                </div>
              </span>
            </div>
            <div class="item">
              <span class="item-body">
                <div class="label">
                  <label for="Main_ctl00_txtPostCode" id="Main_ctl00_lblPostCode">
                    Post code:
                  </label>
                </div>
                <div class="input">
                  <input  formControlName="postcode" type="text" maxlength="20" id="Main_ctl00_txtPostCode" class="textbox textbox" />
                  <div *ngIf="checkError('postcode')" class="text-danger">
                    Invalid postcode
                 </div>
                 <div *ngIf="(customerForm.get('billingAddress.address')!.hasError('maxlength') && customerForm.get('billingAddress.address')!.touched)" class="text-danger">
                  Address has exceeded the limit of 50 characters.
                </div>
                <span style="display: flex; align-items: center;"> 
                <!-- <button [ngClass]="(saveButtonBlur) ? 'button-cp':'button-cp-disable'" type="button" style="margin-top:23px; margin-bottom: 23px;margin-right: 23px;" >Check Billing Address</button> -->
                
                </span>
                </div>
              </span>
            </div>           
            </div>
          </div>
          <div class="buttons" style="margin-top:23px; margin-bottom: 23px;">
            <input type="submit" (click)="checkPostCode()" value="Save" [disabled]="customerForm.invalid" style="float: left;" [ngClass]="(customerForm.valid  && !saveButtonBlur) ? '':'button-disable'"/>
          </div>
          <h2>Delivery Addresses</h2>
          <div id="Main_ctl01_upList" isupdatepanel="true">
              <div class="list">
                <button (click)="openFormPopup()" class="add-address mb-2">Add Address</button>
                  <div>
                      <table class="grid" cellspacing="0" currentsort="" id="Main_ctl01_gridList"
                          style="border-collapse:collapse;">
                          <tbody>
                              <tr class="header-row">
                                  <th scope="col"><a
                                          >Address</a>
                                  </th>
                                  <th scope="col"><a
                                         >City</a>
                                  </th>
                                  <th scope="col"><a
                                         >Postcode</a>
                                  </th>
                                  <th scope="col">Edit</th>
                              </tr>
                              <tr *ngFor="let adr of user.address;let i = index">
                                  <td>
                                    {{adr.address}}
                                  </td>
                                  <td>
                                    {{adr.city}}
                                  </td>
                                  <td>
                                    {{adr.postcode}}
                                  </td>
                                  <td>
                                      <input (click)="openEditAddressPopup(adr,i)" type="image" name="Root$Main$ctl01$gridList$ctl03$btnEdit"
                                          id="Main_ctl01_gridList_btnEdit_0" src="../../../assets/images/edit.png"
                                          alt="Edit"
                                        >
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
              </div>
          </div>
          
        </form>
      </div>
    </div>
  </div>
  <ng-template #successPopup>
    <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title"><b>{{modalHeading}}</b></h5>
          </div>
          <div class="modal-body">
              <p style="color: black;">{{modalMessage}}</p>
              </div>
          <div class="modal-footer">
              <button type="button" class="modal-button" (click)="bsModalRef.hide(); modalHide()">Ok</button>
          </div>
    </div>
</ng-template>

  