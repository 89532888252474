<div class="plywood-board">
    <section class="banner-pages banner-bg-ourrange" role="banner">
        <div class="container">
            <div class="col-md-10 col-md-offset-1">
                <div class="banner-pages-text text-left">
                    <h1>Plywood board</h1>
                    <h3>We offer over 700 different boards and 1000 different edgings.</h3>
                </div>
            </div>
        </div>
    </section>

    <section id="intro" class="section">
        <div class="container">
            <div class="intro">
                <span class="site-navigation"><a [routerLink]="['/']">Home</a> » <a [routerLink]="['/board-range']">Board Range</a> » Plywood board</span>
            </div>
        </div>
    </section>

    <section id="products-selection" class="no-padding">
        <div class="container products-selection-table">
            <div class="row no-gutter">
                <div class="col-md-6">
                    <a (click)="openImageModal(imagePopup,'../../../assets/images/images-our-range-5@2x.jpg')" class="gallery-box" title="Plywood board" data-fancybox-group="thumb">
                        <img src="../../../assets/images/images-our-range-5@2x.jpg" alt="Plywood board" class="img-responsive">
                    </a>
                </div>
                <div class="col-md-6">
                    <div class="products-list-item-title" style="text-align: center">
                        <p>Plywood board</p>
                    </div>
                    <div class="products-list-item-properties" style="padding-bottom:14px">
                        <ul>
                            <li>
                                Plywood is an extremely reliable and consistent board, renowned for being lightweight, having superior strength, stiffness and dimensional stability, making it ideal for construction material for a range of requirements. Ideal for floors, walls and roofs. We have thicknesses from 4mm- 24mm in finishes including Birch, Marine and Fire rated.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="services-drilling-list2" class="products-selection services-edging-list no-padding">
        <div class="container" style="background-color: #ffffff">
            
            <div class="row no-gutter" style="padding-top: 30px; padding-bottom: 30px;text-align: center">
                <div class="col-md-4" style="margin-bottom: 10px">
                    <a class="btn-white btn-white-large" [routerLink]="['/choose-your-boards']">Choose your boards</a>
                </div>
                <div class="col-md-4" style="margin-bottom: 10px">
                    <a class="btn-white btn-white-large" [routerLink]="['/customer/get-quote']">Get a Quote</a>
                </div>
                <div class="col-md-4" style="margin-bottom: 10px">
                    <a id="" [routerLink]="['/contact-us']" class="btn-white btn-white-large">Contact us</a>
                </div>
            </div>
            <div class="row no-gutter" style="margin-bottom:1px; background-color:#c2c2c2">
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>OSB</h4>
                        </div>
                        <img src="../../../assets/images/img-osb-1x@2.jpg" alt="OSB" class="img-responsive">
                        <div class="services-list-desc">
                            <p>Oriented strand board (OSB), also known as SmartPly or sterling board is an engineered chipboard that is manufactured by compressing layers of wood strands with the addition of adhesives. The board has high mechanical properties which makes it ideal for use in load-bearing applications, such as flooring, wall covering and roofing.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Birchply</h4>
                        </div>
                        <img src="../../../assets/images/img-birchply-1x@2.jpg" alt="Birchply" class="img-responsive">
                        <div class="services-list-desc">
                            <p>Birch Plywood has excellent mechanical properties due to its multiple layers or ‘plies’ of wood veneer. The board has a pale colour and fine sanded finish making it popular for furniture design. Available in a range of face grades with ‘S’ being virtually blemish free, then to ‘BB’ where all major defects are replaced by wooden patches.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Standard Ply</h4>
                        </div>
                        <img src="../../../assets/images/img-standard-ply-1x@2.jpg" alt="Standard Ply" class="img-responsive">
                        <div class="services-list-desc">
                            <p>Our Standard Ply is a Hardwood Plywood that is known for its surface hardness, and wear resistance making it suitable for use in construction. With other characteristics such as excellent strength and resistance to creep this material is often used as wall and floor structures.</p>
                        </div>
                    </div>
            </div>
        </div>
    </section>
</div>
<ng-template #imagePopup>
    <form>
        <span class="close" style="opacity: 1.5;" (click)="closeModal()">&times;</span>
        <div class="row">
            <div class="col-md-12">
                    <img alt="image" class="img-responsive"
                    [src]="modalImageUrl" alt="image" class="img-responsive">
            </div>
            </div>
        </form>
</ng-template>