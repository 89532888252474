<div class="log-container">
    <h2>Job full log</h2>
    <div id="Main_ctl00_upList" isupdatepanel="true">
        
          <div class="list">
             <div>
            <table class="grid" cellspacing="0" currentsort="" id="Main_ctl00_gridList" style="border-collapse:collapse;">
                <tbody><tr class="header-row">
                    <th scope="col"><a >Date</a></th><th scope="col"><a href="">Action</a></th><th scope="col"><a href="">Comments</a></th>
                </tr><tr *ngFor="let log of orderLogs.details">
                    <td>
                         {{log.createdon| date: 'dd/MM/yy HH:mm' }}
                      </td><td>
                         {{log.action}}
                      </td><td>
                        {{log.username}}
                      </td><td>
                         {{log.description}}
                      </td>
                </tr>
            </tbody></table>
        </div>
          </div>
    </div>
       </div>
