<div class="edging-container">
    <section class="banner-pages banner-bg-edging" role="banner">
        <div class="container">
            <div class="col-md-10 col-md-offset-1">
                <div class="banner-pages-text text-left">
                    <h1>Edging</h1>
                    <h2><em>"Edge your way to an easier life"</em></h2>
                </div><!-- banner text -->
            </div>
        </div>
    </section>

    <section id="intro" class="section">
        <div class="container">
            <div class="intro">
                <span class="site-navigation"><a [routerLink]="['/']">Home</a> » Services » Edging</span>
                Our highly efficient Industrial Homag edgebanders are equipped with the latest technology to ensure we
                can offer an exceptional finish on a vast range of different edge banding options.
            </div>
            <div style="margin-top: 30px">

                <a [routerLink]="['/customer/get-quote']" class="btn-white btn-white-large">Get a Quote</a>
            </div>
        </div>
    </section>

    <div class="page-container">
        <section id="services" class="services no-padding">
            <div class="container">
                <div class="row no-gutter">
                    <div class="col-lg-6 col-md-6 col-sm-12 services-box-left text-left">
                        <p>Offering a competitive price and exceptional quality comes as standard when using Cutwrights.
                            Current trends of high gloss boards, matt finishes and deep textured melamine boards are
                            just a few of the surfaces our edgebanders are faced with daily. You can be assured our
                            machines are more than capable of applying over fourteen different edge types to a finish
                            that will exceed your expectations.</p>

                        <p>We have two German Homag edgebanders - an Ambition 2274 and Ambition 2264. These production
                            machines can run at speeds of up to 25 metres a minute so larger volumes of edging are very
                            welcome, while smaller runs can equally be accommodated as the machines are fully equipped
                            to automatically change between tooling setups for different edge types.</p>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 service-box-right">
                        <img src="../../../assets/images/images-edging-holczma-2264.jpg" alt="Homag 2264"
                            class="img-responsive">
                    </div>
                </div>
                <div class="row no-gutter">
                    <div class="col-lg-6 col-md-6 col-sm-12 services-box-left text-left">
                        <img src="../../../assets/images/images-edging-holczma-2274.jpg" alt="Homag 2274"
                            class="img-responsive">
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 services-box-right">
                        <p>We can offer PUR bonded edges.</p>

                        <p>What does this mean for you?<br>
                            PUR (Polyurethane Reactive) glue edgbanding technology is the ultimate solution were high
                            bond strength and moisture resistance are required. This high bond strength (chemical cross
                            link) is maintained for panels that are to be used in wet or humid environments, where
                            alternate systems would not be suitable. Another benefit of PUR is that you can achieve an
                            almost invisible glue line.</p>

                        <p>How we achieve our finish?<br>
                            Once a panel has entered the machine it passes a pre-milling station that trims the edge of
                            the panel to ensure a clean crisp edge that's chip free, prior to the edging being applied.
                            Then the soft touch finishing stations get to work, a typical panel will pass through around
                            twelve stations in total. These stations are key to achieving an exceptional finish. Once
                            the panel leaves the machine, there is no need for further manual finishing.</p>
                    </div>
                </div>
            </div>
        </section>
        <section id="services-edging-list" class="services-edging-list no-padding">
            <div class="container">
                <h3>Machine capabilities explained</h3>
                <div class="row no-gutter">
                    <div class="col-lg-2 col-md-2 col-sm-2 services-list animated fadeIn">
                        <img src="../../../assets/images/img-machine-capabilities-1.jpg" alt="" class="img-responsive">
                        <div class="overlay"></div><!-- overlay -->
                        <div class="services-list-title">
                            <p>Minimum / Maximum thickness of panel that can be edged 8mm – 60mm</p>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2 services-list animated fadeIn">
                        <img src="../../../assets/images/img-machine-capabilities-2.jpg" alt="" class="img-responsive">
                        <div class="overlay"></div><!-- overlay -->
                        <div class="services-list-title">
                            <p>Minimum length of panel that can be edged without a recut 150mm</p>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2 services-list animated fadeIn">
                        <img src="../../../assets/images/img-machine-capabilities-3.jpg" alt="" class="img-responsive">
                        <div class="overlay"></div><!-- overlay -->
                        <div class="services-list-title">
                            <p>Minimum length of panel that can be egded with a recut 10mm</p>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2 services-list animated fadeIn">
                        <img src="../../../assets/images/img-machine-capabilities-4.jpg" alt="" class="img-responsive">
                        <div class="overlay"></div><!-- overlay -->
                        <div class="services-list-title">
                            <p>Maximum length of panel that can be edged 3660mm</p>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2 services-list animated fadeIn">
                        <img src="../../../assets/images/img-machine-capabilities-5.jpg" alt="" class="img-responsive">
                        <div class="overlay"></div><!-- overlay -->
                        <div class="services-list-title">
                            <p>Minimum thickness of panel we can corner round 8mm</p>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2 services-list animated fadeIn">
                        <img src="../../../assets/images/img-machine-capabilities-6.jpg" alt="" class="img-responsive">
                        <div class="overlay"></div><!-- overlay -->
                        <div class="services-list-title">
                            <p>Maximum thickness of panel we can corner round 60mm</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="services-drilling-list2" class="services-drilling-list serviceedging no-padding">
            <div class="container" style="background-color: #ffffff">
                <h3>Edging options explained</h3>

                <div class="row no-gutter">

                    <div class="col-md-6 services-list animated fadeIn">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="services-list-title">
                                    <h4>0.3mm Preparation Tape (Radius) </h4>
                                </div>
                                <img src="../../../assets/images/img-edging-1.jpg"
                                    alt="0.3mm Preparation Tape (Radius)" class="img-responsive">
                            </div>
                            <div class="col-md-6">
                                <div class="services-list-title">
                                    <h4>0.3mm Preparation Tape (Square)</h4>
                                </div>
                                <img src="../../../assets/images/img-edging-2.jpg"
                                    alt="0.3mm Preparation Tape (Square)" class="img-responsive">
                            </div>
                        </div>
                        <div class="services-list-desc">
                            <p>If you have edges that would be visible and require sanding we would recommend that you
                                apply preparation tape it will give a professional finish and most importantly save
                                valuable time not having to sand the edges that need to be painted. Edges finished in
                                preparation tape can accept almost any paint or lacquer finish. It’s also great at
                                preventing water penetration into the edges too. Available in two options, square and
                                2.0mm radius.</p>
                        </div>
                    </div>

                    <!-- <div class="col-md-6 services-list animated fadeIn">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="services-list-title">
                                    <h4>3.0mm Solid MDF Edging (Radius)</h4>
                                </div>
                                <img src="../../../assets/images/img-edging-3.jpg"
                                    alt="3.0mm Solid MDF Edging (Radius)" class="img-responsive">
                            </div>
                            <div class="col-md-6">
                                <div class="services-list-title">
                                    <h4>3.0mm Solid MDF Edging (Square)</h4>
                                </div>
                                <img src="../../../assets/images/img-edging-4.jpg"
                                    alt="3.0mm Solid MDF Edging (Square)" class="img-responsive">
                            </div>
                        </div>
                        <div class="services-list-desc">
                            <p>Used exclusively with our RAW MDF lightweight honeycomb boards which customers use to
                                create chunky shelving and furniture. Suitable for paint finishes of any kind the edging
                                can be supplied with square or with 2.0mm radius finish.</p>
                        </div>
                    </div> -->

                    <div class="col-md-6 services-list animated fadeIn">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="services-list-title">
                                    <h4>0.8mm ABS (Radius)</h4>
                                </div>
                                <img src="../../../assets/images/img-edging-7.jpg" alt="0.8mm ABS (Radius)"
                                    class="img-responsive">
                            </div>
                            <div class="col-md-6">
                                <div class="services-list-title">
                                    <h4>1.0mm ABS (Radius)</h4>
                                </div>
                                <img src="../../../assets/images/img-edging-8.jpg" alt="1.0mm ABS (Radius)"
                                    class="img-responsive">
                            </div>
                        </div>
                        <div class="services-list-desc">
                            <p>0.8mm &amp; 1.0mm ABS is the new 2.0mm edging because of a trend toward seamless edges
                                and the use of melamine faced panels being used for door frontals the 0.8mm &amp; 1.0mm
                                suit this application better as it shows less of the core plain colour of the edging
                                which is particularly noticeable on wood grained boards.</p>
                        </div>
                    </div>

                    <!-- <div class="col-md-6 services-list animated fadeIn">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="services-list-title">
                                    <h4>0.4mm ABS (Radius)</h4>
                                </div>
                                <img src="../../../assets/images/img-edging-5.jpg" alt="0.4mm ABS (Radius)"
                                    class="img-responsive">
                            </div>
                            <div class="col-md-6">
                                <div class="services-list-title">
                                    <h4>0.4mm ABS (Radius)</h4>
                                </div>
                                <img src="../../../assets/images/img-edging-6.jpg" alt="0.4mm ABS (Radius)"
                                    class="img-responsive">
                            </div>
                        </div>
                        <div class="services-list-desc">
                            <p>0.4mm ABS Can be used for front edges of furniture but not recommended as it's not as
                                durable as 0.8mm &amp; 1.0mm thick, we would say it's mainly used to cover or seal
                                exposed chipboard edge within furniture manufacture.</p>
                        </div>
                    </div> -->

                    <div class="col-md-6 services-list animated fadeIn">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="services-list-title">
                                    <h4>2.0mm ABS (Radius)</h4>
                                </div>
                                <img src="../../../assets/images/img-edging-9.jpg" alt="2.0mm ABS (Radius)"
                                    class="img-responsive">
                            </div>
                            <div class="col-md-6">
                                <div class="services-list-title">
                                    <h4>2.0mm ABS (Radius)</h4>
                                </div>
                                <img src="../../../assets/images/img-edging-10.jpg" alt="2.0mm ABS (Radius)"
                                    class="img-responsive">
                            </div>
                        </div>
                        <div class="services-list-desc">
                            <p>By far the most commonly requested edge type we apply, mainly used for front edges of
                                carcases, doors and desk top panels it offers a high impact protection. Panel edged with
                                2.0mm will have softer edges and corners due to the thickness of the edging and 2.0mm
                                radius finish.</p>
                        </div>
                    </div>

                    <div class="col-md-6 services-list animated fadeIn">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="services-list-title">
                                    <h4>2.0mm Extra Wide ABS (Radius)</h4>
                                </div>
                                <img src="../../../assets/images/img-edging-11.jpg" alt="2.0mm Extra Wide ABS (Radius)"
                                    class="img-responsive">
                            </div>
                            <div class="col-md-6">
                                <div class="services-list-title">
                                    <h4>2.0mm Extra Wide ABS (Radius)</h4>
                                </div>
                                <img src="../../../assets/images/img-edging-12.jpg" alt="2.0mm Extra Wide ABS (Radius)"
                                    class="img-responsive">
                            </div>
                        </div>
                        <div class="services-list-desc">
                            <p>Used mostly with the Egger Eurolight lightweight panels with honeycomb substrates or
                                bonded panels with chipboard / MDF substrates. Thicknesses vary in size up to and
                                occasionally over 50mm thick. The Egger Eurolight boards are used to create chunky desk
                                tops, shelves, and walk-in wardrobes and this edging finishes the panels perfectly.</p>
                        </div>
                    </div>

                    <div class="col-md-6 services-list animated fadeIn">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="services-list-title">
                                    <h4>1.0mm 3D Acrylic (Radius)</h4>
                                </div>
                                <img src="../../../assets/images/img-edging-13.jpg" alt="1.0mm 3D Acrylic (Radius)"
                                    class="img-responsive">
                            </div>
                            <div class="col-md-6">
                                <div class="services-list-title">
                                    <h4>1.0mm 3D Acrylic (Radius)</h4>
                                </div>
                                <img src="../../../assets/images/img-edging-14.jpg" alt="1.0mm 3D Acrylic (Radius)"
                                    class="img-responsive">
                            </div>
                        </div>
                        <div class="services-list-desc">
                            <p>3D Edge banding is made of transparent acrylic, which is printed or coated on the reverse
                                side of the edging the advantage of this is that the print effect can never be marked
                                once it is applied. The product also has outstanding impact and scratch resistance and
                                looks great too.</p>
                        </div>
                    </div>

                    <div class="col-md-6 services-list animated fadeIn">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="services-list-title">
                                    <h4>1.3mm Acrylic Gloss (Radius)</h4>
                                </div>
                                <img src="../../../assets/images/img-edging-15.jpg" alt="1.3mm Acrylic Gloss (Radius)"
                                    class="img-responsive">
                            </div>
                            <div class="col-md-6">
                                <div class="services-list-title">
                                    <h4>1.3mm Acrylic Gloss (Radius)</h4>
                                </div>
                                <img src="../../../assets/images/img-edging-16.jpg" alt="1.3mm Acrylic Gloss (Radius)"
                                    class="img-responsive">
                            </div>
                        </div>
                        <div class="services-list-desc">
                            <p>This edging is used with our range of acrylic faced boards and UV Lacquered range. More
                                commonly used for edging panels that are being used for kitchens and wardrobes doors,
                                it’s hard wearing.</p>
                        </div>
                    </div>

                    <div class="col-md-6 services-list animated fadeIn">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="services-list-title">
                                    <h4>0.5mm Real Wood Veneer (Radius)</h4>
                                </div>
                                <img src="../../../assets/images/img-edging-17.jpg"
                                    alt="0.5mm Real Wood Veneer (Radius)" class="img-responsive">
                            </div>
                            <div class="col-md-6">
                                <div class="services-list-title">
                                    <h4>0.5mm Real Wood Veneer (Radius)</h4>
                                </div>
                                <img src="../../../assets/images/img-edging-18.jpg"
                                    alt="0.5mm Real Wood Veneer (Radius)" class="img-responsive">
                            </div>
                        </div>
                        <div class="services-list-desc">
                            <p>A cost effective way of finishing real wood veneered panels giving a square edge due to
                                the thickness but minimal impact durability.</p>
                        </div>
                    </div>

                    <div class="col-md-6 services-list animated fadeIn">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="services-list-title">
                                    <h4>2.0mm Real Wood Veneer (Radius)</h4>
                                </div>
                                <img src="../../../assets/images/img-edging-19.jpg"
                                    alt="2.0mm Real Wood Veneer (Radius)" class="img-responsive">
                            </div>
                            <div class="col-md-6">
                                <div class="services-list-title">
                                    <h4>2.0mm Real Wood Veneer (Square)</h4>
                                </div>
                                <img src="../../../assets/images/img-edging-20.jpg"
                                    alt="2.0mm Real Wood Veneer (Radius)" class="img-responsive">
                            </div>
                        </div>
                        <div class="services-list-desc">
                            <p>Mainly used for front edges of carcases, shelves, doors and desk top panels it offers a
                                high impact protection. Panels edged with 2.0mm will have softer edges and corners due
                                to the thickness of the edging and radius finishing. (NB This edging is produced by
                                laminating 4 x 0.5mm edges so we don’t advise the face of the edging to be overly sanded
                                or planed down).</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>