<div>
    <h1>Payments and refunds</h1>
    <div id="Main_ctl00_upList" isupdatepanel="true">
        <div class="list">
            <div>
                <table class="grid" cellspacing="0" currentsort="" id="Main_ctl00_gridList"
                    style="border-collapse:collapse;">
                    <tbody>
                        <tr class="header-row">
                            <th scope="col">&nbsp;</th>
                            <th scope="col">Order</th>
                            <th scope="col"><a>Date</a></th>
                            <th scope="col"><a>Amount</a>
                            </th>
                            <th scope="col"><a>Payment
                                    type</a></th>
                            <th scope="col"><a>Recorded
                                    by</a></th>
                            <th scope="col"><a>Comments</a>
                            </th>
                        </tr>
                        <ng-container *ngFor="let payment of payments">
                            <tr *ngIf="payment.OrderId !== null">
                                <td>
                                    {{payment.IsPayment ? 'Payment' : 'Refund'}}
                                </td>
                                <td>
                                    {{payment.JobId}}
                                </td>
                                <td>
                                    {{payment.ConfirmedOn | date: 'dd/MM/yy HH:mm'}}
                                </td>
                                <td>
                                    £{{truncateValue(payment.Amount)}}
                                </td>
                                <td>
                                    {{reversePaymentType[payment.PaymentType]}}
                                </td>
                                <td>
                                    {{payment.RecordedBy}}
                                </td>
                                <td>
                                    {{payment.Comment}}
                                </td>
                            </tr>
                        </ng-container>
                        
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="buttons">
        <input type="submit" class="btns" name="Root$Main$ctl00$btnRecordAPayment" (click)="openRecordAPaymentPopup(addPaymentTemplate)" value="Record a payment">
        <input type="submit" class="btns" name="Root$Main$ctl00$btnRecordARefund" (click)="openRecordAPaymentPopup(recordTemplate)" value="Record a refund">
        <input type="submit" name="Root$Main$ctl00$btnExportAsCsv" value="Export as csv" id="Main_ctl00_btnExportAsCsv">
    </div>
</div>

<!-- <div class="deffered-trans">
    <h1>Deferred Payments</h1>
    <div id="Main_ctl00_upList" isupdatepanel="true">
        <div class="list">
            <div>
                <table class="grid" cellspacing="0" currentsort="" id="Main_ctl00_gridList"
                    style="border-collapse:collapse;">
                    <tbody>
                        <tr class="header-row">
                            <th scope="col">&nbsp;</th>
                            <th scope="col">Order</th>
                            <th scope="col"><a>Date</a></th>
                            <th scope="col"><a>Amount</a>
                            </th>
                            <th scope="col"><a>Payment
                                    type</a></th>
                            <th scope="col"><a>Recorded
                                    by</a></th>
                            <th scope="col"><a>Comments</a>
                            </th>
                        </tr>
                        <tr>
                            <td>
                                Payment
                            </td>
                            <td>
                                Q_DCSL_DEV_320905
                            </td>
                            <td>
                                01/08/2022
                            </td>
                            <td>
                                £92.49
                            </td>
                            <td>
                                Card payment
                            </td>
                            <td>
                                3 X 600 Base Units
                            </td>
                            <td>
                                Sage Pay Payment 14740526
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div> -->
<!-- </div> -->

<ng-template #recordTemplate>
<form [formGroup]="myForm" (ngSubmit)="onSubmit('refund')">
    <h2 class="mb-5 font-weight">Refund details</h2>
    <h2 *ngIf="this.selectedOrder != ''">Refund {{selectedOrderD.jobId}} : £{{selectedOrderD.totalinvoiceamount}}</h2>
    <span class="close" (click)="closeModal()">&times;</span>
      <div class="form-group">
        <label for="address">Order:</label>
        <select (change)="setSelectedOrder('refund')" formControlName="QuoteId" name="Root$Main$ctl00$lstOrder">
            <option  selected="selected" value="">---Select---</option>
            <option *ngFor="let order of rOrders" [value]="order.quoteId">{{order.jobId}}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="city">Payment type:</label>
        <select formControlName="PaymentType" name="Root$Main$ctl00$lstPaymentType" id="Main_ctl00_lstPaymentType">
            <option selected="selected" value="00000000-0000-0000-0000-000000000000">---Select---</option>
            <option value="3">Bank transfer</option>
                    <option value="0">Card payment</option>
                    <option value="1">Cash</option>
                    <option value="2">Cheque</option>
                    <option value="4">Write off</option>
        </select>
      </div>
      <div class="form-group">
        <label for="postcode">Comments:</label>
        <textarea formControlName="Comment" cols="10"></textarea>
      </div>
      <div class="form-group">
       <div>Please explain the circumstances leading to this refund</div>
      </div>
      <div class="buttons">
        <input type="submit" name="Root$Main$ctl00$btnCancel" value="Cancel" (click)="closeModal()"  id="Main_ctl00_btnCancel">
        <input type="submit" class="save-btn" name="Root$Main$ctl00$btnSave" value="Save" id="Main_ctl00_btnSave">
     </div>
    </form>
    </ng-template>

    <ng-template #addPaymentTemplate>
         
        <form [formGroup]="myForm" (ngSubmit)="onSubmit('payment')">
            <h2 class="mb-5 font-weight">Record a payment</h2>
            <h2 *ngIf="this.selectedOrder != ''">Balance due for {{selectedOrderD.jobId}} : £{{truncateValue(selectedOrderD.totalinvoiceamount)}}</h2>
            <span class="close" (click)="closeModal()">&times;</span>
              <div class="form-group">
                <label for="address">Date:</label>
                <input formControlName="CreatedOn" type="date" />
              </div>
              <div class="form-group">
                <label for="city">Order:</label>
                <select (change)="setSelectedOrder('payment')" formControlName="QuoteId" name="Root$Main$ctl00$lstPaymentType" id="Main_ctl00_lstPaymentType">
                    <option selected="selected" value="00000000-0000-0000-0000-000000000000">---Select---</option>
                    <option *ngFor="let order of pOrders" [value]="order.quoteId">{{order.jobId}}</option>
                </select>
              </div>
              <div class="form-group">
                <label for="address">Amount:</label>
                <span>£</span><input formControlName="Amount" class="amt" type="text" />
              </div>
              <div class="form-group">
                <label for="city">Payment type:</label>
                <select formControlName="PaymentType" name="Root$Main$ctl00$lstPaymentType" id="Main_ctl00_lstPaymentType">
                    <option selected="selected" value="00000000-0000-0000-0000-000000000000">---Select---</option>
                    <option value="3">Bank transfer</option>
                    <option value="0">Card payment</option>
                    <option value="1">Cash</option>
                    <option value="2">Cheque</option>
                    <option value="4">Write off</option>
                </select>
              </div>
              <div class="form-group">
                <label for="postcode">Comments:</label>
                <textarea formControlName="Comment" cols="10"></textarea>
              </div>
              <div class="buttons">
                <input type="submit" name="Root$Main$ctl00$btnCancel" value="Cancel" (click)="closeModal()"  id="Main_ctl00_btnCancel">
                <input type="submit" class="save-btn" name="Root$Main$ctl00$btnSave" value="Save" id="Main_ctl00_btnSave">
             </div>
            </form>
            </ng-template>
