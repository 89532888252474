import { Component } from '@angular/core';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.scss']
})
export class DeliveryComponent {
  constructor(private metadataService: MetadataService){
    
  }
  ngOnInit(): void {
    this.metadataService.setCanonicalLink();
    this.metadataService.setTitle('Delivery Information - London , Mainland UK & Europe - Cutwrights');
    this.metadataService.setDescription('We deliver to you, when and where you want. Delivery available to London, Mainland UK, Europe & Beyond. Get in contact for further information.');
  }


}
