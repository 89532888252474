import { Component, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EndpointApiService } from 'src/app/service/endpoint-api.service';

@Component({
  selector: 'app-discounts',
  templateUrl: './discounts.component.html',
  styleUrls: ['./discounts.component.scss']
})
export class DiscountsComponent {
  discountDictionary: Record<string, number> = {}
  user:any;
  discountKeys: string[] = [
    'cutting',
    'complexcutting',
    'edging',
    'drilling',
    'product',
    'tradefurniture',
    'deliverycharges',
    'priming',
  ];
  bsModalRef!: BsModalRef;
  discountEdit = 0;
  currentDiscount!: string;
  constructor(private modalService: BsModalService, private epService:EndpointApiService) {

  }

  ngOnInit(){
    var token = localStorage.getItem("token")
    const userString = localStorage.getItem('user');
    if (token) {
      this.user = JSON.parse(userString as string)
    }
    this.discountKeys.forEach((key) => {
      this.discountDictionary[key] = this.user.discounts ? this.user.discounts[key] || 0 : 0;
    });

  }
  openEditDiscountPopup(templateRef?: any, discount: string =""): void {
    this.currentDiscount = discount;
    this.discountEdit = this.discountDictionary[discount]
    this.bsModalRef = this.modalService.show(templateRef, { class: 'discount-modal' });
  }
  closeModal(save=false): void {
    if(save){
      this.discountDictionary[this.currentDiscount] = this.discountEdit;
      this.user.discounts = this.discountDictionary
      //delete this.user.Id
      this.user.isLoggedIn=true;
      this.epService.updateUser(this.user).subscribe(data => {
        localStorage.setItem("user",JSON.stringify(this.user))
      })
    }
    this.bsModalRef.hide();
  }
}
