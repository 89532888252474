<div class="edging-container">
    <section class="banner-pages banner-bg-drilling" role="banner">
        <div class="container">
            <div class="col-md-10 col-md-offset-1">
                <div class="banner-pages-text text-left">
                    <h1>Drilling</h1>

                    <h2>Drilled panels from Cutwrights saves a "hole" lot of time</h2>
                </div><!-- banner text -->
            </div>
        </div>
    </section>

    <section id="intro" class="section">
        <div class="container">
            <div class="intro">
                <span class="site-navigation"><a [routerLink]="['/']">Home</a> » Services » Drilling</span>
                Add value to your panels and save time by using our CNC Drilling Service. Our machines are capable of
                face and end drilling as well as routing pockets, cutting angles and radiuses. You can specify up to
                sixteen different drilling requirements online. Alternatively, if you have carcasses that require
                drilling just send us a basic sketch and we can take care of setting out the drilling for you.
            </div>
            <div style="margin-top: 30px">
                <a [routerLink]="['/customer/get-quote']" class="btn-white btn-white-large">Get a Quote</a>
            </div>
        </div>
    </section>

    <div class="page-container">
        <section id="services" class="services no-padding">
            <div class="container">
                <div class="row no-gutter">
                    <div class="col-lg-6 col-md-6 col-sm-12 services-box-left text-left">
                        <p>Our CNC machining centres are smart all-rounders that are well equipped to process wood based
                            panels - we can guarantee accuracy 0.1mm +/-. Panels up to a size of 3660mm x 850mm can be
                            machined, providing they are between 8mm to 50mm thick. You can specify your drilling
                            requirements online. See below the list of options available using the drilling app within
                            the cutting page (Add Drilling).</p>

                        <p>If you require multiple drilling options on your panels, it may be easier to send us a
                            drawing of each panel for us to assess. Currently we have the capacity to process almost a
                            1000 panels a day. Our link between the drilling information on the website and the CNC
                            means we can offer fantastic value on individual components and small batch runs as well as
                            volume production runs.</p>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 services-box-right">
                        <img src="../../../assets/images/images-holzma-bhx-050.jpg" alt="WEEKE BHX 050"
                            class="img-responsive">
                    </div>
                </div>
                <div class="row no-gutter">
                    <div class="col-lg-6 col-md-6 col-sm-12 services-box-left text-left">
                        <img src="../../../assets/images/images-holzma-bhx-055.jpg" alt="WEEKE BHX 055"
                            class="img-responsive">
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 services-box-right">
                        <p><strong>Service benefits</strong></p>

                        <ul>
                            <li>Increase your capacity</li>
                            <li>Shorten your lead times</li>
                            <li>Improve the quality &amp; accuracy of your product</li>
                            <li>Reduce installation time on site</li>
                            <li>Offer more complex designs</li>
                            <li>F.O.C Labelled components for faster assembly</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section id="services-drilling-list" class="services-drilling-list no-padding">
            <div class="container">
                <h3> Drilling option explained</h3>
                <div class="row no-gutter">
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Angled Cut</h4>
                        </div>
                        <a (click)="openImageModal(imagePopup,'../../../assets/images/img-drilling-angled-cut.jpg')" class="gallery-box"
                            title="Angled Cut" data-fancybox-group="thumb">
                            <img src="../../../assets/images/img-drilling-angled-cut.jpg" alt="Angled Cut"
                                class="img-responsive">
                        </a>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Circular Pocket Blind</h4>
                        </div>
                        <a (click)="openImageModal(imagePopup,'../../../assets/images/img-drilling-circular-pocket-blind.jpg')" class="gallery-box"
                            title="Circular Pocket Blind" data-fancybox-group="thumb">
                            <img src="../../../assets/images/img-drilling-circular-pocket-blind.jpg"
                                alt="Circular Pocket Blind" class="img-responsive">
                        </a>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Circular Pocket Through</h4>
                        </div>
                        <a (click)="openImageModal(imagePopup,'../../../assets/images/img-drilling-circular-pocket-through.jpg')" class="gallery-box"
                            title="Circular Pocket Through" data-fancybox-group="thumb">
                            <img src="../../../assets/images/img-drilling-circular-pocket-through.jpg"
                                alt="Circular Pocket Through" class="img-responsive">
                        </a>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Face Drilling Blind</h4>
                        </div>
                        <a (click)="openImageModal(imagePopup,'../../../assets/images/img-drilling-face-drilling-blind.jpg')" class="gallery-box"
                            title="Face Drilling Blind" data-fancybox-group="thumb">
                            <img src="../../../assets/images/img-drilling-face-drilling-blind.jpg"
                                alt="Face Drilling Blind" class="img-responsive">
                        </a>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Face Drilling Through</h4>
                        </div>
                        <a (click)="openImageModal(imagePopup,'../../../assets/images/img-drilling-face-drilling-through.jpg')" class="gallery-box"
                            title="Face Drilling Through" data-fancybox-group="thumb">
                            <img src="../../../assets/images/img-drilling-face-drilling-through.jpg"
                                alt="Face Drilling Through" class="img-responsive">
                        </a>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>End Drilling</h4>
                        </div>
                     <a (click)="openImageModal(imagePopup,'../../../assets/images/img-drilling-end-drilling.jpg')" class="gallery-box"
                            title="End Drilling" data-fancybox-group="thumb">
                            <img src="../../../assets/images/img-drilling-end-drilling.jpg" alt="End Drilling"
                                class="img-responsive">
                        </a>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Rectangular Pocket Blind</h4>
                        </div>
                        <a (click)="openImageModal(imagePopup,'../../../assets/images/img-drilling-rectangular-pocket-blind.jpg')" class="gallery-box"
                            title="Rectangular Pocket Blind" data-fancybox-group="thumb">
                            <img src="../../../assets/images/img-drilling-rectangular-pocket-blind.jpg"
                                alt="Rectangular Pocket Blind" class="img-responsive">
                        </a>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Rectangular Pocket Through</h4>
                        </div>
                        <a (click)="openImageModal(imagePopup,'../../../assets/images/img-drilling-rectangular-pocket-through.jpg')"
                            class="gallery-box" title="Rectangular Pocket Through" data-fancybox-group="thumb">
                            <img src="../../../assets/images/img-drilling-rectangular-pocket-through.jpg"
                                alt="Rectangular Pocket Through" class="img-responsive">
                        </a>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Radius Cut 90 Degree</h4>
                        </div>
                        <a (click)="openImageModal(imagePopup,'../../../assets/images/img-drilling-radius-cut-90.jpg')" class="gallery-box"
                            title="Radius Cut 90 Degree" data-fancybox-group="thumb">
                            <img src="../../../assets/images/img-drilling-radius-cut-90.jpg" alt="Radius Cut 90 Degree"
                                class="img-responsive">
                        </a>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Radius Cut Ellipitcal</h4>
                        </div>
                        <a (click)="openImageModal(imagePopup,'../../../assets/images/img-drilling-radius-cut-elliptical.jpg')" class="gallery-box"
                            title="Radius Cut Ellipitcal" data-fancybox-group="thumb">
                            <img src="../../../assets/images/img-drilling-radius-cut-elliptical.jpg"
                                alt="Radius Cut Ellipitcal" class="img-responsive">
                        </a>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Hinge Plate</h4>
                        </div>
                        <a (click)="openImageModal(imagePopup,'../../../assets/images/img-drilling-hingle-plate.jpg')" class="gallery-box"
                            title="Hinge Plate" data-fancybox-group="thumb">
                            <img src="../../../assets/images/img-drilling-hingle-plate.jpg" alt="Hinge Plate"
                                class="img-responsive">
                        </a>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Hinge Hole</h4>
                        </div>
                        <a (click)="openImageModal(imagePopup,'../../../assets/images/img-drilling-hingle-hole.jpg')" class="gallery-box"
                            title="Hinge Hole" data-fancybox-group="thumb">
                            <img src="../../../assets/images/img-drilling-hingle-hole.jpg" alt="Hinge Hole"
                                class="img-responsive">
                        </a>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Hinge Hole Inserta</h4>
                        </div>
                        <a (click)="openImageModal(imagePopup,'../../../assets/images/img-drilling-hingle-hole-inserta.jpg')" class="gallery-box"
                            title="Hinge Hole Inserta" data-fancybox-group="thumb">
                            <img src="../../../assets/images/img-drilling-hingle-hole-inserta.jpg"
                                alt="Hinge Hole Inserta" class="img-responsive">
                        </a>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Groove</h4>
                        </div>
                        <a (click)="openImageModal(imagePopup,'../../../assets/images/img-drilling-groove.jpg')" class="gallery-box" title="Groove"
                            data-fancybox-group="thumb">
                            <img src="../../../assets/images/img-drilling-groove.jpg" alt="Groove"
                                class="img-responsive">
                        </a>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Shelf Pegs Library</h4>
                        </div>
                        <a (click)="openImageModal(imagePopup,'../../../assets/images/img-drilling-shelf-pegs-library.jpg')" class="gallery-box"
                            title="Shelf Pegs Library" data-fancybox-group="thumb">
                            <img src="../../../assets/images/img-drilling-shelf-pegs-library.jpg"
                                alt="Shelf Pegs Library" class="img-responsive">
                        </a>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Shelf Peg Holes Standard</h4>
                        </div>
                        <a (click)="openImageModal(imagePopup,'../../../assets/images/img-drilling-shelf-pegs-holes-standard.jpg')" class="gallery-box"
                            title="Shelf Peg Holes Standard" data-fancybox-group="thumb">
                            <img src="../../../assets/images/img-drilling-shelf-pegs-holes-standard.jpg"
                                alt="Shelf Peg Holes Standard" class="img-responsive">
                        </a>
                    </div>
                </div>
            </div>
        </section>
    </div>

</div>
<ng-template #imagePopup>
    <form>
        <span class="close" style="opacity: 1.5;" (click)="closeModal()">&times;</span>
        <div class="row">
            <div class="col-md-12">
                    <img alt="image" class="img-responsive"
                    [src]="modalImageUrl" alt="image" class="img-responsive">
            </div>
            </div>
        </form>
</ng-template>