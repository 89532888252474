<div>
    <h1>Discounts</h1>
    <div id="Main_ctl00_upList">
        <div class="list">
            <div>
                <table class="grid" cellspacing="0" currentsort="" id="Main_ctl00_gridList"
                    style="border-collapse:collapse;">
                    <tbody>
                        <tr class="header-row">
                            <th scope="col"><a>Product category</a></th>
                            <th scope="col"><a>Discount</a></th>
                            <th scope="col">&nbsp;</th>
                        </tr>
                        <tr *ngFor="let a of discountKeys">
                            <td>
                                {{a}}
                            </td>
                            <td>
                                {{discountDictionary[a]}}%
                            </td>
                            <td>
                                <input type="image" (click)="openEditDiscountPopup(editTemp, a)"
                                    id="Main_ctl00_gridList_btnEdit_0" src="../../../../../assets/images/edit.png"
                                    alt="Edit">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<ng-template #editTemp>
    <div>
        <span class="close" id="closeModalBtn" (click)="closeModal()">&times;</span>
        <h2>Customer category discount details</h2>
        <div style="display: flex;">
        <label class="discount-label" for="name">Discount: </label>
        <input type="number" id="name" name="name" [(ngModel)]="discountEdit" required>
        <div class="discount-label">%</div>
        </div>
        <div class="buttons" style="display: flex;">
            <input type="submit" name="Root$Main$ctl00$btnCancel" (click)="closeModal(false)" value="Cancel" id="Main_ctl00_btnCancel">
            <input type="submit" name="Root$Main$ctl00$btnSave" (click)="closeModal(true)" value="Save" id="Main_ctl00_btnSave">
         </div>
    </div>
</ng-template>