import { Component } from '@angular/core';

@Component({
  selector: 'app-blog-offer',
  templateUrl: './blog-offer.component.html',
  styleUrls: ['./blog-offer.component.scss']
})
export class BlogOfferComponent {

}
