import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { Order, Quote, eStatusM, eStatusO } from 'src/app/models/cut-wrights.model';
import { Shaker, eShakerTypeM } from 'src/app/models/shaker.model';
import { EndpointApiService } from 'src/app/service/endpoint-api.service';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent {
  @Input() quote!:Quote
  @ViewChild('template') shakerDoor!: TemplateRef<any>;
  sformGroup: any;
  imageSrc = "";
  modalRef!:any
  constructor(private metadataService: MetadataService,private modalService: BsModalService,private formBuilder: FormBuilder,private apiService:EndpointApiService){}
  
  ngOnInit(): void {
    this.metadataService.setTitle('default');
    this.metadataService.setDescription('default');
  }
  
  getType(type:any){  
    return eShakerTypeM[type as keyof typeof eShakerTypeM]
  }
  getLinkName(shaker:Shaker){
    var l_name = ""
    if(shaker.totalnumberofhingeholes > 0){
      l_name += shaker.totalnumberofhingeholes +"Hinge hole"
    }
    if(shaker.totalnumberofmidrails > 0){
      l_name += shaker.totalnumberofmidrails +"Midrail"
    }
  
    return l_name;
  }
  openPopUp(quote:Quote,index:number){
    let shaker = this.quote.shakers[index];
    let hingesArray = this.formBuilder.array([]) as FormArray;
            let midRailsArray = this.formBuilder.array([]) as FormArray;
            let group = this.formBuilder.group({
              name: [shaker.name, Validators.required],
              height: [shaker.height, [Validators.required, Validators.max(2420)]],
              width: [shaker.width, [Validators.required, Validators.max(900)]],
              quantity: [shaker.quantity, Validators.required],
              primed: [shaker.primed],
              weight: [shaker.weight, Validators.required],
              type: [shaker.type],
              totalnumberofmidrails: [shaker.totalnumberofmidrails],
              midrails: midRailsArray,
              hingeholes35mm: [shaker.hingeholes35mm],
              useinsertahinges: [shaker.useinsertahinges],
              hingeside: [shaker.hingeside],
              totalnumberofhingeholes: [shaker.totalnumberofhingeholes],
              hinges: hingesArray,
              drillingdistance: [shaker.drillingdistance],
              // blumInserta:[shaker.blumInserta] //removed by KD as dupicate variable wrt useinsertahinges
            })
            shaker.hinges.forEach(hinge => {
              hingesArray.push(this.formBuilder.group({
                y: [hinge.y]
              }))
            })
            shaker.midrails.forEach(rail => {
              midRailsArray.push(this.formBuilder.group({
                position: [rail.position, [Validators.min(90)]]
              }))
            });
    var template: TemplateRef<any> = this.shakerDoor;
    this.sformGroup = group;
    this.openModal(template)
  }
  openModal(template: TemplateRef<any>): void {
    this.image_handler();
    this.sformGroup.disable();
    this.modalRef = this.modalService.show(template, { class: 'shaker-door-modal', initialState: { iFormGroup: this.sformGroup, close: this.close } });
  }
  image_handler() { 
    if (this.sformGroup.get('width')?.valid && this.sformGroup.get('height')?.valid) {
      let value = this.sformGroup.value;
      let midrails = ""
      let hingeHoles = ""
      if (value.type == 0) {
        midrails = value.midrails?.reduce(((val: any, cVal: any, cIndex:any) => { if (cVal.position) { return val  + cVal.position + "," } return "" }), "") as string
        hingeHoles = value.hinges?.reduce(((val: any, cVal: any,cIndex:any) => { if (cVal.y) { return val  + cVal.y + "," } return "" }), "") as string
        if (midrails.endsWith(',')) {
          midrails = midrails.slice(0, -1); // Remove the last character
        }
        if (hingeHoles.endsWith(',')) {
          hingeHoles = hingeHoles.slice(0, -1); // Remove the last character
        }
      }
      let url = this.apiService.generateImageUrl(
        '22mmMoistureResistantMediteMDF',  // materialCode
        18,                               // thickness
        true,                               // showHandle
        value.type as number,               // type
        true,                               // hasGrain
        value.hingeside == "0" ? 'Left' : 'Right',  // hingeSide
        true,                               // rotate90Degrees
        midrails,                            // midrails
        hingeHoles ? hingeHoles : "",                             // hingeHoles
        value.drillingdistance as number,              // drillingDistance
        // value.blumInserta as boolean,                  // blumInserta
        value.useinsertahinges as boolean,             //blumInserta New variable
        parseInt(value.height as string),             // length
        parseInt(value.width as string),              // width
  
      );
      this.apiService.fetchImage(url).subscribe(data => {
        const uint8Array = new Uint8Array(data);
        const base64String = btoa(String.fromCharCode.apply(null, Array.from(uint8Array)));
        this.imageSrc = `data:image/png;base64,${base64String}`;
      })
    }
  }
  close(){
    this.modalRef.hide()
  }
  getHingeControls(formGroup: FormGroup) {
    return (formGroup.get('hinges') as FormArray).controls as FormGroup[]
  }
  getMidrailControls(formGroup: FormGroup) {
    return (formGroup.get('midrails') as FormArray).controls as FormGroup[]
  }
}
