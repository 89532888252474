<!-- 
    <form method="post" action="" id="mainForm">
      <div class="buttons">
        <input
          type="submit"
          name="Root$btnPrint"
          value="Print"
          id="btnPrint"
          class="controlPrint"
        />
      </div> -->

<head>
  <title>Office | Transport | Collections | Print collection details</title>
  <link href="/App_Templates/Standard.css?v=7" type="text/css" rel="stylesheet" />
  <!--<link href="../../../App_Templates/Print/StyleSheet.css?v=7" type="text/css" rel="stylesheet"> -->
</head>

<body>
  <form method="post" action="" id="mainForm" class="thisForm">
    <div class="buttons">
      <input type="submit" name="Root$btnPrint" value="Print" id="btnPrint" class="controlPrint" />
    </div>

    <div *ngIf="detailsFetched">
      <div>
        <!-- «Start| View - Drop Print Note» -->
        <h2>
          Cutwrights {{ collection ? 'Collection' : 'Delivery' }} Note - Cutwrights Copy
        </h2>
        
        <table class="note-row two-col">
          <tbody>
            <tr>
              <td class="first-col">Date job last updated</td>
              <td class="second-col" colspan="4">{{order.jobCompletedOn}}</td>
              <td class="first-col">Job reference</td>
              <td class="second-col" colspan="3">{{order.jobReference}}</td>
            </tr>
            <tr>
              <td class="first-col">Customer name:</td>
              <td class="second-col" colspan="8">{{order.customerName}}</td>
            </tr>
            <tr>
              <td class="first-col">Company name:</td>
              <td class="second-col" colspan="8">{{order.customerCompanyName}}</td>
            </tr>
            <tr>
              <td class="first-col">Job name:</td>
              <td class="second-col" colspan="8">{{order.name}}</td>
            </tr>
            <tr>
              <td class="first-col">Phone number</td>
              <td class="second-col" colspan="4">{{order.customerMobileNumber}}</td>
              <!-- <td class="first-col">Mobile number</td>
                <td class="second-col" colspan="3"></td> -->
            </tr>
            <tr>
              <td colspan="9" class="spliter"></td>
            </tr>
            <tr *ngIf="!collection">
              <td class="first-col">Delivery schedule</td>
              <td colspan="8" class="note-row-inner">
                <table>
                  <tbody>
                    <tr>
                      <td *ngFor="let drop of order.drops" class="first-col">{{getDeliveryWindow(drop.deliverywindow)}}</td>
                      <!-- <td class="second-col"></td>
                        <td class="third-col"></td> -->
                    </tr>
                    <!-- <tr>
                        <td class="first-col"></td>
                        <td class="second-col"></td>
                        <td class="third-col"></td>
                      </tr> -->
                  </tbody>
                </table>
              </td>
            </tr>
            <tr *ngIf="!collection">
              <td class="first-col">Delivery comments</td>
              <td *ngFor="let drop of order.drops" class="second-col" colspan="8">
                {{drop.notes}}
              </td>
            </tr>
            <tr *ngIf="!collection">
              <td class="first-col">Delivery address</td>
              <td colspan="4">{{quote.address.address}}</td>
              <td class="third-col">Delivery postcode</td>
              <td colspan="3">{{quote.address.postcode}}</td>
            </tr>
            <tr>
              <td class="first-col">Labels</td>
              <td class="second-col" colspan="8">{{quote.panelsNeedsLabelling?"Yes":"No"}}</td>
            </tr>
            <tr>
              <td class="first-col">Offcuts</td>
              <td class="second-col" colspan="8">{{quote.panelsNeedsLabelling?"Yes":"No"}}</td>
            </tr>
            <tr>
              <td class="first-col">Weight</td>
              <td class="second-col" colspan="8">{{quote.includeOffCuts? truncateValue(quote.totalweight) + "Includes Offcuts": truncateValue(quote.weight)}}</td>
            </tr>
            <tr>
              <td class="first-col">Totals</td>

              <td>
                <span>Panels</span>
              </td>
              <td>{{getPartsC()}}</td>
              <td>
                <span>Shaker Doors</span>
              </td>
              <td>{{getShakerDoorsC()}}</td>
              <td>
                <span>Shaker Drawers</span>
              </td>
              <td>{{getShakerDrawerC()}}</td>
              <td>
                <span>Plain Drawer Fronts</span>
              </td>
              <td>{{getPlainDrawerC()}}</td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="quote.ExtraCostItems && quote.ExtraCostItems.length > 0">
          <h2>Extra Cost Items</h2>
          <table class="note-row">
            <thead>
              <tr>
                <th>Item</th>
                <th>Driver Checklist</th>
                <th>Customer Checklist</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of quote.ExtraCostItems">
                <td>{{item}}</td>
                <td><input type="checkbox"></td>
                <td><input type="checkbox"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <table class="note-row customer-signature">
          <tbody>
            <tr>
              <td class="first-col">
                <h3>Customer Acceptance</h3>
              </td>
              <td class="second-col note-row-inner">
                <table>
                  <tbody>
                    <tr>
                      <td class="first-col center">{{collection ? 'Collection' :'Delivery'}} Number</td>
                      <td class="second-col">1 of 1</td>
                    </tr>
                    <tr>
                      <td class="first-col">Signed</td>
                      <td class="second-col">
                        _________________________________
                      </td>
                    </tr>
                    <tr>
                      <td class="first-col">Print Name</td>
                      <td class="second-col">
                        _________________________________
                      </td>
                    </tr>
                    <tr>
                      <td class="first-col">Date</td>
                      <td class="second-col">07/12/2023</td>
                    </tr>
                    <!-- <tr>
                      <td colspan="2" class="first-col">
                        <span>Products identified as FSC are FSC Mix 70%, unless
                          otherwise stated SA-COC-005496<br />
                        </span>
                        <span>Products identified as PEFC are 70% PEFC Certified,
                          unless otherwise stated SA-PEFC/COC-005496</span>
                      </td>
                    </tr> -->
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <div style="page-break-after: always"></div>
        <!-- «End| View - Drop Print Note» -->
      </div>
      <div>
        <!-- «Start| View - Drop Print Note» -->
        <h2>Cutwrights {{collection ? 'Collection' :'Delivery'}} Note - Customer Copy</h2>
        <table class="note-row two-col">
          <tbody>
            <tr>
              <td class="first-col">Date job last updated</td>
              <td class="second-col" colspan="4">{{order.jobCompletedOn}}</td>
              <td class="first-col">Job reference</td>
              <td class="second-col" colspan="3">{{order.jobReference}}</td>
            </tr>
            <tr>
              <td class="first-col">Customer name:</td>
              <td class="second-col" colspan="8">{{order.customerName}}</td>
            </tr>
            <tr>
              <td class="first-col">Company name:</td>
              <td class="second-col" colspan="8">{{order.customerCompanyName}}</td>
            </tr>
            <tr>
              <td class="first-col">Job name:</td>
              <td class="second-col" colspan="8">{{order.name}}</td>
            </tr>
            <tr>
              <td class="first-col">Phone number</td>
              <td class="second-col" colspan="4">{{order.customerMobileNumber}}</td>
              <!-- <td class="first-col">Mobile number</td>
                <td class="second-col" colspan="3"></td> -->
            </tr>
            <tr>
              <td colspan="9" class="spliter"></td>
            </tr>
            <tr class="hidden">
              <td class="first-col">Delivery schedule</td>
              <td colspan="8" class="note-row-inner">
                <table>
                  <tbody>
                    <tr>
                      <td *ngFor="let drop of order.drops" class="first-col">getDeliveryWindow(drop.deliveryWindow)</td>
                      <!-- <td class="second-col"></td>
                        <td class="third-col"></td> -->
                    </tr>
                    <!-- <tr>
                        <td class="first-col"></td>
                        <td class="second-col"></td>
                        <td class="third-col"></td>
                      </tr> -->
                  </tbody>
                </table>
              </td>
            </tr>
            <tr class="hidden">
              <td class="first-col">Delivery comments</td>
              <td class="second-col" colspan="8">
                required ASAP. 2nd order has delivery
              </td>
            </tr>
            <tr class="hidden">
              <td class="first-col">Delivery address</td>
              <td colspan="4">{{quote.address.address}}</td>
              <td class="third-col">Delivery postcode</td>
              <td colspan="3">{{quote.address.postcode}}</td>
            </tr>
            <tr>
              <td class="first-col">Labels</td>
              <td class="second-col" colspan="8">{{quote.panelsNeedsLabelling?"Yes":"No"}}</td>
            </tr>
            <tr>
              <td class="first-col">Offcuts</td>
              <td class="second-col" colspan="8">{{quote.panelsNeedsLabelling?"Yes":"No"}}</td>
            </tr>
            <tr>
              <td class="first-col">Weight</td>
              <td class="second-col" colspan="8">{{quote.includeOffCuts? truncateValue(quote.totalweight) + "Includes Offcuts":truncateValue(quote.weight)}}</td>
            </tr>
            <tr>
              <td class="first-col">Totals</td>

              <td>
                <span>Panels</span>
              </td>
              <td>{{getPartsC()}}</td>
              <td>
                <span>Shaker Doors</span>
              </td>
              <td> {{getShakerDoorsC()}}</td>
              <td>
                <span>Shaker Drawers</span>
              </td>
              <td>{{getShakerDrawerC()}}</td>
              <td>
                <span>Plain Drawer Fronts</span>
              </td>
              <td>{{getPlainDrawerC()}}</td>
            </tr>
          </tbody>
        </table>

        <table class="note-row customer-signature">
          <tbody>
            <tr>
              <td class="first-col">
                <h3>Customer Acceptance</h3>
              </td>
              <td class="second-col note-row-inner">
                <table>
                  <tbody>
                    <tr>
                      <td class="first-col center">{{collection ? 'Collection' :'Delivery'}} Number</td>
                      <td class="second-col">1 of 1</td>
                    </tr>
                    <tr>
                      <td class="first-col">Signed</td>
                      <td class="second-col">
                        _________________________________
                      </td>
                    </tr>
                    <tr>
                      <td class="first-col">Print Name</td>
                      <td class="second-col">
                        _________________________________
                      </td>
                    </tr>
                    <tr>
                      <td class="first-col">Date</td>
                      <td class="second-col">07/12/2023</td>
                    </tr>
                    <!-- <tr>
                      <td colspan="2" class="first-col">
                        <span>Products identified as FSC are FSC Mix 70%, unless
                          otherwise stated SA-COC-005496<br />
                        </span>
                        <span>Products identified as PEFC are 70% PEFC Certified,
                          unless otherwise stated SA-PEFC/COC-005496</span>
                      </td>
                    </tr> -->
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <div style="page-break-after: always"></div>
        <!-- «End| View - Drop Print Note» -->
      </div>
      <div>
        <!-- «Start| List: Part - Drop print Note» -->
        <div *ngIf="quote && quote.parts && quote.parts.length > 0" class="list-part-drop-print-note">
          <h2>Board and Edging</h2>
          <div id="Main_ctl00_upList" isupdatepanel="true">
            <div class="list">
              <div>
                <table class="note-row" cellspacing="0" currentsort="item.LineNumber" id="Main_ctl00_gridList"
                  style="border-collapse: collapse;table-layout:fixed;width:100%;overflow-wrap: break-word;">
                  <tbody>
                    <tr class="header-row">
                      <th scope="col"><a href="">Part</a></th>
                      <th scope="col"><a href="">Material</a></th>
                      <th scope="col"><a href="">L</a></th>
                      <th scope="col"><a href="">W</a></th>
                      <th scope="col"><a href="">QTY</a></th>
                      <th scope="col"><a href="">L1</a></th>
                      <th scope="col"><a href="">L2</a></th>
                      <th scope="col"><a href="">W1</a></th>
                      <th scope="col"><a href="">W2</a></th>
                      <th scope="col"><a href="">Priming</a></th>
                      <th scope="col"><a href="">Part name</a></th>
                      <th scope="col"><a href="">Drilling</a></th>
                    </tr>
                    <tr *ngFor="let part of quote.parts; let i = index">
                      <td>{{i+1}}</td>
                      <td>{{part.materialCode}}</td>
                      <td>{{part.length}}</td>
                      <td>{{part.width}}</td>
                      <td>{{part.quantity}}</td>
                      <td>{{part.l1Description}}</td>
                      <td>{{part.l2Description}}</td>
                      <td>{{part.w1Description}}</td>
                      <td>{{part.w2Description}}</td>
                      <td>{{part.primed ? "Yes":"No"}}</td>
                      <td>{{part.partComment}}</td>
                      <td>{{part.operations ? part.operations : ""}}</td>
                    </tr>
                    <!-- <tr class="alt">
                        <td>10</td>
                        <td>10</td>
                        <td>19mmBlackMoistureResistantValchromatMDF</td>
                        <td>1500</td>
                        <td>400</td>
                        <td>1</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>No</td>
                        <td>TV_drawer front</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>11</td>
                        <td>11</td>
                        <td>19mmBlackMoistureResistantValchromatMDF</td>
                        <td>1346</td>
                        <td>400</td>
                        <td>3</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>No</td>
                        <td>TV_drawer front</td>
                        <td></td>
                      </tr>
                      <tr class="alt">
                        <td>12</td>
                        <td>12</td>
                        <td>19mmBlackMoistureResistantValchromatMDF</td>
                        <td>1400</td>
                        <td>150</td>
                        <td>2</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>No</td>
                        <td>TV_Fillers</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>13</td>
                        <td>13</td>
                        <td>19mmBlackMoistureResistantValchromatMDF</td>
                        <td>2400</td>
                        <td>100</td>
                        <td>2</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>No</td>
                        <td>TV_flap</td>
                        <td></td>
                      </tr>
                      <tr class="alt">
                        <td>14</td>
                        <td>14</td>
                        <td>19mmBlackMoistureResistantValchromatMDF</td>
                        <td>650</td>
                        <td>375</td>
                        <td>1</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>No</td>
                        <td>TV_Gables</td>
                        <td></td>
                      </tr> -->
                  </tbody>
                </table>
              </div>
            </div>
            <input type="hidden" name="Root$Main$ctl00$SelectedListItemIdHolder"
              id="Main_ctl00_SelectedListItemIdHolder" />
          </div>
        </div>
        <div *ngIf=" quote && quote.shakers && quote.shakers.length > 0"
          class="list-part-drop-print-note">
          <h2>Shaker doors and drawer fronts</h2>
          <div id="Main_ctl00_upList" isupdatepanel="true">
            <div class="list">
              <div>
                <table class="note-row" cellspacing="0" currentsort="item.LineNumber" id="Main_ctl00_gridList"
                  style="border-collapse: collapse">
                  <tbody>
                    <tr class="header-row">
                      <th scope="col"><a href="">Part</a></th>
                      <th scope="col"><a href="">Material</a></th>
                      <th scope="col"><a href="">H</a></th>
                      <th scope="col"><a href="">W</a></th>
                      <th scope="col"><a href="">QTY</a></th>
                      <th scope="col"><a href="">Priming</a></th>
                      <th scope="col"><a href="">Number of midrails</a></th>
                      <th scope="col"><a href="">Hinge side</a></th>
                    </tr>
                    <tr *ngFor="let part of quote.shakers; let i = index">
                      <td>{{1000 + i+1}}</td>
                      <td> {{getType(part)}}</td>
                      <td>{{part.height}}</td>
                      <td>{{part.width}}</td>
                      <td>{{part.quantity}}</td>
                      <td>{{getPriming(part)}}</td>
                      <td>{{getMidrails(part)}}</td>
                      <td>{{getHingeSide(part)}}</td>
                    </tr>
                    <!-- <tr class="alt">
                        <td>10</td>
                        <td>10</td>
                        <td>19mmBlackMoistureResistantValchromatMDF</td>
                        <td>1500</td>
                        <td>400</td>
                        <td>1</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>No</td>
                        <td>TV_drawer front</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>11</td>
                        <td>11</td>
                        <td>19mmBlackMoistureResistantValchromatMDF</td>
                        <td>1346</td>
                        <td>400</td>
                        <td>3</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>No</td>
                        <td>TV_drawer front</td>
                        <td></td>
                      </tr>
                      <tr class="alt">
                        <td>12</td>
                        <td>12</td>
                        <td>19mmBlackMoistureResistantValchromatMDF</td>
                        <td>1400</td>
                        <td>150</td>
                        <td>2</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>No</td>
                        <td>TV_Fillers</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>13</td>
                        <td>13</td>
                        <td>19mmBlackMoistureResistantValchromatMDF</td>
                        <td>2400</td>
                        <td>100</td>
                        <td>2</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>No</td>
                        <td>TV_flap</td>
                        <td></td>
                      </tr>
                      <tr class="alt">
                        <td>14</td>
                        <td>14</td>
                        <td>19mmBlackMoistureResistantValchromatMDF</td>
                        <td>650</td>
                        <td>375</td>
                        <td>1</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>No</td>
                        <td>TV_Gables</td>
                        <td></td>
                      </tr> -->
                  </tbody>
                </table>
              </div>
            </div>
            <input type="hidden" name="Root$Main$ctl00$SelectedListItemIdHolder"
              id="Main_ctl00_SelectedListItemIdHolder" />
          </div>
        </div>
        <!-- «End| List: Part - Drop print Note» -->
      </div>
      <div></div>
    </div>
  </form>

  <div id="fuReservoir" style="display: none"></div>

