import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { EndpointApiService } from 'src/app/service/endpoint-api.service';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent {
  @Input() quoteId!:string
  quotation$;
  quote$;
  payment$;
  paymentNotify$!:any;
  constructor(private metadataService: MetadataService,private router: Router,private apiService: EndpointApiService,) {
    this.quotation$ = this.apiService.getQuotationDetails(this.quoteId);
    this.quote$ = this.apiService.getQuote(this.quoteId);
    this.payment$ = this.apiService.getPaymentDetails(this.quoteId);
    this.payment$.subscribe(data => {
      this.paymentNotify$=this.apiService.getPaymentNotifyDetails(data.VPSTxID);
    })
  }
  ngOnInit(): void {
    this.metadataService.setTitle('default');
    this.metadataService.setDescription('default');
  }

  printInvoices(): void {
    let newRelativeUrl = this.router.createUrlTree(['/customer/invoice-receipt']);
    let baseUrl = window.location.href.replace(this.router.url, '');
    window.open(baseUrl + newRelativeUrl, '_blank');
  }
}
