import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Quote } from 'src/app/models/cut-wrights.model';
import { EndpointApiService } from 'src/app/service/endpoint-api.service';
import { MetadataService } from 'src/app/service/metadata.service';
import { PaymentService } from 'src/app/service/payment-service';
import { v4 as uuidv4 } from 'uuid';
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent {
  currentStep = 1;
  dataFromStep1: any;
  isLoading = false
  dataFromStep2: any;
  user;
  quoteId:string;
  amount = "0";
  quote!: Quote;
  leadtime = 9
  collection = false
  tempUser:any;
  paymentData:any;
  errorMessage:string="";
  @ViewChild('paymentErrorPopUp', { static: true })
  paymentErrorPopUp!: TemplateRef<any>;
  bsModalRef!: BsModalRef;
constructor(private metadataService: MetadataService,private paymentService: PaymentService,private router: Router,private route: ActivatedRoute,private apiService: EndpointApiService,private modalService: BsModalService){
  const token = localStorage.getItem('token');
  const userString = localStorage.getItem('user');
  if (token) {
    this.user = JSON.parse(userString as string)}
  this.quoteId = this.route.snapshot.paramMap.get('quoteId') as string;
  this.apiService.getQuote(this.quoteId).subscribe(data =>{
    this.quote=data
    this.collection = data.collection
    this.amount = this.quote.totalinvoiceamount
    this.leadtime = parseInt(this.quote.leadtime);
    this.tempUser= JSON.parse(localStorage.getItem('tempUser')!)
  })
}
  ngOnInit(): void {
    this.metadataService.setTitle('default');
    this.metadataService.setDescription('default');
  }
  onNextStep(data: any) {
    // Save data from each step and navigate to the next step
    if (this.currentStep === 1) {
      this.dataFromStep2 = data;
      this.currentStep = 2;
    } else if (this.currentStep === 2) {
      this.dataFromStep1 = data;
      this.currentStep = 3;
    }
    else if (this.currentStep === 3 && data=='ManualPay'){
      this.makeManualOrder(this.amount);
    }
    else if (this.currentStep===3 && data!='ManualPay'){
      this.makePayment();
    }
  }
  makePayment() {
    // && this.user.creditlimit && this.user.creditlimit > this.amount
    if(this.user.customerType == "1"){
      this.makeManualOrder(this.amount)
    } else{
      this.isLoading = true;
      if(this.user.roles.includes("admin")){
        //Payload for admin order
         this.paymentData = {
          TxType: 'PAYMENT',
          Vendor: 'cutwrightsltd',
          Amount: this.amount,
          Currency: 'GBP',
          VendorTXCode: uuidv4(),
          Description: 'quote',
          NotificationURL: 'https://cutwrights-quote2.azurewebsites.net/api/customer/paymentnotify',
          BillingFirstnames: this.dataFromStep1.firstName || "",
          BillingSurname: this.dataFromStep1.lastName|| "",
          BillingAddress1: this.dataFromStep1.address|| "",
          BillingCity: this.dataFromStep1.city|| "",
          BillingPostCode: this.dataFromStep1.postCode|| "",
          BillingCountry: 'GB',
          DeliveryFirstnames: this.dataFromStep1.firstName|| this.dataFromStep1.firstName,
          DeliverySurname: this.dataFromStep1.lastName|| this.dataFromStep1.lastName,
          DeliveryAddress1: this.quote.address?.address.substring(0,10) || this.dataFromStep1.address.substring(0,10),
          DeliveryCity: this.quote.address?.city|| this.dataFromStep1.city,
          DeliveryPostcode: this.quote.address?.postcode|| this.dataFromStep1.postCode,
          DeliveryCountry: 'GB',
          RedirectionURL:`https://cutwrightsuat.azurewebsites.net/customer/payconfirm/${this,this.quoteId}`,
          quoteId: this.quoteId|| "",
          CustomerName: this.user.firstName +" "+ this.user.lastName //Admin Name
        };
      }else{
      this.paymentData = {
        TxType: 'PAYMENT',
        Vendor: 'cutwrightsltd',
        Amount: this.amount,
        Currency: 'GBP',
        VendorTXCode: uuidv4(),
        Description: 'quote',
        NotificationURL: 'https://cutwrights-quote2.azurewebsites.net/api/customer/paymentnotify',
        BillingFirstnames: this.dataFromStep1.firstName || "",
        BillingSurname: this.dataFromStep1.lastName|| "",
        BillingAddress1: this.dataFromStep1.address|| "",
        BillingCity: this.dataFromStep1.city|| "",
        BillingPostCode: this.dataFromStep1.postCode|| "",
        BillingCountry: 'GB',
        DeliveryFirstnames: this.dataFromStep1.firstName|| this.dataFromStep1.firstName,
        DeliverySurname: this.dataFromStep1.lastName|| this.dataFromStep1.lastName,
        DeliveryAddress1: this.quote.address?.address.substring(0,10) || this.dataFromStep1.address.substring(0,10),
        DeliveryCity: this.quote.address?.city|| this.dataFromStep1.city,
        DeliveryPostcode: this.quote.address?.postcode|| this.dataFromStep1.postCode,
        DeliveryCountry: 'GB',
        RedirectionURL:`https://cutwrightsuat.azurewebsites.net/customer/payconfirm/${this,this.quoteId}`,
        quoteId: this.quoteId|| ""      
        };
      }
      this.paymentService.makePayment(this.paymentData).subscribe(
        (response:any) => {
          const split_string: string[] = response.Output.split("\r\n");
  
          // Create an empty object to store the key-value pairs
          const json_data: { [key: string]: string } = {};
  
          // Iterate through the split string and extract key-value pairs
          for (const item of split_string) {
            const test: string[] = item.split("=");
            json_data[test[0]] = test[1];
          }
          if(json_data['Status']=="INVALID"){
            this.errorMessage=json_data['StatusDetail'];
            this.openErrorPopUp(this.paymentErrorPopUp);
            return
          }
  
          // Add the "VPSTxId" value to the "NextURL" key
          json_data["NextURL"] += `=${json_data["VPSTxId"]}`;
          window.location.href =  json_data["NextURL"];
          this.isLoading = false;
        },
        (error) => {
          const split_string: string[] = error.error.text.split("\r\n");
  
          // Create an empty object to store the key-value pairs
          const json_data: { [key: string]: string } = {};
  
          // Iterate through the split string and extract key-value pairs
          for (const item of split_string) {
            const test: string[] = item.split("=");
            json_data[test[0]] = test[1];
          }
  
          // Add the "VPSTxId" value to the "NextURL" key
          json_data["NextURL"] += `=${json_data["VPSTxId"]}`;
          window.open( json_data["NextURL"]);
          this.isLoading = false;
        }
      );
    }
    
}

makeManualOrder(amount:string){
  let reqBody ;
  if(this.user.roles.includes("admin")){
    reqBody= {
      "quoteId": this.quoteId,
      "customerName" : this.user.firstName +" "+ this.user.lastName//Admin Name
    }

  }else{
    reqBody= {
      "quoteId": this.quoteId
    }
  }
  this.isLoading = true
  this.apiService.makeManualOrder(reqBody).subscribe(data => {
    this.router.navigate([`/customer/payconfirm/${this.quoteId}`])
    this.isLoading = false
  },error => {
    this.isLoading = false
  })
}

checkIfAdmin(){
  if(this.user.roles.includes("admin")){
    return true;
  }else{
    return false;
  }
}
openErrorPopUp(template: TemplateRef<any>){
  this.bsModalRef = this.modalService.show(template, { class: 'modal-dialog-centered' });
}
redirect(){
  this.bsModalRef.hide()
  return this.router.navigate([`/`])
}
}
