import { Component } from '@angular/core';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-cutting',
  templateUrl: './cutting.component.html',
  styleUrls: ['./cutting.component.scss']
})
export class CuttingComponent {
  constructor(private metadataService: MetadataService){
    
  }
  ngOnInit(): void {
    this.metadataService.setCanonicalLink();
    this.metadataService.setTitle('CNC Cutting Service - Cutwrights');
    this.metadataService.setDescription('At Cutwrights we offer a unique reliable CNC cutting service. Working from your instructions we can cut an extensive collection of sheet materials to size on time and fast.');
  }

}
