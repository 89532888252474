<form [formGroup]="addressForm" (ngSubmit)="checkPostCode()">
  <h2 class="mb-5 font-weight">Customer delivery address details</h2>
  <span class="close" (click)="closePopup()">&times;</span>
    <div class="form-group">
      <label for="address">Address</label>
      <textarea class="form-control" id="address" class="input-field" formControlName="address" rows="3" maxlength="49"></textarea>
    </div>
    <div class="form-group">
      <label for="city">City</label>
      <input type="text" class="form-control" id="city" class="input-field" formControlName="city" maxlength="20">
    </div>
    <div class="form-group">
      <label for="postcode">Postcode</label>
      <input type="text" class="form-control" class="input-field" id="postcode" formControlName="postcode" maxlength="13">      
    </div>
    <div><p style="padding-left: 40px;" class="text-danger" *ngIf="addressForm.get('postcode')?.invalid">Invalid Postcode</p></div>
    <button type="submit" class="btn address-btn" [disabled]="addressForm.invalid">Submit</button>
</form>