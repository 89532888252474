<div class="main-body">
<form  [formGroup]="personalInfoForm" (ngSubmit)="checkPostCode()" class="mb-5 pdl-60" style="width: 1270px;" >
  <div class="login-form">
    <h1>Billing Address</h1>
    
    <div class="row mb-3 mt-5">
      <div class="col-sm-1">
        <label>First Name:<span class="required"></span></label>
      </div>
      <div class="col-sm-6">
        <input [readonly]="formDisabled" type="text" class="standard-input" formControlName="firstName">
        <div *ngIf="checkError('firstName')" class="text-danger">
          Sorry, this can't be empty.
       </div>
      </div>
   </div>

    <div class="row mb-3">
      <div class="col-sm-1">
        <label>Surname:<span class="required"></span></label>
      </div>
      <div class="col-sm-6">
        <input [readonly]="formDisabled" type="text" class="standard-input" formControlName="lastName">
        <div *ngIf="checkError('lastName')" class="text-danger">
          Sorry, this can't be empty.
       </div>
      </div>  
    </div>

    <div class="row mb-3">
      <div class="col-sm-1">
        <label>Address:<span class="required"></span></label>
      </div>
      <div class="col-sm-6">
        <textarea [readonly]="formDisabled" class="text-field" formControlName="address" maxlength="49"></textarea>

<div *ngIf="checkError('address') && personalInfoForm.get('address')?.hasError('required')" class="text-danger">
  Sorry, this can't be empty.
</div>

<div *ngIf="checkError('address') && personalInfoForm.get('address')?.hasError('maxlength')" class="text-danger">
  Sorry, the address can't be more than 50 characters.
</div>

      </div>  
    </div>

    <div class="row mb-3">
      <div class="col-sm-1">
        <label>City:<span class="required"></span></label>
      </div>
      <div class="col-sm-6">
        <input [readonly]="formDisabled" type="text" class="standard-input" formControlName="city" maxlength="30">
        <div *ngIf="checkError('city')" class="text-danger">
          Sorry, this can't be empty.
       </div>
      </div>  
    </div>
  
    <div class="row mb-3">
      <div class="col-sm-1">
        <label>Post Code:<span class="required"></span></label>
      </div>
      <div class="col-sm-6">
        <span class="inline-container">
        <input [readonly]="formDisabled"  type="text" class="standard-input" formControlName="postCode" maxlength="20">
          <!-- <input [ngClass]="disableNext? 'button-cp':'button-cp button-disable'" (click)="checkPostCode()" type="button" value="Check Billing Address" style="margin-left: 23px;"> -->
      </span>
        <div *ngIf="checkError('postCode')" class="text-danger">
          Sorry, incorrect postcode.
       </div>
      </div>  
    </div>
  
    <div class="actions-btns">
      <button type="button" class="btn btn btn-secondary btns-action margin-r-15" (click)="goBack()">Back</button>
       <button type="submit"  [ngClass]="disableNext? 'button-disable btn btn-primary btns-action':'btn btn-primary btns-action'" >Next</button>
    </div>
  </div>
  </form>
</div>
  