import { Component } from '@angular/core';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-show-room',
  templateUrl: './show-room.component.html',
  styleUrls: ['./show-room.component.scss']
})
export class ShowRoomComponent {

  constructor(private metadataService: MetadataService,){}

  ngOnInit(): void {
    this.metadataService.setCanonicalLink();
    this.metadataService.setTitle('default');
    this.metadataService.setDescription('default');
  }

}
