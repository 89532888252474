<div class="veneered-board">
    <section class="banner-pages banner-bg-ourrange" role="banner">
        <div class="container">
            <div class="col-md-10 col-md-offset-1">
                <div class="banner-pages-text text-left">
                    <h1>Veneered board</h1>
                    <h3>We offer over 700 different boards and 1000 different edgings.</h3>
                </div><!-- banner text -->
            </div>
        </div>
    </section>

    <section id="intro" class="section">
        <div class="container">
            <div class="intro">
                <span class="site-navigation"><a [routerLink]="['/']">Home</a> » <a [routerLink]="['/board-range']">Board Range</a> » Veneered board</span>
            </div>
        </div>
    </section>

    <section id="products-selection" class="no-padding">
        <div class="container products-selection-table">
            <div class="row no-gutter">
                <div class="col-md-6">
                    <a (click)="openImageModal(imagePopup,'../../../assets/images/images-our-range-8@2x.jpg')" class="gallery-box" title="Veneered board" data-fancybox-group="thumb">
                        <img src="../../../assets/images/images-our-range-8@2x.jpg" alt="Veneered board" class="img-responsive">
                    </a>
                </div>
                <div class="col-md-6">
                    <div class="products-list-item-title" style="text-align: center">
                        <p>Veneered board</p>
                    </div>
                    <div class="products-list-item-properties" style="padding-bottom:14px">
                        <ul>
                            <li>
                                Our veneer is made up from quality real wood veneers bonded to premium boards. Cutwrights veneered products feature beautiful real wood finishes that are suitable for a wide range of interior decorative applications, including high-quality kitchens and bedroom sets, through to luxury hotel &amp; shop-fitting work. We have thicknesses from 6mm – 26mm in finishes including White Oak, Maple and Walnut.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="services-drilling-list2" class="products-selection services-edging-list no-padding">
        <div class="container" style="background-color: #ffffff">
            
            <div class="row no-gutter" style="padding-top: 30px; padding-bottom: 30px;text-align: center">
                <div class="col-md-4" style="margin-bottom: 10px">
                    <a class="btn-white btn-white-large" [routerLink]="['/choose-your-boards']">Choose your boards</a>
                </div>
                <div class="col-md-4" style="margin-bottom: 10px">
                    <a class="btn-white btn-white-large" [routerLink]="['/customer/get-quote']">Get a Quote</a>
                </div>
                <div class="col-md-4" style="margin-bottom: 10px">
                    <a id="" [routerLink]="['/contact-us']" class="btn-white btn-white-large">Contact us</a>
                </div>
            </div>
            <div class="row no-gutter" style="margin-bottom:1px; background-color:#c2c2c2">
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Oak Veneered</h4>
                        </div>
                        <img src="../../../assets/images/img-oak-veneered-1x@2.jpg" alt="Oak Veneered" class="img-responsive">
                        <div class="services-list-desc">
                            <p>Available on Ply and MDF with crown and quarter cut options, Oak veneer is light brown in colour giving a warm feel. Offered with a pre lacquered UV finish on ply, this option can save time as raw veneer will require finishing with Lacquers, Oils and waxes prior to use. With its natural deep grain Oak veneer is ideal for paint finishes that require a grained look. If you are looking for a more select veneer our Dartmoor Range offers wider leaves and more consistent colour.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Walnut Veneered</h4>
                        </div>
                        <img src="../../../assets/images/img-walnut-veneered-1x@2.jpg" alt="Walnut Veneered" class="img-responsive">
                        <div class="services-list-desc">
                            <p>Available on Ply and MDF with crown cut options only, Black Walnut veneer is a rich brown with hints of black grain. Black walnut has the advantage of being able to mix with warm and cool colours. Offered with a pre lacquered UV finish on ply, this option can save time as raw veneer will require finishing with Lacquers, Oils and waxes prior to use. Walnut’s fine grain will always offer a smooth surface after finishing. If you are looking for a more select veneer our Dartmoor Range offers wider leaves and more consistent colour.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Cherry Veneered</h4>
                        </div>
                        <img src="../../../assets/images/img-cherry-veneered-1x@2.jpg" alt="Cherry Veneered" class="img-responsive">
                        <div class="services-list-desc">
                            <p>Available on MDF with crown cut options only, Cherry has a warm tone with hints Red and Brown grain.  Cherry can be finished with Lacquers, Oils and waxes prior to use. Cherry’s fine grain will always offer a smooth surface after finishing. If you are looking for a more select veneer our Dartmoor Range offers wider leaves and more consistent colour.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Maple Veneered</h4>
                        </div>
                        <img src="../../../assets/images/img-maple-veneered-1x@2.jpg" alt="Maple Veneered" class="img-responsive">
                        <div class="services-list-desc">
                            <p>Available on MDF with crown cut options only, Maple is one of the lighter shades of veneer cream in colour with light brown grain. Maple has the advantage of being able to mix with warm and cool colours especially blues. Maple veneer will require finishing with Lacquers, Oils and waxes prior to use. Maple’s fine grain will always offer a smooth surface after finishing. If you are looking for a more select veneer our Dartmoor Range offers wider leaves and more consistent colour.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Pine Veneered</h4>
                        </div>
                        <img src="../../../assets/images/img-pine-veneered-1x@2.jpg" alt="Pine Veneered" class="img-responsive">
                        <div class="services-list-desc">
                            <p>Available on MDF with crown cut options only, Pine is one of the lighter shades of veneer, Yellowish in colour. Pine can be an ideal material to use if you are trying to produce a rustic looking piece of furniture. Pine veneer will require finishing with Lacquers, Oils and waxes prior to use. Pine’s fine grain will always offer a smooth surface after finishing.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Beech Veneered</h4>
                        </div>
                        <img src="../../../assets/images/img-beech-veneered-1x@2.jpg" alt="Beech Veneered" class="img-responsive">
                        <div class="services-list-desc">
                            <p>There is the option of either Steamed or White Beech available on MDF with crown cut options only. Beech generally has a light colour with reddish tones that are more prominent in the steamed option. Beech veneer will require finishing with Lacquers, Oils and waxes prior to use. Beech’s fine grain will always offer a smooth surface after finishing.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Sapele Veneered</h4>
                        </div>
                        <img src="../../../assets/images/img-sapele-veneered-1x@2.jpg" alt="Sapele Veneered" class="img-responsive">
                        <div class="services-list-desc">
                            <p>Available on MDF with crown cut options only, Sapele is a reddish brown that tends to darken with age. Sapele veneer will require finishing with Lacquers, Oils and waxes prior to use. Sapele’s fine grain will always offer a smooth surface after finishing.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Ash Veneered</h4>
                        </div>
                        <img src="../../../assets/images/img-ash-veneered-1x@2.jpg" alt="Ash Veneered" class="img-responsive">
                        <div class="services-list-desc">
                            <p>Available on MDF with crown options only, Ash veneer is light brown in colour with a dark yellow warm feel. Ash veneer will require finishing with Lacquers, Oils and waxes prior to use. With its natural deep grain Oak veneer is ideal for paint finishes that require a grained look.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Wenge Veneered</h4>
                        </div>
                        <img src="../../../assets/images/img-wenge-veneered-1x@2.jpg" alt="Wenge Veneered" class="img-responsive">
                        <div class="services-list-desc">
                            <p>Wenge veneer is a medium brown with dark grain that can appear black once finished. Available on MDF with crown options only, Wenge will require finishing with Lacquers, Oils and waxes prior to use. The grain has a very coarse and straight texture that can become enhanced by the choice of finish.</p>
                        </div>
                    </div>
            </div>
        </div>
    </section>
</div>
<ng-template #imagePopup>
    <form>
        <span class="close" style="opacity: 1.5;" (click)="closeModal()">&times;</span>
        <div class="row">
            <div class="col-md-12">
                    <img alt="image" class="img-responsive"
                    [src]="modalImageUrl" alt="image" class="img-responsive">
            </div>
            </div>
        </form>
</ng-template>