import { Component } from '@angular/core';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent {
  constructor(private metadataService: MetadataService){
    
  }
  ngOnInit(): void {
    this.metadataService.setCanonicalLink();
    this.metadataService.setTitle('Frequently Asked Questions - Cutwrights');
    this.metadataService.setDescription('If you have any questions that is not answered below, please email use at info@cutwrights.com.');
  }

  

}
