export const environment = {
    version : "2024.1.1.4",

    // production: false,
    // key: '1234567890123456',
    // iv: 'abcdefghijklmnop',
    // API_URL: 'https://cutwrights-quote2.azurewebsites.net/api/#path#?code=-nLIPRCpKmqkPTiORlEhe-YhrYMjvRw57WiGib3qwBK7AzFuaMHmUA==&clientId=default',
    // BASE_API_URL: 'https://cutwright-materials.azurewebsites.net/',
    // HOST_URL: 'https://www.cwuat.com',
    // encryptionKey: 'MyEncryptionKey1MyEncryptionKey1',
    // IMAGE_APP_URL: "https://cutwrightsimageapp.azurewebsites.net/images/handlerproducts/myproductname/default.png",
    // baseUrl : "https://cutwrights-quote2.azurewebsites.net/api/customer/paymentinit?code=-nLIPRCpKmqkPTiORlEhe-YhrYMjvRw57WiGib3qwBK7AzFuaMHmUA==&clientId=default",
    
    production: true,
    key: '9425620244225583',
    iv: 'FVTIXZYJQWKWLRZL',
    API_URL: 'https://www.fnapp.cutwrights.com/api/#path#?code=lbVj7UmdT5cMGTqsiQQCxV1AoNtdf3EpRrLm2xrCB-nFAzFuqGaMHg==&clientId=default',
    BASE_API_URL: 'https://cutwrights-material-prod.azurewebsites.net',
    HOST_URL: 'https://www.cutwrights.com',
    encryptionKey: 'MyEncryptionKey1MyEncryptionKey1',
    IMAGE_APP_URL: "https://imageappprod.azurewebsites.net/images/handlerproducts/myproductname/default.png",
    baseUrl : "https://www.fnapp.cutwrights.com/api/customer/paymentinit?code=lbVj7UmdT5cMGTqsiQQCxV1AoNtdf3EpRrLm2xrCB-nFAzFuqGaMHg==&clientId=default",
};