import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    // Check if the user is logged in (by checking for the presence of a token in local storage)
    const isLoggedIn = this.isLoggedIn();

    if (isLoggedIn && this.isAdmin()) {
      return true;
    } else {
      // If not logged in, redirect to the login page or any other page you prefer
      return this.router.parseUrl('/');
    }
  }

  private isLoggedIn(): boolean {
    // Check if the token is present in local storage
    const token = localStorage.getItem('token');
    return !!token; // If token is present, return true; otherwise, return false
  }
  private isAdmin():boolean{
    const user = JSON.parse(localStorage.getItem("user") as string)
    return user.roles.includes("admin");
  }
}
