import { Component, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Address, User } from 'src/app/models/user.model';
import { EndpointApiService } from 'src/app/service/endpoint-api.service';
import { DeliveryAddressPopUpComponent, ModalInitialState } from '../delivery-address-pop-up/delivery-address-pop-up.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MetadataService } from 'src/app/service/metadata.service';
@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent {
  @ViewChild('successPopup', { static: true })
  successPopup!: TemplateRef<any>;
  newPasswordField:boolean=false;
  confirmPasswordField:boolean=false;
  oldPassword:string="";
  isLoggedIn = false;
  customerForm: FormGroup;
  user!: User;
  userDisplayName;
  bsModalRef!: BsModalRef;
  saved = false;
  isAdmin = false;
  showPassword = false;
  subMenu: any = {
    account: true,
    creditLimit: false,
    payment: false,
    discount: false
  };
  impersonate
  payments!: any;
  saveButtonBlur:boolean=false;
  addressValid:boolean=true;
  modalHeading:string="";
  modalMessage:string="";
  
     
  constructor(private metadataService: MetadataService,private apiService: EndpointApiService, private formBuilder: FormBuilder,private renderer: Renderer2, private modalService: BsModalService) {
    this.impersonate = localStorage.getItem('impersonate')
    const token = localStorage.getItem('token');
    this.isLoggedIn = !!token;
    const userString = localStorage.getItem('user');
    if (token) {
      this.isLoggedIn = true;
      this.user = JSON.parse(userString as string)
      this.userDisplayName = this.user.displayName;
      this.isAdmin = this.user.roles.includes("admin") ? true : false;
      
      this.oldPassword=this.user.password!;
    }
    this.customerForm = this.formBuilder.group({
      companyName: [this.user?.companyName],
      referenceNumber:[this.user?.reference, Validators.required],
      displayName: [this.user?.displayName, Validators.required],
      firstName: [this.user?.firstName, Validators.required],
      lastName: [this.user?.lastName, Validators.required],
      password: [this.user.password],
      newPassword:[null],
      confirmPassword: [null],
      mobile: [this.user?.mobile],
      notifications: [this.user?.notifications],
      marketing: [this.user?.marketing],
      billingAddress: this.formBuilder.group({
        address: [this.user?.billingAddress?.address, [Validators.required, Validators.maxLength(200)]],
        city: [this.user?.billingAddress?.city,[Validators.required]],
        postcode: [this.user?.billingAddress?.postcode,[Validators.required]],
      }),
    },{ validator: this.passwordMatchValidator });
    this.customerForm.valueChanges.subscribe(data => {
      this.saved = false;
    })
    this.customerForm.markAllAsTouched();
  }
  ngOnInit(): void {
    this.metadataService.setTitle('default');
    this.metadataService.setDescription('default');
  }
  passwordMatchValidator(formGroup: FormGroup): ValidationErrors | null {
    const passwordControl = formGroup.get('newPassword');
    const confirmPasswordControl = formGroup.get('confirmPassword');

    if (!passwordControl || !confirmPasswordControl) {
      return null;
    }

    if (passwordControl.value !== confirmPasswordControl.value) {
      confirmPasswordControl.setErrors({ passwordMismatch: true });
      return { passwordMismatch: true };
    } else {
      confirmPasswordControl.setErrors(null);
      return null;
    }
  }
  
  updateUser() {
    
    if (this.customerForm.valid && !this.saveButtonBlur) {
      this.user.companyName = this.customerForm.value.companyName;
      this.user.reference=this.customerForm.value.referenceNumber;
      this.user.displayName = this.customerForm.value.displayName;
      this.user.mobile = this.customerForm.value.mobile;
      this.user.notifications = this.customerForm.value.notifications;
      this.user.marketing = this.customerForm.value.marketing;
      this.user.firstName = this.customerForm.value.firstName;
      this.user.lastName = this.customerForm.value.lastName;
      this.user.billingAddress = this.customerForm.value.billingAddress;
      this.user.isLoggedIn = true;
      //delete this.user.Id;
      if (this.customerForm.value.newPassword != null)
      {
        this.user.password=this.customerForm.value.newPassword;
        this.apiService.updateUser(this.user, true).subscribe(data => {
          this.user = data as User;
          localStorage.setItem('user', JSON.stringify(data));
        })
       // window.scrollTo({ top: 0, behavior: 'smooth' }); 
      } 
      else
      {
        this.user.password=this.apiService.decryptData(this.oldPassword)
        this.apiService.updateUser(this.user, true).subscribe(data => {
        this.user = data as User;
        localStorage.setItem('user', JSON.stringify(data));
        }) 
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.saved = true;
      }
        this.openModal(this.successPopup,"success");
    }
  }
  toggleConfirmPassword() {
    this.confirmPasswordField = !this.confirmPasswordField;
  }
  toggleNewPassword() {
    this.newPasswordField = !this.newPasswordField;
  }
  openFormPopup() {
    this.bsModalRef = this.modalService.show(DeliveryAddressPopUpComponent);
    this.bsModalRef.content.formSubmit.subscribe((data: Address) => {
      if (this.user.address && this.user.address.length > 0) {
        this.user.address.push(data);
        //delete this.user.Id
        this.user.isLoggedIn = true;
        this.apiService.updateUser(this.user).subscribe(data => {
          this.user = data as User;
          localStorage.setItem('user', JSON.stringify(data));
        })
      } else {
        this.user.address = []
        this.user.address.push(data);
        //delete this.user.Id
        this.user.isLoggedIn = true;
        this.apiService.updateUser(this.user).subscribe(data => {
          this.user = data as User;
          localStorage.setItem('user', JSON.stringify(data));
        })
      }

    });
  }
  openEditAddressPopup(selectedAddress:Address,index:number) {
    const initialState: ModalInitialState = {
      address: selectedAddress.address, 
      city: selectedAddress.city, 
      postcode: selectedAddress.postcode 
    };
    this.bsModalRef = this.modalService.show(DeliveryAddressPopUpComponent, { initialState });
    this.bsModalRef.content.formSubmit.subscribe((newAddress: Address) => {
        this.user.address[index] = newAddress;
        //delete this.user.Id
        this.user.isLoggedIn = true;
        this.apiService.updateUser(this.user).subscribe(data => {
          this.user = data as User;
          localStorage.setItem('user', JSON.stringify(data));
        })
    });
  }

  checkPostCode() {
    var postcode = this.customerForm.get('billingAddress')?.get('postcode')?.value;
    if (postcode) {
      const normalizedPostcode = postcode.trim().replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
    this.apiService.getPostCodeV(normalizedPostcode).subscribe((data: any) => {
      if (data["valid"] == false) {
        this.customerForm.get('billingAddress')?.get('postcode')?.setErrors({ 'invalid': true });
        this.addressValid=false;
      }
      else {
        this.updateUser();
        // this.addressValid=true;
        // this.validateAddress(data["formatted_address"])
        // this.saveButtonBlur=false;
      }
    });
  }
  }
  validateAddress(formatted_address:string){
    let splits = formatted_address.split(',')
    let address = splits[0]
    splits[splits.length - 2] =splits[splits.length - 2].trim()
    let city = splits[splits.length - 2].split(' ')[0]
    let address_f = this.customerForm.get('billingAddress')?.get('address')?.value
    let city_f = this.customerForm.get('billingAddress')?.get('city')?.value
    if(address_f==null || address_f==="" || !address_f.includes(address)){
      this.customerForm.get('billingAddress')?.get('address')?.setValue(address)
    }
    if(city_f==="" || city_f==null || !city_f.includes(city)){
      this.customerForm.get('billingAddress')?.get('city')?.setValue(city)
    }
  }
  setOffSave(){
    this.saveButtonBlur=true;
  }
  checkError(control: string) {
    return this.customerForm.get('billingAddress')?.get('postcode')?.invalid;
  }
  closeAlert() {
    this.saved = false;
  }

  subMenuClick(menu: string): void {
    for (let key in this.subMenu) {
      if (key === menu) {
        this.subMenu[key] = true;
      } else {
        this.subMenu[key] = false;
      }
    }
  }
  openModal(template: TemplateRef<any>,result: string): void {
   
    if(result==="success"){
    this.modalMessage = "Updated Successfully."
    this.modalHeading = "Success."
    this.bsModalRef = this.modalService.show(template, { class: 'modal-dialog-centered' });
    }
  }
  modalHide(){
  }

}
