import { Component } from '@angular/core';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  isHidden = false;
  currentIndex: number = 0;
  private intervalId: any;
  opacity: number = 1;

  constructor(private metadataService: MetadataService) {
    this.startInterval();
    this.opacityStartInterval();
  }
  ngOnInit(): void {
    this.metadataService.setCanonicalLink();
    this.metadataService.setTitle('MDF Cut to Size - Cutwrights Bespoke Board Cutting');
    this.metadataService.setDescription('Using the latest in Automated machinery, we deliver what we believe to be the “The Ultimate Cutting, Edging and Drilling Service”, across a huge range of boards and finishes.');
  }

  startInterval() {
      this.opacity = 1;
      this.currentIndex = this.currentIndex + 1;
      if(this.currentIndex === 5) {
        this.currentIndex = 0;
      }
  }


  opacityStartInterval() {
     this.opacity = this.opacity - 0.17;
  }

  stopInterval() {
    clearInterval(this.intervalId);
  }

  comments: Comments[] = [
    {
    id: 1,
    comment: 'The MDF has been prepared to perfection and your delivery driver was excellent!',
    name: 'Deborah Larne'
  },
  {
    id: 2,
    comment: 'Easy to use website to a prompt delivery service and quality materials.',
    name: 'Richard de Klerk'
  },
  {
    id: 3,
    comment: 'All the Cutwrights guys are ace.',
    name: 'Rob Williams, www.robscarpentry.co.uk'
  },
  {
    id: 4,
    comment: 'So quick, excellently cut. Very good service.',
    name: 'Andrew Ritchie, Brompton Bicycle Ltd.'
  },
  {
    id: 5,
    comment: 'Great job done on time and with great communication.',
    name: 'Ray Brown &amp; Associates'
  },
  {
    id: 6,
    comment: 'Thanks to you and the whole team for saving the day.',
    name: 'Roger Silveira, East Photographic Ltd.'
  },
]
}

export interface Comments {
  id: number;
  comment: string;
  name: string;
}
