import { Component, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'; 
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-plywood-board',
  templateUrl: './plywood-board.component.html',
  styleUrls: ['./plywood-board.component.scss']
})
export class PlywoodBoardComponent {
  
  bsModalRef!: BsModalRef;
  modalImageUrl! : string;
  constructor(private metadataService: MetadataService,private modalService: BsModalService){}
  ngOnInit(): void {
    this.metadataService.setCanonicalLink();
    this.metadataService.setTitle('default');
    this.metadataService.setDescription('default');
  }
  
  
  openImageModal(template: TemplateRef<any>, url: string): void {
    this.modalImageUrl = url;
    this.bsModalRef = this.modalService.show(template, { class: 'img-modal'});
  }

  closeModal(): void {
    this.bsModalRef.hide();
  }


}
