<header *ngIf="!hideHeader">
<div id="website-banner" class="website-banner">
    <div>
        <div class="float-left">
            <a><img style="border:0px;" name="Root$ctl18$btnLogo" id="ctl18_btnLogo" class="logo"
                    src="../../../../assets/images/Main.Logo.png" alt="Logo"></a>
           
        </div>
        <div class="menu-module office-menu">
            <ng-container *ngFor="let header of headers">
                <span [class]="header.route === activeRoute ? 'selected' : ''">
                    <div class="item">
                        <a [routerLink]="[header.route]">
                            {{header.headerName}}
                        </a>
                    </div>
                </span>
          </ng-container>
        </div>
    </div>
</div>
</header>