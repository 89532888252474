import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForOf, NgIf } from '@angular/common'; // Import NgForOf for *ngFor
import { debug } from 'console';

@Component({
  selector: 'app-choose-board-view-all',
  standalone: true,
  imports: [NgForOf, NgIf], // Import required directives
  templateUrl: './choose-board-view-all.component.html',
  styleUrls: ['./choose-board-view-all.component.scss'] // Use `styleUrls` instead of `styleUrl`
})
export class ChooseBoardViewAllComponent {
  totalTags: any[] = [];
  tags: any;
  materialArray: any[] = [];
  materials: any;
  filtered_tags = []

  constructor(private route: ActivatedRoute, private router: Router) {
    // Load materials
    const data = this.route.snapshot.data['material_tags'];
    this.tags = data[3];
    this.materials = data[0];

    this.filtered_tags = this.tags.filter((tag: any) =>
      tag['code'].toLowerCase()
    );
    
    // Ensure materials data is available
    if (this.materials) {
      for (const key in this.materials) {
        if (this.materials.hasOwnProperty(key)) {
          const materialObject: any = this.materials[key];
          this.materialArray.push(materialObject.code);
        }
      }
    }
  }

  navigateTo(code: string) {
    const clean_code: string = code.replace('/', '%2F');
    this.router.navigate([`./choose-your-boards/${clean_code}`]);
  }
}
