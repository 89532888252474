<div class="main-body">
    <div class="content-area">
        <div class="content-area-content ">
            <div>
                <h2 class="mb-4">Confirmation of quote submission</h2>
                <span class="view-body">
                    <div class="item">
                        <div class="value">
                            <p>You have successfully submitted your request for a quotation.........................</p>

                            <p>If this is your first time using our service, please check your&nbsp;emails as we
                                have&nbsp;sent you a password.&nbsp;You will need this to log into your new account,
                                this is where you will find all&nbsp;quotations you have requested&nbsp;(you can change
                                this password to something more memorable within the "My Account" tab).</p>

                            <p><span style="font-family:arial,sans-serif; font-size:10pt">Our quoting time is quick and
                                    usually prepared within a few minutes.&nbsp;</span>If you have used the special
                                order instructions box within the cutting list, your order will need to be
                                reviewed&nbsp;by our production team before we can provide a quotation.&nbsp;<span
                                    style="font-family:arial,sans-serif; font-size:10pt">As soon as the price is ready
                                    you will receive an email.</span></p>

                            <p>We trust our&nbsp;quotation will be competitive and look forward to working with you.</p>

                            <p>Many thanks</p>

                            <p>From the Cutwrights Team</p>
                        </div>
                    </div>
                </span>
                <div class="buttons">
                    <input type="submit" name="Root$Main$ctl00$btnReturn" (click)="goToQuotes()" value="Return"
                        id="Main_ctl00_btnReturn">
                </div>
            </div>

        </div>
    </div>
</div>