import { AdminHeaders } from "../models/admin.models";

export const ADMIN_HEADERS: AdminHeaders[] = [
    {
        headerName: 'Customers',
        path: 'customers',
        route: '/admin/customers'
    },
    {
        headerName: 'Orders',
        path: 'orders',
        route: '/admin/orders'
    },
    {
        headerName: 'Received Quotes',
        path: 'received-quotes',
        route: '/admin/received-quotes'
    },
    {
        headerName: 'Stock',
        path: 'stock',
        route: '/admin/stock'
    },
    {
        headerName: 'Offcut',
        path: 'off-cut',
        route: '/admin/off-cut'
    },
    // {
    //     headerName: 'Invoices',
    //     path: 'invoices',
    //     route: '/admin/invoices'
    // },
    {
        headerName: 'Transport',
        path: 'transport',
        route: '/admin/transport'
    }
]