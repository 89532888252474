<div class="content-area" [class]="token ? 'from-admin content-area' : 'content-area'">
    <div *ngIf="dataFetched" class="content-area-content ">
        <h2>Order {{order.orderReference}} ({{order.jobReference}})</h2>
        <span class="view-body">
            <div class="item">
                <div class="label">
                    Customer Name:
                </div>
                <div class="value">
                    {{order.customerName}}
                </div>
            </div>
            <div class="item">
                <div class="label">
                    Company Name:
                </div>
                <div class="value">
                    {{order.customerCompanyName}}
                </div>
            </div>
            <div class="item">
                <div class="label">
                    Delivery type:
                </div>
                <div class="value">
                    {{order.collection ?'Collection' : 'Delivery'}}
                </div>
            </div>
            <div class="item">
                <div class="label">
                    Needed by:
                </div>
                <div class="value">
                    {{order.neededBy | date: 'dd/MM/yy' }}
                </div>
            </div>
            <div class="item">
                <div class="label">
                    Off cuts:
                </div>
                <div class="value">
                    {{quote.includeOffCuts? 'Include': 'Exclude'}}
                </div>
            </div>
            <div class="item">
                <div class="label">
                    Panel labels:
                </div>
                <div class="value">
                    {{quote.panelsNeedsLabelling ?'Yes': 'No'}}
                </div>
            </div>
            <!-- <div class="item">
                <div class="label">
                    Delivery/collection date:
                </div>
                <div class="value">
                    No date, No time
                </div>
            </div> -->
            <div class="item">
                <div class="value">
                    <input type="submit" name="Root$ctl21$btnPrintJobLabel" value="Print Job Label"
                    (click)="openPrintTab('print-job-label/')">
                </div>
            </div>
            <div class="item">
                <div class="value">
                    <input type="submit" name="Root$ctl21$btnPrintDoorDrawerCuttingList"
                        value="Print door / drawer cutting list"
                        (click)="openPrintTab('print-door')"
                        id="ctl21_btnPrintDoorDrawerCuttingList">
                </div>
            </div>
            <div class="item">
                <div class="value">
                    <input type="submit" name="Root$ctl21$btnPrintDoorDrawerCuttingList"
                        value="Print Cutting List"
                        (click)=" generatePDF()"
                        id="ctl21_btnPrintDoorDrawerCuttingList">
                </div>
            </div>
            <div class="item">
                <div class="value">
                    <input type="submit" name="Root$ctl21$btnPrintDoorDrawerCuttingList"
                        value="Invoice"
                        (click)="navigateToInvoice()"
                        id="ctl21_btnPrintDoorDrawerCuttingList">
                </div>
            </div>
        </span>
        <div class="buttons">
            <input type="submit" name="Root$ctl21$btnBack" [routerLink]="['/admin/orders']" value="Back"
                id="ctl21_btnBack" class="back-button">
        </div>
        <div class="sub-menu">
            <span [class]="subMenu.isMaterials ? 'selected': ''">
                <div class="item">
                    <a
                       (click)="subMenuClick('isMaterials')">
                        Materials
                    </a>
                </div>
            </span>
            <span [class]="subMenu.isOrders ? 'selected': ''">
                <div class="item">
                    <a
                    (click)="subMenuClick('isOrders')">
                        Order
                    </a>
                </div>
            </span>
            <span [class]="subMenu.isDelivery ? 'selected': ''">
                <div class="item">
                    <a
                    (click)="subMenuClick('isDelivery')">
                        Delivery
                    </a>
                </div>
            </span>
            <span [class]="subMenu.isProdNotes ? 'selected': ''">
                <div class="item">
                    <a  (click)="subMenuClick('isProdNotes')"
                        >
                        Production notes
                    </a>
                </div>
            </span>
            <span [class]="subMenu.isTargets ? 'selected': ''">
                <div class="item">
                    <a  (click)="subMenuClick('isTargets')"
                        >
                        Targets
                    </a>
                </div>
            </span>
            <span [class]="subMenu.isLog ? 'selected': ''">
                <div class="item">
                    <a  (click)="subMenuClick('isLog')"
                       >
                        Log
                    </a>
                </div>
            </span>
        </div>
       
        <div *ngIf="subMenu.isMaterials">
            <app-materials [quote]="quote"></app-materials>
        </div>

        <div *ngIf="subMenu.isOrders">
            <app-order [quote]="quote"></app-order>
        </div>

        <div *ngIf="subMenu.isDelivery">
            <app-delivery [order]="order" [quote]="quote"></app-delivery>
        </div>

        <div *ngIf="subMenu.isProdNotes">
            <app-prod-notes [order]="order"></app-prod-notes>
        </div>

        <div *ngIf="subMenu.isTargets">
            <app-targets [order]="order"></app-targets>
        </div>

        <div *ngIf="subMenu.isLog">
            <app-log [orderLogs]="orderLogs"></app-log>
        </div>

    </div>
</div>