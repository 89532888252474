<div *ngIf="isLoading" class="loader-section">
   <app-loader></app-loader>
</div>
<div *ngIf="quote && (!isLoading)">
<div *ngIf="currentStep === 1">
  <app-delivery-form [quote]="quote" [leadtime]="leadtime" [quoteId]="quoteId" [collection]="collection"
    (next)="onNextStep($event)"></app-delivery-form>
</div>

<div *ngIf="currentStep === 2">
  <app-personal-info-form (back)="currentStep = 1" (next)="onNextStep($event)"></app-personal-info-form>
</div>
<div  *ngIf="currentStep === 3">
  <app-acknowledge (next)="onNextStep($event)"></app-acknowledge>
  <div style="padding: 0px 40px 100px;">
   <button class="btn btn-primary btns-action" (click)="onNextStep('ManualPay')" *ngIf="checkIfAdmin()==true" >Manual Payment</button>
  </div>
</div>
</div>
<ng-template #paymentErrorPopUp>
  <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title"><b>Sorry.</b></h5>
        </div>
        <div class="modal-body">
            <p style="color: black;">{{errorMessage}}</p>
            </div>
        <div class="modal-footer">
            <button type="button" class="modal-button" (click)="redirect()">Okay</button>
        </div>
  </div>
</ng-template>