import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Subscription, debounceTime, every, throttleTime } from 'rxjs';
import { Part, Quote, eStatusM } from 'src/app/models/cut-wrights.model';
import { Shaker, eHingeSide, eShakerType } from 'src/app/models/shaker.model';
import { User } from 'src/app/models/user.model';
import { EndpointApiService } from 'src/app/service/endpoint-api.service';
import { CookieService } from 'ngx-cookie-service';
import { DataService } from 'src/app/service/data-service';
import { MetadataService } from 'src/app/service/metadata.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-get-quote',
  templateUrl: './get-quote.component.html',
  styleUrls: ['./get-quote.component.scss']
})
export class GetQuoteComponent implements OnInit {
  @ViewChild('overflowPopup', { static: true })
  overflowPopup!: TemplateRef<any>;
  quotes: Quote[] = [];
  form: FormGroup;
  formArray: FormArray;
  errorText = ""
  materials = {}
  isQuote = true;
  isNonRecommendedEdging:boolean=false;
  m_obj = {
    "materials": JSON.parse("{}"),
    "edges": JSON.parse("{}"),
    "boards": JSON.parse("{}")
  }
  test = ""
  mTemplateUrl = ""
  formSubcriptions = []
  isLoading = false
  commentText = "Please note some of the services we offer currently can’t be ordered online! (for example Drawer boxes) If you have requested extra services via the comments box these will not be included in the automated quotation. We advise you to wait for us to review these comments during working hours and get back to you with a firm price before proceeding with the order.";
  isQoute: boolean = true;
  isShakerDoor: boolean = false;
  width_errors: string[] = []
  length_errors: string[] = []
  length_warnings: any[] = []
  dataSource: any = [];
  isGetQuoteBtnEnabled: boolean = false;
  content_fetched = false
  submitted = false;
  quoteId!: string;
  quote!: Quote
  isLoggedIn = false;
  formSubs: Subscription[] = []
  materialSubs: Subscription[] = []
  user!: User;
  cForm: FormGroup;
  includeOffCuts:any;
  panelsNeedsLabelling:any;
  warningFlags: boolean[][] = []; 
  rowCount:number=0;
  selectedRowIndex:number=0;
  isFormFocused:boolean=false;
  bsModalRef!: BsModalRef;
  modalMessage: string="";
  modalHeader: string="";
  userString: string="";
  e_codes: any[][] = []
  materialGrain: any[] = []

  constructor(private modalService: BsModalService, private dataService: DataService,private apiService: EndpointApiService,
    private router: Router, private formBuilder: FormBuilder, private route: ActivatedRoute, private el: ElementRef, private _http: HttpClient, private cookieService: CookieService,private metadataService: MetadataService) {
    var data = this.route.snapshot.data['materials'];
    this.materials = data
    let mat = JSON.stringify(data[0]);
    this.m_obj["materials"] = JSON.parse(mat)
    this.m_obj["edges"] = JSON.parse(JSON.stringify(data[1]))
    this.m_obj["boards"] = JSON.parse(JSON.stringify(data[2]))
    let data_t = []
    this.dataSource = Object.keys(this.m_obj["materials"]).filter(data => this.m_obj["materials"][data]["hideincms"] == 0).map(data => {
      return {
        "desc": this.m_obj["materials"][data].description,
        "value": data
      }
    })
    this.route.queryParams.subscribe(params => {
      this.includeOffCuts = params['includeOffCuts'];
      this.panelsNeedsLabelling = params['panelsNeedsLabelling'];
      
    });
    
    this.content_fetched = true;

    this.getTemplate();
    const token = localStorage.getItem('token');
    this.userString = localStorage.getItem('user')!;
    if(this.userString){
    const userJSON = JSON.parse(this.userString)
    if(userJSON.roles.includes("admin")){
      this.userString = localStorage.getItem('tempUser')!;
     }
    }
    this.cForm = this.formBuilder.group({
      parts: [''],
      weight: [''],
      shakerDoors: [''],
      drawers: [''],
      panels: ['', Validators.required],
      offcuts: ['', Validators.required]
    })
    this.cForm.disable();
    if (token) {
      this.isLoggedIn = true;
      this.user = JSON.parse(this.userString)
    }
    this.quoteId = this.route.snapshot.paramMap.get('quoteId') as string;
    this.form = this.formBuilder.group({
      name: ['', [Validators.required,  Validators.maxLength(36)]],
      formArray: this.formBuilder.array([]),
      shakers: this.formBuilder.array([]),
      comments: []
    });
    this.formArray = this.form.get('formArray') as FormArray;
    if (this.quoteId != "" && this.quoteId != null) {
      this.isLoading = true;
      this.apiService.getQuote(this.quoteId).subscribe(data => {
        delete data.Id
        this.quote = data;
        this.quote.includeOffCuts=this.includeOffCuts;
        this.quote.panelsNeedsLabelling=this.panelsNeedsLabelling;
        this.form.get('name')?.setValue(data.name)
        this.form.get('comments')?.setValue(data.comment)
        this.warningFlags = Array(data.parts?.length).fill([]).map(() => Array(4).fill(false));
          for (let i = 0; i < data.parts?.length; i++) {
            this.e_codes[i] = []
            this.c_priming[i] = false
            this.formArray.push(this.formBuilder.group({
              materialCode: [data.parts[i].materialCode, Validators.required],
              materialDescription: [data.parts[i].materialDescription, Validators.required],
              width: [data.parts[i].width, [Validators.required, Validators.min(10)]],
              length: [data.parts[i].length, [Validators.required, Validators.min(10)]],
              quantity: [data.parts[i].quantity, [Validators.required, Validators.min(1), this.validateWholeNumber]],
              l1: [data.parts[i].l1, {
                validators: [],
              }],
              l1Description: [data.parts[i].l1Description,],
              l2: [data.parts[i].l2,],
              l2Description: [data.parts[i].l2Description,],
              w1: [data.parts[i].w1,],
              w1Description: [data.parts[i].w1Description,],
              w2: [data.parts[i].w2,],
              w2Description: [data.parts[i].w2Description,],
              partComment: [data.parts[i].partComment, [this.noCommaValidator, Validators.maxLength(35)]],
              primed: [data.parts[i].primed],
              weight: [0],
              grain:[this.materialGrain[i]]
            }));

            //Grain Swap
            this.newValidateLW(i)
          }

        data.shakers?.forEach(shaker => {
          
          let maxHeight = 500
          let maxWidth = 1200
          let minHeight = 250
          let minWidth = 250
          if (shaker.type == 2) {
            minHeight = 70
            minWidth = 250
            maxHeight = 500
            maxWidth = 1200            
          } else if (shaker.type == 1) {
            minHeight = 250
            minWidth = 250
            maxHeight = 500
            maxWidth = 1200
          } else if (shaker.type == 0) {
            minHeight = 250
            minWidth = 250
            maxHeight = 2420
            maxWidth = 900
          }
          let hingesArray = this.formBuilder.array([]) as FormArray;
          let midRailsArray = this.formBuilder.array([]) as FormArray;
          let group = this.formBuilder.group({
            name: [shaker.name, []],
            height: [shaker.height, [Validators.required, Validators.min(minHeight), Validators.max(maxHeight)]],
            width: [shaker.width, [Validators.required, Validators.min(minWidth), Validators.max(maxWidth)]],
            quantity: [shaker.quantity, [Validators.required, Validators.min(1), this.validateWholeNumber]],
            primed: [shaker.primed],
            weight: [shaker.weight, Validators.required],
            type: [shaker.type],
            totalnumberofmidrails: [shaker.totalnumberofmidrails],
            midrails: midRailsArray,
            hingeholes35mm: [shaker.hingeholes35mm],
            useinsertahinges: [shaker.useinsertahinges],
            hingeside: [shaker.hingeside],
            totalnumberofhingeholes: [shaker.totalnumberofhingeholes],
            hinges: hingesArray,
            drillingdistance: [shaker.drillingdistance],
            // blumInserta: [shaker.blumInserta]  //removed by KD as dupicate variable wrt useinsertahinges
          })
          shaker.hinges.forEach(hinge => {
            hingesArray.push(this.formBuilder.group({
              y: [hinge.y]
            }))
          })
          shaker.midrails.forEach(rail => {
            midRailsArray.push(this.formBuilder.group({
              position: [rail.position, [Validators.min(90), this.midrailOverlapValidator(group.get('height') as AbstractControl)]]
            }))
          });


          group.get('totalnumberofhingeholes')?.valueChanges.subscribe((change: any) => {
            let formArray_h = group.get('hinges') as FormArray

            let count = group.get('totalnumberofhingeholes')?.value as number;
            let c_count = formArray_h.length;
            if (c_count < count) {
              for (let i = 0; i < count - c_count; i++) {
                formArray_h.push(this.formBuilder.group({
                  y: [0, [Validators.min(0)]]
                }));
              }
            } else {
              for (let i = 0; i < c_count - count; i++) {
                formArray_h.removeAt(count - i);
              }
            }
          })
          group.get('totalnumberofmidrails')?.valueChanges.subscribe((change: any) => {
            let formArray_m = group.get('midrails') as FormArray

            let count = group.get('totalnumberofmidrails')?.value as number;
            let c_count = formArray_m.length;
            if (c_count < count) {
              for (let i = 0; i < count - c_count; i++) {
                formArray_m.push(this.formBuilder.group({
                  position: [0, [Validators.min(0), this.midrailOverlapValidator(group.get('height') as AbstractControl)]]
                }));
              }
            } else {
              for (let i = 0; i < c_count - count; i++) {
                formArray_m.removeAt(count - i);
              }
            }
          });
          (this.form.get('shakers') as FormArray).push(group)

        })

        if (data.parts?.length == null || data.parts?.length == 0) {
          this.addRows(5, false);
        } else {
          this.formArray.controls.forEach((elem, index) => {
            //this.focusEachElement(index);
            if (elem.get('materialCode')?.valid && this.dataSource.some((item:any) => item.value == elem.get('materialCode')?.value)) {
              if (this.checkPriming(index)) {
                elem.get('primed')?.enable()
              } else {
                elem.get('primed')?.setValue(false);
                elem.get('primed')?.disable()
              }
              this.getECodes(index);
            }
            elem.get('weight')?.setValue(this.calculateWeight(index))
          })
        }

        //this.form.markAllAsTouched();
        this.formArray.controls.forEach((element, index) => {
          this.formArray.at(index).updateValueAndValidity();
        });
        this.form.markAsDirty()
        this.valChangesInit();
        this.updateView();

        this.isLoading = false;
      }, (error) => {
        this.isLoading = false;
      })

    } else {
      // this.addRows(5, false);
      if (this.cookieService.check('d_quote')) {
        this.c_quoteId = this.cookieService.get('d_quote')
      }
      if (this.c_quoteId && this.c_quoteId != "") {
        this.apiService.getCachedQuote(this.c_quoteId).subscribe((data: any) => {
        if(data != null){
          this.form.get('name')?.setValue(data.name)
          this.form.get('comments')?.setValue(data.comment)
          
        this.warningFlags = Array(data.parts?.length).fill([]).map(() => Array(4).fill(false));

          for (let i = 0; i < data.parts?.length; i++) {
            this.e_codes[i] = []
            this.c_priming[i] = false
            this.formArray.push(this.formBuilder.group({
              materialCode: [data.parts[i].materialCode, Validators.required],
              materialDescription: [data.parts[i].materialDescription, Validators.required],
              width: [data.parts[i].width, [Validators.required, Validators.min(10)]],
              length: [data.parts[i].length, [Validators.required, Validators.min(10)]],
              quantity: [data.parts[i].quantity, [Validators.required, Validators.min(1), this.validateWholeNumber]],
              l1: [data.parts[i].l1, {
                validators: [],
              }],
              l1Description: [data.parts[i].l1Description,],
              l2: [data.parts[i].l2,],
              l2Description: [data.parts[i].l2Description,],
              w1: [data.parts[i].w1,],
              w1Description: [data.parts[i].w1Description,],
              w2: [data.parts[i].w2,],
              w2Description: [data.parts[i].w2Description,],
              partComment: [data.parts[i].partComment, [this.noCommaValidator, Validators.maxLength(35)]],
              primed: [data.parts[i].primed],
              weight: [0],
              grain:[this.materialGrain[i]]
            }));
            setTimeout(() => {
                    //Grain Swap
                    this.newValidateLW(i)
              //this.validateWidth(i)
            }, 0);
          }

          //Populate grain field
          for (let i = 0; i < data.parts?.length; i++) {
            this.getGrain(i);
            this.formArray.at(i).get('grain')?.setValue(this.materialGrain[i])

          }

          data.shakers?.forEach((shaker: any) => {
            let hingesArray = this.formBuilder.array([]) as FormArray;
            let midRailsArray = this.formBuilder.array([]) as FormArray;
            let group = this.formBuilder.group({
              name: [shaker.name, []],
              height: [shaker.height, [Validators.required, Validators.max(2420)]],
              width: [shaker.width, [Validators.required, Validators.max(900)]],
              quantity: [shaker.quantity, [Validators.required, Validators.min(1), this.validateWholeNumber]],
              primed: [shaker.primed],
              weight: [shaker.weight, Validators.required],
              type: [shaker.type],
              totalnumberofmidrails: [shaker.totalnumberofmidrails],
              midrails: midRailsArray,
              hingeholes35mm: [shaker.hingeholes35mm],
              useinsertahinges: [shaker.useinsertahinges],
              hingeside: [shaker.hingeside],
              totalnumberofhingeholes: [shaker.totalnumberofhingeholes],
              hinges: hingesArray,
              drillingdistance: [shaker.drillingdistance],
              // blumInserta: [shaker.blumInserta]  //removed by KD as dupicate variable wrt useinsertahinges
            })
            shaker.hinges.forEach((hinge: any) => {
              hingesArray.push(this.formBuilder.group({
                y: [hinge.y]
              }))
            })
            shaker.midrails.forEach((rail: any) => {
              midRailsArray.push(this.formBuilder.group({
                position: [rail.position, [Validators.min(90), this.midrailOverlapValidator(group.get('height') as AbstractControl)]]
              }))
            });


            group.get('totalnumberofhingeholes')?.valueChanges.subscribe((change: any) => {
              let formArray_h = group.get('hinges') as FormArray

              let count = group.get('totalnumberofhingeholes')?.value as number;
              let c_count = formArray_h.length;
              if (c_count < count) {
                for (let i = 0; i < count - c_count; i++) {
                  formArray_h.push(this.formBuilder.group({
                    y: [0, [Validators.min(0)]]
                  }));
                }
              } else {
                for (let i = 0; i < c_count - count; i++) {
                  formArray_h.removeAt(count - i);
                }
              }
            })
            group.get('totalnumberofmidrails')?.valueChanges.subscribe((change: any) => {
              let formArray_m = group.get('midrails') as FormArray

              let count = group.get('totalnumberofmidrails')?.value as number;
              let c_count = formArray_m.length;
              if (c_count < count) {
                for (let i = 0; i < count - c_count; i++) {
                  formArray_m.push(this.formBuilder.group({
                    position: [0, [Validators.min(0), this.midrailOverlapValidator(group.get('height') as AbstractControl)]]
                  }));
                }
              } else {
                for (let i = 0; i < c_count - count; i++) {
                  formArray_m.removeAt(count - i);
                }
              }
            });
            (this.form.get('shakers') as FormArray).push(group)
          })

          if (data.parts?.length == null || data.parts?.length == 0) {
            this.addRows(5, false);
          } else {
            this.formArray.controls.forEach((elem, index) => {
              if (elem.get('materialCode')?.valid && this.dataSource.indexOf(elem.get('materialCode')?.value) >= 0) {
                if (this.checkPriming(index)) {
                  elem.get('primed')?.enable()
                } else {
                  elem.get('primed')?.setValue(false);
                  elem.get('primed')?.disable()
                }
                this.getECodes(index);
              }
              elem.get('weight')?.setValue(this.calculateWeight(index))
            })
          }
          this.valChangesInit();
          this.updateView();
          // this.form.markAllAsTouched()
          this.formArray.controls.forEach((element, index) => {
            if (!this.isRowEmpty(index)){
              this.formArray.at(index)?.get('length')?.markAsTouched();
              this.formArray.at(index)?.get('width')?.markAsTouched();
              this.formArray?.at(index)?.get('l1')?.markAsTouched();
              this.formArray?.at(index)?.get('w1')?.markAsTouched();
              this.formArray?.at(index)?.get('l2')?.markAsTouched();
              this.formArray?.at(index)?.get('w2')?.markAsTouched();
            }
            // this.formArray.at(index).updateValueAndValidity();
            // this.formArray.at(index).markAsDirty()
          });
          // this.form.markAsDirty()
          this.isLoading = false;
         } else {
          this.cookieService.deleteAll('d_quote')
          this.c_quoteId = ""
          this.newQuoteInit()
         }
         //this.formArray.markAllAsTouched();
        })
      } else {
        this.newQuoteInit()
      }
    }

    // for (let i = 0; i < 5; i++) {
    //   this.e_codes[i] = []
    //   this.c_priming[i] = false
    //   this.formArray.push(this.formBuilder.group({
    //     materialCode: ['', Validators.required],
    //     width: ['', Validators.required],
    //     length: ['', Validators.required],
    //     quantity: ['', Validators.required],
    //     l1: ['', {
    //       validators: [],
    //     }],
    //     l2: ['', ],
    //     w1: ['', ],
    //     w2: ['', ],
    //     partComment:[''],
    //     primed:[false],
    //     weight:[0]
    //   }));
    // }
    // this.updateView();
    window.addEventListener('beforeunload', this.onBeforeUnload.bind(this));
    const storedWarningFlags = localStorage.getItem('warningFlags');

    if (storedWarningFlags) {
      this.warningFlags = JSON.parse(storedWarningFlags);
    }
  }
  onBeforeUnload(event: Event): void {
    localStorage.setItem('warningFlags', JSON.stringify(this.warningFlags));
  }
  ngOnInit(): void {
    this.metadataService.setCanonicalLink();
      this.metadataService.setTitle('Quote | Parts');
    }
  newQuoteInit() {
    this.addRows(5, false);
    this.cached_quote_init();
    this.valChangesInit();
    this.updateView();
    
  }
  validateFormArray(){

    let index=0; 
    let error:any =[]
    this.formArray.controls.forEach((control:any) => {
            control.get('length').markAsTouched();
            control.get('width').markAsTouched();
            control.get('l1').markAsTouched();
            control.get('l2').markAsTouched();
            control.get('w1').markAsTouched();
            control.get('w2').markAsTouched();
            if(!this.newValidateLW(index)){
              error[index]=true;
            }
            else{
              error[index]=false;
              this.calculateWeight(index)    //Calculate index when submitting.          
            }
        //this.validateWidth(index);
      index++;
    });
    
    if (error.some((err:any) => err === true)) {
      return false;
    }
    else return true;
  }

  cached_quote_init() {
    let quote = this.form.value
    //Warning Flag initialized
    this.warningFlags = Array(this.formArray.value.length).fill([]).map(() => Array(4).fill(false));

    let reqBody = {
      "parts": quote.formArray,
      "name": quote.name,
      "shakers": quote.shakers,
      "quoteId": this.c_quoteId
    }
    let formValue = this.form.value
    quote.parts = formValue
    this.apiService.putCachedCutWrightData(reqBody).subscribe((data: any) => {
      this.c_quoteId = data.quoteId
      if (!this.cookieService.check('d_quote') || this.cookieService.get('d_quote') == "") {
        this.cookieService.set('d_quote', data.quoteId, 365);
      }
    })
  }

  updateView() {
    this.cForm.get('weight')?.setValue(this.getWeight());
    this.cForm.get('parts')?.setValue(this.getPartsC());
    this.cForm.get('shakerDoors')?.setValue(this.getShakerDoorsC());
    this.cForm.get('drawers')?.setValue(this.getShakerDrawerC());
    this.cForm.get('parts')?.disable();
    this.cForm.get('weight')?.disable();
  }

  addRows(rows = 11, init = true): void {
    for (let i = 0; i < rows; i++) {
      this.formArray.push(this.formBuilder.group({
        materialCode: ['', Validators.required],
        materialDescription: ['', Validators.required],
        width: ['', [Validators.required, Validators.min(10)]],
        length: ['', [Validators.required, Validators.min(10)]],
        quantity: ['', [Validators.required, Validators.min(1), this.validateWholeNumber]],
        l1: [''],
        l1Description: [''],
        l2: [''],
        l2Description: [''],
        w1: [''],
        w1Description: [''],
        w2: [''],
        w2Description: [''],
        partComment: ['', [this.noCommaValidator, Validators.maxLength(35)]],
        primed: [false],
        weight: [0],
        grain:[this.materialGrain[i]]
      }));
      
    }
    const newRows = Array(rows).fill(false);
    for (let i = 0; i < rows; i++) {
      this.warningFlags.push(newRows.slice());
    }
    if (init) {
      this.valChangesInit();
    }
  }

  typeaheadOnSelect(event: any): void {
    event.q.code = event.ev.item.code;

  }

  validate(): void {
  }

  getQuote(): void {
    var quote = this.form.value;
    if ((quote.shakers.length == 0 && (quote.formArray?.length == 0 || (!quote.formArray.some((arr: any) => arr.materialCode != null && arr.materialCode.length > 0))))) {
      window.scrollTo(0, 0);
      return
    }
    this.updateQuoteValidity();
    this.isLoading = true
    if ((this.form.valid && this.isLoggedIn && (this.quoteId == null || this.quoteId == "")) && this.validateFormArray()) {
      
      quote = this.form.value;
      let reqBody = {
        "parts": quote.formArray,
        "name": quote.name,
        "quoteId": this.c_quoteId,
        "shakers": quote.shakers,
        "jobId": new Date().getUTCMilliseconds(),
        // "includeOffCuts": this.cForm.value.panels,
        // "panelsNeedsLabelling": this.cForm.value.offcuts,
        "customerId": this.user.userId,
        // "optimisingparameter": "Holzma HPP380 STD",
        // "sawparameter": "Holzma HPP380 STD",
        "comment": quote.comments,
        "status": 0,
        "customerName": this.user.firstName + this.user.lastName,
        "customerCompanyName": this.user.companyName,
        "customerMobileNumber": this.user.mobile
        // "numberOfParts": this.getPartsC(),
        // "weight": this.getWeight(),
        // "collection": this.radioForm.get('choice')?.value == 'collection' ? true : false,
        // "address": ad
      }
      this.dataService.setPanelAndOffcut(false);
      this.isLoading = true;
      this.apiService.postCutWrightData(reqBody).subscribe((res: any) => {
        this.quoteId = res.quoteId;
        this.cookieService.deleteAll('d_quote')
        this.apiService.getQuote(this.quoteId).subscribe(data => {
          this.quote = data as Quote
          delete this.quote.Id
          this.submitted = true;
          this.isLoading = false;
        })
      }, (error) => {
        if(error.status == 409){
          // window.alert("Quote id alread exists , kindly do a cache refresh and remove cookies to move forward !!")
          this.cookieService.deleteAll('d_quote')
          this.c_quoteId =""
          this.getQuote()
        }
        this.isLoading = false;
      })
      window.scrollTo(0, 0);
    } else if ((this.form.valid && this.isLoggedIn && this.quote != null && this.quoteId != "")&& this.validateFormArray()) {
      var quote_d = this.form.value;
      this.quote.parts = quote_d.formArray;
      this.quote.shakers = quote_d.shakers
      this.quote.name = quote_d.name
      this.quote.comment = quote_d.comments
      this.quote.status = 0
      this.quote.numberOfParts=this.getPartsC().toString();
      this.quote.weight=this.getWeight()
      this.apiService.putCutWrightData(this.quote).subscribe((data: any) => {
        // this.cookieService.deleteAll('d_quote')
        this.quote.quoteId = data.quoteId
        this.submitted = true
        this.isLoading = false
      }, (error => { this.isLoading = false 
        if(error.status == 409){
          this.cookieService.deleteAll('d_quote')
          this.c_quoteId =""
          this.quoteId = ""
          this.getQuote();
          // window.alert("Quote id alread exists , kindly do a cache refresh and remove cookies to move forward !!")

        }}))
        window.scrollTo(0, 0);
    } else if (this.form.invalid) {
      this.form.markAllAsTouched()
      window.scrollTo(0, 0);
      this.isLoading = false;
      return;
    }
    else if(!this.validateFormArray()){
      window.scrollTo(0, 0);
      this.isLoading = false;
      return;
    }
    else if (true) {
      this.isLoading = false
      let q = this.form.value
      q.parts = q.formArray
      delete q.formArray
      this.quote = q
      this.quote.quoteId = this.c_quoteId;
      this.submitted = true;
    }
    window.scrollTo(0, 0);
    }



  deletePart(index: number): void {
    const formArray = this.form.get('formArray') as FormArray;
    formArray.removeAt(index);
    this.e_codes.splice(index,1);
    this.warningFlags.splice(index, 1);
    this.length_errors.splice(index, 1);
    this.width_errors.splice(index, 1);
    this.length_warnings.splice(index, 1);
    this.materialGrain.splice(index, 1);
  }
  
  getECodes(index: number) {
    const formArray = this.form.get('formArray') as FormArray;
    const formGroup = formArray.at(index) as FormGroup;
    const codeControl = formGroup.controls["materialCode"];
    if (codeControl.valid && codeControl.value != "") {
      this.getGrain(index);
      this.formArray.at(index).get('grain')?.setValue(this.materialGrain[index])
      this.e_codes[index] = []
      this.m_obj["materials"][codeControl.value]["edge_codes"].forEach((code: any) => {        
        this.e_codes[index].push(this.m_obj["edges"][code]);
      })
    } else {
      this.e_codes[index] = [] as string[]
    }
  }
  
  getGrain(index: number) {
    const formArray = this.form.get('formArray') as FormArray;
    const formGroup = formArray.at(index) as FormGroup;
    const codeControl = formGroup.controls["materialCode"];
    
    if (codeControl.valid && codeControl.value != "") {
      const grainValue = this.m_obj["materials"][codeControl.value]["grain"];
      this.materialGrain[index] = (grainValue === "Y") ? true : (grainValue === "N") ? false : null;
    }
  }
  
  getFD(item: string, index: number): string[] {
    return this.e_codes[index];
  }
 typeaheadEdgesValidator(controlName: string, index: number): void {
  setTimeout(() => {
    const formArray = this.form.get('formArray') as FormArray;
    const formGroup = formArray.at(index) as FormGroup;
    const control = formGroup.get(controlName);
    const input = control?.value;

    if (controlName === "materialCode") {
      if (control && control.value !== "" && !this.dataSource.some((datam: any) => input === datam.value)) {
        control?.setValue(null);
        control?.setErrors({ typeaheadInvalid: true });
        control?.markAsTouched();
        formGroup.get('l1')?.setValue(null);
        formGroup.get('l2')?.setValue(null);
        formGroup.get('l1Description')?.setValue(null);
        formGroup.get('l2Description')?.setValue(null);
        formGroup.get('w1')?.setValue(null);
        formGroup.get('w2')?.setValue(null);
        formGroup.get('w1Description')?.setValue(null);
        formGroup.get('w2Description')?.setValue(null);
      } else if (control?.touched && (control.value == null || control.value === "")) {
        control?.setErrors({ required: true });
      }
      this.getECodes(index);
      this.getGrain(index);
    } else if (controlName === "materialDescription") {
      if (control && !this.dataSource.some((datam: any) => input === datam.desc)) {
        control?.setValue(null);
        // control?.setErrors({ typeaheadInvalid: true });
        control?.markAsTouched();
        formGroup.get('materialCode')?.setValue(null);
        // const l1C = formGroup.get('l1');
        // const l2C = formGroup.get('l2');
        // l1C?.setValue(null);
        // l2C?.setValue(null);
        // const l1CD = formGroup.get('l1Description');
        // const l2CD = formGroup.get('l2Description');
        // l1CD?.setValue(null);
        // l2CD?.setValue(null);
        // const w1C = formGroup.get('w1');
        // const w2C = formGroup.get('w2');
        // w1C?.setValue(null);
        // w2C?.setValue(null);
        // const w1CD = formGroup.get('w1Description');
        // const w2CD = formGroup.get('w2Description');
        // w1CD?.setValue(null);
        // w2CD?.setValue(null);
      } else if (control?.touched && (control.value == null || control.value === "")) {
        control?.setErrors({ required: true });
      }
      else if(control && this.dataSource.some((datam: any) => input === datam.desc)){
        this.dataSource.find((datam:any)=>{
          if(input=== datam.desc){
            formGroup.get('materialCode')?.setValue(datam.value);
          }
        })
      }
    } else {
      const codeControl = formGroup.get("materialCode");
      const code = codeControl?.value || "" as string;
      const controlDescriptionValue = formGroup.get(controlName + "Description")?.value;
      const controlDescription = formGroup.get(controlName + "Description");
      const edgingCodeEntered = Object.keys(this.m_obj["edges"]).filter(data => this.m_obj["edges"][data]["description"] === controlDescriptionValue);
      
      if (this.e_codes[index][0]){
        //Looks for the edging entered by user matches with the boards edging.
        this.isNonRecommendedEdging = this.e_codes[index].find(data => data['code'] === edgingCodeEntered[0]);
      }
      
      if ((this.isNonRecommendedEdging === undefined || this.isNonRecommendedEdging===false) && edgingCodeEntered.length !== 0) {
        this.triggerWarning(true, index, controlName);
      }
      if (this.isNonRecommendedEdging === undefined && edgingCodeEntered.length === 0) {
        this.triggerWarning(false, index, controlName);
      }
      if (this.isNonRecommendedEdging) {
        this.triggerWarning(false, index, controlName);
      }

      if (edgingCodeEntered.length === 0) {
        control?.setValue(null);
        controlDescription?.setValue(null);
        if (this.m_obj["materials"][code]["edge_codes"].length > 0) {
          // control?.setErrors({ typeaheadInvalid: true });
          // control?.markAsTouched();
        }
      } else {
        const l1 = formGroup.get('l1');
        const l2 = formGroup.get('l2');
        const w1 = formGroup.get('w1');
        const w2 = formGroup.get('w2');

        if (controlName === 'l1') {
          l1?.setValue(edgingCodeEntered[0]);
        } else if (controlName === 'l2') {
          l2?.setValue(edgingCodeEntered[0]);
        } else if (controlName === 'w1') {
          w1?.setValue(edgingCodeEntered[0]);
        } else if (controlName === 'w2') {
          w2?.setValue(edgingCodeEntered[0]);
        }
      }
           //if (control && codeControl?.valid && (control.value != null || controlDescription?.value != null) && (  !this.e_codes[index].some((edge: any) => edge.description == controlDescriptionValue))) {
    // if (this.m_obj["materials"][code]["edge_codes"].length > 0) {
        //   // control?.setErrors({ typeaheadInvalid: true })
        //   // control?.markAsTouched()
        // }
      
      // else if (control?.touched && control.value == null || control?.value == "") {
      //   control?.setErrors({ required: true })
      // }

      if (codeControl?.invalid) {
        control?.setValue(null);
      }
    }
       //Grain Swap
         this.newValidateLW(index)
    // this.validateWidth(index);
  }, 0);
}

  triggerWarning(input:boolean,index: number,columnName:string) {
    var columnNumber=-1;
    if(columnName==='l1'){
      columnNumber=0;
    }
    if(columnName==='l2'){
      columnNumber=1;
    }
    if(columnName==='w1'){
      columnNumber=2;
    }
    if(columnName==='w2'){
      columnNumber=3;
    }
    this.warningFlags[index][columnNumber]= input;
  }
  checkForMaxName(){
    const control = this.form.get('name')
    if (control?.hasError('maxlength')){
      return true;
    }
    else{
      return false
    }
  }
  checkForError(controlName: string, index: number) {
    const myForm = this.formArray.at(index) as FormGroup;
    const control = myForm.get(controlName);
    // Count the number of fields with data in the row
    let filledFieldsCount = 0;
    Object.keys(myForm.controls).forEach(key => {
      const value = myForm.get(key)?.value;
      if (value !== null && value !== '') {
        filledFieldsCount++;
      }
    });
    // Return false if the count is less than 2
    if (filledFieldsCount < 2) {
      return false;
    }
    // Otherwise, return the usual error check
    return control?.invalid && control?.touched;
  }
  checkForRequiredError(controlName: string, index: number) {
    if (controlName == 'name') {
      const control = this.form.get(controlName)
      return control?.hasError('required')
    }
    else {
      const myForm = this.formArray.at(index) as FormGroup;
      const control = myForm.get(controlName)
      return control?.hasError('required')
    }
  }
   checkForRequiredEdgingError(controlName: string, index: number){
    const myForm = this.formArray.at(index) as FormGroup;
    const control = myForm.get(controlName)
    const w1 = myForm.get('w1Description');
    const w2 = myForm.get('w2Description');
    const l1 = myForm.get('l1Description');
    const l2 = myForm.get('l2Description');
    if (controlName==='length' && control?.value<60 && w1?.value && w2?.value){
      //control?.hasError('edging')
      
       l1?.setErrors({ edgingL: true });
       return true;
    }
    if (controlName==='width' && control?.value<60 && l1?.value && l2?.value){
      
       w1?.setErrors({ edgingW: true });
       return true;
    }
    if (controlName=='width' && control?.value>=60){
      w1?.setErrors(null);
      return false;
    }
    if (controlName=='width' && (l1?.value==null || l2?.value==null)){
      w1?.setErrors(null);
      return false;
    }
    if (controlName=='length' && control?.value>=60){
      l1?.setErrors(null);
      return false;
    }
    if (controlName=='length' && (w1?.value==null || w2?.value==null)){
      l1?.setErrors(null);
      return false;
    }
    
    return false;
  }
  checkForTypeAheadError(controlName: string, index: number) {
    const myForm = this.formArray.at(index) as FormGroup;
    const control = myForm.get(controlName)
    return control?.hasError('typeaheadInvalid')
  }
  checkForSError(controlName: string, index: number, error: string) {
    const myForm = this.formArray.at(index) as FormGroup;
    const control = myForm.get(controlName)
    return control?.hasError(error)
  }

  newValidateLW(index: number) :boolean{ 
    let smallestDifference = Infinity;
    let closestPair: [number, number] = [0, 0];
    for(let i =0; i<2;i++){   
    const myForm = this.formArray.at(index) as FormGroup;
    const codeControl = myForm.get('materialCode');
    let lengthControl = myForm.get('length');
    let widthControl = myForm.get('width');
    
    if(i==1 && !this.materialGrain[index]){
      lengthControl = myForm.get('width');
      widthControl = myForm.get('length');
    }
     //Bypass a Sample board called "Admin".
     if(codeControl!.value=="Admin"){
      return true;
    }
    const widthValue = widthControl?.value;
    const lengthValue = lengthControl?.value;
    // Initial Validation
    if (((lengthValue=="" || lengthValue <10 ) || (widthValue <60 && lengthValue <60) ) && lengthControl?.touched ) {
      lengthControl!.setErrors(null);
      widthControl!.setErrors(null);
      lengthControl?.setErrors({ minLength: true });
      this.length_errors[index] = `Minimum panel size must be at least 60mm X 10mm`;
      return false;
    }
    if (((widthValue=="" && lengthValue <10 || widthValue <10) || (widthValue <60 && lengthValue <60) ) && widthControl?.touched) {
      lengthControl!.setErrors(null);
      widthControl!.setErrors(null);
      widthControl?.setErrors({ minLength: true });
      this.width_errors[index] = `Minimum panel size must be at least 60mm X 10mm`;
      return false;
    }
    


    // Material Code Validation
    if (codeControl?.valid) {
      const trimsize = this.m_obj["materials"][codeControl.value]["trimsize"];
      const stockCodes = this.m_obj["materials"][codeControl.value]["stock_codes"];

      const allBoardLengthWidthPair = [];
      let maxBoardLength = 0;
      let maxBoardWidth = 0;
      let maxBoardArea=0;
      let maxLengthTrimmed =0;
      let maxWidthTrimmed=0;


      for (const stockCode of stockCodes) {
        const board = this.m_obj["boards"][stockCode];
        
        if (board) {
          allBoardLengthWidthPair.push([board.length, board.width]);
          const maxLength = board.length - (trimsize * 2);
          const maxWidth = board.width - (trimsize * 2);
          const boardArea = maxLength*maxWidth;

          if(maxBoardArea<=boardArea){
            maxBoardArea=boardArea;
          }

          if(maxLengthTrimmed<=maxLength){
            maxLengthTrimmed=maxLength
          }
          if(maxWidthTrimmed<=maxWidth){
            maxWidthTrimmed=maxWidth
          }
          
        }
      }
      for (const board of allBoardLengthWidthPair) {
        const [boardLength, boardWidth] = board;
          if (boardLength > maxBoardLength) {
            maxBoardLength = boardLength;
          }
        
          if (boardWidth > maxBoardWidth) {
            maxBoardWidth = boardWidth;
          }
        
      }
      let maxWidthForLargestLength = 0;
      allBoardLengthWidthPair.forEach(([boardLength, boardWidth]) => {
        if (boardLength === maxBoardLength && boardWidth > maxWidthForLargestLength) {
          maxWidthForLargestLength = boardWidth;
        }
      });

      const isLengthMatching = allBoardLengthWidthPair.some(([boardLength]) =>  String(boardLength) == lengthValue);
      const isWidthMatching = allBoardLengthWidthPair.some(([_,boardWidth]) =>  String(boardWidth) == widthValue);

      //Find the closes pair of the INPUT lengths for ERROR message

      const adjustedBoardLengthWidthPair = allBoardLengthWidthPair.map(([boardLength, boardWidth]) => {
        return [
          boardLength - (trimsize * 2),
          boardWidth - (trimsize * 2)
        ];
      });
      // 
      adjustedBoardLengthWidthPair.forEach(([boardLength, boardWidth]) => {
        const lengthDiff = Math.abs(boardLength - Number(lengthValue));
        const widthDiff = Math.abs(boardWidth - Number(widthValue));
        const totalDifference = lengthDiff + widthDiff;

      if (totalDifference < smallestDifference) {
        smallestDifference = totalDifference;
        closestPair = [boardLength, boardWidth];
        }
      });

        const [closestLength, closestWidth] = closestPair;  
      


      for (const stockCode of stockCodes) {
        const board = this.m_obj["boards"][stockCode];

        if (board) {
          const maxLength = board.length - (trimsize * 2);
          const maxWidth = board.width - (trimsize * 2);
          //Check For equal
          if( (lengthValue == board.length || widthValue == board.width) && allBoardLengthWidthPair.length>1){
            //give Length warning
            if(lengthValue == board.length){
              this.length_warnings[index] = `To acheive a finished size of ${lengthValue} a larger board will be used, to stay with a board size of ${lengthValue}, please reduce the panel size to ${ lengthValue - (trimsize * 2)} or less. `;
            }
            //give Width warning
            else if (widthValue == board.width){
              this.length_warnings[index] = `To acheive a finished size of ${widthValue} a larger board will be used, to stay with a board size of ${widthValue}, please reduce the panel size to ${ widthValue - (trimsize * 2)} or less. `;
            }
            continue; //Fetches a new board as this one is getting oversized
          }

          //Check for Success
          if(lengthValue<=maxLength && widthValue<=maxWidth){
            //success
            if(!isLengthMatching && !isWidthMatching){
              this.length_warnings[index]=''
            }
            lengthControl!.setErrors(null);
            widthControl!.setErrors(null);
            return true
            }
          //Check For error
            else{
             //error
             this.length_warnings[index]=''
             widthControl!.setErrors({ maxWidth: true });
             lengthControl!.setErrors({ maxLength: true });
              this.length_errors[index] = `Maximum panel size can be ${closestLength} X ${closestWidth}`;
              this.width_errors[index] = `Maximum panel size can be ${closestLength} X ${closestWidth}`;
            }
          }
        }
      }
    }
    return false
  }
  checkWarning(index:number){
    
    if(this.length_warnings[index]!=undefined && this.length_warnings[index]!=""){
      
      return true;
    }
    return false;
  }
  // validateLength(index: number) {
  //   const myForm = this.formArray.at(index) as FormGroup;
  //   const codeControl = myForm.get('materialCode');
  //   const lengthControl = myForm.get('length')
  //   const widthControl = myForm.get('width')
  //   const widthValue = widthControl?.value
  //   const lengthValue = lengthControl?.value
  //   let maxLength = 0
  //   let maxWidth = 0
  //   if ((widthControl?.valid && (widthValue && ((widthValue >= 60 && lengthValue < 10) || (widthValue < 60 && lengthValue < 60))))) {
  //     lengthControl?.setErrors({ minLength: true })
  //     return;
  //   }
  //   if (codeControl?.valid) {

  //     let trimsize = this.m_obj["materials"][codeControl.value]["trimsize"];
  //     this.m_obj["materials"][codeControl.value]["stock_codes"].forEach((element: string) => {
  //       if (this.m_obj["boards"][element]){
  //         if(lengthValue>this.m_obj["boards"][element].length)
  //       maxLength = maxLength < this.m_obj["boards"][element].length - (trimsize * 2) ? this.m_obj["boards"][element]["length"] - (trimsize * 2) : maxLength;
  //       }
  //     });
  //     this.m_obj["materials"][codeControl.value]["stock_codes"].forEach((element: string) => {
  //       if (this.m_obj["boards"][element]){
  //       maxWidth = maxWidth < this.m_obj["boards"][element].width - (trimsize * 2) ? this.m_obj["boards"][element]["width"] - (trimsize * 2) : maxWidth;
  //       }
  //     });
  //     if (!this.materialGrain[index] && lengthValue && (lengthValue > maxLength)) {
  //       lengthControl.setErrors({ maxLength: true })
  //       this.length_errors[index] = ` maximum panel size can be ${maxLength} X ${maxWidth}`
  //       return;
  //     }
  //     //LEngth Check For grain
  //     if(this.materialGrain[index] && lengthValue && ((lengthValue > maxWidth) || (widthValue >maxLength)) ){
  //       lengthControl.setErrors({ maxLength: true })
  //       this.length_errors[index] = ` maximum panel size can be ${maxLength} X ${maxWidth}`
  //       return;
  //     }
  //   }
  //   const currentErrorsL = lengthControl?.errors as any;
  //   if (currentErrorsL && currentErrorsL["minWidth"]) {
  //     currentErrorsL["minWidth"] = false
  //   }
  //   if (currentErrorsL && currentErrorsL["maxWidth"]) {
  //     currentErrorsL["maxWidth"] = false
  //   }
  //   lengthControl?.setErrors(currentErrorsL);
  //   if (widthControl?.valid && lengthControl?.valid && (lengthValue && ((lengthValue >= 60 && widthValue < 10) || (lengthValue < 60 && widthValue < 60)))) {
  //     widthControl.setErrors({ minWidth: true })
  //     return;
  //   }
  //   if (codeControl?.valid && widthControl?.valid) {

  //     let trimsize = this.m_obj["materials"][codeControl.value]["trimsize"];
  //     this.m_obj["materials"][codeControl.value]["stock_codes"].forEach((element: string) => {
  //       if (this.m_obj["boards"][element]){
  //       maxWidth = maxWidth < this.m_obj["boards"][element].width - (trimsize * 2) ? this.m_obj["boards"][element]["width"] - (trimsize * 2) : maxWidth;
  //       }
  //     });
  //     this.m_obj["materials"][codeControl.value]["stock_codes"].forEach((element: string) => {
  //       if (this.m_obj["boards"][element]){
  //       maxLength = maxLength < this.m_obj["boards"][element].length - (trimsize * 2) ? this.m_obj["boards"][element]["length"] - (trimsize * 2) : maxLength;
  //       }
  //     });
  //     if (widthValue && (widthValue > maxWidth)) {
  //       widthControl.setErrors({ maxWidth: true })
  //       this.width_errors[index] = ` maximum panel size can be ${maxLength} X ${maxWidth}`
  //       return;
  //     }
  //   }
  //   const currentErrors = widthControl?.errors as any;
  //   if (currentErrors && currentErrors["minWidth"]) {
  //     currentErrors["minWidth"] = false
  //   }
  //   if (currentErrors && currentErrors["maxWidth"]) {
  //     currentErrors["maxWidth"] = false
  //   }


  //   widthControl?.setErrors(currentErrors);
  // }
  // validateWidth(index: number) {
  //   const myForm = this.formArray.at(index) as FormGroup;
  //   const codeControl = myForm.get('materialCode');
  //   const widthControl = myForm.get('width')
  //   const lengthControl = myForm.get('length')
  //   const lengthValue = lengthControl?.value
  //   const widthValue = widthControl?.value
  //   let maxWidth = 0
  //   let maxLength = 0
  //   if ((lengthValue && ((lengthValue >= 60 && widthValue < 10) || (lengthValue < 60 && widthValue < 60)))) {
  //     widthControl?.setErrors({ minWidth: true })
  //     return;
  //   }
  //   if (codeControl?.valid && widthValue) {

  //     let trimsize = this.m_obj["materials"][codeControl.value]["trimsize"];
  //     this.m_obj["materials"][codeControl.value]["stock_codes"].forEach((element: string) => {
  //       if (this.m_obj["boards"][element]){
  //       maxWidth = maxWidth < this.m_obj["boards"][element].width - (trimsize * 2) ? this.m_obj["boards"][element]["width"] - (trimsize * 2) : maxWidth;
  //       }
  //     });
  //     this.m_obj["materials"][codeControl.value]["stock_codes"].forEach((element: string) => {
  //       if (this.m_obj["boards"][element]){
  //       maxLength = maxLength < this.m_obj["boards"][element].length - (trimsize * 2) ? this.m_obj["boards"][element]["length"] - (trimsize * 2) : maxLength;
  //       }
  //     });
  //     if (widthValue && (widthValue > maxWidth)) {
  //       widthControl.setErrors({ maxWidth: true })
  //       this.width_errors[index] = ` maximum panel size can be ${maxLength} X ${maxWidth}`
  //       return;
  //     }
  //   }
  //   const currentErrors = widthControl?.errors as any;
  //   if (currentErrors && currentErrors["minWidth"]) {
  //     currentErrors["minWidth"] = false
  //   }
  //   if (currentErrors && currentErrors["maxWidth"]) {
  //     currentErrors["maxWidth"] = false
  //   }
  //   widthControl?.setErrors(currentErrors);
  //   if ((widthValue && ((widthValue >= 60 && lengthValue < 10) || (widthValue < 60 && lengthValue < 60)))) {
  //     lengthControl?.setErrors({ minLength: true })
  //     return;
  //   }
  //   if (codeControl?.valid && lengthValue) {

  //     let trimsize = this.m_obj["materials"][codeControl.value]["trimsize"];
  //     this.m_obj["materials"][codeControl.value]["stock_codes"].forEach((element: string) => {
  //       if (this.m_obj["boards"][element]){
  //       maxLength = maxLength < this.m_obj["boards"][element].length - (trimsize * 2) ? this.m_obj["boards"][element]["length"] - (trimsize * 2) : maxLength;
  //       }
  //     });
  //     this.m_obj["materials"][codeControl.value]["stock_codes"].forEach((element: string) => {
  //       if (this.m_obj["boards"][element]){
  //       maxWidth = maxWidth < this.m_obj["boards"][element].width - (trimsize * 2) ? this.m_obj["boards"][element]["width"] - (trimsize * 2) : maxWidth;
  //       }
  //     });
  //     if (lengthValue && (lengthValue > maxLength)) {
  //       lengthControl.setErrors({ maxLength: true })
  //       this.length_errors[index] = ` maximum panel size can be ${maxLength} X ${maxWidth}`
  //       return;
  //     }
  //   }
  //   const currentErrorsL = lengthControl?.errors as any;
  //   if (currentErrorsL && currentErrorsL["minWidth"]) {
  //     currentErrorsL["minWidth"] = false
  //   }
  //   if (currentErrorsL && currentErrorsL["maxWidth"]) {
  //     currentErrorsL["maxWidth"] = false
  //   }
  //   lengthControl?.setErrors(currentErrorsL);
  // }
  calculateWeight(index: number) {
    const myForm = this.formArray.at(index) as FormGroup;
    if (myForm.valid) {
      const material = myForm?.value?.materialCode
      const length = myForm?.value?.length
      const width = myForm?.value?.width
      const quantity = myForm?.value?.quantity
      const thickness = this.m_obj["materials"][material]["thickness"]
      const cost = this.m_obj["materials"][material]["weight"]
      let weight= (((length * thickness * width) / Math.pow(10, 6)) * cost * quantity).toFixed(2)
      this.formArray.at(index).get('weight')?.setValue(weight);
      return weight;
    }
    return 0;
  }
  calculateWeightMagicList(index: number, formArray:FormArray) {
    const myForm = formArray.at(index) as FormGroup;
    if (myForm.valid) {
      const material = myForm?.value?.materialCode
      const length = myForm?.value?.length
      const width = myForm?.value?.width
      const quantity = myForm?.value?.quantity
      const thickness = this.m_obj["materials"][material]["thickness"]
      const cost = this.m_obj["materials"][material]["weight"]
      return (((length * thickness * width) / Math.pow(10, 6)) * cost * quantity).toFixed(2)
    }
    return 0;
  }
  c_priming: boolean[] = []
  checkPriming(index: number) {
    const myForm = this.formArray.at(index) as FormGroup;
    if (myForm.get('materialCode')?.valid) {
      return this.m_obj["materials"][myForm.value.materialCode]["canbeprimed"];
    }
    return false;
  }
  c_quoteId = ""
  calInProg = false;
  matCalInProg = false;
  valChangesInit() {
    // this.form.valueChanges.subscribe(data => {
    //   this.updateView();
    //   let quote = this.form.value;
    //   // if(!this.isLoggedIn){
    //   let reqBody = {
    //     "parts": quote.formArray,
    //     "name": quote.name,
    //     "shakers": quote.shakers,
    //     "quoteId": this.c_quoteId || this.quote?.quoteId
    //   }
    //   let formValue = this.form.value
    //   // quote.parts = formValue
    //   if (this.quoteId == null || this.quoteId == "") {
    //     this.apiService.putCachedCutWrightData(reqBody).subscribe((data: any) => {
    //       this.c_quoteId = data.quoteId
    //       if (!this.cookieService.check('d_quote') || this.cookieService.get('d_quote') == "") {
    //         this.cookieService.set('d_quote', data.quoteId, 365);
    //       }
    //     })
    //   }
    //   // }
    // });
    this.form.valueChanges.pipe(
      throttleTime(1000) 
    ).subscribe(data => {
      this.updateView();
      let quote = this.form.value;
      let reqBody = {
        "parts": quote.formArray,
        "name": quote.name,
        "shakers": quote.shakers,
        "quoteId": this.c_quoteId || this.quote?.quoteId
      }
    
      // Check if quoteId is not null or empty before making the API call
      if (this.quoteId == null || this.quoteId == "") {
        this.apiService.putCachedCutWrightData(reqBody).subscribe((data: any) => {
          this.c_quoteId = data.quoteId;
          if (!this.cookieService.check('d_quote') || this.cookieService.get('d_quote') == "") {
            this.cookieService.set('d_quote', data.quoteId, 365);
          }
        });
      }
    });
    this.materialSubs.forEach(sub => {
      sub.unsubscribe();
    })
    this.formSubs.forEach(sub => {
      sub.unsubscribe();
    })
    this.formArray.controls.forEach((elem, index) => {
      var subs = elem.valueChanges.subscribe(data => {
        if (this.calInProg == false) {
          this.calInProg = true;
          let val_elem = elem.get('materialCode')
          if (elem.get('materialCode')?.valid && this.dataSource.some((datam: any) => elem.get('materialCode')?.value == datam.value)) {
            if (this.checkPriming(index)) {
              elem.get('primed')?.enable()
            } else {
              elem.get('primed')?.setValue(false);
              elem.get('primed')?.disable()
            }
            this.getECodes(index);
            elem.get('weight')?.setValue(this.calculateWeight(index))
          } else {
            elem.get('weight')?.setValue("");
          }
          this.calInProg = false;
        }
      })
      this.formSubs.push(subs);
      const oldMaterialCode=elem.get('materialCode')?.value;
      var msubs = elem.get('materialCode')?.valueChanges.subscribe(data => {
        if (data !==oldMaterialCode){
        // elem.get('l1Description')?.setValue(null);
        // elem.get('l2Description')?.setValue(null);
        // elem.get('w1Description')?.setValue(null);
        // elem.get('w2Description')?.setValue(null);
        }
      })
      this.materialSubs.push(msubs as Subscription);
    })
  };
  getShakers(): FormArray {
    return this.form.get('shakers') as FormArray
  }
  getPartsC(): number {
    var count = 0;
    this.formArray.value.forEach((elem: Part) => {
      count += Number(elem.quantity) * 1
    });
    return count;
  }
  getShakerDoorsC() {
    let count = 0
    this.form.value.shakers?.forEach((sha: Shaker) => {
      if (sha.type == eShakerType.ShakerDoor) {
        count += sha.quantity;
      }
    })
    return count;
  }
  getShakerDrawerC() {
    let count = 0
    this.form.value.shakers?.forEach((sha: Shaker) => {
      if (sha.type == eShakerType.ShakerDrawer || sha.type == eShakerType.PlainDrawer) {
        count += sha.quantity;
      }
    })
    return count;
  }
  getWeight() {
    var weight = 0;
    this.formArray.value.forEach((elem: Part) => {
      weight += Number(elem.weight);
    });
    return weight.toFixed(2);
  }
  valueFormatter = (value: any) => value.value;
  midrailOverlapValidator(heightControl: AbstractControl): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const enteredValue = parseFloat(control.value);
      const height = parseFloat(heightControl.value);

      if (isNaN(enteredValue) || isNaN(height)) {
        // Invalid input values, no validation error
        return null;
      }

      const minHeightDifference = height - enteredValue;

      if (minHeightDifference < 180) {
        return { midrailOverlap: true };
      }

      return null;
    };
  }
  onSelect(event: TypeaheadMatch, index: number): void {
    this.formArray.at(index).get('materialCode')?.setValue(event.item.value);
    this.test = event.item.value
    this.typeaheadEdgesValidator('materialCode', index);
    this.typeaheadEdgesValidator('l1', index);
    this.typeaheadEdgesValidator('l2', index);
    this.typeaheadEdgesValidator('w1', index);
    this.typeaheadEdgesValidator('w2', index);
    // this.onEnterPressed(event,index,'materialCode')
  }
  onSelectE(event: TypeaheadMatch, index: number, name: string) {
    this.formArray.at(index).get(name)?.setValue(event.item.code);
  }
  onEnterPressed(event: any, i: number, control: string) {
    this.selectRowIndex(i);
    let group_c = this.formArray.at(i)
    if (i > 0 && !group_c.get(control)?.dirty && !group_c.get('materialCode')?.dirty ) {
      let group_p = this.formArray.at(i - 1)
      if (group_c.get('materialCode')?.valid && control == "length" || control == 'width' || control == "quantity") {
        group_c.get(control)?.setValue(group_p.get(control)?.value)
      }
      else if (control == 'materialCode' || control == 'materialDescription' || (group_p.valid)) {
        if (control == "materialDescription") {
          group_c.get("materialCode")?.setValue(group_p.get("materialCode")?.value)
        }
        if(!(control=="l1Description" || control=="l2Description" || control=="w1Description" || control=="w2Description" || control=="l1" || control=="l2" || control=="w1" || control=="w2") || group_c.get(control)?.value===null || group_c.get(control)?.value==="" ){
        group_c.get(control)?.setValue(group_p.get(control)?.value)
        
        }
      }
    }
    // if(!group_c.get(control)?.dirty && control==='l1Description'){
    //   return
    // }


    let p_flag = group_c.get('priming')?.enabled as boolean;
    let skipWeightAndDeleteButton=false;
    if(control=='partComment' || 'comments'){
      
    }
    if (control==='parts'){
      event.preventDefault();
      return
    }
    if(control=='partComment'){
      skipWeightAndDeleteButton = true;
    }
    // if(control == 'materialCode' || control == 'length' || control == 'width'){
    this.onEnterPressedNext(event, (!(p_flag) && control == 'w2Description'), skipWeightAndDeleteButton);
    // }
  }
  onEnterPressedNext(event: KeyboardEvent, skipPriming: boolean, skipWeightAndDeleteButton:boolean): void {
    
    // Prevent the default Enter behavior (submitting the form)
    event.preventDefault();

    // Find the next focusable element
    const currentInput = event.target as HTMLElement;
    const focusableElements = this.el.nativeElement.querySelectorAll(
      'input, select, textarea,[tabindex]:not([tabindex="-1"])'
    );
    const currentIndex = Array.from(focusableElements).indexOf(currentInput);
    let nextIndex = currentIndex + 1;
    if (skipPriming || skipWeightAndDeleteButton) {
      nextIndex += 1;
    }
    // if(skipWeightAndDeleteButton){
    //   nextIndex+=2;
    // }
    
    if (nextIndex < focusableElements.length ) {
      const nextInput = focusableElements[nextIndex] as HTMLElement;
      const nextInputName = nextInput.getAttribute('formControlName');
      if (nextInputName!=='comments'){
      nextInput.focus();
      }
    }
  }
  noCommaValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value && control.value.includes(',')) {
      return { commaNotAllowed: true };
    }
    return null;
  }
  async readExcelFile(event: any) {
    if (this.rowCount < 500) {
      this.isLoading = true;
      const formData = new FormData();
      formData.append('file', event.target.files[0]);
  
      this._http.post('https://cutwright-materials.azurewebsites.net/upload/magicList', formData).subscribe(
        (response) => {
          let parts = response as [];
          let newEntries:any = [];
          let warningFlags = Array(parts?.length).fill([]).map(() => Array(4).fill(false));
  
          parts.forEach((part: any, index) => {
            this.rowCount++;
            if (this.rowCount >= 500) {
              if (this.rowCount === 500) {
                this.openModal(this.overflowPopup, "Attention!", "Please note that our current processing capacity is limited to 500 rows. We apologize for any inconvenience this may cause.");
              }
              return;
            }
            let cleanString = (str: string) => {
              return str.replace(/^[^a-zA-Z0-9]+|[^a-zA-Z0-9]+$/g, '').trim();
            };

            // Find m_code with cleaned strings
            let m_code = Object.keys(this.m_obj["materials"]).find(data => {
            let cleanedDescription = cleanString(this.m_obj["materials"][data]["description"]);
            let cleanedMaterial = cleanString(part.Material);
            return cleanedDescription === cleanedMaterial;
            });

            if (!m_code){
              this.openModal(this.overflowPopup,"Error",`Unfortunately, there is an error at Row number: ${index} under the material section of your Magic List. Please look for extra spaces or typos.`)
              return
            }
            

            let grainValue = this.m_obj["materials"][m_code]["grain"];
            grainValue= (grainValue === "Y") ? true : (grainValue === "N") ? false : null;
            
          
            let l1Code = part.L1 ? Object.keys(this.m_obj["edges"]).find(data => {
              let cleanedDescription = cleanString(this.m_obj["edges"][data]["description"]);
              let cleanedPartL1 = cleanString(part.L1);
              
              let result = cleanedDescription == cleanedPartL1;
              
              if (result) {
                  if (!this.e_codes[index]) this.e_codes[index] = [];
                  this.e_codes[index].push(this.m_obj["edges"][data]);
              }
              
              
              return result;
            }) : null;

            if(l1Code==null){
              part.L1=null;
            }
            
            let l2Code = part.L2 ? Object.keys(this.m_obj["edges"]).find(data => {
              let cleanedDescription = cleanString(this.m_obj["edges"][data]["description"]);
              let cleanedPartL2 = cleanString(part.L2);
              let result = cleanedDescription == cleanedPartL2;
              
              if (result) {
                  if (!this.e_codes[index]) this.e_codes[index] = [];
                  this.e_codes[index].push(this.m_obj["edges"][data]);
              }
              return result;
            }) : null;

            if(l2Code==null){
              part.L2=null;
            }
            let w1Code = part.W1 ? Object.keys(this.m_obj["edges"]).find(data => {
              let cleanedDescription = cleanString(this.m_obj["edges"][data]["description"]);
              let cleanedPartW1 = cleanString(part.W1);
              
              let result = cleanedDescription == cleanedPartW1;
              
              if (result) {
                  if (!this.e_codes[index]) this.e_codes[index] = [];
                  this.e_codes[index].push(this.m_obj["edges"][data]);
              }
              
              return result;
            }) : null;
            if(w1Code==null){
              part.W1=null;
            }
          
            let w2Code = part.W2 ? Object.keys(this.m_obj["edges"]).find(data => {
              let cleanedDescription = cleanString(this.m_obj["edges"][data]["description"]);
              let cleanedPartW2 = cleanString(part.W2);
              
              let result = cleanedDescription == cleanedPartW2;
              
              if (result) {
                  if (!this.e_codes[index]) this.e_codes[index] = [];
                  this.e_codes[index].push(this.m_obj["edges"][data]);
              }
              return result;
            }) : null;

            if(w2Code==null){
              part.W2=null;
            }
            // Clean strings before pushing into form
            let cleanedMaterialCode = cleanString(m_code);
            let cleanedMaterialDescription = cleanString(part.Material);
            newEntries.push(this.formBuilder.group({
              
              materialCode: [cleanedMaterialCode, Validators.required],
              materialDescription: [cleanedMaterialDescription, Validators.required],
              width: [part.Width, [Validators.required, Validators.min(10)]],
              length: [part.Length, [Validators.required, Validators.min(10)]],
              quantity: [part.Qty, [Validators.required, Validators.min(1), this.validateWholeNumber]],
              l1: [l1Code],
              l1Description: [part.L1],
              l2: [l2Code],
              l2Description: [part.L2],
              w1: [w1Code],
              w1Description: [part.W1],
              w2: [w2Code],
              w2Description: [part.W2],
              partComment: [part.Name, [this.noCommaValidator, Validators.maxLength(35)]],
              primed: [part.primed == null ? false : part.primed],
              weight: [0],
              grain: [grainValue]
            }));
            newEntries.at(index).get('weight').setValue(this.calculateWeightMagicList(index,newEntries));
          });
  
          // Clean invalid entries
          for (let i = this.formArray.length - 1; i >= 0; i--) {
            let fe = this.formArray.at(i);
            if (fe.untouched || fe.invalid) {
              this.formArray.removeAt(i);
            }
          }
  
          // Add new entries
          newEntries.forEach((entry:any) => {
            this.formArray.push(entry);       
            
          });
          for (let i = 0;i<this.formArray.length;i++){
            this.getGrain(i);
            this.formArray?.at(i)?.get('length')?.markAsTouched();
            this.formArray?.at(i)?.get('width')?.markAsTouched();
            this.formArray?.at(i)?.get('l1')?.markAsTouched();
            this.formArray?.at(i)?.get('w1')?.markAsTouched();
            this.formArray?.at(i)?.get('l2')?.markAsTouched();
            this.formArray?.at(i)?.get('w2')?.markAsTouched();
          
            // if(this.e_codes[i] == null){
            //   this.formArray?.at(i)?.get('l1Description')?.setValue(null)
            // }
            this.newValidateLW(i)
          }
          this.warningFlags = this.warningFlags.concat(warningFlags);

        
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
    }
  }
  getTemplate() {
    this.apiService.getMagucListTemplate().subscribe(data => {
      this.mTemplateUrl = data
    })
  }
  updateQuoteValidity() {
    let cn = this.formArray.length;
    for (let i = cn - 1; i >= 0; i--) {
      let fe = this.formArray.at(i);
      if (this.isRowEmpty(i)) {
        this.formArray.removeAt(i);
      }
    }
  }
  openModal(template: TemplateRef<any>, header:string, message:string): void {
    this.modalHeader=header;
    this.modalMessage = message;
    this.bsModalRef = this.modalService.show(template, { class: 'modal-dialog-centered' });
  }
  refreshPage(){
    window.location.reload()
  }
  addRowAbove(){
      this.formArray.insert(this.selectedRowIndex,this.formBuilder.group({
        materialCode: ['', Validators.required],
        materialDescription: ['', Validators.required],
        width: ['', [Validators.required, Validators.min(10)]],
        length: ['', [Validators.required, Validators.min(10)]],
        quantity: ['', [Validators.required, Validators.min(1), this.validateWholeNumber]],
        l1: [''],
        l1Description: [''],
        l2: [''],
        l2Description: [''],
        w1: [''],
        w1Description: [''],
        w2: [''],
        w2Description: [''],
        partComment: ['', [this.noCommaValidator, Validators.maxLength(35)]],
        primed: [false],
        weight: [0]
      }));
      
      const newRow = new Array<boolean>(this.warningFlags[0].length).fill(false);

      this.warningFlags.splice(this.selectedRowIndex, 0, newRow);
      this.valChangesInit();
    
  }
  selectRowIndex(index:number){
    this.selectedRowIndex=index;
  }
  setFormFocus(isFocused: boolean) {
    setTimeout(() => {
    this.isFormFocused = isFocused;
    },300);
  }
  validateWholeNumber(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    const maxQuantity=9999;
    if (value === null || value === '') {
      return null; 
    }
    const isWholeNumber = /^\d+$/.test(value);

    if (!isWholeNumber) {
      control.setValue(null); 
      return { invalidNumber: true };
    }
    if (value>maxQuantity){
      control.setValue(maxQuantity);
      
    }
    return null;
  }
  isRowEmpty(index: number): boolean {
    const formGroup = this.formArray.at(index) as FormGroup;
    let isEmpty = true;
    Object.values(formGroup.controls).forEach(control => {
      if (!!control.value) {
        isEmpty = false;
      }
    });
  
    return isEmpty;
  }

listenToQuoteResult(){
    const userString = localStorage.getItem('user');
    this.user = JSON.parse(userString as string)
}

  checkFor150(index:number){
    // Function for edging WARNING if L ow W less that 150mm
    
    const myForm = this.formArray.at(index) as FormGroup;
    const length = myForm.get('length')?.value;
    const width = myForm.get('width')?.value;
    const l1 = myForm.get('l1')?.value;
    const l2 = myForm.get('l2')?.value;
    const w1 = myForm.get('w1')?.value;
    const w2 = myForm.get('w2')?.value;
    
    if((length<150 && (l1||l2)) && (width<150 && (w1||w2))){
      return 'both'
    }
    if(length<150 && (l1||l2)){
      return 'length'
    }

    if(width<150 && (w1||w2)){
      return 'width'
    }
    else{
      return ''
    }
  }

  checkFor60(index:number){
    // Function for edging WARNING if L ow W less that 60mm

    const myForm = this.formArray.at(index) as FormGroup;
    const length = myForm.get('length')?.value;
    const width = myForm.get('width')?.value;
    const l1 = myForm.get('l1')?.value;
    const l2 = myForm.get('l2')?.value;
    const w1 = myForm.get('w1')?.value;
    const w2 = myForm.get('w2')?.value;

    if (length<60 && (w1 || w2)){
      return 'lesslength'
    }

    if(width<60 && (l1 || l2)){
      return 'lesswidth'
    }
    else{
      return ''
    }
    
  }

  checkForMaxWidthEdging(index: number): string {
    const myForm = this.formArray.at(index) as FormGroup;

    const { materialCode, width, l1, l2 } = myForm.controls
    const widthValue = width?.value;


    let error_msg = "Please reduce panel size by 2.0mm to accommodate edge banding."

    if (materialCode?.valid) {
      const trimsize = this.m_obj["materials"][materialCode.value]["trimsize"];
      const stockCodes = this.m_obj["materials"][materialCode.value]["stock_codes"];
      let maxWidthTrimmed = 0;

      //Check if the L and W are max CUT size
      for (const stockCode of stockCodes) {
        const board = this.m_obj["boards"][stockCode];

        if (board) {
          const maxWidth = board.width - (trimsize * 2);

          if (maxWidthTrimmed <= maxWidth) {
            maxWidthTrimmed = maxWidth
          }
        }
      }

      // Actual Check
      if ((widthValue == maxWidthTrimmed || widthValue == (maxWidthTrimmed - 1)) && (l1?.value || l2?.value)) {
        l1?.setErrors({ maxLength: true })
        l2?.setErrors({ maxLength: true })

        if (widthValue == (maxWidthTrimmed - 1)) {
          error_msg = 'Please reduce panel size by 1.0mm to accommodate edge banding.'
        }
        return error_msg;
      }

    }
    l1?.setErrors(null)
    l2?.setErrors(null)
    error_msg = '';

    return '';

  }

  checkForMaxLengthEdging(index: number): string {
    const myForm = this.formArray.at(index) as FormGroup;

    const { materialCode, length, w1, w2 } = myForm.controls
    const lengthValue = length?.value;


    let error_msg = "Please reduce panel size by 2.0mm to accommodate edge banding."

    if (materialCode?.valid) {
      const trimsize = this.m_obj["materials"][materialCode.value]["trimsize"];
      const stockCodes = this.m_obj["materials"][materialCode.value]["stock_codes"];
      let maxLengthTrimmed = 0;
      let maxWidthTrimmed = 0;

      //Check if the L and W are max CUT size
      for (const stockCode of stockCodes) {
        const board = this.m_obj["boards"][stockCode];

        if (board) {
          const maxLength = board.length - (trimsize * 2);

          if (maxLengthTrimmed <= maxLength) {
            maxLengthTrimmed = maxLength
          }
        }
      }

      // Actual Check
      if ((lengthValue == maxLengthTrimmed || lengthValue == (maxLengthTrimmed - 1)) && (w1?.value || w2?.value)) {
        w1?.setErrors({ maxLength: true })
        w2?.setErrors({ maxLength: true })

        if (lengthValue == (maxLengthTrimmed - 1)) {
          error_msg = 'Please reduce panel size by 1.0mm to accommodate edge banding.'
        }
        return error_msg;
      }

    }
    w1?.setErrors(null)
    w2?.setErrors(null)
    error_msg = '';

    return '';
  }

  checkQuantity(controlName: string, index: number){
    const myForm = this.formArray.at(index) as FormGroup;
    const quantity = myForm.get(controlName)?.value;
    if(quantity>=100){
      return true;
    }
    else return false;

  } 

  checkMinimumEdgeDimensions(index : number){
    const myForm = this.formArray.at(index) as FormGroup;

    const { length, width, l1, l2, w1, w2 } = myForm.controls
    const presentCount = [l1, l2, w1, w2].filter(control =>  control.value !== "" && control.value !== null).length;
    const controlsWithValue = { l1: !!l1.value, l2: !!l2.value, w1: !!w1.value, w2: !!w2.value };

    if((length.value <150 && width.value<150) && (presentCount>=3)){
      if(controlsWithValue.l1){
        l1.setErrors({three:true})
      } else {
        l1.setErrors(null);
      }
     
      if(controlsWithValue.l2){
        l2.setErrors({three:true})
      }else {
        l2.setErrors(null);
      }
      if(controlsWithValue.w1){
        w1.setErrors({three:true})
      } else {
        w1.setErrors(null);
      }

      if(controlsWithValue.w2){
        w2.setErrors({three:true})
      } else {
        w2.setErrors(null);
      }
      return true
    }
    else{
      l1.setErrors(null);
      l2.setErrors(null);
      w1.setErrors(null);
      w2.setErrors(null);
      return false;
    }

  }

  // formHasEmpty(): boolean {
  //   const quote = this.form.value;
    
  //   const emptyIndex = quote.formArray.findIndex((arr: any) => {
  //     // Check for materialCode and width conditions
  //     if (!arr.materialCode) 
  //       {
  //         arr.materialDescription.setErrors({ required: true })
          
  //       }
  //     if(arr.materialCode.length === 0)
  //       {
  //       arr.materialDescription.setErrors({ required: true })
        
  //       }
  //     if(!arr.width)
  //       {
  //       arr.width.setErrors({ required: true })
  //       }
  //   });
  //   debugger
  //   if (emptyIndex !==-1){

  //     return true
  //   }
  //   return false
    
  // }

  
}


