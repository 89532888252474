import { Component } from '@angular/core';

@Component({
  selector: 'app-blog-rebranding',
  templateUrl: './blog-rebranding.component.html',
  styleUrls: ['./blog-rebranding.component.scss']
})
export class BlogRebrandingComponent {

}
