<body class="myBody" cz-shortcut-listen="true">
    <ng-template #loading>
        <div class="loader-section">
            <app-loader></app-loader>
        </div>
    </ng-template>
    <form  id="mainForm">
        <!-- <div class="buttons">
            <input type="submit" name="Root$btnPrint" value="Print" id="btnPrint" class="controlPrint">
        </div> -->

        <div>
            <div>
                <div *ngIf="user" id="Main_ctl00_upList" isupdatepanel="true">
                    <div class="list">
                        <span class="list-item-wrapper">
                            <div class="item">
                                <div class="value">
                                    <div class="view-invoice">
                                        <span class="view-body">
                                            <span>
                                                <div class="item">
                                                    <div class="value">
                                                        <span>
                                                            <div class="item">
                                                                <div class="value">
                                                                    <div class="invoice-header-row-block">
                                                                        <div class="invoice-logo invoice-header-row">
                                                                            <img id="Main_ctl00_gridList_ctl00_0_ctl05_0_ctl00_0_btnLogo_0"
                                                                                class="logo invoice-logo"
                                                                                src="../../../assets/images/Main.Logo.png"
                                                                                alt="Logo">
                                                                        </div>
                                                                        <div
                                                                            class="cutwrights-company-details invoice-header-row-dateail">
                                                                            <div
                                                                                class="cutwrights-address details-block" style="margin-left: -67px ;font-size: small;">
                                                                                Unit 6 Links Industrial Estate,
                                                                                <br>
                                                                                Popham Close, Hanworth,
                                                                                <br>
                                                                                Middlesex, TW13 6JE
                                                                                <br>
                                                                            </div>
                                                                            <div
                                                                                class="cutwrights-contact-details details-block">
                                                                                <table class="details-table" style="font-size: small;">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td
                                                                                                class="contact-details-labels">
                                                                                                T</td>
                                                                                            <td>0208 755 4829</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td
                                                                                                class="contact-details-labels">
                                                                                                F</td>
                                                                                            <td>020 8755 4835</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td
                                                                                                class="contact-details-labels">
                                                                                                E</td>
                                                                                            <td>{{"Info@cutwrights.com"}}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td
                                                                                                class="contact-details-labels">
                                                                                                W</td>
                                                                                            <td>https://www.cutwrights.com
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                                <div class="company-vat-info">
                                                                                    <p>
                                                                                        Company No. 07010445
                                                                                        <br>
                                                                                        VAT No. 802164665
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="float-fix" style="height: 40px">
                                                                    </div>
                                                                    <span class="view-body">

                                                                        <span  class="customer-address">
                                                                            <h3 id="Main_ctl00_gridList_ctl00_0_ctl05_0_ctl00_0_groupDeliveryAddress_0"
                                                                                class="box-header">{{isQuote ? "Quote": "Invoice"}}
                                                                            </h3>
                                                                            <div class="item">
                                                                                <div class="value details" >
                                                                                    {{quote.customerCompanyName ? quote.customerCompanyName : quote.customerName}}
                                                                                </div>
                                                                                <div class="value details">
                                                                                    {{user.address[0].address}}
                                                                                </div>
                                                                            </div>
                                                                            <!-- <div class="item">
                                                                                <div class="value">
                                                                                    36 rosebury road
                                                                                </div>
                                                                            </div> -->
                                                                            <div class="item">
                                                                                <div class="value details">
                                                                                    {{user.address[0].postcode}}
                                                                                </div>
                                                                            </div>
                                                                        </span>


                                                                        <span  class="invoice-details">
                                                                            <span>
                                                                                <div *ngIf="!isQuote && orderFetched"  class="item">
                                                                                    <div  class="label padfix">
                                                                                        Invoice No:
                                                                                    </div>
                                                                                    <div class="value fontfix">
                                                                                        {{order.invoiceReference}}
                                                                                    </div>
                                                                                </div>
                                                                                <div *ngIf="isQuote"  class="item">
                                                                                    <div  class="label padfix">
                                                                                        Quote No:
                                                                                    </div>
                                                                                    <div class="value fontfix">
                                                                                        {{quote.jobId}}
                                                                                    </div>
                                                                                </div>
                                                                                <div *ngIf="isQuote"  class="item">
                                                                                    <div  class="label padfix">
                                                                                        Created
                                                                                    </div>
                                                                                    <div class="value fontfix">
                                                                                        {{quote.Id?.CreationTime | date: 'dd/MM/yy'}}
                                                                                    </div>
                                                                                </div>
                                                                                <div class="item">
                                                                                    <div class="label padfix">
                                                                                        Date/tax point
                                                                                    </div>
                                                                                    <div class="value fontfix">
                                                                                        {{quote.Id?.CreationTime | date: 'dd/MM/yy'}}
                                                                                    </div>
                                                                                </div>
                                                                                <div *ngIf="!isQuote && orderFetched" class="item">
                                                                                    <div class="label padfix">
                                                                                        Purchase order #:
                                                                                    </div>
                                                                                    <div class="value fontfix">
                                                                                        {{order.purchaseordernumber}}
                                                                                    </div>
                                                                                </div>
                                                                                <div class="item">
                                                                                    <div class="label padfix">
                                                                                        Reference #:
                                                                                    </div>
                                                                                    <div class="value fontfix">
                                                                                        {{quote.jobId}}
                                                                                    </div>
                                                                                </div>
                                                                                <div class="item">
                                                                                    <div class="label padfix">
                                                                                        Account #:
                                                                                    </div>
                                                                                    <div class="value fontfix">
                                                                                       {{quote.customerReference}}
                                                                                    </div>
                                                                                </div>
                                                                                <div class="item">
                                                                                    <div class="label padfix">
                                                                                        Page
                                                                                    </div>
                                                                                    <div class="value fontfix">
                                                                                        1 of 1
                                                                                    </div>
                                                                                </div>
                                                                            </span>
                                                                        </span>
                                                                        <span class="float-fix"></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </span>

                                                        <span class="invoice-items">
                                                            <div class="item">
                                                                <div class="value">
                                                                    <div class="invoice-items">
                                                                        <div id="Main_ctl00_gridList_ctl00_0_ctl05_0_upList_0"
                                                                            isupdatepanel="true">

                                                                            <div class="list">
                                                                                <div>
                                                                                    <table *ngIf="(quotation$|async) as quotation;else loading" class="grid" cellspacing="0"
                                                                                        currentsort=""
                                                                                        id="Main_ctl00_gridList_ctl00_0_ctl05_0_gridList_0"
                                                                                        style="border-collapse:collapse;">
                                                                                        <tbody >
                                                                                            <tr class="header-row">
                                                                                                <th class="material-description"
                                                                                                    scope="col"><a
                                                                                                        >Product
                                                                                                        description</a>
                                                                                                </th>
                                                                                                <th class="money"
                                                                                                    scope="col"><a
                                                                                                        >Unit
                                                                                                        price</a></th>
                                                                                                <th class="money"
                                                                                                    scope="col"><a
                                                                                                        >Quantity</a>
                                                                                                </th>
                                                                                                <th class="money"
                                                                                                    scope="col"><a
                                                                                                       >Net
                                                                                                        price</a></th>
                                                                                                <th class="money"
                                                                                                    scope="col"><a
                                                                                                        >VAT</a>
                                                                                                </th>
                                                                                                <th class="money"
                                                                                                    scope="col"><a
                                                                                                        >Total</a>
                                                                                                </th>
                                                                                            </tr>
                                                                                            <tr *ngFor="let detail of quotation.details">
                                                                                                <td
                                                                                                    class="material-description">
                                                                                                    {{detail.description}}

                                                                                                </td>
                                                                                                <td class="money">
                                                                                                    {{truncateValue(detail.unitprice)}}
                                                                                                </td>
                                                                                                <td class="money">
                                                                                                    {{detail.quantity}}
                                                                                                </td>
                                                                                                <td class="money">
                                                                                                    {{truncateValue(detail.netprice)}}
                                                                                                </td>
                                                                                                <td class="money">
                                                                                                    {{truncateValue(detail.vat)}}
                                                                                                </td>
                                                                                                <td class="money">
                                                                                                    {{truncateValue(detail.total)}}
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                            </span>
                                            <span class="item payment-status">
                                                <span>
                                                    <div  class="item">
                                                        <div class="value">
                                                            <div class="invoice-legal-notice">
                                                                <span class="view-body">
                                                                    <div class="item">
                                                                        <div class="value">
                                                                            <p>The goods remain the property of
                                                                                Cutwrights Ltd. until paid for in full.
                                                                            </p>

                                                                            <p>&nbsp;</p>
                                                                        </div>
                                                                    </div>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <span  *ngIf="!isQuote">
                                                    


                                                    <div *ngIf="(paymentNotify$ |async) as p_notify" class="item">
                                                        <div class="item">
                                                            <div class="value">
                                                                <p>The payment has been made in full by credit card.</p>
                                                            </div>
                                                        </div>
                                                        <div class="label">
                                                            Card ending with:
                                                        </div>
                                                        <div class="value">
                                                            {{p_notify.Last4Digits}}
                                                        </div>
                                                    </div>
                                                    <div class="item">
                                                        <div *ngIf="!isQuote" class="value">
                                                            
                                                            <p>
                                                               Bank:Natwest Sort code:60-22-03 Account number:62626434
                                                            </p>
                                                            <p>
                                                                Thank you for your business!
                                                            </p>
                                                        </div>
                                                    </div>
                                                    </span>
                                                </span>
                                            </span>
                                            <span class="right summary">
                                                <span *ngIf="(quotation$|async) as quotation;else loading" >
                                                    <div class="item">
                                                        <div class="label l">
                                                            Total Net Amount
                                                        </div>
                                                        <div class="value v">
                                                            {{truncateValue(quotation.totalnetamount)}}
                                                        </div>
                                                    </div>
                                                    <div class="item">
                                                        <div class="label l">
                                                            Carriage Net
                                                        </div>
                                                        <div class="value v">
                                                            {{truncateValue(quotation.carriagenetamount)}}
                                                        </div>
                                                    </div>
                                                    <div class="item">
                                                        <div class="label l">
                                                            Total VAT Amount
                                                        </div>
                                                        <div class="value v">
                                                            {{truncateValue(quotation.totalvatamount)}}
                                                        </div>
                                                    </div>
                                                    <div class="item">
                                                        <div class="label l">
                                                            Invoice Total
                                                        </div>
                                                        <div class="value v">
                                                            {{truncateValue(quotation.totalinvoiceamount)}}
                                                        </div>
                                                    </div>
                                                </span>
                                            </span>
                                            <span class="float-fix">
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </span>
                        <div>
                        <button (click)="updateTitleAndPrint()" class="printButton">Print</button>
                        </div>
                    </div>
                  
                </div>
            </div>

        </div>
    </form>
</body>