import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { DataService,Impersonate } from 'src/app/service/data-service';
import { EndpointApiService } from 'src/app/service/endpoint-api.service';
import * as Papa from 'papaparse';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MetadataService } from 'src/app/service/metadata.service';
@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent {
  @ViewChild('resetPopup', { static: true })
  resetPopup!: TemplateRef<any>;
  @ViewChild('deleteUserPopup', { static: true })
  deleteUserPopup!: TemplateRef<any>;
  deleteUserEmail:string='';
  users$!:Observable<User[]>;
  userListForSearch:any;
  user$!:Observable<User>;
  admin:User
  searchKey = "";
  isLoading: boolean = false;
  bsModalRef!: BsModalRef;
  modalMessage: string="";
  modalHeader: string="";
  emptySearch:boolean=false;
  constructor(private metadataService: MetadataService,private route: Router,private endpointService:EndpointApiService,private _dataService:DataService,private modalService: BsModalService) {

    const userString = localStorage.getItem('user');
    this.admin = JSON.parse(userString as string)
  }
  searchUsers() {
    this.updateUsersList();
  }

  updateUsersList() {
    let filteredUsers;
  
    // Trim and split search key by spaces
    const searchKeys = this.searchKey.trim().toLowerCase().split(" ");
  
    if (searchKeys.length === 0 || searchKeys[0] === "") {
      // If the search key is empty after trimming, show only the first 10 users
      filteredUsers = this.userListForSearch.slice(0, 10);
    } else if (searchKeys.length === 2) {
      // Exact match for "FN LN" format
      const firstName = searchKeys[0];
      const lastName = searchKeys[1];
  
      filteredUsers = this.userListForSearch.filter((user: any) => {
        const searchKeyLower = this.searchKey.toLowerCase();
        return (
          user.firstName?.toLowerCase() === firstName &&
          user.lastName?.toLowerCase() === lastName
        );
      });
    } else {
      // Regular filtering based on individual search keys
      filteredUsers = this.userListForSearch.filter((user: any) => {
        const matchFound = searchKeys.some((key) => {
          return (
            (user.firstName?.toLowerCase()?.includes(key) ?? false) ||
            (user.lastName?.toLowerCase()?.includes(key) ?? false) ||
            (user.email?.toLowerCase()?.includes(key) ?? false) ||
            (user.displayName?.toLowerCase()?.includes(key) ?? false) ||
            (user.companyName?.toLowerCase()?.includes(key) ?? false) ||
            (user.reference?.toLowerCase()?.includes(key) ?? false) ||
            (user.phone?.toLowerCase()?.includes(key) ?? false) ||
            (user.mobile?.toLowerCase()?.includes(key) ?? false)
          );
        });
        return matchFound;
      });
    }
  
    this.users$ = of(filteredUsers);
    this.emptySearch = filteredUsers.length === 0;
  }
  
  ngOnInit(): void {
    this.metadataService.setTitle('default');
    this.metadataService.setDescription('default');
    this.endpointService.getCustomers("").subscribe((data:any)=>{
      this.userListForSearch=data;
      this.searchUsers();
    })
  }
  
  callImpersonate(userId:string){
    this.user$=this.endpointService.getOneCustomer(userId);
    this.user$.subscribe((user:any)=> {
      this.impersonateUser(user);
    })  
  }

  impersonateUser(user:User): void {
    var data:Impersonate={
      user:user,
      admin:this.admin
    }
    this._dataService.emitImpersonateFlow(data);
    // this.route.navigate(['/customer/my-quote/667ea484-18c4-4c16-9b1e-1d631631d3ee']);
  }
  fp(email:string){
    this.endpointService.resetPassword(email).subscribe(data => {
      this.openModal(this.resetPopup,"Success!","Password reset sent.");
    });
  }
  openModal(template: TemplateRef<any>, header:string, message:string): void {
    this.modalHeader=header;
    this.modalMessage = message;
    this.bsModalRef = this.modalService.show(template, { class: 'modal-dialog-centered' });
  }
  openDeleteUserModal(template: TemplateRef<any>, userEmail:string): void {
    this.deleteUserEmail=userEmail;
    this.bsModalRef = this.modalService.show(template, { class: 'modal-dialog-centered' });
  }
  getOrdersCSV(){
    this.endpointService.getCustomersCsv().subscribe((data: any) => {
      const csvData = Papa.unparse(data, {
        header: true,
        quotes: true,
      });
  
      // Create a Blob
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  
      // Create a download link
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
  
      // Set the file name
      link.download = 'export.csv';
  
      // Append the link to the body
      document.body.appendChild(link);
  
      // Trigger the download
      link.click();
  
      // Clean up
      document.body.removeChild(link);
    });
  }
  deleteUser(userEmail:string){
    this.endpointService.deleteUser(userEmail).subscribe((data:any)=>{
     this.ngOnInit()
    })
  }

}
