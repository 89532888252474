import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Order, Quote, eStatusM, eStatusO } from 'src/app/models/cut-wrights.model';
import { EndpointApiService } from 'src/app/service/endpoint-api.service';
import { GoogleMapsService } from 'src/app/service/google-api.service';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent {
  quotations :Order[] = [];
  date_test = new Date().toISOString();
  isLoading: boolean = true;
  userId = ""
  constructor(private metadataService: MetadataService,private apiService: EndpointApiService, private router: Router, private googleMapsService:GoogleMapsService,private route: ActivatedRoute){
    this.userId = this.route.snapshot.paramMap.get('userId') as string;
  }
  ngOnInit(){
    this.metadataService.setTitle('default');
    this.metadataService.setDescription('default');
    this.isLoading = true;
    this.apiService.getCustomerOrders(this.userId).subscribe(data=>{
      this.isLoading = false;
      this.quotations = data as Order[];
    });
  }
  getEDays(quote:Quote){
  const creationDate = new Date() //TODO add creation date
  const expirationLimit = 30; // Expiration limit in days
  const currentDate = new Date();
  const differenceMs = currentDate.getTime() - (creationDate).getTime();
  const differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
  const daysRemaining = expirationLimit - differenceDays;
  return daysRemaining;
  }
  getStatus(quote:Order){
    const statusNum:number = quote.status as number;
    return eStatusO[statusNum as keyof typeof eStatusO]
  }
  navigateToQuoteDetails(quote:Order) {
    const navigationExtras = {
      state: {
        data:{
          quote: quote
        }

      }
    };
    this.router.navigate(['customer/order-details',quote.quoteId], navigationExtras);
  }
  navigateToInvoice(quote:Order){
    window.open(this.router.createUrlTree(['customer/invoice-receipt',quote.quoteId,"inv"], { relativeTo: this.router.routerState.root }).toString(), '_blank');
  }
  truncateValue(value: number): string {
    return this.apiService.truncateDecimal(value);
  }

  makeAChange(quote: any) {
    this.apiService.getQuote(quote.quoteId).subscribe((data: any) => {

      const includeOffCuts = data.includeOffCuts;
      const panelsNeedsLabelling = data.panelsNeedsLabelling;
      {
        this.apiService.mergeQuotes({
          "quoteId": [quote.quoteId]
        }).subscribe((data: any) => {
          this.router.navigate([`/customer/get-quote`, data.quoteId], {
            queryParams: {
              includeOffCuts: includeOffCuts,
              panelsNeedsLabelling: panelsNeedsLabelling

            }
          });
        })
      }

    })
  }
}
