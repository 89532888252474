<div class="orders-list">
    <div class="main-body">
        <div class="content-area">
            <div class="content-area-content ">
                <div>
                    <h2>Orders</h2>
                    <div>
                        <div class="search">
                            <span class="search-body">
                                <div class="item">
                                    <div class="label">
                                        Find:
                                    </div>
                                    <div class="input">
                                        <input [(ngModel)]="quoteF" name="Root$Main$ctl00$txtKeywordSearch" type="text"
                                            id="Main_ctl00_txtKeywordSearch" class="txt textbox" (keyup.enter)="searchOrder()">
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="label">
                                        Created at:
                                    </div>
                                    <div class="input">
                                        <span id="Main_ctl00_spanCreatedAt">
                                            <input [(ngModel)]="fromDate" name="Root$Main$ctl00$txtCreatedAt" type="date"
                                                id="Main_ctl00_txtCreatedAt" class="txt date-box textbox">
                                    

                                            <span id="Main_ctl00_cmvtxtCreatedAt" style="visibility:hidden;">*</span>
                                        </span>
                                        <span id="Main_ctl00_spanCreatedAt2">
                                            <label for="Main_ctl00_txtCreatedAt2" class="width-20">to</label>
                                            <input [(ngModel)]="toDate" name="Root$Main$ctl00$txtCreatedAt2" type="date"
                                                id="Main_ctl00_txtCreatedAt2" class="txt date-box textbox" (keyup.enter)="searchOrder()">
                                           
                                            <span id="Main_ctl00_cmvtxtCreatedAt2" style="visibility:hidden;">*</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="label">
                                        Status:
                                    </div>
                                    <div class="input">
                                        <select [(ngModel)]="statusF" name="Root$Main$ctl00$lstStatus" id="Main_ctl00_lstStatus" class="list">
                                            <option value="-1" selected="selected" (keyup.enter)="searchOrder()">
                                                ---All---</option>
                                            <!-- <option value="0">Archived</option> -->
                                            <!-- 
                                            <option>Customer acceptance
                                            </option> -->
                                            <option value="5">Cancelled</option>
                                            <option value="4">Complete</option>
                                            <option value="0">In progress</option>
                                            <option value="1">Inspection</option>
                                            <option value="2">Ready for delivery
                                            </option>
                                            <option value="3">Ready to collect
                                            </option>
                                            <option value="6">Waiting for Authorization
                                            </option>
                                            <!-- <option>Ready to start</option>
                                            <option>Waiting for
                                                authorisation</option>
                                            <option>Waiting for material
                                            </option> -->

                                        </select>
                                    </div>
                                </div>
                                <span class="buttons" >
                                    <input type="submit" name="Root$Main$ctl00$btnSearch" value="Search"
                                    (click)="searchOrder()" id="Main_ctl00_btnSearch">
                                    <input type="submit" class="csv-button" name="Root$Main$ctl00$btnSearch" value="Export to CSV"
                                    (click)="getOrdersCSV()" id="Main_ctl00_btnSearch">
                                    <!-- <input type="submit" class="csv-button" name="Root$Main$ctl00$btnSearch" value="Check Status"
                                    (click)="populateStatusMap()" id="Main_ctl00_btnSearch"> -->
                                </span>
                            </span>

                        </div>
                        <ng-template #loading>
                            <div class="loader">
                            <app-loader></app-loader>
                            </div>
                         </ng-template>
                        <div class="list" *ngIf='(orders$ | async); else loading'>
                            <div>
                                <table class="grid" cellspacing="0" currentsort="item.CreatedAt DESC"
                                    id="Main_ctl00_gridList" style="border-collapse:collapse; table-layout:fixed; width:100%;overflow-wrap: break-word;">
                                    <tbody>
                                        <tr class="header-row">
                                            <th scope="col"><a
                                                >Order Ref</a>
                                        </th>
                                            <th scope="col"><a
                                                    >Name</a>
                                            </th>
                                            <th scope="col">Company Name</th>
                                            <th scope="col"><a
                                                    >Phone Number</a>
                                            </th>
                                            <th scope="col"><a
                                               >Job Ref</a>
                                            </th>
                                            <th scope="col"><a
                                                    >Job Name</a>
                                            </th>
                                            <th scope="col"><a
                                                        >Created On</a>
                                            </th>
                                            <th scope="col" style="width: 120px;"><a
                                                    >Needed By</a>
                                            </th>
                                            <th scope="col"><a
                                                   >Total</a>
                                            </th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Depot</th>
                                            <th scope="col">Delivery/Collection</th>
                                            <th scope="col" style="width: 80px;">Confirm</th>
                                            <th scope="col">Authorize</th>
                                            <th scope="col">Cancel</th>
                                        </tr>
                                        <div *ngIf="emptySearch" style="margin-top: 50px;">
                                            <b>NO RESULTS FOUND. SORRY.</b>
                                         </div>
                                        <tr  *ngFor="let order of orders$ | async; let index = index">
                                            <td>
                                                <a id="Main_ctl00_gridList_btnReference_0" class="hyperlink"
                                                    (click)="navigateToOrderDetails(order)">{{order.orderReference}}</a>
                                            </td>
                                            <td>
                                                <a id="Main_ctl00_gridList_btnName_0" 
                                                   >{{order.customerName}}</a>
                                            </td>
                                            <td>
                                                {{order.customerCompanyName}}
                                            </td>
                                            <td>
                                                {{order.customerMobileNumber}}
                                            </td>
                                            <td>
                                                <a id="Main_ctl00_gridList_btnReference_0" class="hyperlink"
                                                    (click)="navigateToQuoteDetails(order)">{{order.jobReference}}</a>
                                            </td>
                                            
                                            <td>
                                                 <a >{{order.name}}  </a> 
                                            </td>
                                            <td>
                                                <a>{{ order.createdOn | date:'dd/MM/yyyy' }}</a>
                                           </td>
                                            <td>
                                                <!-- {{order.neededBy}} -->
                                                <div class="input-group">
                                                    <input style="width:110px" type="date" [(ngModel)]="order.neededBy" (ngModelChange)="setNeededBy(order)">
                                                    <!-- <div class="input-group-append">
                                                        <button class="btn btn-primary" (click)="dp.toggle()"></button>
                                                    </div> -->
                                                </div>
                                            </td>
                                            <td>
                                                £{{truncateValue(order.totalinvoiceamount)| number:'1.2-2'}}
                                            </td>
                                            <td>
                                                <!-- <div [innerHTML]="getStatus(order)"></div> -->
                                                <ul *ngIf="order.status==0" class="custom-list">
                                                    <li *ngIf="order.pJobStatus">{{order.pJobStatus}}</li>
                                                    <li *ngIf="order.sJobStatus">{{order.sJobStatus}}</li>
                                                    <li *ngIf="!order.pJobStatus && !order.sJobStatus">
                                                        <p class="hyperlink" (click)="fetchArray(order)"> Fetch Status</p>
                                                        <p *ngIf="statusMap">{{ getStatus(order) }}</p>
                                                    </li>
                                                </ul>
                                                <ul *ngIf="order.status!=0">
                                                    {{getStatus(order)}}
                                                </ul>
                                            </td>
                                            <td>
                                                <div  class="btn-group" dropdown>
                                                    <button dropdownToggle type="button" class="btn btn-primary">
                                                        {{order.depot == 0 ? 'LHR - Hanworth' : 'BOH - Poole'}}
                                                    </button>
                                                    <ul *dropdownMenu class="dropdown-menu" role="menu">
                                                      <li role="menuitem">
                                                        <a class="dropdown-item" (click)="selectDepot(order,0)">LHR - Hanworth</a>
                                                      </li>
                                                      <li role="menuitem">
                                                        <a class="dropdown-item" (click)="selectDepot(order,1)">BOH - Poole</a>
                                                      </li>
                                                    </ul>
                                                  </div>
                                               
                                                <!-- <input type="checkbox"
                                                    onchange="isCollectionConfirmed('cc634b51-d2f0-46ea-af63-05db9503fded')"
                                                    name="IsCollectionConfirmed"> -->
                                            </td>
                                            <td>
                                                {{order.collection ? "Collection" : "Delivery"}}
                                            </td>
                                            <td>
                                                 <input type="checkbox"
                                                 [checked]="order.confirmed"
                                                    (change)="collection(order.orderId)"
                                                    name="IsCollectionConfirmed">
                                            </td>
                                            <td>
                                                <a *ngIf="!order.authorised" class="hyperlink" (click)="authorize(order.orderId)">authorize</a>
                                           </td>
                                           <td>
                                            <a *ngIf="order.status != 5" class="hyperlink" (click)="cancelOrder(order)">cancel</a>
                                       </td>
                                        </tr>
                                        
                                </table>
                            </div>
                        </div>
                        <input type="hidden" name="Root$Main$ctl00$SelectedListItemIdHolder"
                            id="Main_ctl00_SelectedListItemIdHolder">
                    </div>
                    <!-- «End| Orders List» -->
                </div>

            </div>
        </div>
        <div class="floatfix"></div>
    </div>
</div>