import { Component, Input } from '@angular/core';
import { Order } from 'src/app/models/cut-wrights.model';
import { EndpointApiService } from 'src/app/service/endpoint-api.service';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-targets',
  templateUrl: './targets.component.html',
  styleUrls: ['./targets.component.scss']
})
export class TargetsComponent {
  @Input() order!:Order
  targetTime:string="Default";
  actualTime:string="Default";

  constructor(private metadataService: MetadataService,private apiService: EndpointApiService){}
 
  ngOnInit(){
    this.metadataService.setTitle('default');
    this.metadataService.setDescription('default');
    this.apiService.getOrderTarget(this.order.orderId).subscribe(
      (data:any)=>{
       this.targetTime=data.targets[0].target;
       this.actualTime=data.targets[0].actual;
      }
    )
  }
}
