import { Component } from '@angular/core';
import { ADMIN_HEADERS } from '../../constant/headers.contant';
import { AdminHeaders } from '../../models/admin.models';
import { NavigationStart, Route, Router } from '@angular/router';
import { filter } from 'rxjs';
import { DataService } from 'src/app/service/data-service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  activeRoute: string = location.pathname;
  hideHeader:boolean=false;

  constructor(private router: Router,private dataService: DataService) {
    this.dataService.getHeaderFooterFlag().subscribe((data) => {
      this.hideHeader = data;
    });
    router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((route: any) => {
      this.activeRoute = route.url;
    });
  }
  headers: AdminHeaders[] = ADMIN_HEADERS;
}
