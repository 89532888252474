<footer *ngIf="!hideFooter">
 <div class="website-footer" id="website-footer">
    <div class="roman">
        <div class="footer-menu">
            <span class="">
                <div class="item">
                    <a target="_blank">FAQ’s</a>
                </div>
            </span>

            <span class="">
                <div class="item">
                    <a href="../../../../assets/resources/cutwrights-term-and-condition.pdf"
                        target="_blank">Terms &amp; Conditions</a>
                </div>
            </span>

            <span class="">
                <div class="item">
                    <a href="../../../../assets/resources/cutwrights-privacy.pdf" target="_blank">Privacy
                        Policy</a>
                </div>
            </span>

        </div>

        <span class="rightside">
            <span id="ctl20_lblVersion">Version: </span>
            <span id="ctl20_lblAssembly">2017.4.4.20</span>
        </span>
        <span class="rightside">
            Copyright 2015 all rights reserved.
        </span>
    </div>
</div>
</footer>