import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-confirm-quote-submission',
  templateUrl: './confirm-quote-submission.component.html',
  styleUrls: ['./confirm-quote-submission.component.scss']
})
export class ConfirmQuoteSubmissionComponent {
  constructor(private metadataService: MetadataService,private router:Router){

  }
  ngOnInit() {
    this.metadataService.setTitle('default');
    this.metadataService.setDescription('default');
    window.scrollTo(0, 0);
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      window.scrollTo(0, 0);
    });
  }

  goToQuotes(){
    var token = localStorage.getItem("token")
    const userString = localStorage.getItem('user');
    if(token){
      var user = JSON.parse(userString as string)
      this.router.navigate([`/customer/my-quote/${user.userId}`])
    } else{
      this.router.navigate(["/"])
    }

  }
}
