import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DeliveryDetails, Quote } from 'src/app/models/cut-wrights.model';
import { EndpointApiService } from 'src/app/service/endpoint-api.service';
import { bankHolidaysData } from 'src/app/shared/mock-data/bank-holiday-data';
import { DataService } from 'src/app/service/data-service';
import { MetadataService } from 'src/app/service/metadata.service';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'app-delivery-form',
  templateUrl: './delivery-form.component.html',
  styleUrls: ['./delivery-form.component.scss']
})
export class DeliveryFormComponent implements OnInit {
  deliveryForm!: FormGroup;
  @Input() collection: any;
  @Input() quoteId: any;
  @Input() leadtime: any;
  @Input() quote!: Quote;
  @ViewChild('dateInput') dateInput!: ElementRef;
  Depo_0 = ""
  Depo_1 = ""
  defaultDate=new Date();
  maxDate= new Date();
  maxDateString:string='';
  bankHolidays:any
  user!: any;
  isCreditCustomer : boolean = false;
  purchaseOrderFlag :boolean =false;

  constructor(private metadataService: MetadataService,private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute, private endpoint: EndpointApiService,private dateService: DataService) {}
  @Output() next = new EventEmitter<any>();

  onNext() {
    if (this.isCreditCustomer===true && !this.deliveryForm.value.purchaseOrder){
      this.purchaseOrderFlag = true;
      return
    }
    // Process form data from Step 1 and emit it to the parent component
    if (this.deliveryForm.valid) {
      var deliveryDetails: DeliveryDetails = {
        "neededBy": this.deliveryForm.value.needBInput,
        "deliveryTiming": this.deliveryForm.value.deliveryTiming,
        "instructionDetails": this.deliveryForm.value.instructionDetails,
        "purchaseOrder": this.deliveryForm.value.purchaseOrder,
        "depot":this.collection ? parseInt(this.deliveryForm.value.depot) : 0
      };
      if(!!deliveryDetails){
        this.quote.deliveryDetails = deliveryDetails;
        delete this.quote.Id
        this.endpoint.putCutWrightData(this.quote).subscribe(()=>this.next.emit(this.deliveryForm.value))
      }
          
    }
  }
  ngOnInit(): void {
    this.metadataService.setTitle('default');
    this.metadataService.setDescription('default');
    const today = new Date();    
    const leadTime = this.leadtime || 7; 
    this.bankHolidays = bankHolidaysData["england-and-wales"].events.map(holiday => new Date(holiday.date));    
    const defaultDate = this.dateService.addDaysWithWeekendHandling(today,leadTime, this.bankHolidays).toISOString().split('T')[0]; ;
    this.maxDate.setDate( today.getDate()+60);
    this.maxDateString= this.maxDate.toISOString().split('T')[0];    
    this.deliveryForm = this.formBuilder.group({
      needBInput: new FormControl(defaultDate, [Validators.required,this.weekendValidator(),this.futureDateValidator(),this.maxDateValidator(),this.bankHolidayValidator()]),
      deliveryInstructions: new FormControl([]),

      purchaseOrder: new FormControl(this.quote?.deliveryDetails?.purchaseOrder),
    });
    if (!this.collection) {
      this.deliveryForm.addControl("instructionDetails", new FormControl(this.quote.deliveryDetails?.instructionDetails))
    }
    if (!this.collection) {
      this.deliveryForm.addControl("deliveryTiming", new FormControl(this.quote.deliveryDetails?.deliveryTiming, Validators.required))
    }
    if(this.collection){
      this.deliveryForm.addControl("depot", new FormControl(this.quote.deliveryDetails?.depot, Validators.required))
    }
    this.endpoint.getGlobalSetting('DEPOT0').subscribe((data:any) => {
      this.Depo_0 = data["value"]
    })
    this.endpoint.getGlobalSetting('DEPOT1').subscribe((data:any) => {
      this.Depo_1 = data["value"]
    })
 
    this.user=localStorage.getItem('user');
    this.user=JSON.parse(this.user);
    if(this.user.customerType ==='1'){
      this.isCreditCustomer=true;
    }     
  }
  ngAfterViewInit() {
    const today = new Date();
    const leadTime = this.leadtime || 7;
    const bankHolidays = bankHolidaysData["england-and-wales"].events.map(holiday => new Date(holiday.date));
    const formattedToday = this.dateService.addDaysWithWeekendHandling(today, leadTime, bankHolidays).toISOString().split('T')[0];
    this.dateInput.nativeElement.min = formattedToday; // Set the minimum date for the input
  }
  goToDetails(): void {
    this.router.navigate(['customer/quote-details', this.quoteId])
  }
  getStatus(control: string) {
    return !this.deliveryForm.get(control)?.valid
  }

  weekendValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const selectedDate = control.value ? new Date(control.value) : null;
  
      if (selectedDate && (selectedDate.getDay() === 0 || selectedDate.getDay() === 6)) {
        return { weekend: true };
      }
  
      return null;
    };
  }

  futureDateValidator() {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const selectedDate = new Date(control.value);
      const currentDate = new Date();

      if (selectedDate < currentDate) {
        return { pastDate: true }; // Validation failed
      }
      
      return null; // Validation passed
    };
  }

  maxDateValidator(){
    return (control: AbstractControl): { [key: string]: any } | null => {
      const selectedDate = new Date(control.value);
      if (selectedDate>this.maxDate){
          return{maxDateError:true};
        }
     return null; 
    }
  }

  bankHolidayValidator(){
    return (control: AbstractControl): { [key: string]: any } | null => {
      const selectedDate = new Date(control.value);
      if(this.dateService.isBankHoliday(selectedDate,this.bankHolidays)){
      return {bankHolidayError:true};
      }
      return null;
   }
  }

  }
