<div class="show-room">
    <section class="banner-pages banner-bg-showroom" role="banner">
        <div class="container">
            <div class="col-md-10 col-md-offset-1">
                <div class="banner-pages-text text-left">
                    <h1>Showroom</h1>
                </div><!-- banner text -->
            </div>
        </div>
    </section>

    <section id="intro" class="section">
        <div class="container">
            <div class="intro">
                <span class="site-navigation"><a [routerLink]="['/']">Home</a> » Showroom</span>
                <p><strong>The Showroom, it’s not ours! It’s yours…</strong><br>
                    <br>
                    Want to be able to show your clients the full range of products?<br>
                    Having trouble convincing a client of a high-quality product you can supply?<br>
                    Want to meet your clients in a professional environment?<br>
                    <br>
                    Our showroom is just the place to bring them. Designed to give you a hands-on feel for the products
                    we offer; we have several furniture examples on display that showcase the quality of the products we
                    offer. We are sure that there is something here to suit everyone, from luxuriously textured to solid
                    colour melamine faced, or maybe hi-gloss acrylic to perfect matt! With the assistance of one of our
                    knowledgeable team, we are confident that we can help you to find the right material for the
                    job.<br>
                    <br>
                    <strong>Daily appointments available!</strong><br>
                    <br>
                    Monday – Friday, 8.00am – 4.30pm.
                </p>

                <p>The showroom is open for appointments, please send us an email tomake your booking&nbsp;to
                    {{"sales@cutwrights.com"}}.</p>
            </div>
        </div>
    </section>

    <div class="page-container">
        <section id="showroom" class="showroom section no-padding">
            <div class="container">
                <div class="row no-gutter">
                    <div class="col-lg-3 col-sm-3 col-xs-6">
                        <div class="showroom">
                            <a
                                class="showroom-box gallery-box animated fadeIn" title="Showroom image 1"
                                data-fancybox-group="thumb">
                                <img src="../../../assets/images/images-showroom-0@2x.jpg"
                                    alt="Boards" class="img-responsive-no" width="296" height="150">
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-3 col-xs-6">
                        <div class="showroom">
                            <a
                                class="showroom-box gallery-box animated fadeIn" title="Showroom image 2"
                                data-fancybox-group="thumb">
                                <img src="../../../assets/images/images-showroom-1@2x.jpg"
                                    alt="Wardrobes" class="img-responsive-no" width="296" height="150">
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-3 col-xs-6">
                        <div class="showroom">
                            <a
                                class="showroom-box gallery-box animated fadeIn" title="Showroom image 3"
                                data-fancybox-group="thumb">
                                <img src="../../../assets/images/images-showroom-2@2x.jpg"
                                    alt="Doors" class="img-responsive-no" width="296" height="150">
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-3 col-xs-6">
                        <div class="showroom">
                            <a
                                class="showroom-box gallery-box animated fadeIn" title="Showroom image 4"
                                data-fancybox-group="thumb">
                                <img src="../../../assets/images/images-showroom-3@2x.jpg"
                                    alt="Wardrobes" class="img-responsive-no" width="296" height="150">
                            </a>
                        </div>
                    </div>
                </div>

                <div class="row no-gutter">
                    <div class="col-lg-3 col-sm-3 col-xs-6">
                        <div class="showroom">
                            <a 
                                class="showroom-box gallery-box animated fadeIn" title="Showroom image 5"
                                data-fancybox-group="thumb">
                                <img src="../../../assets/images/images-showroom-4@2x.jpg"
                                    alt="Showroom" class="img-responsive-no" width="296" height="150">
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-3 col-xs-6">
                        <div class="showroom">
                            <a
                                class="showroom-box gallery-box animated fadeIn" title="Showroom image 6"
                                data-fancybox-group="thumb">
                                <img src="../../../assets/images/images-showroom-5@2x.jpg"
                                    alt="Wood" class="img-responsive-no" width="296" height="150">
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-3 col-xs-6">
                        <div class="showroom">
                            <a
                                class="showroom-box gallery-box animated fadeIn" title="Showroom image 7"
                                data-fancybox-group="thumb">
                                <img src="../../../assets/images/images-showroom-6@2x.jpg"
                                    alt="..." class="img-responsive-no" width="296" height="150">
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-3 col-xs-6">
                        <div class="showroom">
                            <a
                                class="showroom-box gallery-box animated fadeIn" title="Showroom image 8"
                                data-fancybox-group="thumb">
                                <img src="../../../assets/images/images-showroom-7@2x.jpg"
                                    alt="..." class="img-responsive-no" width="296" height="150">
                            </a>
                        </div>
                    </div>
                </div>

                <div class="row no-gutter">
                    <div class="col-lg-3 col-sm-3 col-xs-6">
                        <div class="showroom">
                            <a
                                class="showroom-box gallery-box animated fadeIn" title="Showroom image 9"
                                data-fancybox-group="thumb">
                                <img src="../../../assets/images/images-showroom-8@2x.jpg"
                                    alt="..." class="img-responsive-no" width="296" height="150">
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-3 col-xs-6">
                        <div class="showroom">
                            <a
                                class="showroom-box gallery-box animated fadeIn" title="Showroom image 10"
                                data-fancybox-group="thumb">
                                <img src="../../../assets/images/images-showroom-9@2x.jpg"
                                    alt="..." class="img-responsive-no" width="296" height="150">
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-3 col-xs-6">
                        <div class="showroom">
                            <a
                                class="showroom-box gallery-box animated fadeIn" title="Showroom image 11"
                                data-fancybox-group="thumb">
                                <img src="../../../assets/images/images-showroom-10@2x.jpg"
                                    alt="..." class="img-responsive-no" width="296" height="150">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>