<div *ngIf="!hideHeader">
<header *ngIf="!isAdmin" id="header" class="">
  <div *ngIf="isLoading" class="loader-section">
    <app-loader></app-loader>
 </div>
<div class="container">
  <app-alert></app-alert>
</div>
    <section id="headline" class="headline" style="height: 40px;">
        <div class="container" *ngIf="!isLoggedIn">
            <div class="row no-gutter">
                <div class="col-md-6 col-sm-6">
                    <div class="headline-left text-query-middle">

                </div>
                </div>
                <div class="col-md-6 col-sm-6">
                    <div *ngIf="!isLoggedIn" class="headline-right text-query-middle" style="padding-top: 1px;">
                        <a id="popup-login" (click)="signIn($event)">
                            Login to my
                            Cutwrights</a> | <a id="popup-signup" (click)="signUp($event)">Sign up</a>
                    </div>
                </div>
            </div>
        </div>

        <div [class]="this.isImp=='true' ? 'user-profile-impersonate': 'user-profile-customer '" *ngIf="isLoggedIn">
          <div class="menu-link-x ">
            <div class="strip"></div>
            <div class="strip"></div>
            <div class="strip"></div>
          </div>
          <div   class="dropdown">
            <div (click)="toggleDropDown()" style="font-size: 13px;"> {{this.isImp == 'false'?"Welcome":"Impersonating"}}, {{userDisplayName}}</div>            
            <div *ngIf="isDropDown" class="dropdown-content">
              <a [routerLink]="['/customer/my-quote',user.userId]" (click)="toggleDropDown()">My Quotes</a>
              <a [routerLink]="['/customer/order-list',user.userId]" (click)="toggleDropDown()">Order List</a>
              <a [routerLink]="['/customer/my-account',user.userId]" (click)="toggleDropDown()">My Account</a>
              <!--<a [routerLink]="['/customer/invoices',user.userId]">Invoices</a>-->
              <a [routerLink]="['/enquiry']" (click)="toggleDropDown()">Enquiry</a>
              <hr />
              <a (click)="onLogout()" (click)="toggleDropDown()">Logout</a>
            </div>
          </div>
          <button class="cancel-btn" *ngIf="this.isImp=='true'" type="button" (click)="endImpFlow()"> x</button>
        </div>

        <div class="sign-up-section" *ngIf="isForgotPassword" (click)="stopEventPropgate($event)">
            <div class="fancybox-skin">
                <div class="fancybox-outer">
                    <div class="fancybox-inner">
                        <form autocomplete="off" class="popup" id="forgot-password_form"
                            novalidate="novalidate">
                            <h5 id="signup-error" class="font-b">Reset Password</h5>
                            <p>Enter your email address to get reset password link</p>
                            <p>
                                <label class="required" for="signupFirstName">E-mail:</label>
                                <input autofocus="autofocus"
                                    id="autofocusField" [(ngModel)]="fpEmail" name="signupFirstName" placeholder="Enter your Email address" size="30"
                                    type="text">
                                <span class="field-validation-valid" data-valmsg-for="signupFirstName"
                                    data-valmsg-replace="true" style="display:block; padding-left:25%;"></span>
                            </p>
                            <p>
    
                                <label></label>
                                <input type="submit" value="Send" (click)="handleFpPassword()" class="btn btn-large"> &nbsp; &nbsp; &nbsp;
                                <!-- <input type="submit" value="Cancel" (click)="cancelReset()" class="btn btn-large"> -->
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="sign-up-section" *ngIf="isSignIn && !isLoggedIn" (click)="stopEventPropgate($event)">
          <div *ngIf="isLoginLoading" class="login-loader-section">
            <app-loader></app-loader>
         </div>
            <div class="fancybox-skin">
                <div class="fancybox-outer">
                    <div class="fancybox-inner">
                        <form [formGroup]="signInForm" autocomplete="off" class="popup" id="signIn_form" (ngSubmit)="onLoginSubmit()" novalidate>
                            <h5 id="signup-error" class="font-b">Login</h5>
                            <p>
                              <label class="required" for="signupFirstName">E-mail:</label>
                              <input autofocus="autofocus" id="autofocusField" formControlName="signupFirstName" placeholder="Your login email" size="30" type="text">
                              <span class="field-validation-invalid" *ngIf="signInForm.get('signupFirstName')?.invalid && signInForm.get('signupFirstName')?.touched">
                                <label></label> Email is required.
                              </span>
                            </p>
                            <p>
                              <label class="required" for="signupPassword">Password:</label>
                              <input data-val="true" [type]="loginPasswordField? 'text' : 'password'" id="signupPassword" formControlName="signupPassword" placeholder="Your password" size="30" type="password">
                              <i [class]="!loginPasswordField? 'fa fa-eye-slash': 'fa fa-eye'" (click)="toggleLoginPassword()"></i>
                              <span class="field-validation-invalid" *ngIf="signInForm.get('signupPassword')?.invalid && signInForm.get('signupPassword')?.touched">
                                <label></label> Password is required.
                              </span>
                            </p>
                              <div id="loginAlertDiv" *ngIf="isErrorWithLoggedIn"><div class="alert alert-danger"><a class="close" data-dismiss="alert" aria-label="close" (click)="closeError()">×</a><strong>Error.</strong> Invalid username and/or password. Please try again.</div></div>
                            
                            <p>
                              <label></label>
                              <input type="submit" value="Login" class="btn btn-large" (click)="onLoginSubmit()" [disabled]="signInForm.invalid">
                            </p>
                            <p>
                              <label></label>
                              <a href="javascript:void(0)" (click)="forgotPassword($event)">Forgot your password?</a>
                            </p>
                          </form>                          
                    </div>
                </div>
            </div>
        </div>

        <div class="sign-up-section" *ngIf="isSignupOn && !isSignedUp" (click)="stopEventPropgate($event)">
            <div class="fancybox-skin">
                <div class="fancybox-outer">
                    <div class="fancybox-inner">
                        <form [formGroup]="signUpForm" autocomplete="off" class="popup" id="signup_form" novalidate (ngSubmit)="onSignupSubmit()">
                            <h5 id="signup-error" class="font-b">Sign up</h5>
                            <p>Please fill the form to receive an <b>activation mail.</b></p>
                          
                            
                            
                            <p>
                              <label class="required" for="firstName">First Name:</label>
                              <input id="firstName" formControlName="firstName" placeholder="Your first name" size="30" type="text">
                              <span class="field-validation-invalid" *ngIf="signUpForm.get('firstName')?.invalid && signUpForm.get('firstName')?.touched">
                                <label></label>First Name is required.
                              </span>
                            </p>
                            
                            <p>
                              <label class="required" for="lastName">Last Name:</label>
                              <input id="lastName" formControlName="lastName" placeholder="Your last name" size="30" type="text">
                              <span class="field-validation-invalid" *ngIf="signUpForm.get('lastName')?.invalid && signUpForm.get('lastName')?.touched">
                                <label></label> Last Name is required.
                              </span>
                            </p>
                        
                            <p>
                              <label for="companyName">Company Name:</label>
                              <input id="companyName" formControlName="companyName" placeholder="Your company name" size="30" type="text">
                            </p>
                            
                            <p>
                              <label class="required" for="postcode">Postcode:</label>
                              <input id="postcode" formControlName="postcode" placeholder="Your postcode" size="30" type="text" (input)="checkPostCode()" maxlength="15">
                             
                              <span class="field-validation-invalid" *ngIf="!postCodeValidity && signUpForm.get('postcode')?.touched">
                                <label></label>  Postcode is Invalid.
                              </span>
                            </p>
                            
                            
                            <p>
                              <label class="required" for="email">Email:</label>
                              <input id="email" formControlName="email" placeholder="Your email address" size="30" type="text">
                              <span class="field-validation-invalid" *ngIf="signUpForm.get('email')?.invalid && signUpForm.get('email')?.touched">
                                <label></label>  Email is required and should be valid.
                              </span>
                            </p>
                            
                            <p>
                              <label for="mobile">Mobile:</label>
                              <input id="mobile" formControlName="mobile" placeholder="Your mobile number" size="30" type="text" maxlength="20">
                            </p>
                            
                            
                            <!-- <p>
                              <label for="notifications">Notifications:</label>
                              <input id="notifications" formControlName="notifications" type="checkbox" class="check">
                            </p> -->
                            
                            <p>
                              <label for="marketing">Marketing:</label>
                              <input id="marketing" formControlName="marketing" type="checkbox" class="check">
                            </p>
                            
                            <p><label></label>
                              <a href="../../../assets/resources/cutwrights-term-and-condition.pdf" target="_blank">Terms &amp;
                                Conditions</a> |
                              <a href="../../../assets/resources/cutwrights-privacy.pdf" target="_blank">Privacy Policy</a>
                            </p>
                          
                            <p>
                              <label></label>
                              <input type="submit" value="Sign up" class="btn btn-large" [disabled]="signUpForm.invalid">
                            </p>
                          
                            <p>
                              <em></em>
                            </p>
                          </form>                          
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="header-content clearfix pb-3 pt-0 m-0">
        <a class="logo" [routerLink]="['/']"><img src="../../../assets/images/logo_cutwrights@2x.svg"
                alt="Cutwrights" width="305" height="100"></a>
        <a class="menu-link" (click)="toggleBurgerMenu()">Menu<span></span></a>
        <nav id="menu" [class]="isMenuHidden ? 'menu menu-desktop active': 'menu menu-desktop'">
            <ul>
                <li (click)="toggleBurgerMenu()" (click)="turnOffSubMenu()"><a [routerLink]="['/']">Home</a></li>
                <li (click)="toggleBurgerMenu()" (click)="turnOffSubMenu()"><a [routerLink]="['/board-range']">Board Range</a></li>
                <li (click)="toggleSubMenu()" class="has-submenu" >
                    <a class="myClass">Services</a>
                    <ul *ngIf="subMenu" (click)="toggleBurgerMenu()" >
                        <li><a [routerLink]="['/cnc-cutting']">Cutting</a></li>
                        <li><a [routerLink]="['/edge-banding']">Edging</a></li>
                        <li><a [routerLink]="['/cnc-drilling-service']">Drilling</a></li>
                        <li><a [routerLink]="['/other-services']">Others</a></li>
                    </ul>
                </li>
                <li (click)="toggleBurgerMenu()" (click)="turnOffSubMenu()"><a  [routerLink]="['/delivery']">Delivery</a></li>
                <li (click)="toggleBurgerMenu()" (click)="turnOffSubMenu()"><a [routerLink]="['/faq']">FAQ'S</a></li>
                <li (click)="toggleBurgerMenu()" (click)="turnOffSubMenu()"><a [routerLink]="['/contact-us']">Contact us</a></li>
                <li (click)="toggleBurgerMenu()" (click)="turnOffSubMenu()"><a [routerLink]="['/customer/get-quote']">Get a Quote</a></li>
                <!-- <li><a *ngIf="isLoggedIn" [routerLink]="['/my-quote']">My Quote</a></li> -->
            </ul>
        </nav>

    </div><!-- header content -->
</header><!-- header -->
<div *ngIf="isAdmin" class="website-banner">
  <div *ngIf="!hideHeader">
  <div>
      <div class="float-left">
          <a><img style="border: 0px;" name="Root$ctl18$btnLogo" id="ctl18_btnLogo" class="logo"
                  src="../../../../assets/images/Main.Logo.png" alt="Logo"></a>
         
      </div>
      <div class="menu-module office-menu">
          <ng-container *ngFor="let header of headers">
              <span [class]="header.route === activeRoute ? 'selected' : ''">
                  <div class="item">
                      <a [routerLink]="[header.route]">
                          <ng-container *ngIf="header.headerName !== 'Received Quotes'; else receivedQuotesBlock">
                            {{ header.headerName }}
                          </ng-container>
                          <ng-template #receivedQuotesBlock>
                            <span>{{ header.headerName }} ({{expiredAndNeedReview}})</span>
                          </ng-template>
                      </a>
                  </div>
              </span>

        </ng-container>
        <span class="user-profile" *ngIf="isLoggedIn">
          <span class="profile-icon profile-icon-new">
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                  <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"/>
                </svg>
              </span>
          <span class="dropdown">
            <button class="dropdown-btn-admin"> {{this.isImp == 'false'?"Welcome":"Impersonating"}}, {{userDisplayName}}</button>
            <!-- <button *ngIf="this.isImp=='true'" type="button" (click)="endImpFlow()"> cancel</button> -->
            <div class="dropdown-content">
              <!-- <a [routerLink]="['/customer/my-quote',user.userId]">My Quotes</a> -->
              <!-- <a [routerLink]="['/customer/order-list',user.userId]">Order List</a>
              <a  [routerLink]="['/customer/my-account',user.userId]">My Account</a>
              <a [routerLink]="['/customer/invoices',user.userId]">Invoices</a>
              <a [routerLink]="['/customer/contact-us']">Contact US</a> -->
              <hr/>
              <a (click)="onLogout()">Logout</a>
            </div>
          </span>
        </span>
      </div>
  </div>
</div>
</div>
</div>
<ng-template #sessionPopup>
  <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title"><b>{{modalHeader}}</b></h5>
        </div>
        <div class="modal-body">
            <p style="color: black;">{{ modalMessage }}</p>
            </div>
        <div class="modal-footer">
            <button type="button" class="modal-button" (click)="closeModal()">Ok</button>
        </div>
  </div>
</ng-template>
