<div class="choose-your-boards-container">
    <section class="banner-pages banner-bg-ourrange" role="banner">
        <div class="container">
            <div class="col-md-10 col-md-offset-1">
                <div class="banner-pages-text text-left">
                    <h1>Choose your board</h1>
                </div>
            </div>
        </div>
    </section>

    <section id="intro" class="section">
        <div class="container">
            <div class="intro">
                <span class="site-navigation"><a [routerLink]="['/']">Home</a> » Choose Your Boards</span>
            </div>
        </div>
    </section>

    <section id="products-selection-header" class="products-selection-header no-padding">
        <div class="container">
            <div class="row no-gutter">
                <div class="col-lg-12 col-md-12 col-sm-12 ">
                    <h3 id="products-step1" tabindex="-1">Step 1: Select from our range</h3>
                </div>
            </div>
        </div>
    </section>

    <section id="products-selection" class="products-selection no-padding">
        <div class="container">
            <div class="row no-gutter products-selection-area">
                <div class="col-lg-3 col-md-3 col-sm-3 products-selection-filter">
                    <h5>Search by name</h5>
                    <p><a (click)="resetSearch()" id="resetSearchLink"><i class="fa fa-times" aria-hidden="true"></i>
                            Reset search</a></p>
                    <input (keyup.enter)="searchByName()" id="filterText" [(ngModel)]="searchKey" class="form-control" name="text" type="text"
                        placeholder="Type here..." style="margin-left: 10px" value="">
                    <a id="filtersSearchButton" (click)="searchByName()" class="btn btn-search">Search</a>

                    <h5>Filter by properties</h5>
                    <p><a (click)="resetFilters()" id="clearFiltersLink"><i class="fa fa-times" aria-hidden="true"></i>
                            Clear filter</a></p>
                    <div class="tag-filter" style="width: 325px;">
                        <form [formGroup]="form">
                            <div *ngFor="let category of getRootControls()| keyvalue"
                                id="91a9daaa-cc35-4f30-91ae-436149765a6e" class="panel-group">
                                <div class="panel">
                                    <div class="panel-heading">
                                        <h4 class="panel-title"> <a data-toggle="collapse" [href]="'#'+ category.key"
                                                aria-expanded="true" class="collapsed"> <i class="fa fa-angle-right"
                                                    aria-hidden="true"></i>{{ category.key }}</a> </h4>
                                    </div>
                                    <div formGroupName="{{ category.key }}" [id]="category.key"
                                    class="panel-collapse collapse in" aria-expanded="true">
                                    <div *ngFor="let subcategory of getCategoryControls(category) | keyvalue:keyDescOrder"
                                        id="c7317af8-98b4-48ca-9820-f02300756e5c" class="panel-body"><input style="margin-right: 10px;"
                                            [formControlName]="getKey(subcategory.key)" id="VeneeredBoard"
                                            type="checkbox">{{ subcategory.key }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
                <div class="col-lg-9 col-md-9 col-sm-9 products-selection-table">
                    <!-- <div class="row no-gutter"> -->
                    <div *ngFor="let material of totalTags | paginate:{itemsPerPage:itemsPerPage,currentPage:currentPage}" class="col-lg-4 col-md-4 col-sm-4 products-list-item">
                        <div [id]="material['code']" (click)="navigateTo(material['code'])"
                        rel="gallery_97e62ce3-09d3-41ee-b369-3db58797fe0b" class="imgGrid gallery-box"
                            [title]="'Board Displayed:' + material['code']"
                            data-fancybox-group="thumb_97e62ce3-09d3-41ee-b369-3db58797fe0b"> <img
                                *ngIf="checkCondition(material)" [src]="getHref(material)" alt="image"
                                class="img-responsive"><img *ngIf="!checkCondition(material)"
                                src="../../../assets/images/alternate_board.jpeg" alt="image" class="img-responsive">
                        </div>/
                        <div class="products-list-item-title">
                            <h2 class="break-title" >{{materials[material['code']]['description']}}</h2>
                        </div>
                        <div data-item="12mmFireResistantMediteMDFClassB" id="97e62ce3-09d3-41ee-b369-3db58797fe0b"
                            class="products-list-item-action"> <span class="seeDetails"><a
                                    (click)="navigateTo(material['code'])"
                                    class="seeDetailsLink"><i class="fa fa-search" aria-hidden="true"></i> See
                                    details</a></span> </div>
                                    <!-- <div data-item="12mmFireResistantMediteMDFClassB" id="97e62ce3-09d3-41ee-b369-3db58797fe0b" class="products-list-item-action">
                                        <span class="seeDetails">
                                            <a href="./choose-your-boards/{{ material['code'] }}" (click)="navigateTo(material['code'])" class="seeDetailsLink">
                                                <i class="fa fa-search" aria-hidden="true"></i> See details
                                            </a>
                                        </span>
                                    </div> -->
                                    
                                    
                    </div>                    
                </div>
                <!-- <div class="loadMoreButton">
                    <button *ngIf="displayedTags.length < totalTags.length" (click)="loadMoreItems(false)" class="btn btn-primary">
                      Load More
                    </button>
                </div> -->
                <pagination-controls class="paginationCB" (pageChange)="currentPage=$event; scrollUp(); "></pagination-controls>

            </div>
        </div>
    </section>
</div>
<div>
<a href="{{hostUrl}}/choose-your-boards-viewall" (click)="gotoViewAll()"></a>
<button style="opacity: 0;" (click)="gotoViewAll()">View All</button>
</div>