  import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Order, Quote, eStatusO } from 'src/app/models/cut-wrights.model';
import { EndpointApiService } from 'src/app/service/endpoint-api.service';
import * as Papa from 'papaparse';
import * as moment from 'moment-timezone';
import { MetadataService } from 'src/app/service/metadata.service';
@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent {
  quoteF="";
  toDate:any;
  fromDate:any;
  customer ="";
  statusF = "-1"
  selectedDate :string []=[]
  statusMap: Map<string, string[]> = new Map<string, string[]>();
  orders$!:Observable<any>
  orderListForSearch:any;
  filteredOrders:any;
  statusText: any;
  emptySearch: boolean=false;

constructor(private cdr: ChangeDetectorRef,private metadataService: MetadataService,private endpointService:EndpointApiService,private router: Router){
  // this.toDate=null;
  // this.fromDate=null;
  // this.getOrders();
  this.selectedDate = Array(100).fill(null);  
}
ngOnInit(): void {
  this.metadataService.setTitle('default');
  this.metadataService.setDescription('default'); 
  
    //this.populateStatusMap();

    //Fetch All orders
    let reqBody = {
      "quote":"",
      "name":"",
      "createdAfter":null,
      "createdBefore":null,
      "status":-1  
    }
    this.endpointService.getOrders(reqBody).subscribe((data)=>{
      this.orderListForSearch = data.map((order: any) => {
        order.createdOn = moment.utc(order.createdOn).tz('Europe/London').format('YYYY-MM-DDTHH:mm:ss');
        return order;
      });
      // data.forEach((element:any,index:any) => {
      //       //this.fetchArray(element);
      //         this.selectedDate[index] = element.neededBy
      //       });
      

       //To Display initial 10 Orders
      this.searchOrder();
    })
}

searchOrder() {
  // Remove spaces from the search string
  const searchKey = this.quoteF.replace(/\s+/g, '').trim().toLowerCase();
  const statusKey = this.statusF.trim();
  const fromDate = this.fromDate ? new Date(`${this.fromDate}T00:00:00`) : null;

  const toDate = this.toDate ? new Date(this.toDate) : null;

  // Parse statusKey as a number
  const statusNum = parseInt(statusKey, 10);

  if (searchKey || statusNum !== -1 || (fromDate && toDate)) {
    this.filteredOrders = this.orderListForSearch.filter((order: any) => {
      const searchMatches = searchKey && (
        (order.customerMobileNumber?.trim().toLowerCase().includes(searchKey) || false) ||
        (order.customerCompanyName?.trim().toLowerCase().includes(searchKey) || false) ||
        (order.customerName?.trim().toLowerCase().includes(searchKey) || false) ||
        (order.orderReference?.trim().toLowerCase().includes(searchKey) || false) ||
        (order.jobReference?.trim().toLowerCase().includes(searchKey) || false)
      );

      const statusMatches = statusNum === -1 || order.status === statusNum;

      const orderDateStr = order.createdOn.replace('Z', '');  // Remove the 'Z'
      const orderDate = new Date(orderDateStr);
      const dateMatches = (fromDate && toDate) ? (orderDate >= fromDate && orderDate <= new Date(toDate.setHours(23, 59, 59, 999))) : true;

      return (searchKey ? searchMatches : true) && (statusNum !== -1 ? statusMatches : true) && (fromDate && toDate ? dateMatches : true);
    });
  } else {
    this.filteredOrders = this.orderListForSearch.slice(0, 200);
  }

  this.orders$ = of(this.filteredOrders);
  this.emptySearch = this.filteredOrders.length === 0;
}


  // getOrders(){
  //   let fromDate:any;
  //   let toDate:any;
  //   fromDate = (new Date(this.fromDate) as Date)
  //   fromDate.setUTCHours(0, 0, 0, 0)
  //   toDate = (new Date(this.toDate) as Date)
  //   toDate.setUTCHours(23, 59, 59, 999);
  //   let reqBody = {
  //     "quote":this.quoteF,
  //     "name":this.quoteF,
  //     "createdAfter":fromDate.toISOString(),
  //     "createdBefore":toDate.toISOString(),
  //     "status":parseInt(this.statusF)    
  //   }
  //   if(this.fromDate==null){
  //     fromDate=null;
  //     reqBody.createdAfter=null;
     
  //   }
  //   if (this.toDate==null){
  //     toDate=null;
  //     reqBody.createdBefore=null;
  //   }
  //   this.orders$ =this.endpointService.getOrders(reqBody);
  //   this.orders$.subscribe((data) => {
  //     console.log(data)
  //     data.forEach((element:any,index:any) => {
  //       //this.fetchArray(element);
  //       this.selectedDate[index] = element.neededBy
  //     });

  //   })
  // }
  getOrdersCSV(){
    if(!this.fromDate){
      this.fromDate=null
    }
    if(!this.toDate){
      this.toDate=null
    }
    let fromDate = (new Date(this.fromDate) as Date)
    fromDate.setUTCHours(0, 0, 0, 0)
    let toDate = (new Date(this.toDate) as Date)
    toDate.setUTCHours(23, 59, 59, 999);
    let reqBody = {
      "quote":this.quoteF,
      "name":this.quoteF,
      "createdAfter":fromDate.toISOString(),
      "createdBefore":toDate.toISOString(),
      "status":parseInt(this.statusF)    
    }
    this.endpointService.getOrdersCsv(reqBody).subscribe((data: any) => {
      const csvData = Papa.unparse(data, {
        header: true,
        quotes: true,
      });
  
      // Create a Blob
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  
      // Create a download link
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
  
      // Set the file name
      link.download = 'export.csv';
  
      // Append the link to the body
      document.body.appendChild(link);
  
      // Trigger the download
      link.click();
  
      // Clean up
      document.body.removeChild(link);
    });
  }

  populateStatusMap(){
    this.orders$.subscribe((data) => {
      data.forEach((element:any) => {
        this.fetchArray(element);
      });

    })

  }
  navigateToOrderDetails(order:Order) {
    const navigationExtras = {
      state: {
        data:{
          order: order
        }
      }
    };
    this.router.navigate(['/admin/order-details',order.orderId], navigationExtras);
  }

  fetchOrderStatus(quoteId:any){
    this.endpointService.getJobStatus(quoteId).subscribe((data)=>{
    this.statusText=data
    });
  }
  fetchArray(quote: Order) {
    this.statusMap.clear();
    this.endpointService.getJobStatus(quote.quoteId).subscribe((data: any) => {
        if (data && data.Status) {
            const statusArray: string[] = data.Status;
            if (!this.statusMap.has(quote.jobReference)) {
                this.statusMap.set(quote.jobReference, statusArray);
            } else {
                const existingStatusArray = this.statusMap.get(quote.jobReference);
                this.statusMap.set(quote.jobReference, existingStatusArray!.concat(statusArray));
            }
        }
    });
}

  navigateToQuoteDetails(quote:Quote) {
    const navigationExtras = {
      state: {
        data:{
          quote: quote
        }
      }
    };
    this.router.navigate(['/admin/quote-details',quote.quoteId], navigationExtras);
  }
  getStatus(quote: Order) {
    const statusNum: number = quote.status as number;
    if (statusNum === 0) {
        const statusArray = this.statusMap.get(quote.jobReference);
        if (statusArray) {
            // Join the array with '<br>' tags
            return statusArray.join('..');
        } else {
            return '';
        }
    } else {
        return eStatusO[statusNum as keyof typeof eStatusO];
    }
}

  authorize(orderId:string){
    this.endpointService.authorizeOrder(orderId).subscribe(data =>{
      //this.getOrders()
      this.ngOnInit();
    })
  }
  collection(orderId:string){
    this.endpointService.confirmOrder(orderId).subscribe(data =>{
      //this.getOrders()
      this.ngOnInit();
    })
  }
  selectDepot(order:Order,depo:number){
    if(depo != order.depot){
      this.endpointService.updateDepot(order.orderId,{
        "Depot": depo
      }).subscribe(data => {
       // this.getOrders()
       this.ngOnInit();
      })
    }
  }
  setNeededBy(order:Order){
    this.endpointService.updateNeededByDate(order.orderId,{
      "NeededBy": order.neededBy
    }).subscribe(data => {
      //this.getOrders()
      this.ngOnInit();
    })
  }
  cancelOrder(order:Order){
    this.endpointService.orderCancel(order.orderId).subscribe(data => {
     // this.getOrders()
     this.ngOnInit();
    })
  }
  getAmount(totalinvoiceamount:string){
    return parseFloat(totalinvoiceamount).toFixed(2);
  }
  truncateValue(value: number): string {
    return this.endpointService.truncateDecimal(value);
  }
}
