<div class="transport-container">
   <form [formGroup]="myForm">
      <h2>Deliveries & Collections</h2>
      <div class="search">
         <span class="search-body">
            <div class="item">
               <div class="label">
                  Find:
               </div>
               <div class="input">
                  <input formControlName="searchKey" placeholder="Click or tap here to enter text.."
                     name="Root$Main$ctl00$txtKeywordSearch" type="text" id="Main_ctl00_txtKeywordSearch"
                     class="txt textbox" (keyup.enter)="searchTransport()">
               </div>
            </div>
            <div class="item">
               <div class="label">
                  Completed:
               </div>
               <div class="input">
                  <select formControlName="isCollected" name="Root$Main$ctl00$lstIsDelivered"
                     id="Main_ctl00_lstIsDelivered" (keyup.enter)="searchTransport()">
                     <option [value]="true">Yes</option>
                     <option selected="selected" [value]="false">No</option>
                  </select>
               </div>
            </div>

            <div class="item">
               <div class="label">
                  Delivery / Collections:
               </div>
               <div class="input">
                  <select formControlName="isCollection" name="Root$Main$ctl00$lstIsDelivered"
                     id="Main_ctl00_lstIsDelivered" (keyup.enter)="searchTransport()">
                     <option selected value="-1">--Select--</option>
                     <option [value]="false">Delivery</option>
                     <option [value]="true">Collection</option>
                  </select>
               </div>
            </div>

            <div class="item">
               <div class="label">
                  Depot:
               </div>
               <div class="input">
                  <select formControlName="depo" name="Root$Main$ctl00$lstIsDelivered" id="Main_ctl00_lstIsDelivered" (keyup.enter)="searchTransport()">
                     <option [value]="-1" selected value="">--Select--</option>
                     <option [value]="0">Hanworth (LHR)</option>
                     <option [value]="1">Poole (BOH)</option>
                  </select>
               </div>
            </div>

            <div class="item">
               <div class="label">
                  Date:
               </div>
               <div class="input">
                  <input formControlName="createdOn" type="date" />
               </div>
            </div>
            <div class="buttons">
               <input  (click)="searchTransport()" type="submit" class="search-btn"
                  name="Root$Main$ctl00$btnSearch" value="Search" id="Main_ctl00_btnSearch">
                  <input  (click)="reset()" type="submit"
                  name="Root$Main$ctl00$btnSearch" value="Reset" id="Main_ctl00_btnSearch">
            </div>
         </span>
      </div>
   </form>
      <div class="loader" *ngIf="isLoading">
          <app-loader></app-loader>
      </div>
   <div class="list" *ngIf='!isLoading'>
      <div>
         <table class="grid" cellspacing="0" currentsort="" id="Main_ctl00_gridList" style="border-collapse:collapse;">
            <tbody>
               <tr class="header-row">
                  <th scope="col" *ngFor="let header of headers">{{header}}</th>
               </tr>
               <ng-container *ngFor="let order of orders$ | async">
                  <!-- "" -->
                  <tr [class]="!validateDate(order.CollectionNotePrintedOn) ? '' : 'out-of-date'" *ngFor="let drop of order.drops; let i = index"> 
                     <td>
                        {{order.neededBy}}
                     </td>
                     <td>
                        <!-- {{_formattedDeliveryWindow[drop.deliverywindow]}} -->
                        <div *ngIf="(!drop.dropcompleted) && (!order.collection)" class="btn-group" dropdown>
                           <button dropdownToggle type="button" class="btn btn-primary">
                              {{_formattedDeliveryWindow[drop.deliverywindow]}}
                           </button>
                           <ul *dropdownMenu class="dropdown-menu" role="menu">
                             <li *ngFor="let key of deliveryWindowKeys" role="menuitem">
                               <a class="dropdown-item" (click)="selectDeliveryWindow(order,i,key)">{{ _formattedDeliveryWindow[key] }}</a>
                             </li>
                           </ul>
                         </div>
                         <p *ngIf="order.collection">N/A</p>
                         <p *ngIf="(!order.collection) && (drop.dropcompleted)">{{_formattedDeliveryWindow[drop.deliverywindow]}}</p>
                     </td>
                     <td>
                        {{order.customerName}}
                     </td>
                     <!-- <td>
                    <a id="Main_ctl00_gridList_btnJobReference_0">Q245680</a>
                 </td> -->
                     <td>
                        {{order.customerCompanyName}}
                     </td>
                     <td>
                        {{order.customerMobileNumber}}
                     </td>
                     <td>
                        {{order.jobReference}}
                     </td>
                     <td>
                        {{order.name}}
                     </td>
                     <td>
                        {{order.collection? 'Collection':'Delivery'}}
                     </td>
                     <td>
                        {{order.depot == 0 ? 'LHR - Hanworth' : 'BOH - Poole'}}
                     </td>
                     <td>
                        <input type="checkbox" (change)="updateDropConfirmation($event,order,i)" [checked]="drop.confirmed"/>
                     </td>
                     <td>
                        <input (click)="openCollectionNotes(order.orderId)" type="image" name="Root$Main$ctl00$gridList$ctl02$btnPrintCollectionNote"
                           id="Main_ctl00_gridList_btnPrintCollectionNote_0" src="../../../assets/images/print.jpg"
                           alt="Print collection note">
                           <p>{{validateDate(order.CollectionNotePrintedOn) ? "":"printed on:" + (order.CollectionNotePrintedOn| date: 'dd/MM/yy')}}</p>
                     </td>
                     <!-- (click)="openEditPopup(collected) -->
                     <td>
                        <a *ngIf="!drop.dropcompleted" id="Main_ctl00_gridList_btnMarkAsCollected_0" (click)="openEditPopup(order,i,collected)"
                           href="javascript:">Mark as completed</a>
                     </td>
                  </tr>
               </ng-container>
            </tbody>
         </table>
      </div>
   </div>
</div>

<!-- <ng-template #collected>
   <div class="collect-modal">
      <h2>Collection receipt details</h2>
      <div>
         <div class="form">
         </div>
         <span class="form-body">
            <div class="item">
               <span class="item-body">
                  <div class="label">
                     <label for="Main_ctl00_txtCollectedBy" id="Main_ctl00_lblCollectedBy">
                        Collected by:
                     </label>
                  </div>
                  <div class="input">
                     <input name="Root$Main$ctl00$txtCollectedBy" type="text" maxlength="200"
                        id="Main_ctl00_txtCollectedBy" class="textbox textbox">
                     <span id="Main_ctl00_rqvCollectedBy" style="visibility:hidden;">*</span>
                  </div>
               </span>
            </div>
            <div class="item">
               <span class="item-body">
                  <div class="label">
                     <label for="Main_ctl00_fuSignature" id="Main_ctl00_lblSignature">
                        Signature:
                     </label>
                  </div>
                  <div class="input">
                     <input type="file" name="Root$Main$ctl00$fuSignature" id="Main_ctl00_fuSignature">
                  </div>
               </span>
            </div> 
            <div class="item">
               <span class="item-body">
                  <div class="label">
                     <label for="Main_ctl00_txtNotes" id="Main_ctl00_lblNotes">
                        Notes:
                     </label>
                  </div>
                  <div class="input">
                     <textarea name="Root$Main$ctl00$txtNotes" rows="5" cols="20" id="Main_ctl00_txtNotes"
                        class="textbox"></textarea>
                  </div>
               </span>
            </div>
         </span>
         <div class="buttons">
            <input type="submit" (click)="closeModal()" style="margin-right: 5px;" name="Root$Main$ctl00$btnCancel"
               value="Cancel" id="Main_ctl00_btnCancel">
            <input type="submit" name="Root$Main$ctl00$btnSave" (click)="markAsCollected(order)" value="Save" id="Main_ctl00_btnSave">
         </div>
      </div>
   </div>
</ng-template> -->
<ng-template #collected>
   <div class="collect-modal">
     <h2>{{selectedOrder.collection ? 'Collection' : 'Delivery'}} receipt details</h2>
     <div>
       <div class="form">
         <form [formGroup]="collectionForm" (ngSubmit)="markAsCollected()">
           <div class="item">
             <span class="item-body">
               <div class="label">
                 <label for="collectedBy">{{selectedOrder.collection ? 'Collected' : 'Delivered'}} by:</label>
               </div>
               <div class="input">
                 <input  formControlName="collectedBy" type="text" id="collectedBy" class="textbox fixed-width">
                 <span style="visibility:hidden;">*</span>
               </div>
             </span>
           </div>
           <div class="item">
             <span class="item-body">
               <div class="label">
                 <label for="notes">Notes:</label>
               </div>
               <div class="input">
                 <textarea formControlName="notes" rows="5" cols="20" id="notes" class="textbox fixed-width"></textarea>
               </div>
             </span>
           </div>
           <div class="buttons">
             <input type="button" (click)="closeModal()" style="margin-right: 5px;" value="Cancel">
             <input type="submit" [disabled]="collectionForm.invalid" value="Save">
           </div>
         </form>
       </div>
     </div>
   </div>
 </ng-template>