import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { EndpointApiService } from 'src/app/service/endpoint-api.service';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-add-plain-drawer-modal',
  templateUrl: './add-plain-drawer-modal.component.html',
  styleUrls: ['./add-plain-drawer-modal.component.scss']
})
export class AddPlainDrawerModalComponent implements OnInit {
  @Input() iformGroup! : FormGroup
  @Input() edit! : boolean
  @Input() close!:() => void
  @Input() shakers! : FormArray
  @Output() onPlainModalClose: EventEmitter<number> = new EventEmitter();
imageSrc="";
  constructor(private metadataService: MetadataService,private _eService:EndpointApiService){
    
  }
  
  closePlainPopup(): void {
    this.onPlainModalClose.emit();
    if(this.iformGroup.valid){
   this.close()
   this.imageSrc = ""
    }
   
  }

  ngOnInit(): void {
    this.metadataService.setTitle('default');
    this.metadataService.setDescription('default');
    this.iformGroup.valueChanges.subscribe(change =>{
      this.image_handler();
    });
    this.image_handler()
  }
  image_handler():void{
    if(this.iformGroup.get('width')?.valid && this.iformGroup.get('height')?.valid){
      let value = this.iformGroup.value;
    let midrails=""
    let hingeHoles = ""
    if(value.type == 0){
      midrails = value.midrails?.reduce(((val:any,cVal:any) => {return val + cVal.position + ","}),"") as string
      hingeHoles = value.hinges?.reduce(((val:any,cVal:any) => {return val + cVal.y + ","}),"") as string
      if (midrails.endsWith(',')) {
        midrails = midrails.slice(0, -1); // Remove the last character
      }
      if (hingeHoles.endsWith(',')) {
        hingeHoles = hingeHoles.slice(0, -1); // Remove the last character
      }
    }
    let url = this._eService.generateImageUrl(
      '22mmMoistureResistantMediteMDF',  // materialCode
      18,                               // thickness
      true,                               // showHandle
      value.type as number,               // type
      true,                               // hasGrain
      value.hingeside == "0" ? 'Left' : 'Right',  // hingeSide
      true,                               // rotate90Degrees
      midrails,                            // midrails
      hingeHoles,                             // hingeHoles
      value.drillingdistance as number,              // drillingDistance
      // value.blumInserta as boolean,                  // blumInserta
      value.useinsertahinges as boolean,             //blumInserta New variable
      parseInt(value.height as string),             // length
      parseInt(value.width as string),              // width

    );
    this._eService.fetchImage(url).subscribe(data =>{
      const uint8Array = new Uint8Array(data);
      const base64String = btoa(String.fromCharCode.apply(null, Array.from(uint8Array)));
      this.imageSrc = `data:image/png;base64,${base64String}`;
    })
    }
  }
  saveAndClose(): void {
    if(this.iformGroup.valid){
      this.onPlainModalClose.emit();
      if(!this.edit){
        (this.shakers).push(this.iformGroup)
      }
      this.close()
      this.imageSrc = "";
      this.onPlainModalClose.emit();


    }
  }
  checkForMinError(name:string,index:number){
    let control = this.iformGroup.get(name);
    return control?.hasError('min')
  }
  checkForMaxError(name:string,index:number){
    let control = this.iformGroup.get(name)
      return control?.hasError('max')
  }
  preventDecimal(event: KeyboardEvent) {
    // Check if the pressed key is a decimal point (.)
    if (event.key === '.' || event.key === ',') {
      event.preventDefault(); // Prevent entering the decimal point
    }
  }
  
}
