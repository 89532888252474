<div style="margin-top: 15px;">
    <h2>Materials</h2>
    <div id="Main_ctl00_upList" isupdatepanel="true">

        <div class="list">
            <div>
                <table class="grid" cellspacing="0" currentsort="" id="Main_ctl00_gridList"
                    style="border-collapse:collapse;">
                    <tbody>
                        <tr class="header-row">
                            <th scope="col"><a
                                    >Supply
                                    Code</a></th>
                            <th scope="col"><a
                                    >Supply</a>
                            </th>
                            <th scope="col">&nbsp;</th>
                            <th scope="col"><a
                                    >P/O
                                    Number</a></th>
                            <th scope="col"><a
                                    >Location</a>
                            </th>
                            <th scope="col"><a
                                   >Origin</a>
                            </th>
                        </tr>
                        <tr *ngFor="let part of quote.parts">
                            <td>
                                {{part.materialCode}}
                            </td>
                            <td>
                                {{part.materialDescription}}
                            </td>
                            <td>
                                <a id="Main_ctl00_gridList_btnReportAProblem_0"
                                    onclick="OpenModal(&quot;/Order/Materials/Describe-a-problem.aspx?.order=cc634b51-d2f0-46ea-af63-05db9503fded&amp;id=aa7a6c8d-36b4-4e95-a0af-b4a817bc92f5&amp;IsModal=False&amp;supplyitem=34a5c7e6-cdea-4e52-9f67-d64fff03e00f&quot;);"
                                    href="javascript:">Report a problem</a>
                            </td>
                            <td>
                                <a id="Main_ctl00_gridList_btnPO_0"
                                    href="/Office/Purchase-Orders/Incomplete-purchase-orders/Purchase-order-details.aspx?purchaseorder="></a>
                            </td>
                            <td>
                                Assigned
                            </td>
                            <td>
                                Stock update
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <input type="hidden" name="Root$Main$ctl00$SelectedListItemIdHolder"
            id="Main_ctl00_SelectedListItemIdHolder">
    </div>
    <!-- «End| List: SupplyItem» -->
</div>
<div>
    <h2>Edgings</h2>
    <div id="Main_ctl01_upList" isupdatepanel="true">

        <div class="list">
            <div>
                <table class="grid" cellspacing="0" currentsort="" id="Main_ctl01_gridList"
                    style="border-collapse:collapse;">
                    <tbody>
                        <tr class="header-row">
                            <th scope="col"><a
                                    >Edging
                                    Code</a></th>
                            <th scope="col"><a
                                   >Edging</a>
                            </th>
                            <th scope="col"><a
                                   >Quantity</a>
                            </th>
                            <th scope="col"><a
                                   >Location</a>
                            </th>
                        </tr>
                        <tr  *ngFor="let edge of quote.edges ">
                            <td>
                                {{edge.code}}
                            </td>
                            <td>
                                {{edge.description}}
                            </td>
                            <td>
                                {{edge.quantity}}
                            </td>
                            <td>
                                In progress
                            </td>
                        </tr>
                        <!-- <tr class="alt">
                            <td>
                                U961ST204
                            </td>
                            <td>
                                0.4mm Graphite Grey U961 ST2 Radius ***DISCONTINUED***
                            </td>
                            <td>
                                2.20
                            </td>
                            <td>
                                To be bought
                            </td>
                        </tr>
                        <tr>
                            <td>
                                U961ST22
                            </td>
                            <td>
                                2.0mm Graphite Grey U961 ST2 Radius
                            </td>
                            <td>
                                1.20
                            </td>
                            <td>
                                Assigned
                            </td>
                        </tr>
                        <tr class="alt">
                            <td>
                                U961ST208
                            </td>
                            <td>
                                0.8mm Graphite Grey U961 ST2 Radius
                            </td>
                            <td>
                                1.00
                            </td>
                            <td>
                                Assigned
                            </td>
                        </tr> -->
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
