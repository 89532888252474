import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-payment-confirm',
  templateUrl: './payment-confirm.component.html',
  styleUrls: ['./payment-confirm.component.scss']
})
export class PaymentConfirmComponent {
  user;
  constructor(private metadataService: MetadataService,private router:Router){
    this.user = JSON.parse(localStorage.getItem('user') as string)
  }
  ngOnInit(): void {
    this.metadataService.setTitle('default');
    this.metadataService.setDescription('default');
  }
  getLink(){
    this.router.navigate([`/customer/my-quote/${this.user.userId}`])
  }
}
