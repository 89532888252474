<div class="main-body">
    <div class="content-area">
        <div class="content-area-content ">
                <h2 class="mb-4">Confirmation of order placement</h2>
                <span class="view-body">
                    <div class="item">
                        <div class="value">
    <p>
        Now that you have placed your order please read below to see what happens next ..............
    </p>

    <p>
        The delivery or collection date you have selected is shown in your online account under 'Orders List' and the
        'Needed By' column, unless you have agreed an alternative with the office, in writing.
    </p>

    <p>
        Please note that <u>collections might not be ready to collect first thing on the selected date</u> - please make sure
        you wait for your text notification for confirmation that your order is ready.
    </p>

    <p>
    <u>If you have chosen delivery, please note that we will require helping hand(s) on site</u> to assist our driver in
        offloading the panels from the vehicle, we will help take the panels to the ground floor entrance and aim to be
        away within 20 minutes. Our transport team will call to confirm the time slot one working day prior to the
        agreed delivery date.
    </p>

    <p>
        <b>Please make sure that your billing address is correct in the 'My Account' tab for invoicing purposes.</b> Your
        invoice will be available online once your order has been completed.
    </p>

    <p>
        Many thanks
    </p>

    <p>
        From the Cutwrights Team
    </p>
    </div>
    </div>
    </span>
    <div class="buttons">
        <input type="submit" name="Root$Main$ctl00$btnReturn" (click)="getLink()" value="Return to Quotes"
            id="Main_ctl00_btnReturn">
    </div>
    </div>
    </div>

</div>