import { Component } from '@angular/core';
import { EndpointApiService } from 'src/app/service/endpoint-api.service';
import { AlertService } from 'src/app/shared/alert-set/alert.service';

@Component({
  selector: 'app-credit-limit',
  templateUrl: './credit-limit.component.html',
  styleUrls: ['./credit-limit.component.scss']
})
export class CreditLimitComponent {  
  user:any={}
  paymentType=this.user.customerType;
  limit:any
  outstandingbalance:any
  payment:any
  userPayment:any
  turnOver =this.user.turnover
  constructor(private _epService: EndpointApiService,private alertService: AlertService){    
  }

  ngOnInit(){
    var token = localStorage.getItem("token")
    const userString = localStorage.getItem('user');
 
    if(token){      
      this.user = JSON.parse(userString as string)      
    }
    this._epService.getCustomerOutstandingBalance(this.user.userId).subscribe({
      next: (data:any) => {
        this.outstandingbalance=data.outStandingBalance.toFixed(2)
      }
    });
      this.paymentType=this.user.customerType;
      this.limit=this.user.creditlimit  
      this.turnOver =this.user.turnover
      //delete this.user.Id ;
  }
  saveChanges(){
    this.user.turnover = this.turnOver
    this.user.creditlimit = this.limit
    this.user.customerType = this.paymentType
    this.user.isLoggedIn = true;
    this._epService.updateUser(this.user).subscribe({
    next: (data) => {      
      localStorage.setItem('user', JSON.stringify(this.user));
      this.alertService.showCLSuccess(true);
    },
    error: (error) => {      
     
      this.alertService.showCLDecline(true);
    }
  });
  }
}
