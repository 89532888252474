<div style="margin-top: 15px;">
    <h2>Boards Order</h2>
    <div id="Main_ctl00_upList" isupdatepanel="true">
        <div class="list">
            <div>
                <table class="grid" cellspacing="0" currentsort="" id="Main_ctl00_gridList"
                    style="border-collapse:collapse;">
                    <tbody>
                        <tr class="header-row">
                            <th scope="col"><a
                                   >Line
                                    number</a></th>
                            <th scope="col">Material</th>
                            <th scope="col"><a >L</a>
                            </th>
                            <th scope="col"><a>W</a>
                            </th>
                            <th scope="col"><a
                                   >Quantity</a>
                            </th>
                            <th scope="col"><a
                                    >L1</a></th>
                            <th scope="col"><a
                                    >L2</a></th>
                            <th scope="col"><a
                                   >W1</a></th>
                            <th scope="col"><a
                                  >W2</a></th>
                            <th scope="col"><a
                                   >Operations</a>
                            </th>
                            <th scope="col"><a
                                  >Name</a></th>
                            <th scope="col"><a
                                  >Needs
                                    priming</a></th>
                        </tr>
                        <tr *ngFor="let part of quote.parts; let i = index">
                            <td>
                                {{i+1}}
                            </td>
                            <td>
                                {{part.materialDescription}}
                            </td>
                            <td>
                                {{part.length}}
                            </td>
                            <td>
                                {{part.width}}
                            </td>
                            <td>
                                {{part.quantity}}
                            </td>
                            <td>
                                {{part.l1Description}}
                            </td>
                            <td>
                                {{part.l2Description}}
                            </td>
                            <td>
                                {{part.w1Description}}
                            </td>
                            <td>
                                {{part.w2Description}}
                            </td>
                            <td>
                                <a id="Main_ctl00_gridList_btnViewDrillingSummary_0"
                                    onclick="OpenModal(&quot;/Quote/Parts/View-operations.aspx?.Quote=cee068bf-e675-4099-97ab-d13a2cbfed1c&amp;.LineNumber=1#&quot;, null, 900,900);"
                                    href="javascript:"></a>
                            </td>
                            <td>
                                {{part.partComment}}
                            </td>
                            <td>
                                {{part.primed?'Yes':'No'}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <input type="hidden" name="Root$Main$ctl00$SelectedListItemIdHolder" id="Main_ctl00_SelectedListItemIdHolder">
    </div>
    <!-- «End| List: Part - Order» -->

    <!-- «Start| List: Part - Order» -->
    <h2>Shaker Door Order</h2>
    <div id="Main_ctl01_upList" isupdatepanel="true">

        <div class="list">
            <div>
                <table class="grid" cellspacing="0" currentsort="" id="Main_ctl01_gridList"
                    style="border-collapse:collapse;">
                    <tbody>
                        <tr class="header-row">
                            <th scope="col"><a
                                   >Line
                                    number</a></th>
                            <th scope="col">Material</th>
                            <th scope="col"><a>H</a>
                            </th>
                            <th scope="col"><a >W</a>
                            </th>
                            <th scope="col"><a
                                    >Quantity</a>
                            </th>
                            <th scope="col"><a
                                  >Operations</a>
                            </th>
                            <th scope="col"><a
                                   >Name</a></th>
                            <th scope="col"><a
                                  >Needs
                                    priming</a></th>
                        </tr>
                        <tr *ngFor="let shaker of quote.shakers;let i=index">
                            <td>
                                {{i+1}}
                            </td>
                            <td>
                                {{getType(shaker.type)}}
                            </td>
                            <td>
                                {{shaker.height}}
                            </td>
                            <td>
                                {{shaker.width}}
                            </td>
                            <td>
                                {{shaker.quantity}}
                            </td>
                            <td>
                                <a *ngIf="shaker.totalnumberofhingeholes > 0 || shaker.totalnumberofmidrails" href="javascript: void(0)"  (click)="openPopUp(quote,i)">{{getLinkName(shaker)}}</a>
                            </td>
                            <td>
                                {{shaker.name}}
                            </td>
                            <td>
                                {{shaker.primed?'Yes':'No'}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <input type="hidden" name="Root$Main$ctl01$SelectedListItemIdHolder" id="Main_ctl01_SelectedListItemIdHolder">
    </div>
    <!-- «End| List: Part - Order» -->
</div>
<ng-template #template>
    <div class="door-modal-popup">
        <button class="close-btn" (click)="close()">x</button>
        <form class="add-a-door" [formGroup]="this.sformGroup">
            <div class="row">
                <div class="col-sm-12">
                    <h1>Add a door</h1>
                </div>

                <div class="col-sm-6">
                    <div class="row mb-3">
                        <div class="col-sm-6">
                            Door name:
                        </div>
                        <div class="col-sm-6">
                            <input type="text" formControlName="name" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-6">
                            Height:
                        </div>
                        <div class="col-sm-6">
                            <input type="number" formControlName="height" value="30" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-6">
                            Width:
                        </div>
                        <div class="col-sm-6">
                            <input type="number" formControlName="width" value="40" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-6">
                            Quantity:
                        </div>
                        <div class="col-sm-6">
                            <input type="number" formControlName="quantity" value="5" />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-6">
                            Needs priming:
                        </div>
                        <div class="col-sm-6">
                            <input type="checkbox" formControlName="primed" />
                        </div>
                    </div>

                    <div class="row mb-3 mt-3">
                        <div class="col-sm-12">
                            <h1>Mid rails</h1>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-6">
                            Number of mid rails:
                        </div>
                        <div class="col-sm-6">
                            <select formControlName="totalnumberofmidrails">
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                            </select>
                        </div>
                    </div>

                    <div formArrayName="midrails">
                        <div *ngFor="let midrail of getMidrailControls(this.sformGroup); let i = index"
                            class="row mb-3">
                            <div class="col-sm-6">
                                Mid rail {{i+1}} Position:
                            </div>
                            <div [formGroupName]="i" class="col-sm-6">
                                <input type="number" formControlName="position" />
                            </div>
                        </div>
                    </div>

                    <div class="row mb-3 mt-3">
                        <div class="col-sm-12">
                            <h1>Hinges</h1>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-6">
                            Drill holes for 35mm cup hinges:
                        </div>
                        <div class="col-sm-6">
                            <input class="checkbox-shaker" type="checkbox" formControlName="hingeholes35mm" />
                        </div>
                    </div>
                    <div [hidden]="sformGroup.get('hingeholes35mm')?.value == false">
                        <div class="row mb-3">
                            <div class="col-sm-6">
                                Use Blum Inserta hinges:
                            </div>
                            <div class="col-sm-6">
                                <input class="checkbox-shaker" type="checkbox" formControlName="useinsertahinges" />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-6">
                                Hinge side:
                            </div>
                            <div class="col-sm-6">
                                <input class="checkbox-shaker" type="radio" [value]="0" formControlName="hingeside" />
                                Left
                                <input class="checkbox-shaker" type="radio" [value]="1" formControlName="hingeside" />
                                Right
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-6">
                                Number of hinges:
                            </div>
                            <div class="col-sm-6">
                                <select formControlName="totalnumberofhingeholes">
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                </select>
                            </div>
                        </div>
                        <div formArrayName="hinges">
                            <div *ngFor="let hinge of getHingeControls(this.sformGroup); let i = index"
                                class="row mb-3">
                                <div class="col-sm-6">
                                    Hinge {{i+1}} Y:
                                </div>

                                <div [formGroupName]="i" class="col-sm-6">
                                    <input type="number" formControlName="y" />
                                </div>
                            </div>

                        </div>
                        <!-- <div class="row mb-3">
               <div class="col-sm-6">
                 Hinge 2 Y:
               </div>
               <div class="col-sm-6">
                 <input type="text" formControlName="hinges[1]" />
               </div>
             </div> -->
                        <div class="row mb-3">
                            <div class="col-sm-6">
                                Drilling distance:
                            </div>
                            <div class="col-sm-6">
                                <input type="text" formControlName="drillingdistance" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6">
                    <img [src]="imageSrc" />
                </div>

                <div class="com-sm-12 text-center mt-4">
                    <input type="button" value="Save and close" (click)="close()" />
                </div>
            </div>
        </form>

    </div>
</ng-template>