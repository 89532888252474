import { Component } from '@angular/core';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-board-range',
  templateUrl: './board-range.component.html',
  styleUrls: ['./board-range.component.scss']
})
export class BoardRangeComponent {
  
  constructor(private metadataService: MetadataService){ }

  ngOnInit(): void {
    this.metadataService.setCanonicalLink();
    this.metadataService.setTitle('Board Range - MDF, High Gloss Acrylic, MFC MDF, MF MDF, Plywood, Chipboard & Veneered - Cutwrights');
    this.metadataService.setDescription('Our board offering is explained briefly below to help with your decision making including MDF, Gloss Acrylic, Veneered, Chipboard and Plywood materials and finishes.');
  }


}
