import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, of } from 'rxjs';
import { Order, formattedDeliveryWindow } from 'src/app/models/cut-wrights.model';
import { EndpointApiService } from 'src/app/service/endpoint-api.service';
import { MetadataService } from 'src/app/service/metadata.service';
@Component({
  selector: 'app-transport',
  templateUrl: './transport.component.html',
  styleUrls: ['./transport.component.scss']
})
export class TransportComponent {
  bsModalRef?: BsModalRef;
  //orders! : Order[]
  orders$!:Observable<Order[]>;
  myForm!: FormGroup;
  collectionForm:FormGroup
  isLoading = false
  ordersI!:any;
  selectedOrder!:Order;
  selectedDropIndex!:number;
  transportListForSearch:any;
  filteredTransport:any
  _formattedDeliveryWindow = formattedDeliveryWindow
  deliveryWindowKeys = Object.keys(formattedDeliveryWindow).map(Number);
  emptySearch: boolean=false;
  
  constructor(private metadataService: MetadataService,private bsModalService: BsModalService, private epService: EndpointApiService,private formBuilder: FormBuilder, private router:Router) {
      this.myForm = this.formBuilder.group({
        searchKey: [''],
        createdOn: [],
        isCollection: ["-1"],
        isCollected: [false],
        depo: ["-1"]
      });
      this.collectionForm = this.formBuilder.group({
        collectedBy: ['', Validators.required],
        notes: ['']
      });
    
      // this.getTransportationOrdersI()
  }
  headers: string[] = ['Needed by Date', 'Delivery Window', 'Name', 'Company Name', 'Phone Number', 'Order Ref', 'Job Name', 'Collection / Delivery', 'Depot', 'Confirmed', 'Print Delivery / Collection Note', 'Mark Collected / Delivered'];
  
  ngOnInit(): void {
    this.metadataService.setTitle('default');
    this.metadataService.setDescription('default');
    
    //Fetch all orders
    this.isLoading = true;
    let value = this.myForm.value
    let reqBody ={
      "searchKey": value.searchKey,
      "createdOn": "0001-01-01T00:00:00Z",
      "isCollection": value.isCollection == "true"? true:false,
      "isCollected": value.isCollected== "true"? true:false,
      "depo": parseInt(value.depo)
    }
    this.epService.getTransportationOrdersI(reqBody).subscribe((data:any) => {
      //this.orders$=data;
      this.transportListForSearch=data;
      this.searchTransport();
      this.isLoading = false;
    })
  }

  searchTransport() {
    let value = this.myForm.value
    // Remove spaces from the search string
    const searchKey = value?.searchKey.replace(/\s+/g, '').trim().toLowerCase();
    const createdOn = value.createdOn ? new Date(value.createdOn) : null;
    const isCollection = value?.isCollection === "true";
    const isDelivery = value?.isCollection === "false";
    const isCompleted = value?.isCollected === "true";
    const depoKey = value?.depo.trim();

    
    // Parse depoKey as a number
    const depoNum = parseInt(depoKey, 10);
    
    if (searchKey || createdOn || depoKey!=-1 || isCollection !== undefined) {
      this.filteredTransport = this.transportListForSearch.filter((transport: any) => {
        const searchMatches = searchKey && (
          (transport.customerCompanyName?.trim().toLowerCase().includes(searchKey) || false) ||
          (transport.customerEmail?.trim().toLowerCase().includes(searchKey) || false) ||
          (transport.customerMobileNumber?.trim().toLowerCase().includes(searchKey) || false) ||
          (transport.customerName?.trim().toLowerCase().includes(searchKey) || false) ||
          (transport.invoiceReference?.trim().toLowerCase().includes(searchKey) || false) ||
          (transport.jobReference?.trim().toLowerCase().includes(searchKey) || false) ||
          (transport.name?.trim().toLowerCase().includes(searchKey) || false) ||
          (transport.orderReference?.trim().toLowerCase().includes(searchKey) || false)
        );
  
        const depoMatches = depoKey && depoNum === transport.depot;
  
        const collectionMatches = isCollection === transport.collection
        const deliveryMatches= isDelivery !== transport.collection;
        const confirmedMatches = isCompleted === transport.isCompleted;
  
        const transportDate = new Date(transport.createdOn);
        const dateMatches = createdOn ? (
          transportDate.getFullYear() === createdOn.getFullYear() &&
          transportDate.getMonth() === createdOn.getMonth() &&
          transportDate.getDate() === createdOn.getDate()
        ) : true;

        if(isCollection==true){
          return (searchKey ? searchMatches : true) &&
          (depoKey!=-1 ? depoMatches : true) &&
          (isCollection == true ? collectionMatches : true) &&
          //(isDelivery == true ? deliveryMatches : true) &&
           (isCompleted !== undefined ? confirmedMatches : true) &&
           dateMatches;

        }
        if (isCollection==false){
          return (searchKey ? searchMatches : true) &&
          (depoKey!=-1 ? depoMatches : true) &&
          (isDelivery ==true ? deliveryMatches : true)&&
          //(isDelivery == true ? deliveryMatches : true) &&
           (isCompleted !== undefined ? confirmedMatches : true) &&
           dateMatches;

        }
  
      });
    } else {
      this.filteredTransport = this.transportListForSearch.slice(0, 200); 
    }
    this.orders$ =  of(this.filteredTransport);
    this.emptySearch = this.filteredTransport.length === 0;
  }
  
  

  openEditPopup(order:Order,dropIndex:number,templateRef?: any, data = null): void {
    this.selectedOrder = order
    this.selectedDropIndex = dropIndex
    this.bsModalRef = this.bsModalService.show(templateRef, { class: 'transport-modal' });
  }

  closeModal(): void {
    this.bsModalRef?.hide();
  }
  // getTransportationOrders(){
  //   this.isLoading = true;
  //   let value = this.myForm.value
  //   let reqBody ={
  //     "searchKey": value.searchKey,
  //     "createdOn": value.createdOn,
  //     "isCollection": value.isCollection == "true"? true:false,
  //     "isCollected": value.isCollected== "true"? true:false,
  //     "depo": parseInt(value.depo)
  //   }
  //   this.epService.getTransportationOrders(reqBody).subscribe(data => {
  //     this.orders = data;
  //     this.isLoading = false;
  //   })
  // }
  validateDate(date :string){
   return date == "0001-01-01T00:00:00Z"
  }
  // getTransportationOrdersI(){
    
  // }
  markAsCollected(){
    let value = this.collectionForm.value
    this.filteredTransport.find((transport:any)=>{
      if(transport.orderId==this.selectedOrder.orderId){
        transport.isCompleted=true; 
        this.searchTransport()
        this.closeModal();
       }
    })
 
    this.epService.markAsollected({"collectedBy":value.collectedBy,"notes":value.notes,"dropIndex":this.selectedDropIndex,"orderId":this.selectedOrder.orderId}).subscribe(data => {
      //this.ngOnInit();
    
      
    //  this.closeModal();
    })
  }
  selectDeliveryWindow(order:Order,index:number,key:number){
    this.epService.uodateDeliveryWindow({
      "orderId":order.orderId,
      "dropIndex":index,
      "deliveryTime":key
  }).subscribe(data => {
    this.ngOnInit()
  })
  }
  updateDropConfirmation(event:any,order:Order,dropIndex:number){
    this.epService.updateDropConfirmation({
      "orderId":order.orderId,
      "value":event.target.checked,
      "dropIndex":dropIndex
    }).subscribe(data => {});
  }
  openCollectionNotes(orderId:string){
    this.epService.updatedPrintCollection(orderId).subscribe(data => {
      const url = this.router.serializeUrl(this.router.createUrlTree([`admin/collection-notes/${orderId}`]));
      window.open(url, '_blank');
    })
  }
  reset(){
    // this.myForm.reset({searchKey: '',
    //   createdOn: new Date().toISOString().split('T')[0] + 'T00:00:00.000Z',
    //   isCollection: "-1",
    //   isCollected: "false",
    //   depo: "-1"})
    this.ngOnInit();  
  }
}
