<div class="main-body">
    <div class="content-area">
        <div class="content-area-content ">
            <div>
                <h2>Invoices</h2>
                <div id="Main_ctl00_upList" isupdatepanel="true">
                    <div class="list">
                        <div>
                            <table class="grid" cellspacing="0" currentsort="" id="Main_ctl00_gridList"
                                style="border-collapse:collapse;">
                                <tbody>
                                    <tr class="header-row">
                                        <th scope="col"><a>Reference</a>
                                        </th>
                                        <th scope="col"><a>Date
                                                created</a></th>
                                        <th scope="col"><a>Job
                                                Reference Number</a></th>
                                        <th scope="col"><a>Job
                                                name</a></th>
                                        <th scope="col"><a>Price</a>
                                        </th>
                                        <th scope="col"><a>Total
                                                (inc. VAT)</a></th>
                                        <th scope="col">Select to print</th>
                                        <th scope="col"><a>Status</a>
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>
                                            Inv-172843
                                        </td>
                                        <td>
                                            25 June 2023 12:40
                                        </td>
                                        <td>
                                            Q372253
                                        </td>
                                        <td>
                                            Anan
                                        </td>
                                        <td>
                                            £254.40
                                        </td>
                                        <td>
                                            £349.68
                                        </td>
                                        <td>
                                            <div class="input">
                                                <input type="checkbox">
                                            </div>
                                        </td>
                                        <td>
                                            Paid
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <input type="hidden" name="Root$Main$ctl00$SelectedListItemIdHolder"
                        id="Main_ctl00_SelectedListItemIdHolder">
                </div>
                <div class="buttons">
                    <input type="submit" name="Root$Main$ctl00$btnPrintSelectedInvoices" (click)="printInvoices()" value="Print selected invoices"
                        id="Main_ctl00_btnPrintSelectedInvoices" class="print-button" style="display: inline-block;">
                </div>
                <!-- «End| List: Invoice - Invoices» -->
            </div>

        </div>
    </div>
</div>