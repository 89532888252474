import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomerRoutingModule } from './customer/customer.routing.module';
import { AdminRoutingModule } from './admin/admin.routing.module';
import { PageNotFoundComponent } from './customer/page-not-found/page-not-found.component';
const routes: Routes = [{path: '**', component: PageNotFoundComponent},];
@NgModule({
  imports: [ CustomerRoutingModule, AdminRoutingModule,RouterModule.forRoot(routes,{
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
