import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { EndpointApiService } from 'src/app/service/endpoint-api.service';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-off-cut',
  templateUrl: './off-cut.component.html',
  styleUrls: ['./off-cut.component.scss']
})
export class OffCutComponent {
  dataSource: any = []
  find = ""
  type = "board"
  dtype = ""
  myForm: FormGroup
  data: any = []
  m_obj = { "materials": JSON.parse("{}"), "edges": JSON.parse("{}"), "boards": JSON.parse("{}") }
  constructor(private metadataService: MetadataService,private activatedRoute: Router, private route: ActivatedRoute, private fb: FormBuilder, private epService:EndpointApiService) {
    this.myForm = this.fb.group({
      MaterialCode: ['', Validators.required],
      MaterialDescription: ['', Validators.required],
      Grain: [false],
      Length: ['', Validators.required],
      Width: ['', Validators.required],
      Comment: ['']
    });
    this.myForm.get('Grain')?.disable()

    var data = this.route.snapshot.data['materials'];
    let mat = JSON.stringify(data[0]);
    this.m_obj["materials"] = JSON.parse(mat)
    this.m_obj["edges"] = JSON.parse(JSON.stringify(data[1]))
    this.m_obj["boards"] = JSON.parse(JSON.stringify(data[2]))
    this.dataSource = Object.keys(this.m_obj["materials"]).filter(data => this.m_obj["materials"][data]["hideincms"] == 0).map(data => {
      return {
        "desc": this.m_obj["materials"][data].description,
        "value": data
      }
    })
  }
  ngOnInit(): void {
    this.metadataService.setTitle('default');
    this.metadataService.setDescription('default');
  }
  saveOffCut(): void {
    confirm('Off cut saved successfully!');
    this.activatedRoute.navigate(['/admin/stock']);
  }

  checkForError(controlName: string) {
    const control = this.myForm.get(controlName)
    return control?.invalid && control?.touched
  }
  checkForRequiredError(controlName: string) {
    const control = this.myForm.get(controlName)
    return control?.hasError('required')
  }
  checkForTypeAheadError(controlName: string) {
    const control = this.myForm.get(controlName)
    return control?.hasError('typeaheadInvalid')
  }
  onSelect(event: TypeaheadMatch) {
    if(this.myForm.get('MaterialDescription')?.valid){
      this.myForm.get('MaterialCode')?.setValue(event.item.value);
      this.myForm.get('Grain')?.setValue(this.m_obj['materials'][event.item.value]['grain'] == 'Y')
    }
   
  }
  typeAheadValid(controlName = "MaterialDescription") {
    const control = this.myForm.get(controlName);
    if (control && control.value != "" && !(this.dataSource.some(
      (datam: any) =>
        control.value == datam.desc))) {
      control?.setValue(null);
      control?.setErrors({ typeaheadInvalid: true })
      control?.markAsTouched()
    }
  }
  onSubmit(){
    let reqBody = this.myForm.value;
    delete reqBody.MaterialDescription
    this.epService.addOffcut(reqBody).subscribe(data => {
      this.saveOffCut()
    })
  }
}
