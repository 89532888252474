  import { Component } from '@angular/core';
  import { ActivatedRoute } from '@angular/router';
  import { EndpointApiService } from 'src/app/service/endpoint-api.service';
  import { MetadataService } from 'src/app/service/metadata.service';

  @Component({
    selector: 'app-stock',
    templateUrl: './stock.component.html',
    styleUrls: ['./stock.component.scss']
  })
  export class StockComponent {
    find = ""
    type = "board"
    dtype = "board"
    data: any = [];
    stockCodeForMaterialsWithHideCms:any[] = [];
    materialArray:any=[]
    m_obj = { "materials": JSON.parse("{}"), "edges": JSON.parse("{}"), "boards": JSON.parse("{}") }
    constructor(private metadataService: MetadataService,private epService: EndpointApiService, private route: ActivatedRoute) {
      var data = this.route.snapshot.data['materials'];
      let mat:any = JSON.stringify(data[0]);
      this.m_obj["materials"] = JSON.parse(mat);
      
      const materialMap:any = {};

      for (const key in this.m_obj["materials"]) {
        if (this.m_obj["materials"].hasOwnProperty(key)) {
          const materialObject: any = this.m_obj["materials"][key];
      
          if (materialObject?.hideincms !== 1) {
            this.stockCodeForMaterialsWithHideCms.push(materialObject.stock_codes);
          }
        }
      }
      this.m_obj["materials"] = JSON.parse(mat)
      this.m_obj["edges"] = JSON.parse(JSON.stringify(data[1]))
      this.m_obj["boards"] = JSON.parse(JSON.stringify(data[2]))
      this.getBoards()
    }
    ngOnInit(): void {
      this.metadataService.setTitle('default');
      this.metadataService.setDescription('default');
    }
    getBoards() {
      const flattenedStockCodes = this.stockCodeForMaterialsWithHideCms.flat();     
      this.data = Object.keys(this.m_obj["boards"]).map((key: string) => {
        if (
          ((this.find && this.m_obj["boards"][key]['code'].toLowerCase().includes(this.find.toLowerCase())) ||
          !this.find ||
          (this.find && this.m_obj["boards"][key]['description'].toLowerCase().includes(this.find.toLowerCase()))) &&
          flattenedStockCodes.includes(this.m_obj["boards"][key]['code'])
        ) {
          let ret = this.m_obj["boards"][key];
          let foundMat: any = null;
          let weightNew: any;
    
          for (const key_m in this.m_obj["materials"]) {
            if (this.m_obj["materials"][key_m].stock_codes.indexOf(key) != -1) {
              foundMat = this.m_obj["materials"][key_m];
              let a = (ret.length * ret.width) / Math.pow(10, 6);
              let b = a * foundMat.thickness;
              let c = b * foundMat.weight;
              weightNew = c.toFixed(2);
              break;
            }
          }
    
          if (foundMat) {
            ret.weight = foundMat?.weight;
            ret.hasGrain = foundMat.grain == "Y";
            ret.warningMessage = foundMat.warningmessage;
            ret.trimParameters = foundMat.parameters;
            ret.weightNew = weightNew;
          }
    
          return ret;
        }
      }).filter(item => item);
    }
    
    
  format(price: string) {
    if (price) {
      return parseFloat(price).toFixed(2)
    }
    return ""
  }
  getMaterials() {
    this.data = Object.keys(this.m_obj["materials"]).map((key: string) => {
      if ((this.find && this.m_obj["materials"][key]['code'].toLowerCase().includes(this.find.toLowerCase())) || (this.find && this.m_obj["materials"][key]['description'].toLowerCase().includes(this.find.toLowerCase())) || !this.find) {
        return this.m_obj["materials"][key]
      }
    }).filter(item => item)
  }
  getEdges() {
    this.data = Object.keys(this.m_obj["edges"]).map((key: string) => {
      if ((this.find && this.m_obj["edges"][key]['code'].toLowerCase().includes(this.find.toLowerCase())) || !this.find || (this.find && this.m_obj["edges"][key]['description'].toLowerCase().includes(this.find.toLowerCase()))) {
        let ret = this.m_obj["edges"][key]
        ret.salesPrice = ret.sellingprice
        ret.hasGrain = ret.edgegrain == "Y"
        ret.warningMessage = ret.warning
        return ret
        // let foundMat:any = null;
        // for (const key in this.m_obj["materials"]) {
        //   if (this.m_obj["materials"][key].edge_codes.contains(key)) {
        //     foundMat = this.m_obj["materials"][key];
        //     break;
        //   }
        // ret.weight =  foundMat?.weight 
        // ret.hasGrain  = foundMat.grain == "Y" 
        // ret.warningMessage = foundMat.warningmessage 
        // ret.trimParameters = foundMat.parameters
        // return ret
      }
    }).filter(item => item)
  }
  getOffcuts() {
    this.epService.getOffcuts({ "Find": this.find, "IsUsed": false }).subscribe(data => {
      return this.data = data
    })
  }
  getOffcutsUsed() {
    this.epService.getOffcuts({ "Find": this.find, "IsUsed": true }).subscribe(data => {
      return this.data = data
    })
  }
  getData() {
    this.dtype = this.type
    if (this.type == "board") {
      this.getBoards();
    } else if (this.type == "edge") {
      this.getEdges();
    } else if (this.type == "offcut") {
      this.getOffcuts()
    }
    else if (this.type == "offcutu") {
      this.getOffcutsUsed()
    }
  }
  getGrain(m_code: string) {
    return this.m_obj["materials"][m_code]['grain'] == "Y" ? "Yes" : "No"
  }
  getTrim(m_code: string) {
    if (this.m_obj["materials"][m_code]) {
      return this.m_obj["materials"][m_code]['trimsize'] || ""
    } else {
      return ""
    }

  }
  deleteOffCut(offcutId: string) {
    this.epService.deleteOffcut(offcutId).subscribe(data => {
      this.getOffcuts()
    })
  }
}
