<div class="delivery-sub">
    <span class="view-body">
        <div class="item">
            <div class="label">
                Total order weight
            </div>
            <div class="value">
                {{truncateValue(quote.weight)}}
            </div>
        </div>
    </span>
</div>

<div>
    <h2>Drops</h2>
    <div onkeypress="javascript:return WebForm_FireDefaultButton(event, 'Main_ctl01_btnSearch')">

        <!-- <div class="search" onkeypress="javascript:return WebForm_FireDefaultButton(event, 'Main_ctl01_btnSearch')">

            <span class="search-body">
                <div class="buttons">
                    <input type="submit" name="Root$Main$ctl01$btnSearch" value="Search" id="Main_ctl01_btnSearch">
                </div>
            </span>

        </div> -->
        <div class="list">
            <div>
                <table class="grid" cellspacing="0" currentsort="item.ExpectedEndTime" id="Main_ctl01_gridList"
                    style="border-collapse:collapse;">
                    <tbody>
                        <tr class="header-row">
                            <th scope="col">Sequence</th>
                            <th scope="col"><a
                                   >Type</a></th>
                            <th scope="col"><a
                                    >Weight</a>
                            </th>
                            <th scope="col">Plan</th>
                            <th scope="col"><a
                                    >Notes</a>
                            </th>
                            <th scope="col"><a
                                    >No. of
                                    parts</a></th>
                            <th scope="col">Split</th>
                            <th scope="col">Edit</th>
                            <th scope="col">Delete</th>
                        </tr>
                        <tr *ngFor="let drop of order.drops; let i = index">
                            <td>
                                {{drop.sequence}} of {{order.drops.length}}
                            </td>
                            <td>
                                {{drop.collection ? "Collection" :"Delivery"}}
                            </td>
                            <td>
                                {{truncateValue(drop.weight)}}
                            </td>
                            <td>

                            </td>
                            <td>
                                {{drop.notes}}
                            </td>
                            <td>
                                <a id="Main_ctl01_gridList_btnItems_0"
                                    onclick="OpenModal(&quot;/Order/Deliveries/View.aspx?id=f2b9a12a-0cac-48fd-9da7-7f644c978387&quot;);"
                                    href="javascript:">{{drop.totalparts}}</a>
                            </td>
                            <td>
                                <input type="image" name="Root$Main$ctl01$gridList$ctl02$btnSplit"
                                    id="Main_ctl01_gridList_btnSplit_0" src="../../../../assets/images/split.png" alt="Split"
                                    onclick="OpenModal(&quot;/Order/Deliveries/Split-Drop.aspx?id=f2b9a12a-0cac-48fd-9da7-7f644c978387&quot;);return false;">
                            </td>
                            <td>
                                <input type="image" name="Root$Main$ctl01$gridList$ctl02$btnEdit"
                                    id="Main_ctl01_gridList_btnEdit_0" src="../../../../assets/images/edit.png" alt="Edit"
                                    onclick="OpenModal(&quot;/Order/Deliveries/Enter.aspx?id=f2b9a12a-0cac-48fd-9da7-7f644c978387&quot;);return false;">
                            </td>
                            <td>

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>