<div class="stock-container">
    <div class="main-body">
        <div class="content-area">
            <div class="content-area-content ">
                <div>
                    <h2>Stock</h2>
                    <div>
                        <div class="search">
                            <span class="search-body">
                                <div class="item">
                                    <div class="label">
                                        Find:
                                    </div>
                                    <div class="input">
                                        <input [(ngModel)]="find" name="Root$Main$ctl00$txtKeywordSearch" type="text"
                                            id="Main_ctl00_txtKeywordSearch" class="txt textbox" (keyup.enter)="getData()">
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="label">
                                        Type
                                    </div>
                                    <div class="input">
                                        <select  [(ngModel)]="type" name="Root$Main$ctl00$ddlMaterialTypes"
                                            id="Main_ctl00_ddlMaterialTypes">
                                            <option value="board">Board</option>
                                            <option value="edge">Edging</option>
                                            <option value="offcut">OffCut</option>
                                            <option value="offcutu">OffCut Used</option>

                                        </select>
                                    </div>
                                </div>
                                <span class="buttons" style="float: none; padding-left: 120px;">
                                    <input (click)="getData()" type="submit" name="Root$Main$ctl00$btnSearch" value="Search"
                                        id="Main_ctl00_btnSearch">
                                </span>
                            </span>

                        </div>
                        <div id="Main_ctl00_upList" isupdatepanel="true">
                            <div class="list">
                                <div>
                                    <table class="grid" cellspacing="0" currentsort="" id="Main_ctl00_gridList"
                                        style="border-collapse:collapse;">
                                        <tbody>
                                            <tr class="header-row">
                                                <th scope="col">Type</th>
                                                <th scope="col"><a>Code</a>
                                                </th>
                                                <th scope="col"><a>Description</a>
                                                </th>
                                                <th scope="col">Sales Price</th>
                                                <th scope="col">Length</th>
                                                <th scope="col">Width</th>
                                                <th scope="col">Weight</th>
                                                <th scope="col"><a>Grain
                                                        </a></th>
                                                <th scope="col"><a>Trim Parameters
                                                        </a></th>
                                                <th scope="col"><a>Customer Warning Message
                                                        </a></th>
                                                <th *ngIf="dtype == 'offcut'"> Actions </th>       
                                            </tr>

                                            <tr *ngFor="let item of data" class="alt">
                                                <td>
                                                    {{dtype}}
                                                 </td><td>
                                                    {{item.Reference || item.code || item.MaterialCode}}
                                                   
                                                 </td><td>
                                                    {{item.description || item.Description}}
                                                 </td>
                                                 <td>
                                                    {{ item.salesPrice ? "£" + format(item.salesPrice) :"" || item.Price? "£" + format(item.Price) :"" || item.sellingprice ? "£" + format(item.sellingprice) :"" || ""}}
                                                 </td><td>
                                                    {{item.length || item.Length || ""}}
                                                 </td><td>
                                                    {{item.width || item.Width || ""}}
                                                 </td><td>

                                                    {{item.weightNew }}
                                                 </td><td>
                                                    <span id="Main_ctl00_gridList_ltrNetPrice_3" style="color:Black;"> {{item.hasGrain ? "Yes" : "" ||  item.MaterialCode ? getGrain(item.MaterialCode):""}}</span>                  
                                                 </td><td>
                                                    {{item.trimParameters || (item.MaterialCode ? getTrim(item.MaterialCode):"" || "")}}
                                                 </td><td>
                                                   {{item.warningMessage || item.Comment || ""}}
                                                 </td>
                                                 <td *ngIf="dtype == 'offcut'"> <a (click)="deleteOffCut(item.OffCutId)">delete</a> </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <input type="hidden" name="Root$Main$ctl00$SelectedListItemIdHolder"
                                id="Main_ctl00_SelectedListItemIdHolder">
                        </div>
                        <!-- <div class="buttons">
                            <input type="submit" name="Root$Main$ctl00$btnExportAsCSV" value="Export as CSV"
                                id="Main_ctl00_btnExportAsCSV">
                            <input type="submit" name="Root$Main$ctl00$btnExportToMagiCut" value="Export to MagiCut"
                                id="Main_ctl00_btnExportToMagiCut">
                            <input type="submit" name="Root$Main$ctl00$btnExportToSage" value="Export to Sage"
                                id="Main_ctl00_btnExportToSage">
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>