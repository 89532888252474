<div class="off-cut-container">
    <form [formGroup]="myForm" (keyup.enter)="onSubmit()">
        <div class="main-body">
            <div class="content-area">
                <div class="content-area-content ">
                    <div>
                        <h2>Off cut details</h2>
                        <div>
                            <div class="form">
                                <span class="form-body">
                                    <div class="item">
                                        <span class="item-body">
                                            <div class="label">
                                                <label for="Main_ctl00_txtMaterial" id="Main_ctl00_lblMaterial">
                                                    Material:
                                                </label>
                                            </div>
                                            <div class="input">

                                                <input class="m-width" formControlName="MaterialDescription" type="text"
                                                    [typeahead]="dataSource" typeaheadOptionField="desc"
                                                    [typeaheadMinLength]="1" [isAnimated]="true" name="Material Code"
                                                    autocomplete="off" [typeaheadScrollable]="true"
                                                    (typeaheadOnSelect)="onSelect($event)"
                                                    (blur)="typeAheadValid()"
                                                    [typeaheadOptionsInScrollableView]="40"
                                                    [typeaheadOptionsLimit]="1000">

                                                <div *ngIf="checkForError('MaterialDescription')" ngb-form-validation>
                                                   <div *ngIf="checkForRequiredError('materialDescription')" class="text-danger">
                                                      Material is required
                                                   </div>
                                                   <div *ngIf="checkForTypeAheadError('MaterialDescription')" class="text-danger">
                                                      Unknown material code
                                                   </div>
                                                </div>

                                            </div>
                                        </span>
                                    </div>
                                    <div class="item">
                                        <span class="item-body">
                                            <div class="label">
                                                <label for="Main_ctl00_chkHasGrain" id="Main_ctl00_lblHasGrain">
                                                    Has Grain:
                                                </label>
                                            </div>
                                            <div class="input">
                                                <span class="aspNetDisabled"><input formControlName="Grain" id="Main_ctl00_chkHasGrain"
                                                        type="checkbox" name="Root$Main$ctl00$chkHasGrain"
                                                        ></span>
                                            </div>
                                        </span>
                                    </div>
                                    <div class="item">
                                        <span class="item-body">
                                            <div class="label">
                                                <label for="Main_ctl00_txtLength" id="Main_ctl00_lblLength">
                                                    L:
                                                </label>
                                            </div>
                                            <div class="input">
                                                <input formControlName="Length" name="Root$Main$ctl00$txtLength" type="text"
                                                    id="Main_ctl00_txtLength" class="number-box textbox">
                                            </div>
                                        </span>
                                    </div>
                                    <div class="item">
                                        <span class="item-body">
                                            <div class="label">
                                                <label for="Main_ctl00_txtWidth" id="Main_ctl00_lblWidth">
                                                    W:
                                                </label>
                                            </div>
                                            <div class="input">
                                                <input formControlName="Width" name="Root$Main$ctl00$txtWidth" type="text"
                                                    id="Main_ctl00_txtWidth" class="number-box textbox">
                                            </div>
                                        </span>
                                    </div>
                                    <div class="item">
                                        <span class="item-body">
                                            <div class="label">
                                                <label for="Main_ctl00_txtDescription" id="Main_ctl00_lblDescription">
                                                    Comment:
                                                </label>
                                            </div>
                                            <div class="input">
                                                <textarea formControlName="Comment" name="Root$Main$ctl00$txtDescription" rows="8" cols="20"
                                                    id="Main_ctl00_txtDescription" class="textbox"></textarea>
                                            </div>
                                        </span>
                                    </div>

                                </span>
                                <div class="buttons">
                                    <input type="submit" name="Root$Main$ctl00$btnCancel" class="submit-btn"
                                        value="Cancel" id="Main_ctl00_btnCancel">
                                    <input type="submit" name="Root$Main$ctl00$btnSave" value="Save"
                                        (click)="onSubmit()" id="Main_ctl00_btnSave">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </form>
</div>