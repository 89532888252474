<p>choose-board-view-all works!</p>
<div *ngFor="let material of filtered_tags">
  <div 
       [attr.data-item]="material" 
       class="products-list-item-action"
       [id]="material"
  >
    <span class="seeDetails">
        <a href="./choose-your-boards/{{ material['code'] }}" (click)="navigateTo(material['code'])" class="seeDetailsLink">
        <i class="fa fa-search" aria-hidden="true"></i> See details
      </a>
    </span>
  </div>
</div>
