<div class="main-body">

    <!-- «Start| Side bar» -->
    <div class="side-bar">

    </div>
    <!-- «End| Side bar» -->
    <div class="content-area">
        <div *ngIf="isLoading" class="loader-section">
         <app-loader></app-loader>
        </div>
        <div class="content-area-content" *ngIf="!isLoading">


            <div>

                <!-- «Start| List: Quotes by Cutomer» -->
                <div id="Main_ctl00_upList_QuotesByCutomer" isupdatepanel="true">
                    <div class="container">
                        <div class="left-column">
                          <h2>My quotes</h2>
                          <h3>Please delete any unwanted quotation</h3>
                        </div>
                        <div class="right-column">
                          <div class="buttons quote-btns">
                            <input [disabled]="selectedQuotes.length == 0" class="merge-quote" type="submit" (click)="mergeQuotes()" name="Root$Main$ctl00$btnMergeQuotes" value="Merge Quotes" onclick="return btnMergeQuotes_Clicked(this);" id="Main_ctl00_btnMergeQuotes">
                            <input type="submit" name="Root$Main$ctl00$btnGetAQuote" value="Get a quote" (click)="goToQuote()" id="Main_ctl00_btnGetAQuote">
                            <input type="submit" name="Root$Main$ctl00$btnHidden" value="Hidden" id="Main_ctl00_btnHidden" class="hidden">
                          </div>
                        </div>
                      </div>
                      
                    <div class="list">
                        <div>
                            <table class="grid" cellspacing="0" currentsort="item.CreatedAt DESC"
                                id="Main_ctl00_gridList" style="border-collapse:collapse;">
                                <tbody>
                                    <tr class="header-row">
                                        <th></th>
                                        <th scope="col">Job Name</th>
                                        <th scope="col">Reference</th>
                                        <th scope="col">Date last edited
                                        </th>
                                        <th scope="col">Price
                                                inc. VAT</th>
                                        <th scope="col">Expires
                                                in</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Depot</th>
                                        <th scope="col">Collection / Delivery</th>
                                        <th scope="col">Lead Time</th>

                                        <th scope="col">Details</th>
                                        <th scope="col">Delete</th>
                                    </tr>
                                    <tr *ngFor="let quote of quotations">
                                        <td>
                                           <input type="checkbox" (change)="updateMergeList($event,quote.quoteId)"/>
                                        </td>
                                        <td>
                                            {{quote.name}}
                                        </td>
                                        <td>
                                            {{quote.jobId}}
                                        </td>
                                        <td>
                                           {{quote?.Id?.CreationTime | date: 'dd/MM/yy HH:mm' }} 
                                        </td>
                                        <td>
                                            
                                            <span *ngIf="getStatus(quote) != 'NeedsReview'">£{{getAmount(quote.totalinvoiceamount)}}</span>
                                            <span *ngIf="getStatus(quote) == 'NeedsReview'">£0.00</span>

                                        </td>
                                        <td>
                                            {{getEDays(quote) <= 0 ? "Expired":getEDays(quote) + "Days"}}  &nbsp;
                                           
                                        </td>
                                        <td>
                                            {{getStatus(quote)}}
                                        </td>
                                        <td style="width:5%">
                                            {{getDepot(quote)}}                                            
                                        </td>
                                        <td style="width:5%">
                                            {{quote.collection? 'Collection':'Delivery'}}
                                        </td>
                                        <td style="width:4%">
                                            {{quote.leadtime}}
                                        </td>
                                        <td>
                                            <a *ngIf="getStatus(quote) == 'Quoted' || getStatus(quote) == 'NeedsReview'" id="Main_ctl00_gridList_btnView_0"
                                            (click)="navigateToQuoteDetails(quote)"
                                                href="javascript:">View</a>
                                            <a (click)="edit(quote.quoteId)" *ngIf="getStatus(quote) == 'Drafted' || getStatus(quote)=='Error'" id="Main_ctl00_gridList_btnView_0"
                                                    href="javascript:">Edit</a>   
                                            <a (click)="reQuote(quote)" *ngIf="getStatus(quote) == 'Expired'" id="Main_ctl00_gridList_btnView_0"
                                                    href="javascript:">ReQuote</a>                  
                                        </td>
                                        <td>
                                          <div>
                                            <img  (click)="delQuotePopUp(quote.quoteId)" src="../../../assets/images/delete.png" alt="Delete">
                                          </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                    <input type="hidden" name="Root$Main$ctl00$SelectedListItemIdHolder"
                        id="Main_ctl00_SelectedListItemIdHolder">
                </div>
                <!-- «End| List: Quotes by Cutomer» -->
            </div>

        </div>
</div>
<ng-template #deletePopup>
    <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title"><b>{{modalHeader}}</b></h5>
          </div>
          <div class="modal-body">
              <p style="color: black;">{{ modalMessage }}</p>
          </div>
          <span class="modal-footer">
              <button type="button" class="modal-button" (click)="delQuote(idForDelete)">Yes</button>
              <button type="button" class="modal-button" (click)="cancelDelete()" >No</button>
        </span>
    </div>
</ng-template>