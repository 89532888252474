import { Component, Input } from '@angular/core';
import { OrderLog } from 'src/app/models/cut-wrights.model';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss']
})
export class LogComponent {
  @Input() orderLogs!:OrderLog
  constructor(private metadataService: MetadataService){}
  ngOnInit(): void {
    this.metadataService.setTitle('default');
    this.metadataService.setDescription('default');
  }
}
