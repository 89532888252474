import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withFetch } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataService, initializeApp } from './service/data-service';
import { CorsInterceptor } from './shared/interceptors/cors-google-interceptor';
import { PaymentService } from './service/payment-service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CustomerModule } from './customer/customer.module';
import { AdminModule } from './admin/admin.module';
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    CustomerModule,
    AdminModule,
    FormsModule,
    ReactiveFormsModule,
    TypeaheadModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule,
    ModalModule.forRoot()
  ],
  providers: [DataService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,  // Factory function is defined here
      deps: [DataService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CorsInterceptor,
      multi: true
    },PaymentService,
    provideHttpClient(withFetch()), 
     provideClientHydration()],
  bootstrap: [AppComponent]
})
export class AppModule { }
