import { Component, Input } from '@angular/core';
import { DataService } from 'src/app/service/data-service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent {
  hideFooter: boolean = false;

  constructor(private dataService: DataService) {
    this.dataService.getHeaderFooterFlag().subscribe((data) => {
      this.hideFooter = data;
    });
  }
}