<div class="delivery-container">
    <section class="banner-pages banner-bg-delivery" role="banner">
        <div class="container">
            <div class="col-md-10 col-md-offset-1">
                <div class="banner-pages-text text-left">
                    <h1>Delivery</h1>
                    <h2>We deliver to you… when and where you want</h2>
                </div><!-- banner text ends -->
            </div>
        </div>
    </section>

    <section id="works" class="works section">
        <div class="container">
            <div class="intro">
                <span class="site-navigation"><a [routerLink]="['/']">Home</a> » Delivery</span>
            </div>
        </div>
    </section>

    <div class="page-container">
        <section id="delivery" class=" no-padding">
            <div class="container delivery">
                <div class="row no-gutter">
                    <div class="col-lg-6 col-md-6 col-sm-12 delivery-box-text text-left">
                        <h3>London &amp; Home Counties?</h3>
    
                        <p>Deliveries within London and the Home Counties are carried out using our own fleet of dedicated
                            vehicles. This reliable personal service offers experienced helpful drivers that deliver at a
                            time suitable to you between the hours of 8:00am and 4:00pm. We take the utmost care to ensure
                            your panels arrive on time and undamaged.</p>
    
                        <p>We'll need someone on site to give our driver a hand in getting the panels to the door especially
                            with larger sizes and quantities.&nbsp;Our aim is to offload and be back on the road
                            within&nbsp;20 minutes.</p>
    
                        <p>How much does this cost? We quote a fixed price for this service and you can obtain a price
                            automatically by typing in your delivery postcode when requesting a quotation online.</p>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 delivery-box-pic">
                        <img src="../../../assets/images/images-delivery-van.jpg"
                            alt="Cutwrights truck" class="img-responsive" width="100%" height="286">
                    </div>
                </div>
            </div>
            <!-- end of row1 -->
            <div class="container delivery">
                <div class="row no-gutter">
                    <div class="col-lg-6 col-md-6 col-sm-12 delivery-box-pic">
                        <img src="../../../assets/images/images-delivery-truck.jpg"
                            alt="Cutwrights truck" class="img-responsive" width="100%" height="286">
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 delivery-box-text text-left">
                        <h3>Mainland UK?</h3>
    
                        <p>If you require a delivery outside London &amp; the Home Counties we can help!</p>
    
                        <p>Every year we have a number of projects that need to be shipped to addresses that are too far or
                            uneconomical for us to send one of our own vehicles.</p>
    
                        <p>Prices are quoted on application, on a per job basis and projects are shipped in custom made
                            shipping boxes, all panels are checked by quality control prior to packing and we use a number
                            of packaging techniques to keep the panels protected whilst they’re in transit.</p>
                    </div>
                </div>
            </div>
            <!-- end of row2 -->
            <div class="container delivery">
                <div class="row no-gutter">
                    <div class="col-lg-6 col-md-6 col-sm-12 delivery-box-text text-left">
                        <h3>Europe and beyond?</h3>
    
                        <p>If you require deliveries to European or International destinations we can help!</p>
    
                        <p>We have number of projects that need to be shipped to addresses that are not in Mainland UK.<br>
                            Prices are quoted on a per job basis and projects are shipped in custom made shipping
                            boxes.&nbsp;All panels are checked by quality control prior to packing and we use a number of
                            techniques to keep the panels protected whilst they’re in transit. We will also track your order
                            to make sure it reaches its destination on time!</p>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 delivery-box-pic">
                        <img src="../../../assets/images/images-delivery-ship.jpg"
                            alt="Cutwrights truck" class="img-responsive" width="100%" height="286">
                    </div>
                </div>
            </div>
            <!-- end of row3 -->
            <div class="container delivery">
                <div class="row no-gutter">
                    <div class="col-lg-6 col-md-6 col-sm-12 delivery-box-pic">
                        <img src="../../../assets/images/images-delivery-packup@1x.jpg"
                            alt="Cutwrights truck" class="img-responsive" width="100%" height="286">
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 delivery-box-text text-left">
                        <h3>Collection?</h3>
    
                        <p>If you wish to collect your cut to size panels instead you can Monday - Friday between 8:00am -
                            4:30pm</p>
    
                        <p>Our automated system will send a text message to let you know as soon as your order is complete
                            and ready for collection.</p>
    
                        <p>If you need any assistance loading then we are always happy to help.</p>
                    </div>
                </div>
            </div>
            <!-- end of row4 -->
        </section><!-- delivery -->
    </div>
</div>

