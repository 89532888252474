<div class="prod-notes-container">
      
    <!-- «Start| Form: Order - Production Notes» -->
    <h2>Order Details</h2>
    <div>
        
       <div class="form">
          <div id="Main_ctl00_vsMessageBox" style="display:none;">
    
        </div>
          <span class="form-body">
             <div class="item">
                <span class="item-body">
                   <div class="label">
                      <label for="Main_ctl00_txtProductionNotes" id="Main_ctl00_lblProductionNotes">
                         Production notes:
                      </label>
                   </div>
                   <div class="input txt-area">
                      <textarea [(ngModel)]="order.productionNotes" name="Root$Main$ctl00$txtProductionNotes" rows="5" cols="20" id="Main_ctl00_txtProductionNotes" class="textbox txt-area"></textarea>
                   </div>
                </span>
             </div>
          </span>
          <div class="buttons">
             <input (click)="updateProdNotes()" type="submit" name="Root$Main$ctl00$btnSave" value="Save" id="Main_ctl00_btnSave">
          </div>
       </div>
    
    </div>
     </div>
