<footer *ngIf="!hideFooter" class="footer" id="footer">
    <div class="footer-top">
        <div class="container">
            <div class="row-one">
                <div class="footer-col col-md-3 footer-separator">
                    <h5>Overview</h5>
                    <p>
                        <a [routerLink]="['/board-range']">Board Range</a><br>
                        <a [routerLink]="['/delivery']">Delivery</a><br>
                        <a [routerLink]="['/faq']">FAQ'S</a><br>
                        <a [routerLink]="['/contact-us']">Contact us</a><br>
                        <a [routerLink]="['/blog']">Blog</a><br>
                        <a>Environment</a><br>
                        <a>Showroom</a><br>
                        <a [routerLink]="['/customer/get-quote']">Get a Quote</a>
                    </p>
                    <h5>Fast product finder</h5>
                    <div>
                        <form id="form-search" (ngSubmit)="findProduct($event)" method="post">
                            <input id="quickSearchText" [(ngModel)]="searchKey" class="form-control" name="text" type="text" placeholder="Type here...">
                            <button id="quickSearchButton" type="submit" class="">SEARCH</button>
                        </form>
                    </div>
                </div>
                <div class="footer-col col-md-3 footer-separator">
                    <h5>Services</h5>
                    <p>
                        <a [routerLink]="['/cnc-cutting']">Cutting</a><br>
                        <a [routerLink]="['/edge-banding']">Edging</a><br>
                        <a [routerLink]="['/cnc-drilling-service']">Drilling</a><br>
                        <a [routerLink]="['/other-services']">Other Services</a><br>
                    </p>
                    <h5>Board Range</h5>
                    <p>
                        <a [routerLink]="['/decorative-board']">Decorative board</a><br>
                        <a [routerLink]="['/mdf-board']">MDF board</a><br>
                        <a [routerLink]="['/melamine-board']">Melamine board</a><br>
                        <a [routerLink]="['/plywood-board']">Plywood Board</a><br>
                        <a [routerLink]="['/veneered-board']">Veneered board</a>
                    </p>
                </div>
                <div class="footer-col col-md-3 footer-separator">
<h5>Company Information</h5>

<p><strong>Cutwrights Ltd - Hanworth Depot</strong><br>
Unit 6 Links Industrial Estate<br>
Popham Close<br>
Hanworth, Middlesex<br>
TW13 6JE</p>

<p><strong>Cutwrights Ltd - Poole Depot</strong><br>
Unit G4&nbsp;Fleets Corner Business Park<br>
Nuffield Road<br>
Poole, Dorset<br>
BH17 0LA<br>
<br>
Cutwrights Ltd registered in England and Wales.<br>
Company No: 07010445<br>
VAT No: 802164665</p>

<h5>&nbsp;</h5>
                </div>
                <div class="footer-col col-md-3">
<h5>Follow us</h5>

<span class="footer-logo">
<a href="https://www.facebook.com/CutwrightsLtd/?locale=en_GB" target="_blank" class="logo-link"><img src="../../../../assets/images/facebook.png"  alt="facebook" width="40vw"> </a> 
<a href="https://www.linkedin.com/company/cutwrightsltd" target="_blank" class="logo-link"><img src="../../../../assets/images/linkedin.png" alt="linkedin" width="40vw"></a>

</span>
                    <h5>Usage conditions</h5>
                    <p>
                        <a href="../../../assets/resources/cutwrights-term-and-condition.pdf" target="_blank">Terms &amp; Conditions</a> |
                        <a href="../../../assets/resources/cutwrights-privacy.pdf" target="_blank">Privacy Policy</a>
                        <br>
                        ©2024 all rights reserved.<br>Version {{versionVariable}}
                        <br>
                    </p>
                    <br>
                    <!-- <p>
                       <a href="https://www.agilesoftwaresystems.com/" target="_blank" style="color:inherit; text-decoration: none;">Powered by: Agile Software Systems Ltd.</a>
                    </p> -->
                    
                </div>
            </div>
        </div>
    </div>
</footer>
