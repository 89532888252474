import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from 'environment.uat';
import { enableProdMode } from '@angular/core';
if (environment.production) {
  enableProdMode(); // Enable Angular production mode
}
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
