import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Material } from '../models/material.model';
import { Observable, forkJoin, map, of } from 'rxjs';
import { ResolveFn, Router } from '@angular/router';
import { DataService } from './data-service';
import { Order, OrderLog, QuotationDetails, QuotationModel, Quote } from '../models/cut-wrights.model';
import * as CryptoJS from 'crypto-js';
import { User } from '../models/user.model';
import { NominalCode } from '../models/nominalCode.model';
import { DepartmentCode } from '../models/departmentCode.model';
import { PaymentModel } from '../models/payment.model';
import { PaymentResponseModel } from '../models/payment_response_model';
import { getEnvironment } from '../../../environment-loader';

@Injectable({
  providedIn: 'root'
})
export class EndpointApiService {
  // // dev config
  private environment = getEnvironment()
  API_URL = this.environment.API_URL
  BASE_API_URL = this.environment.BASE_API_URL;
  HOST_URL = this.environment.HOST_URL;
  // HOST_URL = 'http://localhost:7071/api'
  encryptionKey = this.environment.encryptionKey;
  IMAGE_APP_URL = this.environment.IMAGE_APP_URL

  // //  // prod config
  //  API_URL = 'https://cutwrights-quote-prod.azurewebsites.net/api/#path#?code=vturStVSKkdi1JaB56iOCPsM6WHiPIG4B9p7ALtBtVARAzFuaRQTMQ==&clientId=default';
  //  BASE_API_URL = 'https://cutwrights-material-prod.azurewebsites.net';
  //  HOST_URL = 'https://cutwrights-quote2.azurewebsites.net/api/';
  //  // HOST_URL = 'http://localhost:7071/api'
  //  encryptionKey = 'MyEncryptionKey1MyEncryptionKey1';
  //  IMAGE_APP_URL = "https://imageappprod.azurewebsites.net/images/handlerproducts/myproductname/default.png"

  constructor(private http: HttpClient) { }
  putCutWrightData(data: any) {
    return this.http.put(this.API_URL.replace('#path#','quote'), data);
  }
  postCutWrightData(data: any) {
    return this.http.post(this.API_URL.replace('#path#','quote'), data);
  }
  getCachedQuote(quoteId:string){
    return this.http.get(this.API_URL.replace('#path#',`quote/cache/${quoteId}`))
  }
  makeManualPayment(reqBody:any){
    return this.http.post(this.API_URL.replace('#path#',`customer/paymentmanual`),reqBody)
  }
  saveProdNotes(reqBody:any, orderId:string){
    return this.http.post(this.API_URL.replace('#path#',`order/productionnotes/${orderId}`),reqBody)

  } 
  authorizeOrder(orderId:string){
    return this.http.post(this.API_URL.replace('#path#',`order/authorise/${orderId}`),{})
  } 
  confirmOrder(orderId:string){
    return this.http.post(this.API_URL.replace('#path#',`order/confirm/${orderId}`),{})
  } 
  updatedPrintCollection(orderId:string){
    return this.http.post(this.API_URL.replace('#path#',`order/collectionnoteprinted/${orderId}`),"")
  }
  makeManualOrder(reqBody:any){
    return this.http.post(this.API_URL.replace('#path#',`order/ordermanual`),reqBody)
  }
  getOffcuts(reqBody:any){
    return this.http.post(this.API_URL.replace('#path#',`offcut/data`),reqBody)   
  }
  addOffcut(reqBody:any){
    return this.http.post(this.API_URL.replace('#path#',`offcut`),reqBody)   
  }
  getCustomerPayments(customerId:string){   
    return this.http.get(this.API_URL.replace('#path#',`customer/payments/${customerId}`))
  }

  getCustomerOutstandingBalance(customerId: string){  
    return this.http.get(this.API_URL.replace('#path#',`customer/outstandingbalance/${customerId}`),{})
    }
 
  updateNeededByDate(orderId:string,body:any){
    return this.http.post(this.API_URL.replace('#path#',`order/updateorderneededbydate/${orderId}`),body)
  }
  orderCancel(orderId:string){
    return this.http.post(this.API_URL.replace('#path#',`order/cancel/${orderId}`),{})
  }
  updateDepot(orderId:string,body:any){
    return this.http.post(this.API_URL.replace('#path#',`order/updateorderdepot/${orderId}`),body)
  }
  getOrdersCsv(reqBody:any){
    return this.http.post(this.API_URL.replace('#path#',`order/exports`),reqBody);
  }
  getCustomersCsv(){
    return this.http.get(this.API_URL.replace('#path#',`customer/exports`));
  }
  getExpiredAndNeedReview(){
    return this.http.get(this.API_URL.replace('#path#',`quotes/numberofquotesexpiredorneedsreview`));
  }
  putCachedCutWrightData(data: any) {
    return this.http.put(this.API_URL.replace('#path#',`quote/cache/`), data);
  }
  excludeQuote(quoteId:string) {
    return this.http.post(this.API_URL.replace('#path#',`quote/exclude/${quoteId}`), {});
  }
  deleteCachedCutWrightData(quoteId:string) {
    return this.http.delete(this.API_URL.replace('#path#',`quote/cache/${quoteId})`));
  }
  getMaterialsData() {
    return this.http.get(`${this.BASE_API_URL}/data/materials`);
  }
  getMaterialTags(){
    return this.http.get(`${this.BASE_API_URL}/data/materials/tags`);
  }
  getEdgesData() {
    return this.http.get(`${this.BASE_API_URL}/data/edges`);
  }
  getGenSasUrl() {
    return this.http.get(`${this.BASE_API_URL}/getGenUrl`);
  }
  getNominalCodes() {
    return this.http.get<NominalCode[]>(this.API_URL.replace('#path#',`nominalcodes`));
  }
  getDepartmentCodes() {
    return this.http.get<DepartmentCode[]>(this.API_URL.replace('#path#',`departmentcodes`));
  }
  getPaymentDetails(id:string) {
    return this.http.get<PaymentModel>(this.API_URL.replace('#path#',`payment/details`)+`&id=${id}`);
  }

  getPaymentNotifyDetails(id:string) {
    return this.http.get<PaymentResponseModel>(this.API_URL.replace('#path#',`payment/notify`)+`&id=${id}`);
  }

  getMagucListTemplate() {
    return this.http.get<string>(this.API_URL.replace('#path#',`communication/magiclisttemplate`));
  }
  patchQuotationDetails(body:any,quoteId:string){
    return this.http.patch(this.API_URL.replace('#path#',`quote/quotationdetails/${quoteId}`),body);
  }
  
  removeQuotationDetails(body:any,quoteId:string){
    return this.http.patch(this.API_URL.replace('#path#', `quote/removequotationdetails/${quoteId}`),body);
  }

  mergeQuotes(body:any){
    return this.http.post(this.API_URL.replace('#path#',`quote/mergequotes`),body);
  }

  getBoardsData() {
    return this.http.get(`${this.BASE_API_URL}/data/boards`);
  }
  getQuotationDetails(quoteId: string) {
    const token = localStorage.getItem('token');

  // Create headers and add the token if available
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    if (token) {
      headers.set('Authorization', `${token}`);
    }
    return this.http.get<QuotationModel>(this.API_URL.replace('#path#',`/quote/details/${quoteId}`), { headers: headers });
  }
  getQuote(quoteId: string,useTokenB = false) {
    
    var token = localStorage.getItem('token');
    if(useTokenB){
      token = localStorage.getItem('token_b');
    }

    // Create headers and add the token if available
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    if (token) {
      headers.set('Authorization', `${token}`);
    }

    return this.http.get<Quote>(this.API_URL.replace('#path#',`quote/${quoteId}`), { headers });
  }
  getOrder(orderId: string,useTokenB = false) {

    var token = localStorage.getItem('token');
    if(useTokenB){
      token = localStorage.getItem('token_b');
    }
    // Create headers and add the token if available
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    if (token) {
      headers.set('Authorization', `${token}`);
    }
    return this.http.get<Order>(this.API_URL.replace('#path#',`order/${orderId}`), { headers });
  }
  getGlobalSetting(s_name:string,useTokenB = false) {

    var token = localStorage.getItem('token');
    if(useTokenB){
      token = localStorage.getItem('token_b');
    }
    // Create headers and add the token if available
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    if (token) {
      headers.set('Authorization', `${token}`);
    }
    return this.http.get(this.API_URL.replace('#path#',`communication/globalsettingsbyname/${s_name}`), { headers });
  }
  getOrderLogs(orderId: string,useTokenB = false) {
    var token = localStorage.getItem('token');
    if(useTokenB){
      token = localStorage.getItem('token_b');
    }
    // Create headers and add the token if available
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    if (token) {
      headers.set('Authorization', `${token}`);
    }
    return this.http.get<OrderLog>(this.API_URL.replace('#path#',`order/log/${orderId}`), { headers });
  }


  getCustomerQuotations(customerId: string) {
    const token = localStorage.getItem('token');

    // Create headers and add the token if available
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    if (token) {
      headers.set('Authorization', `${token}`);
    }
    return this.http.get(this.API_URL.replace('#path#',`/customer/quotes/${customerId}`), { headers: headers });
  }
  getTermsAndC(){
    return this.http.get(this.API_URL.replace('#path#',`communication/cutwrightstermsandconditions`));
  }
  getDownloadUrl(quoteId: string) {
    const token = localStorage.getItem('token');

    // Create headers and add the token if available
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    if (token) {
      headers.set('Authorization', `${token}`);
    }
    return this.http.get<string>(this.API_URL.replace('#path#',`quote/designfile/${quoteId}`), { headers: headers });
  }
  addUser(user: any) {
    if (user.password) {
      user.password = this.encryptData(user.password);
    }
    user.roles=['customer']
    return this.http.post(this.API_URL.replace('#path#',`user`), user);
  }
  updateUser(user: any, encryptP = false) {
    if (user.password && encryptP) {
      user.password = this.encryptData(user.password);
    }
    return this.http.put(this.API_URL.replace('#path#',`user`), user);
  }
  login(user: any,encrypt=true) {
    if(encrypt){
      user.password = this.encryptData(user.password);
    }
    user.email = user.email.toLowerCase()
    return this.http.post(this.API_URL.replace('#path#',`user/login`), user);
  }
  logout(user: any) {
    user.password = this.encryptData(user.password);
    return this.http.post(this.API_URL.replace('#path#',`user/logout`), user);
  }
  resetPassword(email:string){
    const token = localStorage.getItem('token');
    // Create headers and add the token if available
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    if (token) {
      headers.set('Authorization', `${token}`);
    }
    return this.http.get(this.API_URL.replace('#path#',`users/forgottonpassword/${email}`) +'&reset=true', { headers: headers });
    //return this.http.get(this.API_URL.replace('#path#',`users/forgottonpassword/${email}`))
  }

  forgotPassword(email:string){
    return this.http.get(this.API_URL.replace('#path#',`users/forgottonpassword/${email}`))
  }
  
  decryptData(encryptedText: string): string {
    let key = this.environment.key; // 16 bytes for 128-bit AES
    let iv = this.environment.iv;

    const ciphertext = CryptoJS.enc.Base64.parse(encryptedText);
  
    const decrypted = CryptoJS.AES.decrypt(
      {
        ciphertext: ciphertext,
        salt: '',
        iv: CryptoJS.enc.Utf8.parse(iv),
        key: CryptoJS.enc.Utf8.parse(key),
      },
      CryptoJS.enc.Utf8.parse(key),
      {
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    const decryptedText = CryptoJS.enc.Utf8.stringify(decrypted);
    return decryptedText;
  }
  
  
  encryptData(input: string) {
let key = this.environment.key; // 16 bytes for 128-bit AES
let iv = this.environment.iv;

// Encrypt with AES-CBC and PKCS7 padding
const encrypted = CryptoJS.AES.encrypt(input, CryptoJS.enc.Utf8.parse(key), {
  iv: CryptoJS.enc.Utf8.parse(iv),
  mode: CryptoJS.mode.CBC,
  padding: CryptoJS.pad.Pkcs7,
});


return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
  }
  getPostCodeV(postcode: string) {
    return this.http.get(`${this.BASE_API_URL}/postcode?postcode=${postcode}`);
  }

  getCustomers(key:string){
    const token = localStorage.getItem('token');

    // Create headers and add the token if available
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    if (token) {
      headers.set('Authorization', `${token}`);
    }
    return this.http.get<User[]>(this.API_URL.replace('#path#',`users`)+`&key=${key}`,{ headers: headers })  
  }

  getOneCustomer(userId:string){
    const token = localStorage.getItem('token');
    // Create headers and add the token if available
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    if (token) {
      headers.set('Authorization', `${token}`);
    }
    return this.http.get<User>(this.API_URL.replace('#path#',`user/${userId}`),{ headers: headers })  
    }

  getQuotes(reqBody:any){
    const token = localStorage.getItem('token');

    // Create headers and add the token if available
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    if (token) {
      headers.set('Authorization', `${token}`);
    }
   
    return this.http.post<Quote[]>(this.API_URL.replace('#path#',`quotes/data`),reqBody,{ headers: headers })  
  }
  getOrders(reqBody:any){
    const token = localStorage.getItem('token');

    // Create headers and add the token if available
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    if (token) {
      headers.set('Authorization', `${token}`);
    }
   
    return this.http.post<Order[]>(this.API_URL.replace('#path#',`orders/data`),reqBody,{ headers: headers })  
  }
  getTransportationOrders(reqBody:any){
    const token = localStorage.getItem('token');

    // Create headers and add the token if available
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    if (token) {
      headers.set('Authorization', `${token}`);
    }
    return this.http.post<Order[]>(this.API_URL.replace('#path#',`order/transport/details`),reqBody,{ headers: headers })  
  }
  getTransportationOrdersI(reqBody:any){
    const token = localStorage.getItem('token');

    // Create headers and add the token if available
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    if (token) {
      headers.set('Authorization', `${token}`);
    }
    return this.http.post<Order[]>(this.API_URL.replace('#path#',`order/transport/details/init`),reqBody,{ headers: headers })  
  }

  updateDropConfirmation(reqBody:any){
    const token = localStorage.getItem('token');

    // Create headers and add the token if available
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    if (token) {
      headers.set('Authorization', `${token}`);
    }
    return this.http.post<Order[]>(this.API_URL.replace('#path#',`order/drop/confirm`),reqBody,{ headers: headers })  
  }
  markAsollected(reqBody:any){
    const token = localStorage.getItem('token');

    // Create headers and add the token if available
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    if (token) {
      headers.set('Authorization', `${token}`);
    }
    return this.http.post(this.API_URL.replace('#path#',`order/collected`),reqBody,{ headers: headers })  
  }
  uodateDeliveryWindow(reqBody:any){
    const token = localStorage.getItem('token');

    // Create headers and add the token if available
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    if (token) {
      headers.set('Authorization', `${token}`);
    }
    return this.http.post(this.API_URL.replace('#path#',`order/drop/deliveryTime`),reqBody,{ headers: headers })  
  }
  getCustomerOrders(customerId: string) {
    const token = localStorage.getItem('token');

    // Create headers and add the token if available
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    if (token) {
      headers.set('Authorization', `${token}`);
    }
    return this.http.get(this.API_URL.replace('#path#',`customer/orders/${customerId}`), { headers: headers });
  }
  fetchImage(url: string) {
    return this.http.get(url, { responseType: 'arraybuffer' })
    //   (data: ArrayBuffer) => {
    //     this.imageSrc = this.getBase64Image(data);
    //   },
    //   error => {
    //     console.error('Error fetching image:', error);
    //   }
    // );
  }
  sendQuoteEmail(quoteId:string){
    return this.http.post(this.API_URL.replace('#path#',`quote/sendemail/${quoteId}`),{})
  }
  postEnquiry(body:any){
    return this.http.post(this.API_URL.replace('#path#',`communication/customerenquiry`),body)
  }
  deleteOffcut(offcutId:string){
    return this.http.post(this.API_URL.replace('#path#',`offcut/update/${offcutId}`),{})
  }
  deleteUser(userEmail:string){
    return this.http.delete(this.API_URL.replace('#path#',`users/deleteuser/${userEmail}`),{})
  }

  getPopUP(){
    return this.http.get(this.API_URL.replace('#path#',`communication/contentbyname/POPUP_MESSAGE`),{})
  }


  getJobStatus(orderId:string){
      return this.http.get(this.API_URL.replace('#path#',`order/jobstatus/${orderId}`));
  }


  getOrderTarget(orderId: string) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    if (token) {
      headers.set('Authorization', `${token}`);
    }
    return this.http.get(this.API_URL.replace('#path#',`order/ordertargets/${orderId}`), { headers: headers });
  }
  format(price:string){
    if(price){
      return parseFloat(price).toFixed(2)
    }
    return ""
  }

  generateImageUrl(materialCode: string, thickness: number, showHandle: boolean,
    type: number, hasGrain: boolean, hingeSide: string,
    rotate90Degrees: boolean, midRails: string, hingeHoles: string,
    drillingDistance: number, useinsertahinges: boolean, length: number,
    width: number): string {
    const baseUrl = this.IMAGE_APP_URL;
    const params = new HttpParams({
      fromObject: {
        MaterialCode: materialCode,
        Thickness: thickness.toString(),
        ShowHandle: showHandle.toString(),
        Type: type.toString(),
        HasGrain: hasGrain.toString(),
        HingeSide: hingeSide,
        Rotate90Degrees: rotate90Degrees.toString(),
        MidRails: midRails,
        HingeHoles: hingeHoles,
        DrillingDistance: drillingDistance.toString(),
        BlumInserta: useinsertahinges.toString(),
        Length: length.toString(),
        Width: width.toString()
      }
    });

    return `${baseUrl}?${params.toString()}`;
  }
  resolveQuote(quoteId:string){
    return this.http.post(this.API_URL.replace('#path#',`quote/resolve/${quoteId}`),{});
  }

  
  public truncateDecimalQuantity(value: string | number): string {
    const numericValue = typeof value === 'string' ? parseFloat(value) : value;
  
    if (isNaN(numericValue)) {
      //console.error(`Invalid number input: ${value}`);
      return '0'; 
    }
  
    // If it's an integer, return the whole number
    if (numericValue % 1 === 0) {
      return numericValue.toString(); 
    }
  
    // Format with 2 decimal places initially and remove trailing ".00", if present
    let formattedString = numericValue.toFixed(2); 
    if (formattedString.endsWith(".00")) {
      formattedString = formattedString.slice(0, -3);
    }
  
    // Preserve the original string if no trailing zeros are present.
    return formattedString; 
  }
  
 
  public truncateDecimal(value: string | number): string {
    const numericValue = typeof value === 'string' ? parseFloat(value) : value;
    
    if (isNaN(numericValue)) {
      //console.error(`Invalid number input: ${value}`);
      return '0'; 
    }
    
    var roundedValue = (Math.round(numericValue * 100) / 100).toString();
    return parseFloat(roundedValue).toFixed(2);
  }

}
export const materialsResolver: ResolveFn<any> = () => {
  return inject(DataService).data;
};
export const materialTagsResolver: ResolveFn<any> = (route) => {
  // return inject(DataService).data;
  if(route.url[0].path=="off-cut" || route.url[0].path=="stock"){
    return inject(DataService).data;
  }  
  const dataService = inject(DataService);
  const router = inject(Router);
  let fullPath =  route.url[0].path;
  if(route.url[1]){
  fullPath = route.url[1].path;
  //decoding
  fullPath = fullPath.replaceAll('%2F', '/') // Replace %2F with /decode
  .replaceAll('%28','(')
  .replaceAll('%29',')')
  }
  return of(dataService.data).pipe(
    map((data:any) => {
      
      // const isValid = data[3].includes(fullPath) || fullPath=="choose-your-boards";
      const isValid = data[3].find((item: { code: string; }) => item.code === fullPath)|| fullPath=="choose-your-boards";

      if (!isValid) {
        // If invalid, navigate to 404 and return `null` or empty data
        router.navigate(['/page-not-found']); // Use the path for 404 as defined in routes
        return null;
      }
      return data;
    })
  );
};



