<header id="header">
    <section id="headline" class="headline">
        <div class="col-md-7 col-sm-7 blog-box-text text-left article">
            <img src="https://www.cutwrights.com/ShowArticleImage/26055b7e-a4a2-45be-b292-1fabc5b0a575?width=585" alt="Cutwrights truck" style="width: 100%; height: 100%;" width="682" height="184">
            <div class="date">Date: 30.01.2019, Author: Cutwrights</div>
            <h2>Rebranding Announcement</h2>
            <h3><strong><span>So what's changing and when?</span></strong></h3>
            <ul>
                <li>- Fear not, there won't be any changes to services or the way we work just a fresh look and feel to our website, stationery, vans and depot signage.</li>
                <li>- You'll spot the new designs from Feb 2019.</li>
            </ul>
        </div>
    </section>
</header>
