<div class="others-container">

    <section class="banner-pages banner-bg-cutting" role="banner">
        <div class="container">
            <div class="col-md-10 col-md-offset-1">
                <div class="banner-pages-text text-left">
                    <h1>Others</h1>
                    <h3>Working with others, gets the job done quicker</h3>
                </div><!-- banner text -->
            </div>
        </div>
    </section>

    <section id="intro" class="section">
        <div class="container">
            <div class="intro">
                <span class="site-navigation"><a [routerLink]="['/']">Home</a> » Services » Others</span>
                <p>We offer even more than cutting, edging and drilling! We are committed to providing a range of other
                    services that can assist in making your life easier. Take a look at some of the options below.</p>
            </div>
        </div>
    </section>

    <section id="cutting" class="services no-padding">
        <!-- row 1 -->
        <div class="container">
            <div class="row no-gutter">
                <div class="col-lg-6 col-md-6 col-sm-12 services-box-left text-left">
                    <p><strong>Priming</strong></p>

                    <p>Primed and ready to go...</p>

                    <p>Cutwrights' priming service is another popular service we offer. Check the priming box when
                        submitting you order online and we will white prime your panels ready for painting. We use a
                        water based primer that is applied to the panels using a spray system so it gives a consistent
                        flat finish this can be over painted by a vast majority of top coats finishes. Other materials
                        can also be primed for example Oak veneer can look great painted as it has&nbsp;a deep grain. We
                        charge around £10.00 PSM for this service, priming both faces of the chosen panel as
                        standard.&nbsp;We will also prime any edges that have preparation tape applied to them.</p>

                    <p>Due to the production process in our priming department we're currently unable to offer labelling
                        on primed panels, however a printed cutting list will be provided with your collection or
                        delivery paperwork.</p>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 services-box-right">
                    <img src="../../../assets/images/images-others-priming.jpg" alt="Priming" class="img-responsive">
                </div>
            </div>
        </div>
        <!-- row 2 -->
        <div class="container ">
            <div class="row no-gutter">
                <div class="col-lg-6 col-md-6 col-sm-12 services-box-left text-left">
                    <img src="../../../assets/images/images-others-shaker-doors.jpg" alt="Shaker Doors"
                        class="img-responsive">
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 services-box-right">
                    <p><strong>Doors</strong></p>

                    <p>Our traditionally made 5 Piece Shaker door is timeless, available in any size from 250-2420mm in
                        height and 250-900mm in width. The stiles and rails are a fixed size of 90mm and the finished
                        thickness is 21mm.</p>

                    <p>Ordering is simple:&nbsp;this can be done online specifying sizes, adding mid rails, hinges and
                        priming. We also offer an accompanying plain drawer front option in 21mm thick. Made from
                        standard and moisture resistant MDF Cutwrights' doors are durable and never seem to go out of
                        fashion. We have been supplying these doors for over 10 years so we can guarantee their quality
                        will last.</p>
                </div>
            </div>
        </div>
        <!-- row 3 -->
        <div class="container">
            <div class="row no-gutter">
                <div class="col-lg-6 col-md-6 col-sm-12 services-box-left text-left">
                    <p><strong>Drawer boxes</strong></p>

                    <p>Ideal for use with wardrobe applications available in any width, height and nominal depth. You
                        can choose from our vast range of colours; most customers benefit from using the same material
                        that they have chosen for their carcass to make their drawers. Designed to be compatible with
                        under mounted drawer runners by Blum and Grass that we can supply should you wish. Made from
                        18mm material, pre-drilled for cam and dowel, assembly can take as little as a couple of
                        minutes.&nbsp;</p>

                    <p>If you wish to obtain a price for drawers, please provide us with three
                        measurements:&nbsp;internal carcass width, height of drawer box side and nominal length runner
                        you intend to use.</p>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 services-box-right">
                    <img src="../../../assets/images/images-others-drawer-boxes.jpg" alt="Drawer Boxes"
                        class="img-responsive">
                </div>
            </div>
        </div>
        <!-- row 4 -->
        <div class="container ">
            <div class="row no-gutter">
                <div class="col-lg-6 col-md-6 col-sm-12 services-box-left text-left">
                    <img src="../../../assets/images/images-others-j-profile-handle.jpg" alt="J Profile Doors Handle"
                        class="img-responsive">
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 services-box-right">
                    <p><strong>J Profile Doors</strong></p>

                    <p>J Profile doors give clean lines to any furniture design, when spray finished in matt or gloss
                        lacquers they are easy to clean and maintain.</p>

                    <p>&nbsp;</p>
                </div>
            </div>
        </div>
        <!-- row 5 -->
        <div class="container">
            <div class="row no-gutter">
                <div class="col-lg-6 col-md-6 col-sm-12 services-box-left text-left">
                    <p><strong>Bonding Service</strong></p>

                    <p>Require a board that doesn’t come as standard?</p>

                    <p>A more cost-effective option if thicker panels are required is to bond two 18mm thick MFC boards
                        together to give a 36mm thick panel. Thicker panels can enhance the look of shelves within
                        walk-in wardrobes and alcove units.&nbsp;Our customers appreciate this option as you do not have
                        to commit to a minimum order quantity of boards,&nbsp;you can have as little as one panel.</p>

                    <p>Please contact us for more details before entering your cutting list so we can confirm the
                        availability of wider edging.</p>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 services-box-right">
                    <img src="../../../assets/images/images-others-bonding.jpg" alt="Bonding Service"
                        class="img-responsive">
                </div>
            </div>
        </div>
    </section>

</div>