import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Shaker, eHingeSide, eShakerType } from 'src/app/models/shaker.model';
import { HttpParams } from '@angular/common/http';
import { EndpointApiService } from 'src/app/service/endpoint-api.service';
import { MetadataService } from 'src/app/service/metadata.service';


@Component({
  selector: 'app-shaker-door',
  templateUrl: './shaker-door.component.html',
  styleUrls: ['./shaker-door.component.scss']
})
export class ShakerDoorComponent {
  @ViewChild('template') shakerDoor!: TemplateRef<any>;
  @ViewChild('shakerDrawerModal') shakerDrawer!: TemplateRef<any>;
  @ViewChild('plainDrawerModal') plainDrawer!: TemplateRef<any>;
  edit = false
  @Input() shakers!: FormArray;
  isQoute: boolean = false;
  focussedElement = ""
  imageSrc = "";
  isShakerDoor: boolean = true;
  modalRef?: BsModalRef;
  shakerModalRef?: BsModalRef;
  plainModalRef?: BsModalRef;
  sformGroup!: FormGroup;
  eShakerType = eShakerType;
  update = false;
  config={
    backdrop:true,
    ignoreBackdropClick:true
  };
  constructor(private metadataService: MetadataService,private modalService: BsModalService, private fb: FormBuilder, private _eService: EndpointApiService) { }

  ngOnInit() {
    this.metadataService.setTitle('default');
    this.metadataService.setDescription('default');
   }
  openModal(template: TemplateRef<any>, type: eShakerType, edit = false): void {
    this.edit = edit
    if (!edit) {
      this.sformGroup = this.addShaker(type)
      //this.createShakerValidators(this.sformGroup);
    }
    if (type == 0) {
      this.sformGroup.valueChanges.subscribe(change => {
        if (this.sformGroup.get('width')?.valid && this.sformGroup.get('height')?.valid) {
          if(this.update == false){
            this.image_handler();
          }
        }
      })
      this.image_handler();
      let shake = this.shakers
      let editVar = edit
      this.modalRef = this.modalService.show(template, {
      class: 'shaker-door-modal',
      initialState: {
        iformGroup: this.sformGroup,
        edit: editVar as boolean,
        shakers: shake as FormArray,
        close: this.closeModalRef,
      },
      ...this.config // Spread the config object here
  });
    }
    if (type ==1){
      this.openShakerModal(template,type,true)
    }
    if (type ==2){      
      this.openPlainModal(template,type,true)
    }  
  }
  
  closeModalRef() {
    this.modalRef?.hide()
  }

  openShakerModal(shakerTemplate: TemplateRef<any>, type: eShakerType, edit = false): void {
    if (!edit) {
      this.sformGroup = this.addShaker(type)
    }
    this.shakerModalRef = this.modalService.show(shakerTemplate, { class: 'shaker-door-modal' ,initialState :{iformGroup: this.sformGroup, edit:edit as boolean, shakers:this.shakers as FormArray},...this.config});
  }

  openPlainModal(plainTemplate: TemplateRef<any>, type: eShakerType, edit = false): void {
    if (!edit) {
      this.sformGroup = this.addShaker(type)
    }
    this.plainModalRef = this.modalService.show(plainTemplate, { class: 'shaker-door-modal',initialState :{iformGroup: this.sformGroup, edit:edit as boolean, shakers:this.shakers as FormArray},...this.config});
  }

  closeShakerModal(): void {
    this.shakerModalRef?.hide();
  }

  closePlainModal(): void {
    this.plainModalRef?.hide();
  }

  closePopup(): void {
    this.modalRef?.hide();
  }

  saveAndClose(): void {
 
    this.modalRef?.hide();
    if(!this.edit){
      (this.shakers as FormArray).push(this.sformGroup)
    }
  }

  addShaker(type: eShakerType) {
    let height = 0
    let width = 0
    let maxHeight = 500
    let maxWidth = 1200
    let minHeight = 250
    let minWidth = 250
    if (type == 2) {
      height = 141,
        width = 595
      maxHeight = 500
      maxWidth = 1200
      minHeight = 70
      minWidth = 250
    } else if (type == 1) {
      height = 285
      width = 595
      maxHeight = 500
      maxWidth = 1200
    } else if (type == 0) {
      height = 715
      width = 595
      maxHeight = 2420
      maxWidth = 900
    }
    let hingesArray = this.fb.array([]) as FormArray;
    let group = this.fb.group({
      name: [''],
      height: [height, [Validators.required, Validators.min(minHeight), Validators.max(maxHeight)]],
      width: [width, [Validators.required, Validators.min(minWidth), Validators.max(maxWidth)]],
      quantity: [1, [Validators.required,this.validateWholeNumber]],
      primed: [false],
      weight: [0, Validators.required],
      type: [type],
      totalnumberofmidrails: [0],
      midrails: this.fb.array([]),
      hingeholes35mm: [false],
      useinsertahinges: [false],
      hingeside: [],
      totalnumberofhingeholes: [0],
      hinges: hingesArray,
      drillingdistance: [5,[Validators.min(3),Validators.max(8)]],
      // blumInserta: [false]  //removed by KD as dupicate variable wrt useinsertahinges
    });
    this.createShakerValidators(group);
    // (this.shakers as FormArray).push(group);
    return group;
  }

  createShakerValidators(group:FormGroup): FormGroup {
    

    
    group.get("hingeholes35mm")?.valueChanges.subscribe(change => {
      if (!group.get("hingeholes35mm")?.value) {
        group.get("hinges")?.reset();
        group.get("useinsertahinges")?.reset();
        group.get("hingeside")?.reset();
        group.get("totalnumberofhingeholes")?.reset();
        group.get("drillingdistance")?.reset();
        
      } else {
        let heightC = group.get('height')?.value as number;
        let hHoles = group.get('totalnumberofhingeholes');
        if (heightC >= 1700) {
          hHoles?.setValue(4)
        }
        else if (heightC >= 900) {
          hHoles?.setValue(3)
        }
        else {
          hHoles?.setValue(2)
        }
      }
    })
    group.get('totalnumberofhingeholes')?.valueChanges.subscribe((change: any) => {
      this.hingeHolesValidate(group as FormGroup)
    })
    group.get('height')?.valueChanges.subscribe((change: any) => {
      if (group.get("hingeholes35mm")?.value) {
        let heightC = group.get('height')?.value as number;
        let hHoles = group.get('totalnumberofhingeholes');
        if (heightC >= 1700) {
          hHoles?.setValue(4)
        }
        else if (heightC >= 900) {
          hHoles?.setValue(3)
        }
        else {
          hHoles?.setValue(2)
        }
      }})
    group.get('totalnumberofmidrails')?.valueChanges.subscribe((change: any) => {
      let formArray = group.get('midrails') as FormArray
      let count = group.get('totalnumberofmidrails')?.value as number;
      let c_count = formArray.length;
      if (c_count < count) {
        for (let i = 0; i < count - c_count; i++) {
          let g = this.fb.group({
            position: [0, [Validators.min(90)]]
          });
          g.get('position')?.addValidators(this.midrailOverlapValidator(group.get('height') as AbstractControl))
          formArray.push(g);
        }
      } else {
        for (let i = 0; i < c_count - count; i++) {
          formArray.removeAt(count - i);
        }
      }
      if (count == 1) {
        let height = group.get('height')?.value as number;
        let m1 = height / 2
        formArray.at(0).setValue({ "position": m1.toFixed(2) })
      }
      if (count == 2) {
        let height = group.get('height')?.value as number;
        let m1 = (height / 3) + 15;
        let m2 = height - m1;
        formArray.at(0).setValue({ "position": m1.toFixed(2) })
        formArray.at(1).setValue({ "position": m2.toFixed(2) })

      }
      if (count == 3) {
        let height = group.get('height')?.value as number;
        let m1 = (height / 4) + 22.5;
        let m2 = (m1 + (height / 4)) - 22.5;
        let m3 = height - m1
        formArray.at(0).setValue({ "position": m1.toFixed(2) })
        formArray.at(1).setValue({ "position": m2.toFixed(2) })
        formArray.at(2).setValue({ "position": m3.toFixed(2) })
      }
      if (count == 4) {
        let height = group.get('height')?.value as number;
        let m1 = (height / 5) + 27;
        let m2 = (m1 + (height / 5)) - 18;
        let m3 = (m2 + (height / 5)) - 18;
        let m4 = height - m1;
        formArray.at(0).setValue({ "position": m1.toFixed(1) })
        formArray.at(1).setValue({ "position": m2.toFixed(1) })
        formArray.at(2).setValue({ "position": m3.toFixed(1) })
        formArray.at(3).setValue({ "position": m4.toFixed(1) })
      }
    })
    return group;
  }
  deleteItem(index: number) {
    const shakersArray = this.shakers as FormArray;
    shakersArray.removeAt(index);
  }
  editItem(index: number) {
    const shakersArray = this.shakers as FormArray;
    this.sformGroup = shakersArray.at(index) as FormGroup;
    let type = this.sformGroup.get('type')?.value;
    var template: TemplateRef<any> = this.shakerDoor;
    if (type == eShakerType.ShakerDoor) {
      template = this.shakerDoor
    } else if (type == eShakerType.ShakerDrawer) {
      template = this.shakerDrawer
    } else if (type == eShakerType.PlainDrawer) {
      template = this.plainDrawer
    }
    this.openModal(template, type, true)
  }
  getShakersControls(): FormArray {
    return this.shakers as FormArray;
  }
  getHingeControls(formGroup: FormGroup) {
    return (formGroup.get('hinges') as FormArray).controls as FormGroup[]
  }
  getMidrailControls(formGroup: FormGroup) {
    return (formGroup.get('midrails') as FormArray).controls as FormGroup[]
  }
  midrailOverlapValidator(heightControl: AbstractControl): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const enteredValue = parseFloat(control.value);
      const height = parseFloat(heightControl.value);

      if (isNaN(enteredValue) || isNaN(height)) {
        // Invalid input values, no validation error
        return null;
      }

      if (enteredValue < 90) {
        return { minimum: true };
      }

      const minHeightDifference = height - enteredValue;

      if (minHeightDifference < 180) {
        return { midrailOverlap: true };
      }

      return null;
    };
  }
  checkForMinError(name: string, index: number) {
    let control = this.sformGroup.get(name);
    if (name == 'midrails') {
      let cval = (control as FormArray).at(index) as FormGroup
      return cval.get('position')?.hasError('min')
    } else {
      return control?.hasError('min')
    }
  }
  checkForMaxError(name: string, index: number) {
    let control = this.sformGroup.get(name);
    if (name == 'midrails') {
      let cval = (control as FormArray).at(index) as FormGroup
      return cval.get('position')?.hasError('max')
    } else {
      return control?.hasError('max')
    }
  }
  checkForMidRailError(index: number) {
    let cval = (this.sformGroup.get('midrails') as FormArray).at(index)
    return cval.get('position')?.hasError('midrailOverlap')
  }


  getBase64Image(imageData: ArrayBuffer): string {
    const uint8Array = new Uint8Array(imageData);
    const binaryString = uint8Array.reduce((str, byte) => str + String.fromCharCode(byte), '');
    return `data:image/png;base64,${btoa(binaryString)}`;
  }
  image_handler() { 
    if (this.sformGroup.get('width')?.valid && this.sformGroup.get('height')?.valid) {
      let value = this.sformGroup.value;
      let midrails = ""
      let hingeHoles = ""
      if (value.type == 0) {
        midrails = value.midrails?.reduce(((val: any, cVal: any, cIndex:any) => { if (cVal.position) { return val + (this.focussedElement == "m"+cIndex ? "T":"") + cVal.position + "," } return "" }), "") as string
        hingeHoles = value.hinges?.reduce(((val: any, cVal: any,cIndex:any) => { if (cVal.y) { return val + (this.focussedElement == "h"+cIndex ? "T":"") + cVal.y + "," } return "" }), "") as string
        if (midrails.endsWith(',')) {
          midrails = midrails.slice(0, -1); // Remove the last character
        }
        if (hingeHoles.endsWith(',')) {
          hingeHoles = hingeHoles.slice(0, -1); // Remove the last character
        }
      }
      let url = this._eService.generateImageUrl(
        '22mmMoistureResistantMediteMDF',  // materialCode
        18,                               // thickness
        true,                               // showHandle
        value.type as number,               // type
        true,                               // hasGrain
        value.hingeside == "0" ? 'Left' : 'Right',  // hingeSide
        true,                               // rotate90Degrees
        midrails,                            // midrails
        hingeHoles ? hingeHoles : "",                             // hingeHoles
        value.drillingdistance as number,              // drillingDistance
        value.useinsertahinges as boolean,             //blumInserta New variable
        // value.blumInserta as boolean,                  // blumInserta
        parseInt(value.height as string),             // length
        parseInt(value.width as string),              // width

      );
      this._eService.fetchImage(url).subscribe(data => {
        const uint8Array = new Uint8Array(data);
        const base64String = btoa(String.fromCharCode.apply(null, Array.from(uint8Array)));
        this.imageSrc = `data:image/png;base64,${base64String}`;
      })
    }
  }
  hingeHolesValidate(group: FormGroup) {
    this.update = true
    let formArray = group.get('hinges') as FormArray
    let count = group.get('totalnumberofhingeholes')?.value as number;
    let c_count = formArray.length;
    if (c_count < count) {
      for (let i = 0; i < count - c_count; i++) {
        formArray.push(this.fb.group({
          y: [0, [Validators.min(0)]]
        }));
      }
    } else {
      for (let i = 0; i < c_count - count; i++) {
        formArray.removeAt(count - i);
      }
    }
    if (formArray.length > 1) {
      for (let i = 1; i < formArray.length - 1; i++) {
        let c = formArray.at(i)
        c.setValue({ y: 0 })
        c.enable()
      }
      let first = formArray.at(0)
      first.setValue({ y: 100 })
      let second = formArray.at(formArray.length - 1)
      second.setValue({ y: (group.get('height')?.value as number) - 100 })
    }
    let maxGap = 0;
    let validateGap = false;
    let heightM = group.get('height')?.value as number
    if (formArray.length == 3) {
      maxGap = (heightM - 200) / 2;
      validateGap = true;
    }
    else if (formArray.length == 4) {
      maxGap = (heightM - 200) / 3;
      validateGap = true;
    }
    else if (formArray.length == 5) {
      maxGap = (heightM - 200) / 4;
      validateGap = true;
    }
    else if (formArray.length == 6) {
      maxGap = (heightM - 200) / 5;
      validateGap = true;
    }
    if (validateGap) {
      for (let i = 1; i < formArray.length - 1; i++) {
        let prevVal = parseFloat(formArray.at(i - 1).get('y')?.value)
        formArray.at(i).setValue({ "y": parseFloat((prevVal + maxGap).toFixed(2)) })
      } 
      // for(let i =1 ; i<formArray.length;i++){
      //   let val = formArray.at(i).get('y')?.value
      //   let prevVal = formArray.at(i-1).get('y')?.value
      //   if((val- prevVal) < maxGap ){
      //     formArray.at(i).get('y')?.setErrors({"minGap" : true})
      //   }
      // }
    }
    this.update = false
    this.image_handler()
  }
  setFocus(elem :string){
    this.focussedElement = elem;
    this.image_handler()
  }
  resetFocus(elem :string){
    this.focussedElement = "";
    this.image_handler()
  }
  validateWholeNumber(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    const maxQuantity=9999;
    if (value === null || value === '') {
      return null; // No validation error if the field is empty.
    }
    const isWholeNumber = /^\d+$/.test(value);

    if (!isWholeNumber) {
      control.setValue(null); // Reset the control value to null if it's not a whole number.
      return { invalidNumber: true };
    }
    if (value>maxQuantity){
      control.setValue(maxQuantity);
      
    }
    return null;
  }
  getHingeSide(item:any):string{
    if(item.get('hingeside')?.value == 0){
      return "LEFT";
    } 
    if(item.get('hingeside')?.value == 1){
    return "RIGHT";
    }
    else{
      return ""
    }
  }
  preventDecimal(event: KeyboardEvent) {
    // Check if the pressed key is a decimal point (.)
    if (event.key === '.' || event.key === ',') {
      event.preventDefault(); // Prevent entering the decimal point
    }
  }
  

}









