<div class="main-body">
    <!-- «End| Side bar» -->
    <div class="content-area">
        <div class="content-area-content ">
            <div>
                <!-- «Start| List: Order - Orders list» -->
                <h2>Orders list</h2>
                <div id="Main_ctl00_upList" isupdatepanel="true">

                   <div class="loader" *ngIf="isLoading">
                       <app-loader></app-loader>
                   </div>

                    <div class="list" *ngIf="!isLoading">
                        <div>
                            <table class="grid" cellspacing="0" currentsort="" id="Main_ctl00_gridList"
                                style="border-collapse:collapse;">
                                <tbody>
                                    <tr class="header-row">
                                        <th scope="col">Job Name
                                        </th>
                                        <th scope="col">Reference
                                        </th>
                                        <th scope="col">Date Ordererd (Created
                                                at)</th>
                                        <th scope="col">Price inc VAT (Total)
                                        </th>
                                        
                                        <th scope="col">Needed
                                                by Date</th>
                                        <th scope="col">Depot</th>
                                        <th scope="col">Collection / Delivery</th>
                                        <th scope="col">Invoice</th>
                                        <th scope="col">View</th>
                                        
                                        <th scope="col">Status</th>
                                        <th scope="col">Duplicate</th>
                                    </tr>
                                    <tr *ngFor="let quote of quotations">
                                        <td>
                                            {{quote.name}}
                                        </td>
                                        <td>
                                            {{quote.jobReference}}
                                        </td>
                                        <td>
                                            {{quote?.Id?.CreationTime | date: 'dd/MM/yy HH:mm' }} 
                                        </td>
                                        <td>
                                            £{{truncateValue(quote.totalinvoiceamount)}}
                                        </td>
                                        
                                        <td>
                                            {{quote.neededBy| date: 'dd/MM/yy'  || ""}}
                                        </td>
                                        <td style="width:5%">
                                            {{quote.depot===0? 'LHR - Hanworth':'BOH - Poole'}}                                            
                                        </td>
                                        <td style="width:5%">
                                            {{quote.collection? 'Collection':'Delivery'}}
                                        </td>
                                        <td>
                                            <a *ngIf="[2,3,4].includes(quote.status)" class="hyperlink" (click)="navigateToInvoice(quote)" id="Main_ctl00_gridList_btnViewInvoice_0"
                                               >{{quote.invoiceReference}}</a> 
                                               <span *ngIf="quote.paymentId"> (Paid)</span>
                                        </td>
                                       
                                        <td>
                                            <a class="hyperlink" (click)="navigateToQuoteDetails(quote)"  id="Main_ctl00_gridList_btnViewDetails_0">view
                                                details</a>
                                        </td>
                                        
                                        <td>
                                            {{getStatus(quote)}}
                                        </td>
                                        <td>
                                            <a class="hyperlink" id="Main_ctl00_gridList_btnDuplicate_0" (click)="makeAChange(quote)"
                                              >Duplicate</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- «End| List: Order - Orders list» -->
            </div>

        </div>
    </div>
    <div class="floatfix"></div>
</div>