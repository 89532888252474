import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Order, Quote } from 'src/app/models/cut-wrights.model';
import { Shaker, eShakerType } from 'src/app/models/shaker.model';
import { DataService } from 'src/app/service/data-service';
import { EndpointApiService } from 'src/app/service/endpoint-api.service';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-print-door',
  templateUrl: './print-door.component.html',
  styleUrls: ['./print-door.component.scss']
})
export class PrintDoorComponent implements AfterViewInit {
  orderId= ""
  order!:Order;
  drawers:Shaker[] = []
  doors:Shaker[] = []
  plainD:Shaker[]=[]
  quote!:Quote;
  detailsFetched = false
  constructor(private metadataService: MetadataService,private epService:EndpointApiService, private route:ActivatedRoute,private dataService:DataService){
    this.orderId = this.route.snapshot.paramMap.get('id') as string;
    this.epService.getOrder(this.orderId,true).subscribe(data => {
      this.order=data
      this.epService.getQuote(this.order.quoteId).subscribe(data =>{
        this.quote = data
        this.quote.shakers?.forEach((sha: Shaker) => {
          if (sha.type == eShakerType.ShakerDrawer) {
            this.drawers.push(sha)
          }
          else if (sha.type == eShakerType.PlainDrawer) {
            this.plainD.push(sha)
          }
          else if (sha.type == eShakerType.ShakerDoor) {
            this.doors.push(sha)
          }
        })
        this.detailsFetched = true
      })
    })
    this.dataService.setHeaderFooterFlag(true);
  }
  ngOnInit(): void {
    this.metadataService.setTitle('default');
    this.metadataService.setDescription('default');
  }
  
  ngAfterViewInit(): void {
    const header: any = document.getElementsByClassName('website-banner');
    const footer = document.getElementById('website-footer');
    if(header[0] && footer) {
      header[0].style.display = "none";
      footer.style.display = "none";
    }
  }
  getMaterials(type:string,id:string){
    if(type == "door"){
      return this.quote.shakermaterials.filter(material => id == material.shakerId);
    }
    else if(type == "pdoor"){
      
      return this.quote.shakermaterials.filter(material => id == material.shakerId);
    }
    else if(type == "drawer"){
      
      return this.quote.shakermaterials.filter(material => id == material.shakerId);
    }
    return []

  }
}
