import { Component, Input } from '@angular/core';
import { Order } from 'src/app/models/cut-wrights.model';
import { EndpointApiService } from 'src/app/service/endpoint-api.service';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-prod-notes',
  templateUrl: './prod-notes.component.html',
  styleUrls: ['./prod-notes.component.scss']
})
export class ProdNotesComponent {
  constructor(private metadataService: MetadataService,private _epService : EndpointApiService){}
  @Input() order!:Order
  ngOnInit(): void {
    this.metadataService.setTitle('default');
    this.metadataService.setDescription('default');
  }
  updateProdNotes(){
    let reqBody = {
      "notes":this.order.productionNotes
    }
    this._epService.saveProdNotes(reqBody, this.order.orderId).subscribe(data =>{
      
    })
  }
}
