import { Component,Input } from '@angular/core';
import { AlertService } from '../alert.service';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  animations: [
    trigger('fadeOut', [
      state('visible', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0 })),
      transition('visible => hidden', animate('500ms ease-out')),
    ]),
  ],
})
export class AlertComponent {

  clSuccessFlag:Boolean=false;
  clDeclineFlag:Boolean=false;
  signUpSuccessFlag:Boolean=false;
  signUpDeclineFlag:Boolean=false;
  animationState: string = 'visible';

  constructor(private alertService: AlertService) {}
  ngOnInit() {
    this.signUpAlerts();
    this.creditLimitAlerts();
  }
  
  creditLimitAlerts() {
    this.alertService.CLFlag$.subscribe((event) => {
      if (event.type === 'success') {
      this.clSuccessFlag = event.data;
      this.startAutoHideAnimation();
      }else if (event.type=='decline'){
        this.clDeclineFlag=event.data;

      }
    });

  }
  creditLimitAlertsDismiss(){
    this.clSuccessFlag = false;
    this.clDeclineFlag=false;
  }
  signUpAlerts(){

    this.alertService.signUpEvent$.subscribe((event) => {
      if (event.type === 'success') {
        this.signUpSuccessFlag=event.data;
        
        this.startAutoHideAnimation();
      } else if (event.type === 'decline') {
        this.signUpDeclineFlag=event.data;
        
        this.startAutoHideAnimation();
      }      
    });
  }

  signUpAlertDismiss(){
    this.signUpSuccessFlag=false;
    this.signUpDeclineFlag=false;
    
  }
  
  startAutoHideAnimation() {
    this.animationState = 'visible'; 
    setTimeout(() => {
      this.animationState = 'hidden'; 
    }, 5000); 
  }

}