<div class="door-modal-popup">
    <button class="close-btn" (click)="closePlainPopup()">x</button>
    <form class="add-a-door" [formGroup]="iformGroup">
      <div class="row">
        <div class="col-sm-12">
          <h1>Add a drawer</h1>
        </div>
    
        <div class="col-sm-6">
          <div class="row mb-3">
            <div class="col-sm-6">
              Drawer name:
            </div>
            <div class="col-sm-6">
              <input type="text" formControlName="name" />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-sm-6">
              Height:
            </div>
            <div class="col-sm-6">
              <input type="number" formControlName="height" value="30" (keydown)="preventDecimal($event)"/>
              <div *ngIf="checkForMaxError('height',0)" class="text-danger mt-1" ngb-form-validation>
                The height of the drawer must be 500mm or less
             </div>
            <div *ngIf="checkForMinError('height',0)" class="text-danger mt-1" ngb-form-validation>
              The height of the drawer must be 70mm or more
           </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-sm-6">
              Width:
            </div>
            <div class="col-sm-6">
              <input type="number" formControlName="width" value="40" (keydown)="preventDecimal($event)" />
              <div *ngIf="checkForMaxError('width',0)" class="text-danger mt-1" ngb-form-validation>
                the width of the drawer must be 1200mm or less.
             </div>
             <div *ngIf="checkForMinError('width',0)" class="text-danger mt-1" ngb-form-validation>
              The width of the drawer must be 250mm or more
           </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-sm-6">
              Quantity:
            </div>
            <div class="col-sm-6">
              <input type="number" pattern="\d+" formControlName="quantity" value="5" />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-sm-6">
              Needs priming:
            </div>
            <div class="col-sm-6">
              <input type="checkbox" formControlName="primed" />
            </div>
          </div>
        </div>
    
        <div class="col-sm-6">
          <img [src]="imageSrc" />
        </div>
    
        <div class="com-sm-12 text-center mt-4">
          <input type="button" [disabled]="iformGroup.invalid" value="Save and close" [class]="iformGroup.invalid? 'get-shaker-btn-disabled': 'get-shaker-btn-enabled'" value="Save and close" (click)="saveAndClose()" />
        </div>
      </div>
    </form>
    
 </div>