import { Component, HostListener, Inject, OnDestroy, PLATFORM_ID, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { EndpointApiService } from './service/endpoint-api.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';
import { DataService } from './service/data-service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  title = 'cut-wrights';
 

  constructor(private renderer: Renderer2,private apiService: EndpointApiService,@Inject(PLATFORM_ID) private platformId: Object, private modalService: BsModalService, private cookieService: CookieService, private dataService: DataService) {
  
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
    this.renderer.addClass(document.body, 'angular-loaded'); //
    }
    
  }
  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
    localStorage.clear();
    }
  }
  
  // Uncomment and use if needed to clear local storage on window unload
  // @HostListener('window:beforeunload', ['$event'])
  // beforeUnloadHandler(event: Event) {
  //   localStorage.clear();
  // }
}
