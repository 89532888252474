<section class="banner-pages banner-bg-ourrange" role="banner">
    <div class="container">
        <div class="col-md-10 col-md-offset-1">
            <div class="banner-pages-text text-left">
                <h1>Our Board Range</h1>
<h3>We offer over 300 different boards and 500 different edgings.</h3>
            </div><!-- banner text -->
        </div>
    </div>
</section>

<section id="intro" class="section">
    <div class="container">
        <div class="intro">
            <span class="site-navigation"><a [routerLink]="['/']">Home</a> » Our Range</span>
<p style="text-align: center;">We offer over 300&nbsp;different boards and 500 different edgings.<br>
Our board offering is explained&nbsp;briefly below to help with your decision making.&nbsp;We recommend using our board finder on&nbsp;the next page&nbsp;"CHOOSE YOUR BOARDS" and use the filters&nbsp;to narrow your search requirements.</p>
        </div>
        <div style="margin-top: 30px">
            <a class="btn-white btn-white-large" [routerLink]="['/choose-your-boards']">Choose your boards</a>
        </div>
    </div>
</section>

<div class="page-container">
    <section id="ourrange" class="ourranges section no-padding">
        <div class="container">
            <div class="row no-gutter">
                    <div class="col-lg-4 col-sm-4 col-xs-12">
                        <div class="ourrange-title animated fadeIn">
                            <h2 style="padding-top: 15px;">
                                <a style="color:#fff">Decorative board</a>
                            </h2>
                        </div>
                        <div class="ourrange animated fadeIn">
                            <a class="ourrange-box gallery-box" title="Decorative board" data-fancybox-group="thumb">
                                <img src="../../../assets/images/18-MET-SAP4-GLOSS-ALV-MDF1mm.jpg" alt="Decorative board" class="img-responsive-no">
                            </a>
                        </div>
                        <div class="ourrange-desc animated fadeIn">
                            <p>Our wide range of decorative boards contains both contemporary and traditional styles; with material finishes such as Matt, Gloss, Woodgrain, Leather and Uni Colours. These versatile products can be used as door fronts, feature panels or as an alternative to a Bonded Laminate. By combining these materials with carcasses made from melamine faced board you can cultivate almost any style for kitchens, bedrooms, bathrooms and the shop fitting industries.</p>
                        </div>
                        <div class="ourrange-link" style="position: relative; bottom: 10px; text-align: center;font-size: 16px">
                            <a class="btn-white btn-white-large" [routerLink]="['/decorative-board']">Decorative board</a>
                        </div>
                    </div>
                    <!-- <div class="col-lg-4 col-sm-4 col-xs-12">
                        <div class="ourrange-title animated fadeIn">
                            <h2>
                                <a style="color:#fff">Hollow Core board</a>
                            </h2>
                        </div>
                        <div class="ourrange animated fadeIn">
                            <a class="ourrange-box gallery-box" title="Hollow Core board" data-fancybox-group="thumb">
                                <img src="../../../assets/images/CWLITE50MM%20Raw%20Edge.jpg" alt="Hollow Core board" class="img-responsive-no">
                            </a>
                        </div>
                        <div class="ourrange-desc animated fadeIn">
                            <p>A Composite board with strong surface layers combined with light yet robust cardboard honeycomb core. In addition, its lightweight features and outstanding stability offers many design and construction options.</p>
                        </div>
                        <div class="ourrange-link" style="position: relative; bottom: 10px; text-align: center;font-size: 16px">
                            <a class="btn-white btn-white-large" [routerLink]="['/customer/hollow-board']">Hollow Core board</a>
                        </div>
                    </div> -->
                    <div class="col-lg-4 col-sm-4 col-xs-12">
                        <div class="ourrange-title animated fadeIn">
                            <h2 style="padding-top: 15px;">
                                <a style="color:#fff">MDF board</a>
                            </h2>
                        </div>
                        <div class="ourrange animated fadeIn">
                            <a class="ourrange-box gallery-box" title="MDF board" data-fancybox-group="thumb">
                                <img src="../../../assets/images/images-our-range-6@2x.jpg" alt="MDF board" class="img-responsive-no" width="391" height="220">
                            </a>
                        </div>
                        <div class="ourrange-desc animated fadeIn">
                            <p>MDF is a medium density fibreboard used in many internal applications. The smooth surface of the boards is suitable for many finishing applications, they are ideal for machining into profiles too. We offer a comprehensive range of all types of MDF including Standard, Moisture Resistant, Fire Retardant and the latest innovation Tricoya- which can be used in exterior applications. The MDF we offer comes in a variety of thicknesses and board sizes. Our preferred brand to sell is Medite.</p>
                        </div>
                        <div class="ourrange-link" style="position: relative; bottom: 10px; text-align: center;font-size: 16px">
                            <a class="btn-white btn-white-large" [routerLink]="['/mdf-board']">MDF board</a>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-4 col-xs-12">
                        <div class="ourrange-title animated fadeIn">
                            <h2 style="padding-top: 15px;">
                                <a style="color:#fff">Melamine board</a>
                            </h2>
                        </div>
                        <div class="ourrange animated fadeIn">
                            <a class="ourrange-box gallery-box" title="Melamine board" data-fancybox-group="thumb">
                                <img src="../../../assets/images/images-our-range-7@2x.jpg" alt="Melamine board" class="img-responsive-no" width="391" height="220">
                            </a>
                        </div>
                        <div class="ourrange-desc animated fadeIn">
                            <p>Melamine-faced boards have either a chipboard or MDF core with a melamine paper applied to each face. The quality and realism of the reproductions allow you to offer the look and feel of veneers without the premium price. The panel has a pre-finished, hard wearing surface, which is easy to maintain. With a huge choice of finishes available from leading manufacturers such as Egger, Kronospan and Xylocleaf, whatever the texture, effect or colour you require, we will have a product to suit. </p>
                        </div>
                        <div class="ourrange-link" style="position: relative; bottom: 10px; text-align: center;font-size: 16px">
                            <a class="btn-white btn-white-large" [routerLink]="['/melamine-board']">Melamine board</a>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-4 col-xs-12">
                        <div class="ourrange-title animated fadeIn">
                            <h2 style="padding-top: 15px;">
                                <a style="color:#fff">Plywood board</a>
                            </h2>
                        </div>
                        <div class="ourrange animated fadeIn">
                            <a class="ourrange-box gallery-box" title="Plywood board" data-fancybox-group="thumb">
                                <img src="../../../assets/images/images-our-range-5@2x.jpg" alt="Plywood board" class="img-responsive-no" width="391" height="220">
                            </a>
                        </div>
                        <div class="ourrange-desc animated fadeIn">
                            <p>Plywood is an extremely reliable and consistent board, renowned for being lightweight, having superior strength, stiffness and dimensional stability, making it ideal for construction material for a range of requirements. Ideal for floors, walls and roofs. We have thicknesses from 4mm- 24mm in finishes including Birch, Marine and Fire rated.</p>
                        </div>
                        <div class="ourrange-link" style="position: relative; bottom: 10px; text-align: center;font-size: 16px">
                            <a class="btn-white btn-white-large" [routerLink]="['/plywood-board']">Plywood board</a>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-4 col-xs-12">
                        <div class="ourrange-title animated fadeIn">
                            <h2 style="padding-top: 15px;">
                                <a style="color:#fff">Veneered board</a>
                            </h2>
                        </div>
                        <div class="ourrange animated fadeIn">
                            <a class="ourrange-box gallery-box" title="Veneered board" data-fancybox-group="thumb">
                                <img src="../../../assets/images/images-our-range-8@2x.jpg" alt="Veneered board" class="img-responsive-no" width="391" height="220">
                            </a>
                        </div>
                        <div class="ourrange-desc animated fadeIn">
                            <p>Our veneer is made up from quality real wood veneers bonded to premium boards. Cutwrights veneered products feature beautiful real wood finishes that are suitable for a wide range of interior decorative applications, including high-quality kitchens and bedroom sets, through to luxury hotel &amp; shop-fitting work. We have thicknesses from 6mm – 26mm in finishes including White Oak, Maple and Walnut.</p>
                        </div>
                        <div class="ourrange-link" style="position: relative; bottom: 10px; text-align: center;font-size: 16px">
                            <a class="btn-white btn-white-large" [routerLink]="['/veneered-board']">Veneered board</a>
                        </div>
                    </div>
            </div>
        </div>
    </section>

</div>
