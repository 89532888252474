import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, switchMap,map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsService {
  private apiKey = "AIzaSyBnMwfzOejmW-b2zd4FhovtUn99XYzGhY8"







  // private apiKey = 'AIzaSyA1Wrl2mW7e6BTV_xPN-Cvy3xvLNaiO2II';                                 
  constructor(private http: HttpClient) { }
  getGeoLocation(postcode: string): Observable<any> {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(postcode)}&key=${this.apiKey}`;
    return this.http.get(url);
  }

  getDistance(origin: string, destination: string): Observable<any> {
    const url = `https://maps.googleapis.com/maps/api/distancematrix/json?origins=${origin}&destinations=${destination}&key=${this.apiKey}`;
    return this.http.get(url);
  }

  getDuration(origin: string, destination: string): Observable<any> {
    const url = `https://maps.googleapis.com/maps/api/distancematrix/json?origins=${origin}&destinations=${destination}&key=${this.apiKey}`;
    return this.http.get(url);
  }

  checkCongestionZone(coordinates: string): Observable<any> {
    const url = `https://roads.googleapis.com/v1/snapToRoads?path=${coordinates}&interpolate=true&key=${this.apiKey}`;
    return this.http.get(url);
  }

  getPlaceDetails(placeId: string): Observable<any> {
    const url = `https://maps.googleapis.com/maps/api/place/details/json?placeid=${placeId}&key=${this.apiKey}`;
    return this.http.get(url);
  }
  calculateDeliveryCharges(deliveryType: string, destinationPostcode: string): Observable<number> {
    // If collection, return zero
    if (deliveryType === "Collection") {
      return of(0);
    }
  
    return new Observable((subscriber) => {
      this.getGeoLocation(destinationPostcode).subscribe((geoLocationResponse) => {
        const geoLocation = geoLocationResponse.results[0].geometry.location;
  
        // If GeoLocation is null or blank, show error and return zero
        if (!geoLocation) {
          subscriber.next(0);
          subscriber.complete();
          return;
        }
  
        this.getDistance(`17.3850,78.4867`, `${geoLocation.lat},${geoLocation.lng}`).subscribe((distanceResponse) => {
          const distanceInMiles = distanceResponse.rows[0].elements[0].distance.value * 0.000621371;
          let deliveryCharges = 0;
  
          if (distanceInMiles === 0) {
            deliveryCharges = 0; // Minimum Delivery Charges is Zero
            subscriber.next(deliveryCharges);
            subscriber.complete();
            return;
          } else {
            let price = distanceInMiles * 0.7;
  
            this.checkCongestionZone(`${geoLocation.lat},${geoLocation.lng}`).subscribe((congestionZoneResponse) => {
              const snappedPoints = congestionZoneResponse.snappedPoints;
  
              if (snappedPoints.length > 0) {
                const placeId = snappedPoints[0].placeId;
  
                this.getPlaceDetails(placeId).subscribe((placeDetailsResponse) => {
                  const placeDetails = placeDetailsResponse.result;
                  const isInCongestionZone = placeDetails.types.includes('congestion_zone');
  
                  if (isInCongestionZone) {
                    price += 11.50;
                  }
  
                  this.getDuration('ORIGIN_COORDINATES', `${geoLocation.lat},${geoLocation.lng}`).subscribe((durationResponse) => {
                    const durationInHours = durationResponse.rows[0].elements[0].duration.value / 3600;
  
                    // Calculate the duration in hours
                    const calcDuration = Math.floor(2 * durationInHours);
  
                    // Calculate the price per delivery trip based on duration and distance
                    let pricePerDeliveryTrip = price + (calcDuration * calcDuration * 0.1 / distanceInMiles);
  
                    // Set a minimum price of 30
                    if (pricePerDeliveryTrip < 30) {
                      pricePerDeliveryTrip = 30;
                    }
  
                    // Round up the delivery charges to the nearest whole number
                    deliveryCharges = Math.ceil(pricePerDeliveryTrip);
  
                    subscriber.next(deliveryCharges);
                    subscriber.complete();
                  });
                });
              } else {
                subscriber.next(deliveryCharges);
                subscriber.complete();
              }
            });
          }
        });
      });
    });
  }
  
  }
  
  
