<div class="content-area">
    <div class="content-area-content ">


        <div>

            <!-- «Start| Form: Issue» -->
            <h2 *ngIf="submitted==true">Thank you for your Enquiry, We will get back to you.</h2>
            
            <div *ngIf="submitted==false">
                <h2>Details</h2>
                <div class="form">
                    <div id="Main_ctl00_vsMessageBox" style="display:none;">

                    </div>
                    <span class="form-body">
                        <div class="item">
                            <span class="item-body">
                                <div class="label">
                                    <label for="Main_ctl00_vtlRadioButtons" id="Main_ctl00_vtlRadioButtonsLabel">
                                        Is it related to an order?
                                    </label>
                                </div>
                                <div class="input">
                                    <table id="Main_ctl00_vtlRadioButtons">
                                        <tbody>
                                            <tr>
                                                <td><input id="Main_ctl00_vtlRadioButtons_0" type="radio"
                                                        name="Root$Main$ctl00$vtlRadioButtons" value="Yes"
                                                        [(ngModel)]="orderChecked"><label class="pl5"
                                                        for="Main_ctl00_vtlRadioButtons_0">Yes</label></td>
                                            </tr>
                                            <tr>
                                                <td><input id="Main_ctl00_vtlRadioButtons_1" type="radio"
                                                        [(ngModel)]="orderChecked"
                                                        name="Root$Main$ctl00$vtlRadioButtons" value="No"><label
                                                        class="pl5" for="Main_ctl00_vtlRadioButtons_1">No</label></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </span>
                        </div>
                        <div class="item" *ngIf="orderChecked === 'Yes'">
                            <span class="item-body">
                                <div class="label">
                                    <label for="Main_ctl00_lstOrder" id="Main_ctl00_lblOrder">
                                        Order:
                                    </label>
                                </div>
                                <div class="input">
                                    <select name="Root$Main$ctl00$lstOrder" (change)="change()"
                                        [(ngModel)]="selectedOrderId" class="order-select" id="Main_ctl00_lstOrder">
                                        <option selected="selected" value="">
                                            ---Select---</option>
                                        <option *ngFor="let order of orders" [value]="order.orderId">
                                            {{order.jobReference}}</option>
                                    </select>
                                </div>
                            </span>
                        </div>
                        <div class="item">
                            <span class="item-body">
                                <div class="label">
                                    <label for="Main_ctl00_txtCustomerNote" id="Main_ctl00_lblCustomerNote">
                                        Customer note:
                                    </label>
                                </div>
                                <div class="input">
                                    <textarea [(ngModel)]="c_note" name="Root$Main$ctl00$txtCustomerNote" rows="5"
                                        cols="20" id="Main_ctl00_txtCustomerNote" class="textbox"></textarea>
                                </div>
                            </span>
                        </div>
                        <ng-container *ngIf="selectedOrder">
                            <!-- <div class="item">
                            <span class="item-body">
                                <div class="label">
                                    <label>
                                        Order Reference:
                                    </label>
                                </div>
                                <div class="input">
                                      <span>OPO123434</span>
                                </div>
                            </span>
                        </div> -->

                            <div class="item">
                                <span class="item-body">
                                    <div class="label">
                                        <label>
                                            Order Reference:
                                        </label>
                                    </div>
                                    <div class="input">
                                        <span>{{selectedOrder.jobReference}}</span>
                                    </div>
                                </span>
                            </div>

                            <div class="item">
                                <span class="item-body">
                                    <div class="label">
                                        <label>
                                            Total Invoice Amount:
                                        </label>
                                    </div>
                                    <div class="input">
                                        <span>{{selectedOrder.totalinvoiceamount}}</span>
                                    </div>
                                </span>
                            </div>
                        </ng-container>
                    </span>
                    <div class="buttons">
                        <input type="submit" class="cancel" (click)="goToQuotes()" name="Root$Main$ctl00$btnCancel"
                            value="Cancel">
                        <input (click)="postEnquiry()" type="submit" name="Root$Main$ctl00$btnSend" value="Send"
                            id="Main_ctl00_btnSend">
                    </div>
                </div>
            </div>
        </div>
        <div>
            <br>
            <br>
            <span class="view-body">
                <div class="item">
                    <div class="value">
                        <p>&nbsp;</p>

                        <p>&nbsp;</p>

                        <p><strong><span style="font-size:16px">Alternatively, you can always call us on: 0208 755
                                    4829</span></strong></p>
                    </div>
                </div>
            </span>
        </div>

    </div>
</div>