<div *ngIf="isLoading" class="loader-section">
    <app-loader></app-loader>
</div>
<div *ngIf="!isLoading">
    <div *ngIf="submitted">
        <app-confirm-quote-submission></app-confirm-quote-submission>
        <!-- <section class="banner-pages banner-bg-delivery" role="banner">
            <div class="container">
                <div class="col-md-10 col-md-offset-1">
                    <div class="banner-pages-text text-left">
                        <h1>You have successfully submitted your request for a quotation.........................</h1>
                        <h2>If this is your first time using our service, please check your emails as we have sent you a
                            password. You will need this to log into your new account, this is where you will find all
                            quotations you have requested (you can change this password to something more memorable
                            within
                            the "My Account" tab).
                            Our quoting time is quick and usually prepared within a few minutes. If you have used the
                            special order instructions box within the cutting list, your order will need to be reviewed
                            by
                            our production team before we can provide a quotation. As soon as the price is ready you
                            will
                            receive an email.
                            We trust our quotation will be competitive and look forward to working with you.
                            Many thanks
                        </h2>
                        <h2>From the Cutwrights Team</h2>
                    </div>
                </div>
            </div>
        </section> -->
    </div>
    <div *ngIf="!submitted" class="r-summary">
        <div *ngIf="!isLoggedIn" class="heading">
            <h3 style="margin-bottom: 20px">ALREADY REGISTERED WITH US?</h3>
        </div>
        <form *ngIf="!isLoggedIn">
            <div class="login-form" [formGroup]="loginForm">
                <div class="email">
                    <div class="login-labels email-label">
                        Email
                    </div>
                    <div class="email-inp">
                        <input style="width: 220px" class="login-inp" type="text" name="email" autocomplete="off" formControlName="email">
                    </div>
                </div>
                <div class="password">
                    <div class="login-labels pass-label">
                        Password
                    </div>
                    <div class="pass-inp">
                        <input style="width: 220px" class="login-inp" type="password" name="password" autocomplete="off"
                            formControlName="password">
                    </div>
                </div>
                <div class="login-button">
                    <div>
                        <button type="button" class="login-btn btn btn-primary" (click)="onSubmit()"
                            [disabled]="loginForm.invalid">
                            Login
                        </button>
                    </div>
                    <div class="fp">
                        <a class="fp-link" (click)="forgotPasswordPopUp()">Forgot your password?</a>
                    </div>
                </div>
            </div>
        </form>
        
        <div class="ord-s" [style.margin-top.px]="isLoggedIn ? 0 : 80">
            <section id="intro" class="section">
                <div class="container">
                    <div class="intro">
                        <span class="site-navigation">
                            Home» <a style="color: brown;" (click)="goBack()">Back</a> 
                        </span>
                    </div>
                </div>
            </section><!-- intro ends-->
            <form [formGroup]="cForm">
                <h3 style="margin-bottom: 20px;">ORDER SUMMARY</h3>
                <div class="ord-s-feilds">
                    <div style="width:90px" class="login-labels">
                        <label style="width:0%" for="parts">Parts</label>
                    </div>
                    <div class="">
                        <input formControlName="parts" id="parts" class="login-inp" type="text" name="parts"
                            autocomplete="off">
                    </div>
                    <div style="width:90px" class="login-labels">
                        <label style="width:0%" for="shaker-doors">Shaker doors</label>
                    </div>
                    <div class="">
                        <input class="login-inp" formControlName="shakerDoors" id="shaker-doors" type="text" name="shaker-doors" autocomplete="off">
                    </div>
                    <div style="width:90px" class="login-labels">
                        <label style="width:0%" for="drawers">Drawer Fronts</label>
                    </div>
                    <div class="">
                        <input class="login-inp" formControlName="drawers" type="text" id="drawers" name="drawers" autocomplete="off">
                    </div>
                    <div style="width:90px" class="login-labels">
                        <label style="width:0%" for="total-weight">
                            Totalweight
                            <span>
                                (excluding offcuts)
                            </span>
                        </label>
                    </div>
                    <div class="">
                        <input class="login-inp" formControlName="weight" type="text" id="total-weight"
                            name="total-weight" autocomplete="off">
                    </div>
                </div>
                <div style="margin: 30px 0px 80px 38px;">
                    <div class="s-labels">
                        <label class="nw" for="panels">
                            Would you like your panels labelled <span class="required"></span>
                        </label>
                    
                        <select [(ngModel)]="panelsValue" formControlName="panels" name="i-select" id="panels" (change)="cacheQuoteResult()">
                            <option [value]="null" disabled selected>Select an option</option>
                            <option [value]="true">Yes</option>
                            <option [value]="false">No</option>
                        </select>
                    
                        <div class="error-message">
                            <span class="text-danger" *ngIf="cForm.get('panels')?.value === null">
                                Needs Selection.
                            </span>
                        </div>
                    </div>
                    <div class="s-labels s-margin">
                        <label class="nw" for="offcuts">
                            Would you like your offcuts included <span class="required"></span>
                        </label>
                        <!-- <label for="i-select">:</label> -->
                        <select [(ngModel)]="offcutsValue" formControlName="offcuts" name="i-select" id="offcuts"  (change)="cacheQuoteResult()">
                            <option [value]="null" disabled selected>Select an option</option>
                            <option [value]="true">Yes</option>
                            <option [value]="false">No</option>
                        </select>
                        <div class="error-message">
                            <span class="text-danger" *ngIf="cForm.get('offcuts')?.value === null">
                                Needs Selection.
                            </span>
                        </div>
                    </div>
                </div>
                
            </form>
        </div>
        <div class="d-summary">
            <h3 style="margin-bottom: 20px;">DELIVERY / COLLECTION</h3>
            <form>
                <div class="login-form" [formGroup]="radioForm">
                    <div class="collection">
                        <div class="login-labels pass-label">
                            Collection
                        </div>
                        <div class="pass-inp">
                            <input class="login-inp" type="radio" value="collection" formControlName="choice"
                                (click)="clearOther('collection')" (change)="cacheQuoteResult()">
                        </div>
                    </div>
                    <div class="s-delivery">
                        <div class="login-labels delivery-label">
                            Delivery
                        </div>
                        <div class="delivery-inp">
                            <input class="login-inp" type="radio" value="delivery" formControlName="choice"
                                (click)="clearOther('delivery')" (change)="cacheQuoteResult()">
                        </div>
                    </div>
                    <div *ngIf="this.radioForm.get('choice')?.value == ('delivery')" class="address-form">
                        <form [formGroup]="addressForm" (ngSubmit)="onSubmit()">
                        <div *ngIf="address.length>0">
                            <label class="login-labels pass-label" for="address-select">Select Address:</label>
                            <select style="font-size: 13px; font-style: normal !important;" class="d-width login-inp ad-width"  (change)="updateSelectedAddress($event);cacheQuoteResult();checkPostCode()"
                                id="address-select" formControlName="addressSelect" [(ngModel)]="address[selectedIndex]">
                                <option *ngFor="let adr of address" [ngValue]="adr">{{ adr.address }}</option>

                                <!-- Add more options if needed -->
                            </select>
                        </div>
                            <br>

                            <label class="login-labels pass-label" for="address-textarea">Address:</label>
                            <textarea style="font-size: 13px; font-style: normal !important;" class="d-width login-inp address ad-width" id="address-textarea"
                                formControlName="address" rows="4" cols="50" (change)="cacheQuoteResult()"></textarea>
                            <br>

                            <label class="login-labels pass-label" for="city-input">City:</label>
                            <input class="d-width login-inp ad-width" type="text" id="city-input"
                                formControlName="city" (change)="cacheQuoteResult()">
                            <br>

                            <label class="login-labels pass-label" for="postcode-input">Post Code:</label>
                            <div>
                            <input class="d-width login-inp ad-width" type="text" id="postcode-input"
                                formControlName="postcode" (input)="checkPostCode()" (change)="cacheQuoteResult()">
                            <div *ngIf="checkError('postcode')" class="text-danger" style="margin-left: 165px;">
                                Invalid postcode
                            </div>    
                            </div>
                            <br>

                            <!-- <button type="button" (click)="confirmAddress()" [ngClass]="checkButton? 'checkButtonEnabled':'checkButtonDisabled'">CONFIRM DELIVERY ADDRESS</button> -->
                           
                        </form>
                    </div>

                </div>
            </form>
        </div>
        <div *ngIf="!isLoggedIn" class="d-summary" style="border-top: 10px solid #ebecee;">
            <h3 style="padding-bottom: 10px;">YOUR DETAILS</h3>
            <div class="login-form d-list">
                
                <form  [formGroup]="contactForm" style="margin:unset">                    
                    <div class="contact-name">
                        <div class="login-labels pass-label">contact name *</div>
                        <div class="pass-inp" style="padding-bottom: 20px;">
                            <input style="width: 220px;" class="d-width login-inp" type="text" formControlName="displayName"
                                autocomplete="off" />
                        </div>
                    </div>
                    <div class="email">
                        <div class="login-labels email-label">email *</div>
                        <div class="email-inp" style="padding-bottom: 20px;">
                            <input style="width: 220px;" class="d-width login-inp" type="text" formControlName="email" autocomplete="off" />
                        </div>
                    </div>
                    <div class="mobilenumber">
                        <div class="login-labels mobilenumber-label">mobile number *</div>
                        <div class="mobilenumber-inp" style="padding-bottom: 20px;">
                            <input style="width: 220px;" class="d-width login-inp" type="text" formControlName="mobile"
                                autocomplete="off" />
                        </div>
                    </div>
                    <div class="postcode">
                        <div class="login-labels postcode-label">postcode *</div>
                        <div formGroupName="billingAddress" class="postcodenumber-inp" style="padding-bottom: 20px;">
                            <input style="width: 220px;" class="d-width login-inp" type="text" formControlName="postcode"
                                autocomplete="off" (input)="checkBillingPostCode()" />
                                <span class="field-validation-invalid text-danger" style="margin-left: 165px;" *ngIf="postCodeValidity==false">
                                    <label></label>  POSTCODE IS INVALID.
                                </span>
                        </div>
                       
                    </div>
                    <!-- <div class="notifications">
                        <div class="notifications-inp">
                            <label class="login-labels" for="notifications">notifications</label>
                            <input class="login-inp" id="notifications" type="checkbox" formControlName="notifications"
                                autocomplete="off" />
                            <label class="c-labels" for="notifications">I allow Cutwrights to send Marketing
                                Information</label>
                        </div>
                    </div> -->
                    <div class="marketing">
                        <div class="marketing-inp">
                            <label class="login-labels" for="marketing">marketing</label>
                            <div>
                                <input class="login-inp" type="checkbox" id="marketing" formControlName="marketing"
                                    autocomplete="off" />
                                <label class="c-labels" for="marketing">I allow Cutwrights to send Marketing
                                    Information</label>
                            </div>
                        </div>
                    </div>
                </form>                
            </div>
        </div>
        <div class="login-button">
            <div>
                <button type="button" (click)="goBack()"
                 class="login-btn back-button btn btn-primary">
                 Back
                </button>
                <button type="button" (click)="getQuote()"
                    [disabled]="(this.cForm.value.panels==null|| this.cForm.value.offcuts==null || (this.radioForm.get('choice')?.value == ('delivery') && addressForm.invalid) || radioForm.invalid || ((!isLoggedIn) &&contactForm.invalid))"
                    class="login-btn quote-fetch btn btn-primary">
                    GET QUOTE
                </button>
            </div>
        </div>
    </div>
</div>
<ng-template #errorPopup>
      <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"><b>{{modalHeader}}</b></h5>
            </div>
            <div class="modal-body">
                <p style="color: black;">{{ modalMessage }}</p>
                </div>
            <div class="modal-footer">
                <button type="button" class="modal-button" (click)="bsModalRef.hide()">Ok</button>
            </div>
      </div>
  </ng-template>
  <ng-template #forgotPassword>
    <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title"><b>Reset Password</b></h5>
          </div>
          <div class="modal-body">
            <p>Enter your email address to get reset password link</p>
            <p>
                <label class="required" for="signupFirstName">E-mail:</label>
                <input autofocus="autofocus" id="autofocusField" [(ngModel)]="fpEmail" name="signupFirstName"
                    placeholder="Enter your Email address" size="30" type="text">
                <span class="field-validation-valid" data-valmsg-for="signupFirstName" data-valmsg-replace="true"
                    style="display:block; padding-left:25%;"></span>
            </p>
            <p>
    
                <label></label>
                <input type="submit" value="Send" (click)="handleFpPassword()" class="btn btn-large"> &nbsp; &nbsp; &nbsp;
                <input type="submit" value="Cancel" (click)="cancelReset()" class="btn btn-large">
            </p>
          </div>
    </div>
</ng-template>
  