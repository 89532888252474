<div class="main-body">
    <div class="content-area">
        <div class="content-area-content ">
            

            <div>
                <div class="shaker-doors-list">
                    <h3>
                        Doors and drawers
                    </h3>
                    <div  *ngIf="shakers.controls.length <= 0" class="shaker-door-empty">
                    <h4>There are no doors or drawers to display.</h4>
                    </div>
                    <div>

                        <div *ngIf="shakers.controls.length > 0" id="Main_ctl00_upList" isupdatepanel="true">
                            <div class="list">
                              <div>
                                <table class="grid" cellspacing="0" id="Main_ctl00_gridList" style="border-collapse:collapse;">
                                  <tbody>
                                    <tr class="header-row">
                                      <th scope="col"><a>Name</a></th>
                                      <th scope="col">Height</th>
                                      <th scope="col">Width</th>
                                      <th scope="col"><a>Quantity</a></th>
                                      <th scope="col"><a>Needs priming</a></th>
                                      <th scope="col">Number of midrails (door only)</th>
                                      <th scope="col">Hinge side (door only)</th>
                                      <th scope="col">Edit</th>
                                      <th scope="col">Delete</th>
                                    </tr>
                                    <tr *ngFor="let item of getShakersControls().controls; let i = index">
                                      <td>
                                        {{ item.get('name')?.value }}
                                      </td>
                                      <td>
                                        {{ item.get('height')?.value }}
                                      </td>
                                      <td>
                                        {{ item.get('width')?.value }}
                                      </td>
                                      <td>
                                        {{ item.get('quantity')?.value }}
                                      </td>
                                      <td>
                                        {{ item.get('primed')?.value ? 'Yes' : 'No' }}
                                      </td>
                                      <td>
                                        {{ item.get('totalnumberofmidrails')?.value }}
                                      </td>
                                      <td>
                                        {{item.get('type')?.value == 0 ?  getHingeSide(item) : " " }}
                                      </td>
                                      <td>
                                        <input type="image" name="Root$Main$ctl00$gridList$ctl02$btnEdit" id="Main_ctl00_gridList_btnEdit_{{ i }}"
                                          src="../../../assets/images/edit.png" alt="Edit" (click)="editItem(i)">
                                      </td>
                                      <td>
                                        <input type="image" name="Root$Main$ctl00$gridList$ctl02$btnDeleteCommand"
                                          id="Main_ctl00_gridList_btnDeleteCommand_{{ i }}"
                                          confirmtext="Are you sure you want to delete this Part?"
                                          src="../../../assets/images/delete.png" alt="Delete" (click)="deleteItem(i)">
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          
                            <input type="hidden" name="Root$Main$ctl00$SelectedListItemIdHolder"
                                id="Main_ctl00_SelectedListItemIdHolder">
                        </div>
                        <div class="buttons">
                            <input type="submit" (click)="openModal(template,eShakerType.ShakerDoor)"
                                name="Root$Main$ctl00$btnAddNewShakerDoor" value="Add new shaker door"
                                id="Main_ctl00_btnAddNewShakerDoor" class="button mt-10">
                            <input type="submit" (click)="openShakerModal(shakerDrawerModal,eShakerType.ShakerDrawer)" name="Root$Main$ctl00$btnAddNewShakerDrawerFront"
                                value="Add new shaker drawer front" id="Main_ctl00_btnAddNewShakerDrawerFront"
                                class="button mt-10">
                            <input type="submit" (click)="openPlainModal(plainDrawerModal,eShakerType.PlainDrawer)" name="Root$Main$ctl00$btnAddNewPlainDrawerFront"
                                value="Add new plain drawer front" id="Main_ctl00_btnAddNewPlainDrawerFront"
                                class="button mt-10">
                        </div>

                    </div>
                </div>
        </div>
    </div>
</div>

<ng-template #shakerDrawerModal>
    <app-add-shaker-drawer-modal [close]="closeModalRef" [iformGroup]="sformGroup" [shakers]="shakers" [edit]="edit" (onShakerModalClose)="closeShakerModal()"></app-add-shaker-drawer-modal>
</ng-template>

<ng-template #plainDrawerModal>
   <app-add-plain-drawer-modal [close]="closeModalRef" [iformGroup]="sformGroup" [shakers]="shakers" [edit]="edit"  (onPlainModalClose)="closePlainModal()"></app-add-plain-drawer-modal>
</ng-template>

<ng-template #template>
   <div class="door-modal-popup">
      <button class="close-btn" (click)="closePopup()">x</button>
      <form class="add-a-door" [formGroup]="this.sformGroup">
        <div class="row">
          <div class="col-sm-12">
            <h1>Add a door</h1>
          </div>
      
          <div class="col-sm-6">
            <div class="row mb-3">
              <div class="col-sm-6">
                Door name:
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="name" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6">
                Height:
              </div>
              <div class="col-sm-6">
                <input type="number" formControlName="height" value="30" (keydown)="preventDecimal($event)" />
                <div *ngIf="checkForMaxError('height',0)" class="text-danger mt-1" ngb-form-validation>
                  The height of the door must be 2420mm or less
               </div>
               <div *ngIf="checkForMinError('height',0)" class="text-danger mt-1" ngb-form-validation>
                The height of the door must be 250mm or more
             </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6">
                Width:
              </div>
              <div class="col-sm-6">
                <input type="number" formControlName="width" value="40" (keydown)="preventDecimal($event)"/>
                <div *ngIf="checkForMaxError('width',0)" class="text-danger mt-1" ngb-form-validation>
                  the width of the door must be 900mm or less.
               </div>
               <div *ngIf="checkForMinError('width',0)" class="text-danger mt-1" ngb-form-validation>
                The height of the door must be 250mm or more
             </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6">
                Quantity:
              </div>
              <div class="col-sm-6">
                <input type="number" pattern="\d+" formControlName="quantity" value="5" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6">
                Needs priming:
              </div>
              <div class="col-sm-6">
                <input type="checkbox" formControlName="primed" />
              </div>
            </div>
      
            <div class="row mb-3 mt-3">
              <div class="col-sm-12">
                <h1>Mid rails</h1>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6">
                Number of mid rails:
              </div>
              <div class="col-sm-6">
                <select formControlName="totalnumberofmidrails">
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select>
              </div>
            </div>
      
            <div formArrayName="midrails">
              <div *ngFor="let midrail of getMidrailControls(this.sformGroup); let i = index" class="row mb-3">
                <div class="col-sm-6">
                  Mid rail {{i+1}} Position:
                </div>
                <div [formGroupName]="i"  class="col-sm-6">
                  <input type="number" (focus)="setFocus('m'+i)" (focusout)="resetFocus('m'+i)" formControlName="position" />
                  <div *ngIf="checkForMinError('midrails',i)" class="text-danger mt-1" ngb-form-validation>
                    The midrail would overlap the frame of the door.
                 </div>
                 <div *ngIf="checkForMidRailError(i)" class="text-danger mt-1" ngb-form-validation>
                 The midrail would overlap the frame of the door.
               </div>
                </div>
              </div>
            </div>
      
            <div class="row mb-3 mt-3">
              <div class="col-sm-12">
                <h1>Hinges</h1>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6">
                Drill holes for 35mm cup hinges:
              </div>
              <div class="col-sm-6">
                <input class="checkbox-shaker" type="checkbox" formControlName="hingeholes35mm" />
              </div>
            </div>
            <div [hidden]="sformGroup.get('hingeholes35mm')?.value == false">
            <div class="row mb-3">
              <div class="col-sm-6">
                Use Blum Inserta hinges:
              </div>
              <div class="col-sm-6">
                <input class="checkbox-shaker" type="checkbox" formControlName="useinsertahinges"/>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6">
                Hinge side:
              </div>
              <div class="col-sm-6">
                <input class="checkbox-shaker" type="radio" [value]="0" formControlName="hingeside"  /> Left
                <input class="checkbox-shaker" type="radio" [value]="1" formControlName="hingeside"  /> Right
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6">
                Number of hinges:
              </div>
              <div class="col-sm-6">
                <select formControlName="totalnumberofhingeholes">
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                </select>
              </div>
            </div>
            <div formArrayName="hinges">
            <div *ngFor="let hinge of getHingeControls(this.sformGroup); let i = index" class="row mb-3">
              <div class="col-sm-6">
                Hinge {{i+1}} Y:
              </div>
              
              <div [formGroupName]="i"  class="col-sm-6">
                <input type="number" (focus)="setFocus('h'+i)" (focusout)="resetFocus('h'+i)" formControlName="y" />
                <div *ngIf="hinge.get('y')?.hasError('minGap')" class="text-danger mt-1" ngb-form-validation>
                  The minimum width should be mainatined
               </div>
              </div>
            </div>
           
          </div>
            <!-- <div class="row mb-3">
              <div class="col-sm-6">
                Hinge 2 Y:
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="hinges[1]" />
              </div>
            </div> -->
            <div class="row mb-3">
              <div class="col-sm-6">
                Drilling distance:
              </div>
              <div class="col-sm-6">
                <input type="text" formControlName="drillingdistance" />
                <div *ngIf="sformGroup.get('drillingdistance')?.hasError('min') || sformGroup.get('drillingdistance')?.hasError('max')" class="text-danger mt-1" ngb-form-validation>
                  The drilling distance must be between 3mm and 8mm.
               </div>
              </div>
            </div>
          </div>
          </div>
      
          <div class="col-sm-6">
            <img [src]="imageSrc" />
          </div>
      
          <div class="com-sm-12 text-center mt-4">
            
            <input type="button" value="Save and close" (click)="saveAndClose()"  [disabled]='sformGroup.invalid' [class]="sformGroup.invalid? 'get-shaker-btn-disabled': 'get-shaker-btn-enabled'"/>
          </div>
        </div>
      </form>
      
   </div>
</ng-template>