import { Component, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-hollow-board',
  templateUrl: './hollow-board.component.html',
  styleUrls: ['./hollow-board.component.scss']
})
export class HollowBoardComponent {
  bsModalRef!: BsModalRef;
  modalImageUrl! : string;
  constructor(private modalService: BsModalService,private metadataService: MetadataService){}
  ngOnInit(): void {
    this.metadataService.setCanonicalLink();
    this.metadataService.setTitle('default');
    this.metadataService.setDescription('default');
  }
  
  openImageModal(template: TemplateRef<any>, url: string): void {
    this.modalImageUrl = url;
    this.bsModalRef = this.modalService.show(template, { class: 'img-modal'});
  }

  closeModal(): void {
    this.bsModalRef.hide();
  }

}
