import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { CustomerListComponent } from './customer-list/customer-list.component';
import { CommonAdminModule } from './common/common.admin.module';
import { OrdersComponent } from './orders/orders.component';
import { ReceivedQuotesComponent } from './received-quotes/received-quotes.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { SharedModule } from '../shared/shared.module';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { MaterialsComponent } from './sub-menu/materials/materials.component';
import { OrderComponent } from './sub-menu/order/order.component';
import { DeliveryComponent } from './sub-menu/delivery/delivery.component';
import { ProdNotesComponent } from './sub-menu/prod-notes/prod-notes.component';
import { TargetsComponent } from './sub-menu/targets/targets.component';
import { LogComponent } from './sub-menu/log/log.component';
import { TransportComponent } from './transport/transport.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TransportTabNewComponent } from './transport-tab-new/transport-tab-new.component';
import { PrintJobLabelComponent } from './print-job-label/print-job-label.component';
import { PrintDoorComponent } from './print-door/print-door.component';
import { StockComponent } from './stock/stock.component';
import { OffCutComponent } from './off-cut/off-cut.component';
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  declarations: [
    AdminLayoutComponent,
    CustomerListComponent,
    OrdersComponent,
    ReceivedQuotesComponent,
    InvoicesComponent,
    OrderDetailsComponent,
    MaterialsComponent,
    OrderComponent,
    DeliveryComponent,
    ProdNotesComponent,
    TargetsComponent,
    LogComponent,
    TransportComponent,
    TransportTabNewComponent,
    PrintJobLabelComponent,
    PrintDoorComponent,
    StockComponent,
    OffCutComponent
  ],
  imports: [
    BrowserModule,
    TypeaheadModule.forRoot(),
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    CommonAdminModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
  ]
})
export class AdminModule { }
