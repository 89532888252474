import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { SubMenu } from '../models/admin.models';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EndpointApiService } from 'src/app/service/endpoint-api.service';
import { PaymentService } from 'src/app/service/payment-service';
import { Order, OrderLog, Quote } from 'src/app/models/cut-wrights.model';
import jsPDF from 'jspdf';
import "jspdf-autotable";
import { Shaker, eShakerTypeM } from 'src/app/models/shaker.model';
import { MetadataService } from 'src/app/service/metadata.service';
@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnDestroy, AfterViewInit {
  token = "";
  hideInvoice = false
  constructor(private metadataService: MetadataService,private apiService: EndpointApiService, private router: Router,private route: ActivatedRoute,private paymentService: PaymentService,private modalService: BsModalService, private formBuilder: FormBuilder){
    this.token = this.route.snapshot.paramMap.get('token') as string;
    this.orderId = this.route.snapshot.paramMap.get('orderId') as string;
    if(this.token != null && this.token != ""){
      var decodedParam = decodeURIComponent(this.token);
      localStorage.setItem("token_b",decodedParam)
      this.hideInvoice = true
    }
    this.apiService.getOrder(this.orderId,true).subscribe(data => {
      this.order = data as Order
      this.quoteId = this.order.quoteId
      this.apiService.getQuote(this.quoteId,true).subscribe(data => {
        this.quote = data as Quote
        this.apiService.getOrderLogs(this.quoteId,true).subscribe(data =>{
          this.orderLogs = data
          this.dataFetched = true
        })
      })

    })
  }
  ngOnInit(): void {
    this.metadataService.setTitle('default');
    this.metadataService.setDescription('default');
  }
  

  openPrintTab(route: string) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/admin/${route}/${this.orderId}`])
    );
  
    window.open(url, '_blank');
  }

ngAfterViewInit(): void {
  const footer = document.getElementById('website-footer');
   if(footer && this.token) {
    footer.style.position = 'relative';
    footer.style.top = 'calc(187vh)';
   }
}
  ngOnDestroy(): void {
    if(this.token != null && this.token != ""){
      localStorage.removeItem("token_b")
    }
  }
  orderLogs!:OrderLog
  order!:Order
  quoteId =""
  dataFetched = false
  quote!:Quote;
  subMenu: any = {
    isMaterials: true,
    isDelivery: false,
    isOrders: false,
    isProdNotes: false,
    isLog: false,
    isTargets: false
  } as SubMenu;
  activeTab: string = 'isMaterials';
  orderId = ""
  subMenuClick(activeTab: string): void {
    this.activeTab = activeTab;
    for (var key in this.subMenu) {
        if(key === activeTab) {
          this.subMenu[activeTab] = true;
        } else {
          this.subMenu[key] = false;
        }
    }
  }
  generatePDF() {
    const pdf = new (jsPDF as any)({
      orientation: 'portrait',
    });
    // Set the document title
    
    let body:any[] = []
    // this.quote$.subscribe(data =>{
      let data = this.quote
      let data_order = this.order
      pdf.setFontSize(30);
    pdf.text('Cutwrights Limited', 10, 10); 
    pdf.setFontSize(15);
    pdf.text(`Your cutting list reference: ${data_order.orderReference} (${data.jobId})`, 10, 20);
    pdf.text(`Your job name:${data.name}`, 10, 30);  
    pdf.text(`Weight:${parseFloat(data.weight).toFixed(2)} kg`, 10, 40);
      if(data.parts.length > 0){
        data.parts.forEach((part,index) =>{
          body.push([index+1,part.materialDescription,part.length,part.width,part.quantity,part.l1Description,part.l2Description,part.w1Description,part.w2Description,part.operations,part.partComment,part.primed?"Yes":"No"])
        })
        pdf.text('Panels', 10, 60);
        pdf.setFontSize(9);
        pdf.autoTable({
          head: [["Part", "Material", "L" , "W", "Qty","L1","L2","W1","W2","Operations","Name","Priming"]],
          body: body,
          startY:70,
          margin: { top: 20 },
          theme: 'grid',
          styles: {
            textColor: [0, 0, 0], // Set text color to black (RGB: 0, 0, 0)
            lineColor: [0, 0, 0], // Set border color to black (RGB: 0, 0, 0)
          },
          headStyles: {
            fillColor: [255, 255, 255], // Set header background color to white (RGB: 255, 255, 255)
            textColor: [0, 0, 0], // Set header text color to black (RGB: 0, 0, 0)
            lineWidth: 0.2, // Set header border width
            lineColor: [0, 0, 0], // Set header border color to black (RGB: 0, 0, 0)
          },
          columnStyles: {
            0: { columnWidth: 'auto' },
            1: { columnWidth: 'auto' },
            2: { columnWidth: 'auto' },
            3: { columnWidth: 'auto' },
            4: { columnWidth: 'auto' },
            5: { columnWidth: 'auto' },
            6: { columnWidth: 'auto' },
            7: { columnWidth: 'auto' },
            8: { columnWidth: 'auto' },
            9: { columnWidth: 'auto' },
            10: { columnWidth: 'auto' },
            11: { columnWidth: 'auto' }
          },
        });
      } else {
          pdf.text('There ar no parts to display',8, pdf.autoTable.previous.finalY + 10);
      }
      body = []
      data.shakers.forEach((part,index) =>{
        body.push([index+1,eShakerTypeM[part.type as keyof typeof eShakerTypeM],part.height,part.width,part.quantity,this.getLinkName(part),part.name,part.primed?"Yes":"No"])
      })
      if(data.shakers.length > 0){
        pdf.setFontSize(15);
        pdf.text('Shaker Doors',10, pdf.autoTable.previous.finalY + 10);
        pdf.setFontSize(9);
      pdf.autoTable({
        head: [["Part", "Material", "H" , "W", "Qty","Operations","Name","Needs Priming"]],
        body: body,
        startY: pdf.autoTable.previous.finalY + 20,
        margin: { top: 20 },
        theme: 'grid',
        styles: {
          textColor: [0, 0, 0], // Set text color to black (RGB: 0, 0, 0)
          lineColor: [0, 0, 0], // Set border color to black (RGB: 0, 0, 0)
        },
        headStyles: {
          fillColor: [255, 255, 255], // Set header background color to white (RGB: 255, 255, 255)
          textColor: [0, 0, 0], // Set header text color to black (RGB: 0, 0, 0)
          lineWidth: 0.2, // Set header border width
          lineColor: [0, 0, 0], // Set header border color to black (RGB: 0, 0, 0)
        },
        columnStyles: {
          0: { columnWidth: 'auto' },
          1: { columnWidth: 'auto' },
          2: { columnWidth: 'auto' },
          3: { columnWidth: 'auto' },
          4: { columnWidth: 'auto' },
          5: { columnWidth: 'auto' },
          6: { columnWidth: 'auto' },
          7: { columnWidth: 'auto' },
          8: { columnWidth: 'auto' },
          9: { columnWidth: 'auto' },
          10: { columnWidth: 'auto' },
          11: { columnWidth: 'auto' }
        },
      });
      }
      window.open(pdf.output('bloburl', { filename: 'new-file.pdf' }), '_blank');
      // });
    }
    getLinkName(shaker:Shaker){
      var l_name = ""
      if(shaker.totalnumberofhingeholes > 0){
        l_name += shaker.totalnumberofhingeholes +"Hinge hole"
      }
      if(shaker.totalnumberofmidrails > 0){
        l_name += shaker.totalnumberofmidrails +"Midrail"
      }
      return l_name;
    }
    navigateToInvoice(){
      window.open(this.router.createUrlTree(['admin/invoice-receipt',this.quote.quoteId,"inva"], { relativeTo: this.router.routerState.root }).toString(), '_blank');
    }
}
