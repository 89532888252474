<div class="melamine-board">
    <section class="banner-pages banner-bg-ourrange" role="banner">
        <div class="container">
            <div class="col-md-10 col-md-offset-1">
                <div class="banner-pages-text text-left">
                    <h1>Melamine board</h1>
                    <h3>We offer over 700 different boards and 1000 different edgings.</h3>
                </div><!-- banner text -->
            </div>
        </div>
    </section>

    <section id="intro" class="section">
        <div class="container">
            <div class="intro">
                <span class="site-navigation"><a [routerLink]="['/']">Home</a> » <a [routerLink]="['/board-range']">Board Range</a> » Melamine board</span>
            </div>
            
        </div>
    </section>

    <section id="products-selection" class="no-padding">
        <div class="container products-selection-table">
            <div class="row no-gutter">
                <div class="col-md-6">
                    <a (click)="openImageModal(imagePopup,'../../../assets/images/images-our-range-7@2x.jpg')" class="gallery-box" title="Melamine board" data-fancybox-group="thumb">
                        <img src="../../../assets/images/images-our-range-7@2x.jpg" alt="Melamine board" class="img-responsive">
                    </a>
                </div>
                <div class="col-md-6">
                    <div class="products-list-item-title" style="text-align: center">
                        <p>Melamine board</p>
                    </div>
                    <div class="products-list-item-properties" style="padding-bottom:14px">
                        <ul>
                            <li>
                                Melamine-faced boards have either a chipboard or MDF core with a melamine paper applied to each face. The quality and realism of the reproductions allow you to offer the look and feel of veneers without the premium price. The panel has a pre-finished, hard wearing surface, which is easy to maintain. With a huge choice of finishes available from leading manufacturers such as Egger, Kronospan and Xylocleaf, whatever the texture, effect or colour you require, we will have a product to suit. 
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="services-drilling-list2" class="products-selection services-edging-list no-padding">
        <div class="container" style="background-color: #ffffff">
            
            <div class="row no-gutter" style="padding-top: 30px; padding-bottom: 30px;text-align: center">
                <div class="col-md-4" style="margin-bottom: 10px">
                    <a class="btn-white btn-white-large" [routerLink]="['/choose-your-boards']">Choose your boards</a>
                </div>
                <div class="col-md-4" style="margin-bottom: 10px">
                    <a class="btn-white btn-white-large" [routerLink]="['/customer/get-quote']">Get a Quote</a>
                </div>
                <div class="col-md-4" style="margin-bottom: 10px">
                    <a id="" [routerLink]="['/contact-us']" class="btn-white btn-white-large">Contact us</a>
                </div>
            </div>
            <div class="row no-gutter" style="margin-bottom:1px; background-color:#c2c2c2">
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Melamine Faced Chipboard</h4>
                        </div>
                        <img src="../../../assets/images/img-melamine-faced-chipboard-1x@2.jpg" alt="Melamine Faced Chipboard" class="img-responsive">
                        <div class="services-list-desc">
                            <p>These boards have a chipboard core with a melamine paper applied to each face. Our largest proportion of available colours and decors is available as melamine faced chipboard, it can be used in many applications including furniture for kitchens, bedrooms, hotels and shopfitting. The main difference between this and the MDF core is weight, with the chipboard being marginally lighter due to the wood particles being less dense.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 services-list animated fadeIn">
                        <div class="services-list-title">
                            <h4>Melamine Faced MDF</h4>
                        </div>
                        <img src="../../../assets/images/img-melamine-faced-1x@2.jpg" alt="Melamine Faced MDF" class="img-responsive">
                        <div class="services-list-desc">
                            <p>Our Melamine Faced MDF has a standard MDF core with melamine paper bonded to each face, in some cases the melamine paper is treated further with a UV lacquer to create matt and gloss finishes. This material is a cost effective alternative to the use of solid wood with consistent finish and quality.</p>
                        </div>
                    </div>
            </div>
        </div>
    </section>
</div>
<ng-template #imagePopup>
    <form>
        <span class="close" style="opacity: 1.5;" (click)="closeModal()">&times;</span>
        <div class="row">
            <div class="col-md-12">
                    <img alt="image" class="img-responsive"
                    [src]="modalImageUrl" alt="image" class="img-responsive">
            </div>
            </div>
        </form>
</ng-template>