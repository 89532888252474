import { Component } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {
  isLoggedIn:boolean=false;
  constructor(private metadataService: MetadataService){
    const token = localStorage.getItem('token');
    if (token) {
      this.isLoggedIn = true;
    }
    
  }
  ngOnInit(): void {
    this.metadataService.setCanonicalLink();
    this.metadataService.setTitle('Contact Cutwrights - 0208 755 4829 - info@cutwrights.com');
    this.metadataService.setDescription('If you have any questions please contact us at info@cutwrights.com or call us on 0208 755 4829.');
  }


}
