       <div class="main-body">
              <form class="mb-5 pdl-60" style="padding: 0px 40px 0px 40px;">
                     <h1>Terms and Conditions</h1>
                     <div class="scrollable-box">
       <p>Important Information:</p>
       <p><strong>Please make sure your billing address is correct in the 'My Account' tab before placing your order.</strong></p>
       <p><span style="color: #ff0000;"><strong>If your following payment results in an error,&nbsp;</strong><strong>please check with your card provider that you have 3D Security in place.&nbsp;</strong><strong>This is a fairly recent, additional layer of security and will be compulsory for all online and in store purchases by the 1 September 2021.</strong></span></p>
       <p><strong><span style="color: #ff0000;">Please do not attempt payment more than once if you receive an error message.</span>&nbsp;</strong>(Please note: we do not accept payment by American Express).</p>
       <p>Terms and Conditions</p>
       <p><strong>1. &nbsp;&nbsp;&nbsp;Definition</strong></p>
       <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; In these conditions, unless the context requires otherwise:</p>
       <p>1.1 &ldquo;Buyer&rdquo; means the person who buys or agrees to buy goods from the Company.</p>
       <p>1.2 &ldquo;Company&rdquo; means Cutwrights Ltd.</p>
       <p>1.3 &ldquo;Conditions&rdquo; means the terms and conditions of sale set out in this document and any special terms &nbsp;&nbsp;&nbsp;&nbsp;</p>
       <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; and conditions agreed in writing by the Company.</p>
       <p>1.4 &ldquo;Collection date&rdquo; means the date specified by the Company when the goods are to be collected.</p>
       <p>1.5 &ldquo;Delivery date&rdquo; means the date specified by the Company when the goods are to be delivered.</p>
       <p>1.6 &ldquo;Goods&rdquo; means the material or service which the Buyer agrees to buy from the Company.</p>
       <p><strong>2</strong>.&nbsp;&nbsp;&nbsp;&nbsp;<strong>Effect of Conditions</strong></p>
       <p>2.1 These Conditions shall apply to all contracts for the sale of Goods by the Company to the Buyer to &nbsp;&nbsp;</p>
       <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the exclusion of all other terms and conditions including any terms or conditions which the Buyer</p>
       <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; purport to apply under any purchase order, confirmation of order or similar document.</p>
       <p>2.2 All orders for Goods shall be deemed to be an offer by the Buyer to pursuant to these Conditions.</p>
       <p>2.3 Acceptance of delivery/ or collection of the Goods shall be deemed conclusive evidence of the</p>
       <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; buyer&rsquo;s acceptance of these Conditions.</p>
       <p>2.4 Any variation to these Conditions (including any special terms and conditions agreed between the</p>
       <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; parties) shall be inapplicable unless agreed in writing by the Company.</p>
       <p><strong>3</strong>.&nbsp;&nbsp;<strong>&nbsp;Price</strong></p>
       <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Unless previously withdrawn the price of Goods shall be the Company&rsquo;s quoted price which shall be</p>
       <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; valid for 30 days from the date on which it is submitted to the buyer unless such period is extended</p>
       <p>&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;in writing by the company.</p>
       <p>3.2 The Company&rsquo;s quoted price is based on information supplied by the Buyer.</p>
       <p><strong>4</strong>.&nbsp;&nbsp;&nbsp;<strong>Acceptance of Goods</strong></p>
       <p>4.1 The Company requires the Buyer to confirm by email in writing acceptance of the quotation, making</p>
       <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; reference to the latest revision number.</p>
       <p>4.2 The Company will not be held responsible for cutting the wrong revision of job if specified by the &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
       <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Buyer.</p>
       <p><strong>5.</strong>&nbsp;&nbsp;&nbsp;&nbsp;<strong>Payment</strong></p>
       <p>5.1 The Company must receive full payment for the Goods when the Buyer accepts the quotation and</p>
       <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Wishes to proceed.</p>
       <p><strong>6</strong>.&nbsp;&nbsp;&nbsp;&nbsp;<strong>Delivery of Goods</strong></p>
       <p>6.1 The Company requires the Buyer to check their Goods upon delivery. Any discrepancies must be</p>
       <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; reported by telephone or email to the Company within 48 hours. The Company would then &nbsp;</p>
       <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; investigate the problem and where necessary issue replacement Goods. If replacement goods are</p>
       <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; re-delivered the Company would need to collect from the Buyer the incorrect Goods.</p>
       <p><strong>7</strong>.&nbsp;&nbsp;&nbsp;<strong>Collection of Goods</strong></p>
       <p>7.1 The Company requires the Buyer to check their Goods on collection. Once the Buyer has left the</p>
       <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; premises the Company will not be held responsible for any discrepancies or damages to any of the</p>
       <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;Goods.</p>
       <p><strong>8. &nbsp;&nbsp;Liability</strong></p>
       <p>&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;The Company will not be liable to the Buyer for any loss of profits, administrative inconvenience,</p>
       <p>&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;disappointment, indirect or consequential loss of damage arising out of any problem in relation to the</p>
       <p>&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;Goods and we shall have no liability to pay any money to you by the way of compensation other than</p>
       <p>&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;any refund that we make under these conditions. This does not affect your statutory rights as a</p>
       <p>&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;consumer.</p>
       <p><strong>9</strong>. &nbsp;&nbsp;<strong>Cancellation Of Goods</strong></p>
       <p>9.1 Due to the Bespoke nature of the product once an item has been paid for it is at the Companies discretion if</p>
       <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the Goods can be cancelled.</p>
       <p><strong>10</strong>.&nbsp;<strong>Miscellaneous</strong></p>
       <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; If any term or provision in these Conditions shall in whole or in part be held to any extent to be illegal or</p>
       <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; unenforceable under any enactment or rule of law that term or provision or part shall to that extent to be</p>
       <p>&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;deemed not to form part of these Conditions and the enforceability of the remainder of these conditions shall &nbsp;</p>
       <p>&nbsp;&nbsp;&nbsp;&nbsp; not be affected.</p>
       <p><strong>11</strong>.<strong>Proper Law of Contract</strong></p>
       <p>&nbsp;&nbsp;&nbsp;&nbsp; This contract is subject to the Law of England and Wales.</p> 
       </div>
       <div>
                            <input type="checkbox" class="checkbox-trms" id="acceptCheckbox" (change)="onCheckboxChange()"
                                   [checked]="isChecked" required>
                            <label for="acceptCheckbox" class="terms-text w-100">I have read and accept the Terms and
                                   Conditions</label>
                     </div>
                     <br>

                     <div class="actions-btns">
                            <button type="button" class="btn btn-primary btns-action" id="nextButton" (click)="onNext()"
                                   [disabled]="(!isChecked) || nextClicked">Next</button>
                     </div>
              </form>
       </div>