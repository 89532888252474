import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { interval, Subscription } from 'rxjs';
import { Quote, eStatusM, eStatusO } from 'src/app/models/cut-wrights.model';
import { User } from 'src/app/models/user.model';
import { EndpointApiService } from 'src/app/service/endpoint-api.service';
import { GoogleMapsService } from 'src/app/service/google-api.service';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-my-quote',
  templateUrl: './my-quote.component.html',
  styleUrls: ['./my-quote.component.scss']
})
export class MyQuoteComponent implements OnInit {
  @ViewChild('deletePopup', { static: true })
  deletePopup!: TemplateRef<any>;
  bsModalRef!: BsModalRef;
  modalMessage: string="";
  modalHeader: string="";
  quotations :Quote[] = [];
  date_test = new Date().toISOString();
  isLoading: boolean = true;
  userId = ""
  user!:User;
  idForDelete:any;
  private quotesSubscription!: Subscription;
  selectedQuotes:string[] = [];
  constructor(private metadataService: MetadataService,private apiService: EndpointApiService, private router: Router, private googleMapsService:GoogleMapsService,private route: ActivatedRoute,private modalService: BsModalService){
    this.userId = this.route.snapshot.paramMap.get('userId') as string;
  }
  ngOnInit(){
    this.metadataService.setTitle('default');
    this.metadataService.setDescription('default');
    const userString = localStorage.getItem('user');
    this.user = JSON.parse(userString as string)
    this.isLoading = true;
    this.getQuotes()
    const minuteInterval = 1 * 60 * 1000; // 1 minute in milliseconds
    // Create an observable that emits values every minute
    const source = interval(minuteInterval);
    this.quotesSubscription =source.subscribe(() => {
      this.getQuotes()
    })
  }
  ngOnDestroy() {
    if (this.quotesSubscription) {
      this.quotesSubscription.unsubscribe();
    }
  }
  getQuotes(){
    this.apiService.getCustomerQuotations(this.userId).subscribe(data=>{
      this.isLoading = false;
      this.quotations = data as Quote[];
    });
  }
  getEDays(quote:Quote){
  const creationDate = new Date(quote.createdOn as string) //TODO add creation date
  const expirationLimit = 30; // Expiration limit in days
  const currentDate = new Date();
  const differenceMs = currentDate.getTime() - (creationDate).getTime();
  const differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
  const daysRemaining = expirationLimit - differenceDays;
  return daysRemaining;
  }
  getStatus(quote:Quote){
  const statusNum:number = quote.status;
  return eStatusM[statusNum as keyof typeof eStatusM];
  }

  getDepot(quote:Quote){
    if(!quote.collection){
      var depotNumber = quote.depot;
      if(depotNumber==0){
      return'LHR - Hanworth'
      }else{
        return'BOH - Poole'
      }
    }
    else return '-'
  }


  navigateToQuoteDetails(quote:Quote) {
    const navigationExtras = {
      state: {
        data:{
          quote: quote
        }

      }
    };
    this.router.navigate(['/customer/quote-details',quote.quoteId], navigationExtras);
  }
  goToQuote(){
    this.router.navigate(['/customer/get-quote'])
  }
  edit(quoteId:string){
    this.router.navigate([`/customer/get-quote`,quoteId])
  }
  addUser(){
    this.googleMapsService.calculateDeliveryCharges('Delivery','500049').subscribe(data => {
    })
  }
  updateMergeList(event:any,quoteId:string){
    if(event.target.checked){
      this.selectedQuotes.push(quoteId)
    } else{
      this.selectedQuotes = this.selectedQuotes.filter(quote=> quote !=quoteId)
    }
  }
  mergeQuotes(){
    if(this.selectedQuotes.length > 0){
      this.apiService.mergeQuotes({
        "quoteId":this.selectedQuotes
      }).subscribe((data:any) => {
        this.router.navigate([`/customer/get-quote`,data.quoteId])
      })
    }
  }
  openModal(template: TemplateRef<any>, header:string, message:string): void {
    this.modalHeader=header;
    this.modalMessage = message;
    this.bsModalRef = this.modalService.show(template, { class: 'modal-dialog-centered' });
  }

  delQuotePopUp(quoteId:string){
    this.idForDelete=quoteId;
    this.openModal(this.deletePopup, 'Delete Quote.','Are you sure you want delete the quote?');
  }
  delQuote(quoteId:string){
    
    this.apiService.excludeQuote(quoteId).subscribe(data => {
      this.getQuotes();
      this.bsModalRef.hide();
      delete this.idForDelete
    })
  }
  cancelDelete(){
    delete this.idForDelete;
    this.bsModalRef.hide();
  }
  reQuote(quote:Quote){
    delete quote.Id
    quote.status = 1;
    this.apiService.putCutWrightData(quote).subscribe(data => {
      this.getQuotes()
    })
  }
  getAmount(totalinvoiceamount:string){
    return parseFloat(totalinvoiceamount).toFixed(2);
  }
}
