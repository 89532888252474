<div class="cutting-container">
    <section class="banner-pages banner-bg-cutting" role="banner">
        <div class="container">
            <div class="col-md-10 col-md-offset-1">
                <div class="banner-pages-text text-left">
                    <h1 style="font-size: 45px;">CNC Cutting Service</h1>

                    <h2 style="font-size: 30px;"><em>"Cut down your lead times not your boards"</em></h2>
                </div><!-- banner text -->
            </div>
        </div>
    </section>

    <section id="intro" class="section">
        <div class="container">
            <div class="intro">
                <span class="site-navigation"><a [routerLink]="['/']" >Home</a> » Services » Cutting</span>
                <p>Our HOLZMA HPP380 and HPP300 Production Beam Saws are the latest in automated cutting machinery
                    -&nbsp;they are the heartbeat of the business and the first stage of the process so it has to be
                    precise.<br>
                    At Cutwrights, we offer a unique reliable cutting service;&nbsp;working from your instructions we
                    can cut an extensive collection of sheet materials, to size, on time and fast.</p>
            </div>
            <div style="margin-top: 30px">

                <a [routerLink]="['/customer/get-quote']" class="btn-white btn-white-large">Get a Quote</a>
            </div>
        </div>
    </section>

    <div class="page-container">
        <section id="cutting" class="services no-padding">
            <div class="container">
                <div class="row no-gutter">
                    <div class="col-lg-6 col-md-6 col-sm-12 services-box-left text-left">
                        <p>Our CNC controlled saws are capable of cutting sheet sizes up to 3.8m long x 3.8m wide and
                            sheet thicknesses up to 80mm with a sizing accuracy of 0.1mm +/-</p>

                        <p>The saws have 2 blades, 1 specifically designed to cut the underside of the panel to ensure
                            that your panels are cut chip free.</p>

                        <p>These blades must remain sharp at all times to ensure a clean crisp cut , therefore our saws
                            are preset to warn the operator when the saw blades have reached the end of their optimum
                            cutting performance. When this warning is reached the saw operators change the blades and
                            continue with the cutting process again. The Air flotation table ensures that sensitive
                            materials can be moved through the machine without risk of damage. Panels can also be
                            labelled for your convenience making identifying your component parts easier.</p>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 services-box-right">
                        <img src="../../../assets/images/images-holzma-hpp-300.jpg"
                            alt="HOLZMA HPP300 Production Beam Saw" class="img-responsive">
                    </div>
                </div>
            </div>
            <div class="container ">
                <div class="row no-gutter">
                    <div class="col-lg-6 col-md-6 col-sm-12 services-box-left text-left">
                        <img src="../../../assets/images/images-holzma-hpp-380.jpg"
                            alt="HOLZMA HPP380 Production Beam Saw" class="img-responsive">
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 services-box-right">
                        <img src="../../../assets/images/images-cutting-workers-color.jpg" alt=""
                            class="img-responsive">
                    </div>
                </div>
            </div>
        </section><!-- cutting -->
    </div>
</div>