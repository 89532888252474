import { Component, Input } from '@angular/core';
import { Order, Quote } from 'src/app/models/cut-wrights.model';
import { EndpointApiService } from 'src/app/service/endpoint-api.service';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.scss']
})
export class DeliveryComponent {
  @Input() order!:Order
  @Input() quote!:Quote

  constructor(private metadataService: MetadataService,private apiService: EndpointApiService){}
  
  ngOnInit(): void {
    this.metadataService.setTitle('default');
    this.metadataService.setDescription('default');
  }
  truncateValue(value: string | number): string {
    if (typeof value === 'string') {
      return this.apiService.truncateDecimal(value);
    }
  
    if (typeof value === 'number') {
      return this.apiService.truncateDecimal(value.toString());
    }
    throw new Error('Invalid input type. Please provide a string or a number.');
  }
  
}
