<div class="targets-container">
    <h2>Targets</h2>
    <div id="Main_ctl00_upList" isupdatepanel="true">
          <div class="list">
             <div>
            <table class="grid" cellspacing="0" currentsort="" id="Main_ctl00_gridList" style="border-collapse:collapse;">
                <tbody><tr class="header-row">
                    <th scope="col">Stage</th><th scope="col"><a>Target</a></th><th scope="col">Actual</th>
                </tr><tr>
                    <td>
                         Cutting
                     </td>
                      <td>
                        {{targetTime}}
                      </td>
                      <td>
                        {{actualTime}}
                      </td>
                </tr><tr class="alt">
                    <td>
                         Assembly
                      </td><td>
                         00:00
                      </td><td>
                         
                      </td>
                </tr><tr>
                    <td>
                         Edging
                      </td><td>
                         00:00
                      </td><td>
                         
                      </td>
                </tr><tr class="alt">
                    <td>
                         Drilling
                      </td><td>
                         00:00
                      </td><td>
                         
                      </td>
                </tr><tr>
                    <td>
                         Priming
                      </td><td>
                         00:00
                      </td><td>
                         
                      </td>
                </tr>
            </tbody></table>
        </div>
          </div>
    </div>
       </div>
