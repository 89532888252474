import { Component, EventEmitter, Output } from '@angular/core';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-acknowledge',
  templateUrl: './acknowledge.component.html',
  styleUrls: ['./acknowledge.component.scss']
})
export class AcknowledgeComponent {
  isChecked = false;
  nextClicked = false
  constructor(private metadataService: MetadataService) { }

  ngOnInit(): void {
    this.metadataService.setTitle('default');
    this.metadataService.setDescription('default');
  }
  @Output() next = new EventEmitter<any>();

  onNext() {
    this.next.emit();
    this.nextClicked = true
  }
  onCheckboxChange() {
    this.isChecked = !this.isChecked;
  }
}
