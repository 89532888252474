<div>
    <h1>Credit limit details</h1>
    <div>

        <div class="form">
            <div id="Main_ctl00_vsMessageBox" style="display:none;">

            </div>
            <span class="form-body">
                <div class="item">
                    <span class="item-body">
                        <div class="label">
                            <label for="Main_ctl00_lstType" id="Main_ctl00_lblType">
                                Type:
                            </label>
                        </div>
                        <div class="input">
                            <select [(ngModel)]="paymentType" name="Root$Main$ctl00$lstType" class="type" id="Main_ctl00_lstType">
                                <option selected="true">---Select---</option>
                                <option  [value]="0">Pay in advance
                                </option>
                                <option [value]="1">Use credit</option>
                            </select>
                            <span id="Main_ctl00_rqvType" style="visibility:hidden;">*</span>
                        </div>
                    </span>
                </div>
                <div class="item">
                    <span class="item-body">
                        <div class="label">
                            <label for="Main_ctl00_txtLimit" id="Main_ctl00_lblLimit">
                                Limit:
                            </label>
                        </div>
                        <div class="input">
                            <input name="Root$Main$ctl00$txtLimit" [(ngModel)]="limit" type="text" [value]="user.creditlimit" id="Main_ctl00_txtLimit"
                                class="number-box textbox">
                            <span id="Main_ctl00_rqvLimit" style="visibility:hidden;">*</span>
                            <span id="Main_ctl00_rnvLimit" style="visibility:hidden;">*</span>
                        </div>
                    </span>
                </div>
                <div class="item">
                    <span class="item-body">
                        <div class="label">
                            <label for="Main_ctl00_txtTurnOver" id="Main_ctl00_lblTurnOver">
                                Turn over:
                            </label>
                        </div>
                        <div class="input">
                            <input name="Root$Main$ctl00$txtTurnOver" [(ngModel)]="turnOver" type="text" [value]="user.turnover" class="number-box textbox">
                            <span id="Main_ctl00_rnvTurnOver" style="visibility:hidden;">*</span>
                        </div>

                    </span>
                </div>
                <div class="item">
                    <span class="item-body">
                        <div class="label">
                            <label for="Main_ctl00_rdonlyBalance" id="Main_ctl00_rdonlyBalanceLabel">
                                Outstanding balance
                            </label>
                        </div>
                        <div class="input">
                            £{{outstandingbalance}}
                        </div>
                    </span>
                </div>
            </span>
            <div class="buttons">
                <input type="submit" name="Root$Main$ctl00$btnSave" (click)="saveChanges()" value="Save" id="Main_ctl00_btnSave"
                    confirmtext="Are you sure you wish to save?">
            </div>
        </div>

    </div>
</div>