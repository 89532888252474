import { Component, Input } from '@angular/core';
import { Quote } from 'src/app/models/cut-wrights.model';
import { MetadataService } from 'src/app/service/metadata.service';

@Component({
  selector: 'app-materials',
  templateUrl: './materials.component.html',
  styleUrls: ['./materials.component.scss']
})
export class MaterialsComponent {
  @Input() quote!:Quote;
  constructor(private metadataService: MetadataService){}
  ngOnInit(): void {
    this.metadataService.setTitle('default');
    this.metadataService.setDescription('default');
  }
}
