import { NgModule } from "@angular/core";
import { HomeComponent } from './home/home.component';
import { BoardRangeComponent } from './board-range/board-range.component';
import { FaqComponent } from './faq/faq.component';
import { GetQuoteComponent } from './get-quote/get-quote.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { QuoteResultComponent } from './quote-result/quote-result.component';
import { RouterModule } from "@angular/router";
import { MyQuoteComponent } from "./my-quote/my-quote.component";
import { QuoteDetailsComponent } from "./quote-details/quote-details.component";
import { SharedModule } from "../shared/shared.module";
import { ContactUsComponent } from './contact-us/contact-us.component';
import { OrderListComponent } from './order-list/order-list.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { PaymentService } from "../service/payment-service";
import { ShakerDoorComponent } from "./shaker-door/shaker-door.component";
import { ConfirmQuoteSubmissionComponent } from "./confirm-quote-submission/confirm-quote-submission.component";
import { BsModalService } from "ngx-bootstrap/modal";
import { DeliveryFormComponent } from './delivery-form/delivery-form.component';
import { PersonalInfoFormComponent } from './personal-info-form/personal-info-form.component';
import { PaymentComponent } from './payment/payment.component';
import { AcknowledgeComponent } from './acknowledge/acknowledge.component';
import { PaymentConfirmComponent } from './payment-confirm/payment-confirm.component';
import { InvoiceReceiptComponent } from './invoice-receipt/invoice-receipt.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DeliveryAddressPopUpComponent } from './delivery-address-pop-up/delivery-address-pop-up.component';
import { AddShakerDrawerModalComponent } from './add-shaker-drawer-modal/add-shaker-drawer-modal.component';
import { AddPlainDrawerModalComponent } from './add-plain-drawer-modal/add-plain-drawer-modal.component';
import { CustomerLayoutComponent } from './customer-layout/customer-layout.component';
import { DeliveryComponent } from './delivery/delivery.component';
import { CuttingComponent } from './cutting/cutting.component';
import { EdgingComponent } from './edging/edging.component';
import { DrillingComponent } from './drilling/drilling.component';
import { OthersComponent } from './others/others.component';
import { ChooseYourBoardsComponent } from './choose-your-boards/choose-your-boards.component';
import { ChooseYourBoardsDetailsComponent } from './choose-your-boards-details/choose-your-boards-details.component';
import { EnquiryComponent } from './enquiry/enquiry.component';
import { DecorativeBoardComponent } from './decorative-board/decorative-board.component';
import { HollowBoardComponent } from './hollow-board/hollow-board.component';
import { MdfBoardComponent } from './mdf-board/mdf-board.component';
import { MelamineBoardComponent } from './melamine-board/melamine-board.component';
import { PlywoodBoardComponent } from './plywood-board/plywood-board.component';
import { VeneeredBoardComponent } from './veneered-board/veneered-board.component';
import { SubMenuModule } from "./my-account/sub-menu/sub-menu.module";
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ShowRoomComponent } from './show-room/show-room.component';
import { BlogComponent } from "./blog/blog.component";
import { BlogOfferComponent } from "./blog-offer/blog-offer.component";
import { BlogMessageComponent } from "./blog-message/blog-message.component";
import { BlogRebrandingComponent } from "./blog-rebranding/blog-rebranding.component";
import { BlogReceptionComponent } from "./blog-reception/blog-reception.component";
import { NgxPaginationModule } from "ngx-pagination";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
@NgModule({
    declarations: [
      PageNotFoundComponent,
       HomeComponent,
       BoardRangeComponent,
       FaqComponent,
       GetQuoteComponent,
       QuoteResultComponent,
       MyQuoteComponent,
       QuoteDetailsComponent,
       ContactUsComponent,
       OrderListComponent,
       InvoicesComponent,
       MyAccountComponent,
       ShakerDoorComponent,
       ConfirmQuoteSubmissionComponent,
       DeliveryFormComponent,
       PersonalInfoFormComponent,
       PaymentComponent,
       AcknowledgeComponent,
       PaymentConfirmComponent,
       InvoiceReceiptComponent,
       DeliveryAddressPopUpComponent,
       AddShakerDrawerModalComponent,
       AddPlainDrawerModalComponent,
       CustomerLayoutComponent,
       DeliveryComponent,
       CuttingComponent,
       EdgingComponent,
       DrillingComponent,
       OthersComponent,
       ChooseYourBoardsComponent,
       ChooseYourBoardsDetailsComponent,
       EnquiryComponent,
       DecorativeBoardComponent,
       HollowBoardComponent,
       MdfBoardComponent,
       MelamineBoardComponent,
       PlywoodBoardComponent,
       VeneeredBoardComponent,
       ShowRoomComponent,
       BlogComponent,
       BlogOfferComponent,
       BlogMessageComponent,
       BlogRebrandingComponent,
       BlogReceptionComponent
  ],
    imports: [
      BrowserModule,
      FormsModule,
      CommonModule,
      ReactiveFormsModule,
      RouterModule,
      TypeaheadModule.forRoot(),
      SharedModule,
      ModalModule,
      SubMenuModule,
      BsDropdownModule,
      NgxPaginationModule,
    ],
    exports: [ 
    ],
    providers: [BsModalService, PaymentService]
  })
  export class CustomerModule { }